/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSetting,
    StreemApiSettingFromJSON,
    StreemApiSettingFromJSONTyped,
    StreemApiSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListCompanySettingsResponse
 */
export interface StreemApiListCompanySettingsResponse {
    /**
     * 
     * @type {Array<StreemApiSetting>}
     * @memberof StreemApiListCompanySettingsResponse
     */
    settings?: Array<StreemApiSetting>;
}

export function StreemApiListCompanySettingsResponseFromJSON(json: any): StreemApiListCompanySettingsResponse {
    return StreemApiListCompanySettingsResponseFromJSONTyped(json, false);
}

export function StreemApiListCompanySettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListCompanySettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': !exists(json, 'settings') ? undefined : ((json['settings'] as Array<any>).map(StreemApiSettingFromJSON)),
    };
}

export function StreemApiListCompanySettingsResponseToJSON(value?: StreemApiListCompanySettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': value.settings === undefined ? undefined : ((value.settings as Array<any>).map(StreemApiSettingToJSON)),
    };
}


