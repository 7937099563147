/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image2dItem,
    Image2dItemFromJSON,
    Image2dItemFromJSONTyped,
    Image2dItemToJSON,
    Model3dItem,
    Model3dItemFromJSON,
    Model3dItemFromJSONTyped,
    Model3dItemToJSON,
    Movie2dItem,
    Movie2dItemFromJSON,
    Movie2dItemFromJSONTyped,
    Movie2dItemToJSON,
    PickListItem,
    PickListItemFromJSON,
    PickListItemFromJSONTyped,
    PickListItemToJSON,
    TextItem,
    TextItemFromJSON,
    TextItemFromJSONTyped,
    TextItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface CatalogItem
 */
export interface CatalogItem {
    /**
     * 
     * @type {string}
     * @memberof CatalogItem
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogItem
     */
    billboard?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogItem
     */
    onCamera?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogItem
     */
    onGlass?: boolean;
    /**
     * 
     * @type {Model3dItem}
     * @memberof CatalogItem
     */
    model3d?: Model3dItem;
    /**
     * 
     * @type {Image2dItem}
     * @memberof CatalogItem
     */
    image2d?: Image2dItem;
    /**
     * 
     * @type {TextItem}
     * @memberof CatalogItem
     */
    text?: TextItem;
    /**
     * 
     * @type {object}
     * @memberof CatalogItem
     */
    arrow?: object;
    /**
     * 
     * @type {Movie2dItem}
     * @memberof CatalogItem
     */
    movie2d?: Movie2dItem;
    /**
     * 
     * @type {PickListItem}
     * @memberof CatalogItem
     */
    pickList?: PickListItem;
    /**
     * 
     * @type {object}
     * @memberof CatalogItem
     */
    measure?: object;
}

export function CatalogItemFromJSON(json: any): CatalogItem {
    return CatalogItemFromJSONTyped(json, false);
}

export function CatalogItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'billboard': !exists(json, 'billboard') ? undefined : json['billboard'],
        'onCamera': !exists(json, 'on_camera') ? undefined : json['on_camera'],
        'onGlass': !exists(json, 'on_glass') ? undefined : json['on_glass'],
        'model3d': !exists(json, 'model3d') ? undefined : Model3dItemFromJSON(json['model3d']),
        'image2d': !exists(json, 'image2d') ? undefined : Image2dItemFromJSON(json['image2d']),
        'text': !exists(json, 'text') ? undefined : TextItemFromJSON(json['text']),
        'arrow': !exists(json, 'arrow') ? undefined : json['arrow'],
        'movie2d': !exists(json, 'movie2d') ? undefined : Movie2dItemFromJSON(json['movie2d']),
        'pickList': !exists(json, 'pick_list') ? undefined : PickListItemFromJSON(json['pick_list']),
        'measure': !exists(json, 'measure') ? undefined : json['measure'],
    };
}

export function CatalogItemToJSON(value?: CatalogItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'billboard': value.billboard,
        'on_camera': value.onCamera,
        'on_glass': value.onGlass,
        'model3d': Model3dItemToJSON(value.model3d),
        'image2d': Image2dItemToJSON(value.image2d),
        'text': TextItemToJSON(value.text),
        'arrow': value.arrow,
        'movie2d': Movie2dItemToJSON(value.movie2d),
        'pick_list': PickListItemToJSON(value.pickList),
        'measure': value.measure,
    };
}


