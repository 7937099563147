/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Destination,
    DestinationFromJSON,
    DestinationFromJSONTyped,
    DestinationToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransitionLabeledDestination
 */
export interface TransitionLabeledDestination {
    /**
     * 
     * @type {string}
     * @memberof TransitionLabeledDestination
     */
    text?: string;
    /**
     * 
     * @type {Destination}
     * @memberof TransitionLabeledDestination
     */
    destination?: Destination;
}

export function TransitionLabeledDestinationFromJSON(json: any): TransitionLabeledDestination {
    return TransitionLabeledDestinationFromJSONTyped(json, false);
}

export function TransitionLabeledDestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransitionLabeledDestination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'destination': !exists(json, 'destination') ? undefined : DestinationFromJSON(json['destination']),
    };
}

export function TransitionLabeledDestinationToJSON(value?: TransitionLabeledDestination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'destination': DestinationToJSON(value.destination),
    };
}


