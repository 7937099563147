/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventToolArrow
 */
export interface StreemApiAnalyticEventToolArrow {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventToolArrow
     */
    elementId?: string;
}

export function StreemApiAnalyticEventToolArrowFromJSON(json: any): StreemApiAnalyticEventToolArrow {
    return StreemApiAnalyticEventToolArrowFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventToolArrowFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventToolArrow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elementId': !exists(json, 'element_id') ? undefined : json['element_id'],
    };
}

export function StreemApiAnalyticEventToolArrowToJSON(value?: StreemApiAnalyticEventToolArrow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element_id': value.elementId,
    };
}


