import { action, computed, observable, runInAction } from 'mobx';
import { CompanyPublicSettings } from '../util/types';
import { getCompanyCodeFromUrl } from '@streem/toolbox';
import { fetchCompanyPublicSettings } from '../util/fetch_company_settings';

export class ConfigStore {
    @observable private _publicSettings: CompanyPublicSettings;
    @observable public initialized = false;

    @computed
    public get publicSettings(): CompanyPublicSettings | undefined {
        return this._publicSettings;
    }

    @action
    public async initialize() {
        const companyCode = getCompanyCodeFromUrl();
        const settings: CompanyPublicSettings = await fetchCompanyPublicSettings(companyCode);
        runInAction(() => {
            this._publicSettings = settings;
            this.initialized = true;
        });
    }
}
