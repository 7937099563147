/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPreflight,
    StreemApiPreflightFromJSON,
    StreemApiPreflightFromJSONTyped,
    StreemApiPreflightToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiStreemRoomCredentials
 */
export interface StreemApiStreemRoomCredentials {
    /**
     * 
     * @type {string}
     * @memberof StreemApiStreemRoomCredentials
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiStreemRoomCredentials
     */
    token?: string;
    /**
     * 
     * @type {StreemApiPreflight}
     * @memberof StreemApiStreemRoomCredentials
     */
    preflight?: StreemApiPreflight;
    /**
     * 
     * @type {string}
     * @memberof StreemApiStreemRoomCredentials
     */
    dataKey?: string;
}

export function StreemApiStreemRoomCredentialsFromJSON(json: any): StreemApiStreemRoomCredentials {
    return StreemApiStreemRoomCredentialsFromJSONTyped(json, false);
}

export function StreemApiStreemRoomCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiStreemRoomCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'preflight': !exists(json, 'preflight') ? undefined : StreemApiPreflightFromJSON(json['preflight']),
        'dataKey': !exists(json, 'data_key') ? undefined : json['data_key'],
    };
}

export function StreemApiStreemRoomCredentialsToJSON(value?: StreemApiStreemRoomCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'token': value.token,
        'preflight': StreemApiPreflightToJSON(value.preflight),
        'data_key': value.dataKey,
    };
}


