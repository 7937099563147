/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LightAmbient,
    LightAmbientFromJSON,
    LightAmbientFromJSONTyped,
    LightAmbientToJSON,
    LightAndroidLightProbe,
    LightAndroidLightProbeFromJSON,
    LightAndroidLightProbeFromJSONTyped,
    LightAndroidLightProbeToJSON,
    LightDirectionalLight,
    LightDirectionalLightFromJSON,
    LightDirectionalLightFromJSONTyped,
    LightDirectionalLightToJSON,
    LightPointLight,
    LightPointLightFromJSON,
    LightPointLightFromJSONTyped,
    LightPointLightToJSON,
} from './';

/**
 * 
 * @export
 * @interface Light
 */
export interface Light {
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    id?: string;
    /**
     * 
     * @type {LightAmbient}
     * @memberof Light
     */
    ambient?: LightAmbient;
    /**
     * 
     * @type {LightPointLight}
     * @memberof Light
     */
    pointLight?: LightPointLight;
    /**
     * 
     * @type {LightAndroidLightProbe}
     * @memberof Light
     */
    androidLightProbe?: LightAndroidLightProbe;
    /**
     * 
     * @type {LightDirectionalLight}
     * @memberof Light
     */
    directionalLight?: LightDirectionalLight;
}

export function LightFromJSON(json: any): Light {
    return LightFromJSONTyped(json, false);
}

export function LightFromJSONTyped(json: any, ignoreDiscriminator: boolean): Light {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ambient': !exists(json, 'ambient') ? undefined : LightAmbientFromJSON(json['ambient']),
        'pointLight': !exists(json, 'point_light') ? undefined : LightPointLightFromJSON(json['point_light']),
        'androidLightProbe': !exists(json, 'android_light_probe') ? undefined : LightAndroidLightProbeFromJSON(json['android_light_probe']),
        'directionalLight': !exists(json, 'directional_light') ? undefined : LightDirectionalLightFromJSON(json['directional_light']),
    };
}

export function LightToJSON(value?: Light | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ambient': LightAmbientToJSON(value.ambient),
        'point_light': LightPointLightToJSON(value.pointLight),
        'android_light_probe': LightAndroidLightProbeToJSON(value.androidLightProbe),
        'directional_light': LightDirectionalLightToJSON(value.directionalLight),
    };
}


