/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemDeviceState
 */
export interface WallItemDeviceState {
    /**
     * 
     * @type {Date}
     * @memberof WallItemDeviceState
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemDeviceState
     */
    audioPermissionsGranted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WallItemDeviceState
     */
    connectionMethod?: WallItemDeviceStateConnectionMethod;
    /**
     * 
     * @type {number}
     * @memberof WallItemDeviceState
     */
    batteryLevel?: number;
}

export function WallItemDeviceStateFromJSON(json: any): WallItemDeviceState {
    return WallItemDeviceStateFromJSONTyped(json, false);
}

export function WallItemDeviceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemDeviceState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'audioPermissionsGranted': !exists(json, 'audio_permissions_granted') ? undefined : json['audio_permissions_granted'],
        'connectionMethod': !exists(json, 'connection_method') ? undefined : json['connection_method'],
        'batteryLevel': !exists(json, 'battery_level') ? undefined : json['battery_level'],
    };
}

export function WallItemDeviceStateToJSON(value?: WallItemDeviceState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'audio_permissions_granted': value.audioPermissionsGranted,
        'connection_method': value.connectionMethod,
        'battery_level': value.batteryLevel,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemDeviceStateConnectionMethod {
    WIFI = 'CONNECTION_METHOD_WIFI',
    CELLULAR = 'CONNECTION_METHOD_CELLULAR',
    UNKNOWN = 'CONNECTION_METHOD_UNKNOWN',
    INVALID = 'CONNECTION_METHOD_INVALID'
}


