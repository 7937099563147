/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUpdateCompanyIdentityProviderRequest
 */
export interface StreemApiUpdateCompanyIdentityProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateCompanyIdentityProviderRequest
     */
    provider?: StreemApiUpdateCompanyIdentityProviderRequestProvider;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateCompanyIdentityProviderRequest
     */
    groupingId?: string;
}

export function StreemApiUpdateCompanyIdentityProviderRequestFromJSON(json: any): StreemApiUpdateCompanyIdentityProviderRequest {
    return StreemApiUpdateCompanyIdentityProviderRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateCompanyIdentityProviderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateCompanyIdentityProviderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'groupingId': !exists(json, 'grouping_id') ? undefined : json['grouping_id'],
    };
}

export function StreemApiUpdateCompanyIdentityProviderRequestToJSON(value?: StreemApiUpdateCompanyIdentityProviderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'grouping_id': value.groupingId,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiUpdateCompanyIdentityProviderRequestProvider {
    COGNITO = 'IDENTITY_PROVIDER_COGNITO',
    FUSIONAUTH = 'IDENTITY_PROVIDER_FUSION_AUTH',
    INVALID = 'IDENTITY_PROVIDER_INVALID'
}


