/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSetting
 */
export interface StreemApiSetting {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSetting
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSetting
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSetting
     */
    type?: StreemApiSettingType;
}

export function StreemApiSettingFromJSON(json: any): StreemApiSetting {
    return StreemApiSettingFromJSONTyped(json, false);
}

export function StreemApiSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function StreemApiSettingToJSON(value?: StreemApiSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiSettingType {
    STRING = 'TYPE_STRING',
    BOOLEAN = 'TYPE_BOOLEAN',
    INTEGER = 'TYPE_INTEGER',
    DOUBLE = 'TYPE_DOUBLE',
    INVALID = 'TYPE_INVALID'
}


