/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactCounts
 */
export interface StreemApiArtifactCounts {
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    gpsPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    mesh?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    recording?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    recordingTrack?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    streemshot?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    transcription?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    sceneshot?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    layoutEstimation?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    texturedMesh?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    bookmark?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactCounts
     */
    roomOutcomeReport?: number;
}

export function StreemApiArtifactCountsFromJSON(json: any): StreemApiArtifactCounts {
    return StreemApiArtifactCountsFromJSONTyped(json, false);
}

export function StreemApiArtifactCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactCounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gpsPosition': !exists(json, 'gps_position') ? undefined : json['gps_position'],
        'mesh': !exists(json, 'mesh') ? undefined : json['mesh'],
        'recording': !exists(json, 'recording') ? undefined : json['recording'],
        'recordingTrack': !exists(json, 'recording_track') ? undefined : json['recording_track'],
        'streemshot': !exists(json, 'streemshot') ? undefined : json['streemshot'],
        'transcription': !exists(json, 'transcription') ? undefined : json['transcription'],
        'sceneshot': !exists(json, 'sceneshot') ? undefined : json['sceneshot'],
        'layoutEstimation': !exists(json, 'layout_estimation') ? undefined : json['layout_estimation'],
        'texturedMesh': !exists(json, 'textured_mesh') ? undefined : json['textured_mesh'],
        'bookmark': !exists(json, 'bookmark') ? undefined : json['bookmark'],
        'roomOutcomeReport': !exists(json, 'room_outcome_report') ? undefined : json['room_outcome_report'],
    };
}

export function StreemApiArtifactCountsToJSON(value?: StreemApiArtifactCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gps_position': value.gpsPosition,
        'mesh': value.mesh,
        'recording': value.recording,
        'recording_track': value.recordingTrack,
        'streemshot': value.streemshot,
        'transcription': value.transcription,
        'sceneshot': value.sceneshot,
        'layout_estimation': value.layoutEstimation,
        'textured_mesh': value.texturedMesh,
        'bookmark': value.bookmark,
        'room_outcome_report': value.roomOutcomeReport,
    };
}


