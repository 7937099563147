/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransitionElementTap,
    TransitionElementTapFromJSON,
    TransitionElementTapFromJSONTyped,
    TransitionElementTapToJSON,
    TransitionLabeledDestination,
    TransitionLabeledDestinationFromJSON,
    TransitionLabeledDestinationFromJSONTyped,
    TransitionLabeledDestinationToJSON,
    TransitionYesNo,
    TransitionYesNoFromJSON,
    TransitionYesNoFromJSONTyped,
    TransitionYesNoToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transition
 */
export interface Transition {
    /**
     * 
     * @type {TransitionLabeledDestination}
     * @memberof Transition
     */
    next?: TransitionLabeledDestination;
    /**
     * 
     * @type {TransitionYesNo}
     * @memberof Transition
     */
    yesNo?: TransitionYesNo;
    /**
     * 
     * @type {TransitionElementTap}
     * @memberof Transition
     */
    elementTap?: TransitionElementTap;
}

export function TransitionFromJSON(json: any): Transition {
    return TransitionFromJSONTyped(json, false);
}

export function TransitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next': !exists(json, 'next') ? undefined : TransitionLabeledDestinationFromJSON(json['next']),
        'yesNo': !exists(json, 'yes_no') ? undefined : TransitionYesNoFromJSON(json['yes_no']),
        'elementTap': !exists(json, 'element_tap') ? undefined : TransitionElementTapFromJSON(json['element_tap']),
    };
}

export function TransitionToJSON(value?: Transition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next': TransitionLabeledDestinationToJSON(value.next),
        'yes_no': TransitionYesNoToJSON(value.yesNo),
        'element_tap': TransitionElementTapToJSON(value.elementTap),
    };
}


