/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUpdateCompanyRequest
 */
export interface StreemApiUpdateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateCompanyRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiUpdateCompanyRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateCompanyRequest
     */
    parentCompanySid?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiUpdateCompanyRequest
     */
    subCompanyLimit?: number;
}

export function StreemApiUpdateCompanyRequestFromJSON(json: any): StreemApiUpdateCompanyRequest {
    return StreemApiUpdateCompanyRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'parentCompanySid': !exists(json, 'parent_company_sid') ? undefined : json['parent_company_sid'],
        'subCompanyLimit': !exists(json, 'sub_company_limit') ? undefined : json['sub_company_limit'],
    };
}

export function StreemApiUpdateCompanyRequestToJSON(value?: StreemApiUpdateCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'active': value.active,
        'parent_company_sid': value.parentCompanySid,
        'sub_company_limit': value.subCompanyLimit,
    };
}


