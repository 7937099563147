const LOCAL_STORAGE_PREFERRED_AUDIO_OUTPUT_KEY = 'preferredAudioOutputId';
const LOCAL_STORAGE_PREFERRED_AUDIO_INPUT_KEY = 'preferredMicrophoneId';
const LOCAL_STORAGE_PREFERRED_VIDEO_INPUT_KEY = 'preferredCameraId';

export const getPreferredAudioOutputId = (): string | null =>
    localStorage.getItem(LOCAL_STORAGE_PREFERRED_AUDIO_OUTPUT_KEY);
export const getPreferredAudioInputId = (): string | null =>
    localStorage.getItem(LOCAL_STORAGE_PREFERRED_AUDIO_INPUT_KEY);
export const getPreferredVideoInputId = (): string | null =>
    localStorage.getItem(LOCAL_STORAGE_PREFERRED_VIDEO_INPUT_KEY);

export const setPreferredAudioOutputId = (deviceId: string): void =>
    localStorage.setItem(LOCAL_STORAGE_PREFERRED_AUDIO_OUTPUT_KEY, deviceId);
export const setPreferredAudioInputId = (deviceId: string): void =>
    localStorage.setItem(LOCAL_STORAGE_PREFERRED_AUDIO_INPUT_KEY, deviceId);
export const setPreferredVideoInputId = (deviceId: string): void =>
    localStorage.setItem(LOCAL_STORAGE_PREFERRED_VIDEO_INPUT_KEY, deviceId);

export const removePreferredAudioOutputId = (): void =>
    localStorage.removeItem(LOCAL_STORAGE_PREFERRED_AUDIO_OUTPUT_KEY);
export const removePreferredAudioInputId = (): void =>
    localStorage.removeItem(LOCAL_STORAGE_PREFERRED_AUDIO_INPUT_KEY);
export const removePreferredVideoInputId = (): void =>
    localStorage.removeItem(LOCAL_STORAGE_PREFERRED_VIDEO_INPUT_KEY);
