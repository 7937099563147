/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAuthIdentityCompanyApiKey,
    StreemApiAuthIdentityCompanyApiKeyFromJSON,
    StreemApiAuthIdentityCompanyApiKeyFromJSONTyped,
    StreemApiAuthIdentityCompanyApiKeyToJSON,
    StreemApiAuthIdentityRoomShareToken,
    StreemApiAuthIdentityRoomShareTokenFromJSON,
    StreemApiAuthIdentityRoomShareTokenFromJSONTyped,
    StreemApiAuthIdentityRoomShareTokenToJSON,
    StreemApiAuthIdentityUser,
    StreemApiAuthIdentityUserFromJSON,
    StreemApiAuthIdentityUserFromJSONTyped,
    StreemApiAuthIdentityUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAuthIdentity
 */
export interface StreemApiAuthIdentity {
    /**
     * 
     * @type {StreemApiAuthIdentityUser}
     * @memberof StreemApiAuthIdentity
     */
    user?: StreemApiAuthIdentityUser;
    /**
     * 
     * @type {StreemApiAuthIdentityCompanyApiKey}
     * @memberof StreemApiAuthIdentity
     */
    companyApiKey?: StreemApiAuthIdentityCompanyApiKey;
    /**
     * 
     * @type {StreemApiAuthIdentityRoomShareToken}
     * @memberof StreemApiAuthIdentity
     */
    roomShareToken?: StreemApiAuthIdentityRoomShareToken;
}

export function StreemApiAuthIdentityFromJSON(json: any): StreemApiAuthIdentity {
    return StreemApiAuthIdentityFromJSONTyped(json, false);
}

export function StreemApiAuthIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAuthIdentity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : StreemApiAuthIdentityUserFromJSON(json['user']),
        'companyApiKey': !exists(json, 'company_api_key') ? undefined : StreemApiAuthIdentityCompanyApiKeyFromJSON(json['company_api_key']),
        'roomShareToken': !exists(json, 'room_share_token') ? undefined : StreemApiAuthIdentityRoomShareTokenFromJSON(json['room_share_token']),
    };
}

export function StreemApiAuthIdentityToJSON(value?: StreemApiAuthIdentity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': StreemApiAuthIdentityUserToJSON(value.user),
        'company_api_key': StreemApiAuthIdentityCompanyApiKeyToJSON(value.companyApiKey),
        'room_share_token': StreemApiAuthIdentityRoomShareTokenToJSON(value.roomShareToken),
    };
}


