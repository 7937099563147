/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactGpsPosition
 */
export interface StreemApiArtifactGpsPosition {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactGpsPosition
     */
    sourcedFromUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactGpsPosition
     */
    sourcedFromDeviceSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactGpsPosition
     */
    clientCreatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactGpsPosition
     */
    latitudeDegrees?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactGpsPosition
     */
    longitudeDegrees?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactGpsPosition
     */
    accuracyMeters?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactGpsPosition
     */
    formattedAddress?: string;
}

export function StreemApiArtifactGpsPositionFromJSON(json: any): StreemApiArtifactGpsPosition {
    return StreemApiArtifactGpsPositionFromJSONTyped(json, false);
}

export function StreemApiArtifactGpsPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactGpsPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourcedFromUserSid': !exists(json, 'sourced_from_user_sid') ? undefined : json['sourced_from_user_sid'],
        'sourcedFromDeviceSid': !exists(json, 'sourced_from_device_sid') ? undefined : json['sourced_from_device_sid'],
        'clientCreatedAt': !exists(json, 'client_created_at') ? undefined : (new Date(json['client_created_at'])),
        'latitudeDegrees': !exists(json, 'latitude_degrees') ? undefined : json['latitude_degrees'],
        'longitudeDegrees': !exists(json, 'longitude_degrees') ? undefined : json['longitude_degrees'],
        'accuracyMeters': !exists(json, 'accuracy_meters') ? undefined : json['accuracy_meters'],
        'formattedAddress': !exists(json, 'formatted_address') ? undefined : json['formatted_address'],
    };
}

export function StreemApiArtifactGpsPositionToJSON(value?: StreemApiArtifactGpsPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourced_from_user_sid': value.sourcedFromUserSid,
        'sourced_from_device_sid': value.sourcedFromDeviceSid,
        'client_created_at': value.clientCreatedAt === undefined ? undefined : (value.clientCreatedAt.toISOString()),
        'latitude_degrees': value.latitudeDegrees,
        'longitude_degrees': value.longitudeDegrees,
        'accuracy_meters': value.accuracyMeters,
        'formatted_address': value.formattedAddress,
    };
}


