/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroupReservation,
    StreemApiGroupReservationFromJSON,
    StreemApiGroupReservationFromJSONTyped,
    StreemApiGroupReservationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateGroupReservationResponse
 */
export interface StreemApiCreateGroupReservationResponse {
    /**
     * 
     * @type {StreemApiGroupReservation}
     * @memberof StreemApiCreateGroupReservationResponse
     */
    groupReservation?: StreemApiGroupReservation;
}

export function StreemApiCreateGroupReservationResponseFromJSON(json: any): StreemApiCreateGroupReservationResponse {
    return StreemApiCreateGroupReservationResponseFromJSONTyped(json, false);
}

export function StreemApiCreateGroupReservationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateGroupReservationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupReservation': !exists(json, 'group_reservation') ? undefined : StreemApiGroupReservationFromJSON(json['group_reservation']),
    };
}

export function StreemApiCreateGroupReservationResponseToJSON(value?: StreemApiCreateGroupReservationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_reservation': StreemApiGroupReservationToJSON(value.groupReservation),
    };
}


