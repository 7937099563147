export function formatInviteCode(code: string): string {
    const unformatted = code.replace(/\D/g, '');
    let formatted = '';
    if (unformatted.length <= 3) {
        formatted = unformatted;
    }
    if (unformatted.length > 3 && unformatted.length <= 6) {
        formatted = `${unformatted.substring(0, 3)}-${unformatted.substring(3)}`;
    }
    if (unformatted.length > 6) {
        formatted = `${unformatted.substring(0, 3)}-${unformatted.substring(
            3,
            6,
        )}-${unformatted.substring(6)}`;
    }
    return formatted;
}
