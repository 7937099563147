/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetGpsPositionResponse
 */
export interface StreemApiGetGpsPositionResponse {
    /**
     * 
     * @type {StreemApiArtifact}
     * @memberof StreemApiGetGpsPositionResponse
     */
    gpsPositionArtifact?: StreemApiArtifact;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGetGpsPositionResponse
     */
    roomSid?: string;
}

export function StreemApiGetGpsPositionResponseFromJSON(json: any): StreemApiGetGpsPositionResponse {
    return StreemApiGetGpsPositionResponseFromJSONTyped(json, false);
}

export function StreemApiGetGpsPositionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetGpsPositionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gpsPositionArtifact': !exists(json, 'gps_position_artifact') ? undefined : StreemApiArtifactFromJSON(json['gps_position_artifact']),
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
    };
}

export function StreemApiGetGpsPositionResponseToJSON(value?: StreemApiGetGpsPositionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gps_position_artifact': StreemApiArtifactToJSON(value.gpsPositionArtifact),
        'room_sid': value.roomSid,
    };
}


