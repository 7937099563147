/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemArtifactCompletionRequest
 */
export interface WallItemArtifactCompletionRequest {
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifactCompletionRequest
     */
    artifactId?: string;
}

export function WallItemArtifactCompletionRequestFromJSON(json: any): WallItemArtifactCompletionRequest {
    return WallItemArtifactCompletionRequestFromJSONTyped(json, false);
}

export function WallItemArtifactCompletionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArtifactCompletionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactId': !exists(json, 'artifact_id') ? undefined : json['artifact_id'],
    };
}

export function WallItemArtifactCompletionRequestToJSON(value?: WallItemArtifactCompletionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifact_id': value.artifactId,
    };
}


