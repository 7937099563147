/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArElementArrow,
    StreemApiArElementArrowFromJSON,
    StreemApiArElementArrowFromJSONTyped,
    StreemApiArElementArrowToJSON,
    StreemApiArElementMeasurement,
    StreemApiArElementMeasurementFromJSON,
    StreemApiArElementMeasurementFromJSONTyped,
    StreemApiArElementMeasurementToJSON,
    StreemApiArElementModel3d,
    StreemApiArElementModel3dFromJSON,
    StreemApiArElementModel3dFromJSONTyped,
    StreemApiArElementModel3dToJSON,
    StreemApiArElementText,
    StreemApiArElementTextFromJSON,
    StreemApiArElementTextFromJSONTyped,
    StreemApiArElementTextToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArElement
 */
export interface StreemApiArElement {
    /**
     * 
     * @type {StreemApiArElementArrow}
     * @memberof StreemApiArElement
     */
    arrow?: StreemApiArElementArrow;
    /**
     * 
     * @type {StreemApiArElementMeasurement}
     * @memberof StreemApiArElement
     */
    measurement?: StreemApiArElementMeasurement;
    /**
     * 
     * @type {StreemApiArElementModel3d}
     * @memberof StreemApiArElement
     */
    model3d?: StreemApiArElementModel3d;
    /**
     * 
     * @type {StreemApiArElementText}
     * @memberof StreemApiArElement
     */
    text?: StreemApiArElementText;
}

export function StreemApiArElementFromJSON(json: any): StreemApiArElement {
    return StreemApiArElementFromJSONTyped(json, false);
}

export function StreemApiArElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arrow': !exists(json, 'arrow') ? undefined : StreemApiArElementArrowFromJSON(json['arrow']),
        'measurement': !exists(json, 'measurement') ? undefined : StreemApiArElementMeasurementFromJSON(json['measurement']),
        'model3d': !exists(json, 'model3d') ? undefined : StreemApiArElementModel3dFromJSON(json['model3d']),
        'text': !exists(json, 'text') ? undefined : StreemApiArElementTextFromJSON(json['text']),
    };
}

export function StreemApiArElementToJSON(value?: StreemApiArElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arrow': StreemApiArElementArrowToJSON(value.arrow),
        'measurement': StreemApiArElementMeasurementToJSON(value.measurement),
        'model3d': StreemApiArElementModel3dToJSON(value.model3d),
        'text': StreemApiArElementTextToJSON(value.text),
    };
}


