import { useEffect, useState } from 'react';
import Analytics, { RegionCode } from '@streem/analytics';
import { StreemAPI } from '@streem/api';
import StreemAuth from '@streem/auth';
import StreemSDK from '@streem/sdk-core';
import { GlobalStyles, Row, ThemeProvider, styled } from '@streem/ui-react';
import { config } from './config';
import appLogger from './util/app_logger';
import { ErrorBoundary } from './components/error_boundary';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CustomerViewPage } from './pages/customer_view_page';
import { useInitializeGlobalStores, GlobalStoreProvider } from './hooks/use_global_context';
import { InviteValidator } from './components/invite_validator';
import { ViewValidator } from './components/view_validator';
import { AnalyticsListener } from './components/analytics_listener';
import { useGetCompanyRegistration } from './hooks/use_get_company_registration';
import { addRegionCodeToDatadogRumSession } from './util/datadog';
import CV2GlobalStyles from './customer_view_global_styles';
import { InviteCodePage } from './pages/invite_code_page';
import { TokenHandler } from './components/token_handler';
import { configureReactLogger } from '@streem/sdk-react';
export function App(): JSX.Element {
    const { regionalApiUrl, error, regionCode, wallUrl } = useGetCompanyRegistration();
    const globalStores = useInitializeGlobalStores();
    const [configuredPackages, setConfiguredPackages] = useState(false);

    useEffect(() => {
        if (!window.navigator.cookieEnabled || !regionalApiUrl || !regionCode || !wallUrl) return;

        appLogger.setContextValue('regionCode', regionCode);
        addRegionCodeToDatadogRumSession(regionCode);

        StreemAuth.configure({
            regionalApiUrl,
            globalApiUrl: config.urls.globalApi,
            appId: config.appId,
            logger: appLogger.extend('auth'),
        });

        StreemAPI.configure({
            apiUrl: regionalApiUrl,
            appId: config.appId,
            tokenFetcher: StreemAuth.tokenFetcher,
        });

        Analytics.configure({
            appId: config.appId,
            regionCode: regionCode as RegionCode,
        });

        StreemSDK.configure({
            appId: config.appId,
            appVersion: config.buildTag,
            logger: appLogger.extend('sdk-core'),
            googleMapsKey: config.googleMapsKey,
            wallUrl,
        });

        configureReactLogger(appLogger.extend('sdk-react'));

        setConfiguredPackages(true);
        // This needs to happen after StreemAPI is configured
        globalStores.configStore.initialize();
    }, [regionalApiUrl, regionCode, globalStores.configStore, wallUrl]);

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <GlobalStyles />
                <CV2GlobalStyles />
                <GlobalStoreProvider value={globalStores}>
                    <Container>
                        <BrowserRouter>
                            <ViewValidator>
                                <Switch>
                                    <Route path="/" exact>
                                        <InviteCodePage
                                            configuredPackages={configuredPackages}
                                            getCompanyRegistrationError={error}
                                        />
                                    </Route>
                                    <Route path="/i/:inviteId">
                                        <InviteValidator
                                            configuredPackages={configuredPackages}
                                            getCompanyRegistrationError={error}
                                        >
                                            <AnalyticsListener>
                                                <CustomerViewPage />
                                            </AnalyticsListener>
                                        </InviteValidator>
                                    </Route>
                                    <Route path="/token">
                                        <TokenHandler
                                            configuredPackages={configuredPackages}
                                            isGetCompanyRegistrationError={error}
                                        >
                                            <AnalyticsListener>
                                                <CustomerViewPage />
                                            </AnalyticsListener>
                                        </TokenHandler>
                                    </Route>
                                    <Redirect to="/" />
                                </Switch>
                            </ViewValidator>
                        </BrowserRouter>
                    </Container>
                </GlobalStoreProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

const Container = styled(Row)`
    background: white;
    display: flex;
    width: 100%;
    min-height: -webkit-fill-available;
`;
