import { APIError } from './errors/api_error';
import { GetCompanyRegistrationResponseInternal } from './types/global_api.types';

export interface GetCompanyRegistrationResponse {
    companyRegistration?: CompanyRegistration | null;
    apiUrl?: string | null;
    loggingUrl?: string | null;
    wallUrl?: string | null;
}

export interface CompanyRegistration {
    companyCode?: string | null;
    regionCode?: string | null;
    companyName?: string | null;
    companyLogoUrl?: string | null;
    switchWorkspacesEnabled?: boolean;
}

interface Options {
    companyCode: string;
    globalDomain: string;
}
export async function getCompanyRegistration({
    companyCode,
    globalDomain,
}: Options): Promise<GetCompanyRegistrationResponse> {
    const globalApiUrl = `https://global-api.${globalDomain}/v1`;
    const response = await fetch(`${globalApiUrl}/company-registrations/${companyCode}`);

    if (!response.ok) {
        throw new APIError(
            `Could not fetch company registration for companyCode: '${companyCode}'`,
            response,
        );
    }

    try {
        const data = (await response.json()) as GetCompanyRegistrationResponseInternal;
        return {
            companyRegistration: {
                companyCode: data.company_registration.company_code,
                regionCode: data.company_registration.region_code,
                companyName: data.company_registration.company_name,
                companyLogoUrl: data.company_registration.company_logo_url,
                switchWorkspacesEnabled: data.company_registration.switch_workspaces_enabled,
            },
            apiUrl: removeTrailingForwardSlash(data.api_url),
            loggingUrl: data.logging_url,
            wallUrl: data.wall_url,
        };
    } catch (error) {
        throw new APIError(
            `Error getting company registration for companyCode: '${companyCode}'`,
            response,
        );
    }
}

export function removeTrailingForwardSlash(apiUrl: string): string {
    return apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl;
}
