import { styled, StreemGlyphBrandColors, Box } from '@streem/ui-react';
import { CenteredCard } from './centered_card';
import { PoweredByStreemFooter } from './powered_by_streem_footer';

interface ErrorBoxProps {
    headerText: string;
    messageText: any;
    testid?: string;
}

export function ErrorBox(props: ErrorBoxProps) {
    return (
        <Box width="100vw" data-testid={props.testid}>
            <LogoContainer>
                <StreemGlyphBrandColors width="120px" height="120px" />
            </LogoContainer>
            <CenteredCard header={props.headerText}>{props.messageText}</CenteredCard>
            <PoweredByStreemFooter />
        </Box>
    );
}

const LogoContainer = styled(Box)`
    display: flex;
    height: 20vh;
    width: 100%;
    text-align: center;
    align-items: flex-end;
    justify-content: center;
`;
