/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArElement,
    StreemApiArElementFromJSON,
    StreemApiArElementFromJSONTyped,
    StreemApiArElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactStreemshotRevision
 */
export interface StreemApiArtifactStreemshotRevision {
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    revisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    streemshotSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    storageUrl?: string;
    /**
     * 
     * @type {Array<StreemApiArElement>}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    arElements?: Array<StreemApiArElement>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiArtifactStreemshotRevision
     */
    edited?: boolean;
}

export function StreemApiArtifactStreemshotRevisionFromJSON(json: any): StreemApiArtifactStreemshotRevision {
    return StreemApiArtifactStreemshotRevisionFromJSONTyped(json, false);
}

export function StreemApiArtifactStreemshotRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactStreemshotRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revisionNumber': !exists(json, 'revision_number') ? undefined : json['revision_number'],
        'streemshotSid': !exists(json, 'streemshot_sid') ? undefined : json['streemshot_sid'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'arElements': !exists(json, 'ar_elements') ? undefined : ((json['ar_elements'] as Array<any>).map(StreemApiArElementFromJSON)),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'edited': !exists(json, 'edited') ? undefined : json['edited'],
    };
}

export function StreemApiArtifactStreemshotRevisionToJSON(value?: StreemApiArtifactStreemshotRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revision_number': value.revisionNumber,
        'streemshot_sid': value.streemshotSid,
        'storage_url': value.storageUrl,
        'ar_elements': value.arElements === undefined ? undefined : ((value.arElements as Array<any>).map(StreemApiArElementToJSON)),
        'url': value.url,
        'edited': value.edited,
    };
}


