/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiWebhook,
    StreemApiWebhookFromJSON,
    StreemApiWebhookFromJSONTyped,
    StreemApiWebhookToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiUpdateWebhookResponse
 */
export interface StreemApiUpdateWebhookResponse {
    /**
     * 
     * @type {StreemApiWebhook}
     * @memberof StreemApiUpdateWebhookResponse
     */
    webhook?: StreemApiWebhook;
}

export function StreemApiUpdateWebhookResponseFromJSON(json: any): StreemApiUpdateWebhookResponse {
    return StreemApiUpdateWebhookResponseFromJSONTyped(json, false);
}

export function StreemApiUpdateWebhookResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateWebhookResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'webhook': !exists(json, 'webhook') ? undefined : StreemApiWebhookFromJSON(json['webhook']),
    };
}

export function StreemApiUpdateWebhookResponseToJSON(value?: StreemApiUpdateWebhookResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'webhook': StreemApiWebhookToJSON(value.webhook),
    };
}


