import { contextValues, getDocumentHash, logUncaughtErrors } from '@streem/logger';
import ReactDOM from 'react-dom';
import { App } from './app';
import appLogger from './util/app_logger';

appLogger.setContextValue(contextValues.GIT_REF, getDocumentHash());
appLogger.setContextValue(contextValues.USER_AGENT, navigator.userAgent);
logUncaughtErrors(appLogger);

ReactDOM.render(<App />, document.getElementById('root'));
