/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSmsDeliveryStatus
 */
export interface StreemApiSmsDeliveryStatus {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    smsDeliveryStatusSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    messageId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    deliveryStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    invitationSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    errorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSmsDeliveryStatus
     */
    smsPlatform?: StreemApiSmsDeliveryStatusSmsPlatform;
}

export function StreemApiSmsDeliveryStatusFromJSON(json: any): StreemApiSmsDeliveryStatus {
    return StreemApiSmsDeliveryStatusFromJSONTyped(json, false);
}

export function StreemApiSmsDeliveryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSmsDeliveryStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'smsDeliveryStatusSid': !exists(json, 'sms_delivery_status_sid') ? undefined : json['sms_delivery_status_sid'],
        'messageId': !exists(json, 'message_id') ? undefined : json['message_id'],
        'deliveryStatus': !exists(json, 'delivery_status') ? undefined : json['delivery_status'],
        'invitationSid': !exists(json, 'invitation_sid') ? undefined : json['invitation_sid'],
        'errorCode': !exists(json, 'error_code') ? undefined : json['error_code'],
        'smsPlatform': !exists(json, 'sms_platform') ? undefined : json['sms_platform'],
    };
}

export function StreemApiSmsDeliveryStatusToJSON(value?: StreemApiSmsDeliveryStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sms_delivery_status_sid': value.smsDeliveryStatusSid,
        'message_id': value.messageId,
        'delivery_status': value.deliveryStatus,
        'invitation_sid': value.invitationSid,
        'error_code': value.errorCode,
        'sms_platform': value.smsPlatform,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiSmsDeliveryStatusSmsPlatform {
    TWILIO = 'SMS_PLATFORM_TWILIO',
    TELNYX = 'SMS_PLATFORM_TELNYX',
    INVALID = 'SMS_PLATFORM_INVALID'
}


