/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiUserWallCredentialsStreemWall,
    StreemApiUserWallCredentialsStreemWallFromJSON,
    StreemApiUserWallCredentialsStreemWallFromJSONTyped,
    StreemApiUserWallCredentialsStreemWallToJSON,
    StreemApiUserWallCredentialsTwilioSync,
    StreemApiUserWallCredentialsTwilioSyncFromJSON,
    StreemApiUserWallCredentialsTwilioSyncFromJSONTyped,
    StreemApiUserWallCredentialsTwilioSyncToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiUserWallCredentials
 */
export interface StreemApiUserWallCredentials {
    /**
     * 
     * @type {StreemApiUserWallCredentialsTwilioSync}
     * @memberof StreemApiUserWallCredentials
     */
    twilioSync?: StreemApiUserWallCredentialsTwilioSync;
    /**
     * 
     * @type {StreemApiUserWallCredentialsStreemWall}
     * @memberof StreemApiUserWallCredentials
     */
    streemWall?: StreemApiUserWallCredentialsStreemWall;
}

export function StreemApiUserWallCredentialsFromJSON(json: any): StreemApiUserWallCredentials {
    return StreemApiUserWallCredentialsFromJSONTyped(json, false);
}

export function StreemApiUserWallCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUserWallCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'twilioSync': !exists(json, 'twilio_sync') ? undefined : StreemApiUserWallCredentialsTwilioSyncFromJSON(json['twilio_sync']),
        'streemWall': !exists(json, 'streem_wall') ? undefined : StreemApiUserWallCredentialsStreemWallFromJSON(json['streem_wall']),
    };
}

export function StreemApiUserWallCredentialsToJSON(value?: StreemApiUserWallCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'twilio_sync': StreemApiUserWallCredentialsTwilioSyncToJSON(value.twilioSync),
        'streem_wall': StreemApiUserWallCredentialsStreemWallToJSON(value.streemWall),
    };
}


