/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleTypeInterval,
    GoogleTypeIntervalFromJSON,
    GoogleTypeIntervalFromJSONTyped,
    GoogleTypeIntervalToJSON,
    StreemApiAggregatedRoomMetricsAggregatedBy,
    StreemApiAggregatedRoomMetricsAggregatedByFromJSON,
    StreemApiAggregatedRoomMetricsAggregatedByFromJSONTyped,
    StreemApiAggregatedRoomMetricsAggregatedByToJSON,
    StreemApiAggregatedRoomMetricsMetrics,
    StreemApiAggregatedRoomMetricsMetricsFromJSON,
    StreemApiAggregatedRoomMetricsMetricsFromJSONTyped,
    StreemApiAggregatedRoomMetricsMetricsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAggregatedRoomMetrics
 */
export interface StreemApiAggregatedRoomMetrics {
    /**
     * 
     * @type {StreemApiAggregatedRoomMetricsAggregatedBy}
     * @memberof StreemApiAggregatedRoomMetrics
     */
    aggregatedBy?: StreemApiAggregatedRoomMetricsAggregatedBy;
    /**
     * 
     * @type {StreemApiAggregatedRoomMetricsMetrics}
     * @memberof StreemApiAggregatedRoomMetrics
     */
    metrics?: StreemApiAggregatedRoomMetricsMetrics;
    /**
     * 
     * @type {GoogleTypeInterval}
     * @memberof StreemApiAggregatedRoomMetrics
     */
    timeRange?: GoogleTypeInterval;
}

export function StreemApiAggregatedRoomMetricsFromJSON(json: any): StreemApiAggregatedRoomMetrics {
    return StreemApiAggregatedRoomMetricsFromJSONTyped(json, false);
}

export function StreemApiAggregatedRoomMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAggregatedRoomMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregatedBy': !exists(json, 'aggregated_by') ? undefined : StreemApiAggregatedRoomMetricsAggregatedByFromJSON(json['aggregated_by']),
        'metrics': !exists(json, 'metrics') ? undefined : StreemApiAggregatedRoomMetricsMetricsFromJSON(json['metrics']),
        'timeRange': !exists(json, 'time_range') ? undefined : GoogleTypeIntervalFromJSON(json['time_range']),
    };
}

export function StreemApiAggregatedRoomMetricsToJSON(value?: StreemApiAggregatedRoomMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregated_by': StreemApiAggregatedRoomMetricsAggregatedByToJSON(value.aggregatedBy),
        'metrics': StreemApiAggregatedRoomMetricsMetricsToJSON(value.metrics),
        'time_range': GoogleTypeIntervalToJSON(value.timeRange),
    };
}


