/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiListLicensesResponse,
    StreemApiListLicensesResponseFromJSON,
    StreemApiListLicensesResponseToJSON,
} from '../models';

export interface ListLicensesRequest {
    pageSize?: number;
    pageToken?: string;
}

/**
 * 
 */
export class LicensesApi extends runtime.BaseAPI {

    /**
     * Lists all current licenses available in the system
     * List Licenses
     */
    async listLicensesRaw(requestParameters: ListLicensesRequest): Promise<runtime.ApiResponse<StreemApiListLicensesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/licenses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListLicensesResponseFromJSON(jsonValue));
    }

    /**
     * Lists all current licenses available in the system
     * List Licenses
     */
    async listLicenses(pageSize?: number, pageToken?: string): Promise<StreemApiListLicensesResponse> {
        const response = await this.listLicensesRaw({ pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

}
