/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiGlobalCompanyRegistration
 */
export interface StreemApiGlobalCompanyRegistration {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    regionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    companyLogoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    switchWorkspacesEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    companySid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiGlobalCompanyRegistration
     */
    companyActive?: boolean;
}

export function StreemApiGlobalCompanyRegistrationFromJSON(json: any): StreemApiGlobalCompanyRegistration {
    return StreemApiGlobalCompanyRegistrationFromJSONTyped(json, false);
}

export function StreemApiGlobalCompanyRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGlobalCompanyRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyCode': !exists(json, 'company_code') ? undefined : json['company_code'],
        'regionCode': !exists(json, 'region_code') ? undefined : json['region_code'],
        'companyName': !exists(json, 'company_name') ? undefined : json['company_name'],
        'companyLogoUrl': !exists(json, 'company_logo_url') ? undefined : json['company_logo_url'],
        'switchWorkspacesEnabled': !exists(json, 'switch_workspaces_enabled') ? undefined : json['switch_workspaces_enabled'],
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'companyActive': !exists(json, 'company_active') ? undefined : json['company_active'],
    };
}

export function StreemApiGlobalCompanyRegistrationToJSON(value?: StreemApiGlobalCompanyRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_code': value.companyCode,
        'region_code': value.regionCode,
        'company_name': value.companyName,
        'company_logo_url': value.companyLogoUrl,
        'switch_workspaces_enabled': value.switchWorkspacesEnabled,
        'company_sid': value.companySid,
        'company_active': value.companyActive,
    };
}


