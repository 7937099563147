/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSmsDeliveryStatus,
    StreemApiSmsDeliveryStatusFromJSON,
    StreemApiSmsDeliveryStatusFromJSONTyped,
    StreemApiSmsDeliveryStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetInvitationSmsStatusesResponse
 */
export interface StreemApiGetInvitationSmsStatusesResponse {
    /**
     * 
     * @type {Array<StreemApiSmsDeliveryStatus>}
     * @memberof StreemApiGetInvitationSmsStatusesResponse
     */
    smsDeliveryStatuses?: Array<StreemApiSmsDeliveryStatus>;
}

export function StreemApiGetInvitationSmsStatusesResponseFromJSON(json: any): StreemApiGetInvitationSmsStatusesResponse {
    return StreemApiGetInvitationSmsStatusesResponseFromJSONTyped(json, false);
}

export function StreemApiGetInvitationSmsStatusesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetInvitationSmsStatusesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'smsDeliveryStatuses': !exists(json, 'sms_delivery_statuses') ? undefined : ((json['sms_delivery_statuses'] as Array<any>).map(StreemApiSmsDeliveryStatusFromJSON)),
    };
}

export function StreemApiGetInvitationSmsStatusesResponseToJSON(value?: StreemApiGetInvitationSmsStatusesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sms_delivery_statuses': value.smsDeliveryStatuses === undefined ? undefined : ((value.smsDeliveryStatuses as Array<any>).map(StreemApiSmsDeliveryStatusToJSON)),
    };
}


