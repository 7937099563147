/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiMediaPlacement,
    StreemApiMediaPlacementFromJSON,
    StreemApiMediaPlacementFromJSONTyped,
    StreemApiMediaPlacementToJSON,
    StreemApiMeetingFeatures,
    StreemApiMeetingFeaturesFromJSON,
    StreemApiMeetingFeaturesFromJSONTyped,
    StreemApiMeetingFeaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiMeeting
 */
export interface StreemApiMeeting {
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    externalMeetingId?: string;
    /**
     * 
     * @type {StreemApiMediaPlacement}
     * @memberof StreemApiMeeting
     */
    mediaPlacement?: StreemApiMediaPlacement;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    mediaRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    meetingArn?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    meetingHostId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    meetingId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMeeting
     */
    primaryMeetingId?: string;
    /**
     * 
     * @type {StreemApiMeetingFeatures}
     * @memberof StreemApiMeeting
     */
    meetingFeatures?: StreemApiMeetingFeatures;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiMeeting
     */
    tenantIds?: Array<string>;
}

export function StreemApiMeetingFromJSON(json: any): StreemApiMeeting {
    return StreemApiMeetingFromJSONTyped(json, false);
}

export function StreemApiMeetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiMeeting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalMeetingId': !exists(json, 'external_meeting_id') ? undefined : json['external_meeting_id'],
        'mediaPlacement': !exists(json, 'media_placement') ? undefined : StreemApiMediaPlacementFromJSON(json['media_placement']),
        'mediaRegion': !exists(json, 'media_region') ? undefined : json['media_region'],
        'meetingArn': !exists(json, 'meeting_arn') ? undefined : json['meeting_arn'],
        'meetingHostId': !exists(json, 'meeting_host_id') ? undefined : json['meeting_host_id'],
        'meetingId': !exists(json, 'meeting_id') ? undefined : json['meeting_id'],
        'primaryMeetingId': !exists(json, 'primary_meeting_id') ? undefined : json['primary_meeting_id'],
        'meetingFeatures': !exists(json, 'meeting_features') ? undefined : StreemApiMeetingFeaturesFromJSON(json['meeting_features']),
        'tenantIds': !exists(json, 'tenant_ids') ? undefined : json['tenant_ids'],
    };
}

export function StreemApiMeetingToJSON(value?: StreemApiMeeting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_meeting_id': value.externalMeetingId,
        'media_placement': StreemApiMediaPlacementToJSON(value.mediaPlacement),
        'media_region': value.mediaRegion,
        'meeting_arn': value.meetingArn,
        'meeting_host_id': value.meetingHostId,
        'meeting_id': value.meetingId,
        'primary_meeting_id': value.primaryMeetingId,
        'meeting_features': StreemApiMeetingFeaturesToJSON(value.meetingFeatures),
        'tenant_ids': value.tenantIds,
    };
}


