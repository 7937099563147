/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListSceneshotsResponse
 */
export interface StreemApiListSceneshotsResponse {
    /**
     * 
     * @type {Array<StreemApiArtifact>}
     * @memberof StreemApiListSceneshotsResponse
     */
    sceneshotArtifacts?: Array<StreemApiArtifact>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListSceneshotsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListSceneshotsResponse
     */
    totalSize?: number;
}

export function StreemApiListSceneshotsResponseFromJSON(json: any): StreemApiListSceneshotsResponse {
    return StreemApiListSceneshotsResponseFromJSONTyped(json, false);
}

export function StreemApiListSceneshotsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListSceneshotsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sceneshotArtifacts': !exists(json, 'sceneshot_artifacts') ? undefined : ((json['sceneshot_artifacts'] as Array<any>).map(StreemApiArtifactFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListSceneshotsResponseToJSON(value?: StreemApiListSceneshotsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sceneshot_artifacts': value.sceneshotArtifacts === undefined ? undefined : ((value.sceneshotArtifacts as Array<any>).map(StreemApiArtifactToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


