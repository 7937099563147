/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DestinationTutorialExitsEntry,
    DestinationTutorialExitsEntryFromJSON,
    DestinationTutorialExitsEntryFromJSONTyped,
    DestinationTutorialExitsEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface DestinationTutorial
 */
export interface DestinationTutorial {
    /**
     * 
     * @type {string}
     * @memberof DestinationTutorial
     */
    tutorialId?: string;
    /**
     * 
     * @type {Array<DestinationTutorialExitsEntry>}
     * @memberof DestinationTutorial
     */
    exits?: Array<DestinationTutorialExitsEntry>;
}

export function DestinationTutorialFromJSON(json: any): DestinationTutorial {
    return DestinationTutorialFromJSONTyped(json, false);
}

export function DestinationTutorialFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationTutorial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tutorialId': !exists(json, 'tutorial_id') ? undefined : json['tutorial_id'],
        'exits': !exists(json, 'exits') ? undefined : ((json['exits'] as Array<any>).map(DestinationTutorialExitsEntryFromJSON)),
    };
}

export function DestinationTutorialToJSON(value?: DestinationTutorial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tutorial_id': value.tutorialId,
        'exits': value.exits === undefined ? undefined : ((value.exits as Array<any>).map(DestinationTutorialExitsEntryToJSON)),
    };
}


