/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateCompanyApiKeyRequest
 */
export interface StreemApiCreateCompanyApiKeyRequest {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCreateCompanyApiKeyRequest
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyApiKeyRequest
     */
    label?: string;
}

export function StreemApiCreateCompanyApiKeyRequestFromJSON(json: any): StreemApiCreateCompanyApiKeyRequest {
    return StreemApiCreateCompanyApiKeyRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyApiKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyApiKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function StreemApiCreateCompanyApiKeyRequestToJSON(value?: StreemApiCreateCompanyApiKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'label': value.label,
    };
}


