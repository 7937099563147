/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiMediaPlacement
 */
export interface StreemApiMediaPlacement {
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    audioFallbackUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    audioHostUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    eventIngestionUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    screenDataUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    screenSharingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    screenViewingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    signalingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiMediaPlacement
     */
    turnControlUrl?: string;
}

export function StreemApiMediaPlacementFromJSON(json: any): StreemApiMediaPlacement {
    return StreemApiMediaPlacementFromJSONTyped(json, false);
}

export function StreemApiMediaPlacementFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiMediaPlacement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audioFallbackUrl': !exists(json, 'audio_fallback_url') ? undefined : json['audio_fallback_url'],
        'audioHostUrl': !exists(json, 'audio_host_url') ? undefined : json['audio_host_url'],
        'eventIngestionUrl': !exists(json, 'event_ingestion_url') ? undefined : json['event_ingestion_url'],
        'screenDataUrl': !exists(json, 'screen_data_url') ? undefined : json['screen_data_url'],
        'screenSharingUrl': !exists(json, 'screen_sharing_url') ? undefined : json['screen_sharing_url'],
        'screenViewingUrl': !exists(json, 'screen_viewing_url') ? undefined : json['screen_viewing_url'],
        'signalingUrl': !exists(json, 'signaling_url') ? undefined : json['signaling_url'],
        'turnControlUrl': !exists(json, 'turn_control_url') ? undefined : json['turn_control_url'],
    };
}

export function StreemApiMediaPlacementToJSON(value?: StreemApiMediaPlacement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audio_fallback_url': value.audioFallbackUrl,
        'audio_host_url': value.audioHostUrl,
        'event_ingestion_url': value.eventIngestionUrl,
        'screen_data_url': value.screenDataUrl,
        'screen_sharing_url': value.screenSharingUrl,
        'screen_viewing_url': value.screenViewingUrl,
        'signaling_url': value.signalingUrl,
        'turn_control_url': value.turnControlUrl,
    };
}


