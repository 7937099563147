/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallLogEntry,
    StreemApiCallLogEntryFromJSON,
    StreemApiCallLogEntryFromJSONTyped,
    StreemApiCallLogEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetUserCallLogResponse
 */
export interface StreemApiGetUserCallLogResponse {
    /**
     * 
     * @type {Array<StreemApiCallLogEntry>}
     * @memberof StreemApiGetUserCallLogResponse
     */
    callLogEntries?: Array<StreemApiCallLogEntry>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGetUserCallLogResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGetUserCallLogResponse
     */
    totalSize?: number;
}

export function StreemApiGetUserCallLogResponseFromJSON(json: any): StreemApiGetUserCallLogResponse {
    return StreemApiGetUserCallLogResponseFromJSONTyped(json, false);
}

export function StreemApiGetUserCallLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetUserCallLogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callLogEntries': !exists(json, 'call_log_entries') ? undefined : ((json['call_log_entries'] as Array<any>).map(StreemApiCallLogEntryFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiGetUserCallLogResponseToJSON(value?: StreemApiGetUserCallLogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_log_entries': value.callLogEntries === undefined ? undefined : ((value.callLogEntries as Array<any>).map(StreemApiCallLogEntryToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


