/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleApiHttpBody,
    GoogleApiHttpBodyFromJSON,
    GoogleApiHttpBodyFromJSONTyped,
    GoogleApiHttpBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateCompanyAssetRequest
 */
export interface StreemApiCreateCompanyAssetRequest {
    /**
     * 
     * @type {GoogleApiHttpBody}
     * @memberof StreemApiCreateCompanyAssetRequest
     */
    httpBody?: GoogleApiHttpBody;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyAssetRequest
     */
    filename?: string;
}

export function StreemApiCreateCompanyAssetRequestFromJSON(json: any): StreemApiCreateCompanyAssetRequest {
    return StreemApiCreateCompanyAssetRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyAssetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyAssetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'httpBody': !exists(json, 'http_body') ? undefined : GoogleApiHttpBodyFromJSON(json['http_body']),
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
    };
}

export function StreemApiCreateCompanyAssetRequestToJSON(value?: StreemApiCreateCompanyAssetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'http_body': GoogleApiHttpBodyToJSON(value.httpBody),
        'filename': value.filename,
    };
}


