/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WallItemARElementArrow,
    WallItemARElementArrowFromJSON,
    WallItemARElementArrowFromJSONTyped,
    WallItemARElementArrowToJSON,
    WallItemARElementMeasurement,
    WallItemARElementMeasurementFromJSON,
    WallItemARElementMeasurementFromJSONTyped,
    WallItemARElementMeasurementToJSON,
    WallItemARElementModel3d,
    WallItemARElementModel3dFromJSON,
    WallItemARElementModel3dFromJSONTyped,
    WallItemARElementModel3dToJSON,
    WallItemARElementText,
    WallItemARElementTextFromJSON,
    WallItemARElementTextFromJSONTyped,
    WallItemARElementTextToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemARElement
 */
export interface WallItemARElement {
    /**
     * 
     * @type {WallItemARElementArrow}
     * @memberof WallItemARElement
     */
    arrow?: WallItemARElementArrow;
    /**
     * 
     * @type {WallItemARElementMeasurement}
     * @memberof WallItemARElement
     */
    measurement?: WallItemARElementMeasurement;
    /**
     * 
     * @type {WallItemARElementText}
     * @memberof WallItemARElement
     */
    text?: WallItemARElementText;
    /**
     * 
     * @type {WallItemARElementModel3d}
     * @memberof WallItemARElement
     */
    model3d?: WallItemARElementModel3d;
}

export function WallItemARElementFromJSON(json: any): WallItemARElement {
    return WallItemARElementFromJSONTyped(json, false);
}

export function WallItemARElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemARElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arrow': !exists(json, 'arrow') ? undefined : WallItemARElementArrowFromJSON(json['arrow']),
        'measurement': !exists(json, 'measurement') ? undefined : WallItemARElementMeasurementFromJSON(json['measurement']),
        'text': !exists(json, 'text') ? undefined : WallItemARElementTextFromJSON(json['text']),
        'model3d': !exists(json, 'model3d') ? undefined : WallItemARElementModel3dFromJSON(json['model3d']),
    };
}

export function WallItemARElementToJSON(value?: WallItemARElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arrow': WallItemARElementArrowToJSON(value.arrow),
        'measurement': WallItemARElementMeasurementToJSON(value.measurement),
        'text': WallItemARElementTextToJSON(value.text),
        'model3d': WallItemARElementModel3dToJSON(value.model3d),
    };
}


