/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufAny,
    GoogleProtobufAnyFromJSON,
    GoogleProtobufAnyFromJSONTyped,
    GoogleProtobufAnyToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiErrorResponseError
 */
export interface StreemApiErrorResponseError {
    /**
     * 
     * @type {string}
     * @memberof StreemApiErrorResponseError
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiErrorResponseError
     */
    message?: string;
    /**
     * 
     * @type {Array<GoogleProtobufAny>}
     * @memberof StreemApiErrorResponseError
     */
    meta?: Array<GoogleProtobufAny>;
}

export function StreemApiErrorResponseErrorFromJSON(json: any): StreemApiErrorResponseError {
    return StreemApiErrorResponseErrorFromJSONTyped(json, false);
}

export function StreemApiErrorResponseErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiErrorResponseError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'meta': !exists(json, 'meta') ? undefined : ((json['meta'] as Array<any>).map(GoogleProtobufAnyFromJSON)),
    };
}

export function StreemApiErrorResponseErrorToJSON(value?: StreemApiErrorResponseError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
        'meta': value.meta === undefined ? undefined : ((value.meta as Array<any>).map(GoogleProtobufAnyToJSON)),
    };
}


