/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextStyle,
    TextStyleFromJSON,
    TextStyleFromJSONTyped,
    TextStyleToJSON,
    ViewStyle,
    ViewStyleFromJSON,
    ViewStyleFromJSONTyped,
    ViewStyleToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextTheme
 */
export interface TextTheme {
    /**
     * 
     * @type {ViewStyle}
     * @memberof TextTheme
     */
    viewStyle?: ViewStyle;
    /**
     * 
     * @type {TextStyle}
     * @memberof TextTheme
     */
    titleStyle?: TextStyle;
    /**
     * 
     * @type {TextStyle}
     * @memberof TextTheme
     */
    contentStyle?: TextStyle;
}

export function TextThemeFromJSON(json: any): TextTheme {
    return TextThemeFromJSONTyped(json, false);
}

export function TextThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextTheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewStyle': !exists(json, 'view_style') ? undefined : ViewStyleFromJSON(json['view_style']),
        'titleStyle': !exists(json, 'title_style') ? undefined : TextStyleFromJSON(json['title_style']),
        'contentStyle': !exists(json, 'content_style') ? undefined : TextStyleFromJSON(json['content_style']),
    };
}

export function TextThemeToJSON(value?: TextTheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'view_style': ViewStyleToJSON(value.viewStyle),
        'title_style': TextStyleToJSON(value.titleStyle),
        'content_style': TextStyleToJSON(value.contentStyle),
    };
}


