/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateGroupReservationRequestCallDetailCustomer
 */
export interface StreemApiCreateGroupReservationRequestCallDetailCustomer {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequestCallDetailCustomer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequestCallDetailCustomer
     */
    planType?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequestCallDetailCustomer
     */
    contractNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCreateGroupReservationRequestCallDetailCustomer
     */
    callsAvailable?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequestCallDetailCustomer
     */
    address?: string;
}

export function StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSON(json: any): StreemApiCreateGroupReservationRequestCallDetailCustomer {
    return StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSONTyped(json, false);
}

export function StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateGroupReservationRequestCallDetailCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'planType': !exists(json, 'plan_type') ? undefined : json['plan_type'],
        'contractNumber': !exists(json, 'contract_number') ? undefined : json['contract_number'],
        'callsAvailable': !exists(json, 'calls_available') ? undefined : json['calls_available'],
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

export function StreemApiCreateGroupReservationRequestCallDetailCustomerToJSON(value?: StreemApiCreateGroupReservationRequestCallDetailCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'plan_type': value.planType,
        'contract_number': value.contractNumber,
        'calls_available': value.callsAvailable,
        'address': value.address,
    };
}


