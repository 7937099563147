/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListCompanyUsersResponse
 */
export interface StreemApiListCompanyUsersResponse {
    /**
     * 
     * @type {Array<StreemApiUser>}
     * @memberof StreemApiListCompanyUsersResponse
     */
    users?: Array<StreemApiUser>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListCompanyUsersResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListCompanyUsersResponse
     */
    totalSize?: number;
}

export function StreemApiListCompanyUsersResponseFromJSON(json: any): StreemApiListCompanyUsersResponse {
    return StreemApiListCompanyUsersResponseFromJSONTyped(json, false);
}

export function StreemApiListCompanyUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListCompanyUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(StreemApiUserFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListCompanyUsersResponseToJSON(value?: StreemApiListCompanyUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(StreemApiUserToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


