/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ElementPresentation,
    ElementPresentationFromJSON,
    ElementPresentationFromJSONTyped,
    ElementPresentationToJSON,
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
    Transition,
    TransitionFromJSON,
    TransitionFromJSONTyped,
    TransitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    id?: string;
    /**
     * 
     * @type {Array<ElementPresentation>}
     * @memberof Step
     */
    elements?: Array<ElementPresentation>;
    /**
     * 
     * @type {Array<Transition>}
     * @memberof Step
     */
    transitions?: Array<Transition>;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    titleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    descriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    statusTextId?: string;
    /**
     * 
     * @type {StreemApiPoint}
     * @memberof Step
     */
    focalPoint?: StreemApiPoint;
}

export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'elements': !exists(json, 'elements') ? undefined : ((json['elements'] as Array<any>).map(ElementPresentationFromJSON)),
        'transitions': !exists(json, 'transitions') ? undefined : ((json['transitions'] as Array<any>).map(TransitionFromJSON)),
        'titleId': !exists(json, 'title_id') ? undefined : json['title_id'],
        'descriptionId': !exists(json, 'description_id') ? undefined : json['description_id'],
        'statusTextId': !exists(json, 'status_text_id') ? undefined : json['status_text_id'],
        'focalPoint': !exists(json, 'focal_point') ? undefined : StreemApiPointFromJSON(json['focal_point']),
    };
}

export function StepToJSON(value?: Step | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'elements': value.elements === undefined ? undefined : ((value.elements as Array<any>).map(ElementPresentationToJSON)),
        'transitions': value.transitions === undefined ? undefined : ((value.transitions as Array<any>).map(TransitionToJSON)),
        'title_id': value.titleId,
        'description_id': value.descriptionId,
        'status_text_id': value.statusTextId,
        'focal_point': StreemApiPointToJSON(value.focalPoint),
    };
}


