/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCompany,
    StreemApiCompanyFromJSON,
    StreemApiCompanyFromJSONTyped,
    StreemApiCompanyToJSON,
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateCompanyResponse
 */
export interface StreemApiCreateCompanyResponse {
    /**
     * 
     * @type {StreemApiCompany}
     * @memberof StreemApiCreateCompanyResponse
     */
    company?: StreemApiCompany;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyResponse
     */
    oidcIssuer?: string;
    /**
     * 
     * @type {StreemApiUser}
     * @memberof StreemApiCreateCompanyResponse
     */
    ownerUser?: StreemApiUser;
}

export function StreemApiCreateCompanyResponseFromJSON(json: any): StreemApiCreateCompanyResponse {
    return StreemApiCreateCompanyResponseFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : StreemApiCompanyFromJSON(json['company']),
        'oidcIssuer': !exists(json, 'oidc_issuer') ? undefined : json['oidc_issuer'],
        'ownerUser': !exists(json, 'owner_user') ? undefined : StreemApiUserFromJSON(json['owner_user']),
    };
}

export function StreemApiCreateCompanyResponseToJSON(value?: StreemApiCreateCompanyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': StreemApiCompanyToJSON(value.company),
        'oidc_issuer': value.oidcIssuer,
        'owner_user': StreemApiUserToJSON(value.ownerUser),
    };
}


