import { detect } from 'detect-browser';
import { once } from './once';

export class VideoElementManager {
    constructor(private readonly video: HTMLVideoElement) {}

    /**
     * addVideoToDOM adds the HTMLVideoElement to the DOM
     * so we can reliably use the MediaStream attached to it.
     * When the video element is not attached to the DOM, we have
     * encountered erroneous video errors.
     *
     * The video stream needs to be inside of the view (and be visible)
     * to avoid screen timeouts in safari 15.0+
     */
    public addHiddenVideoToDOM(): VideoElementManager {
        this.video.style.position = 'absolute';
        this.video.style.left = '0x';
        this.video.style.top = '0px';
        this.video.style.zIndex = '-9999';
        this.video.style.height = '1px';
        this.video.style.width = '1px';
        document.body.appendChild(this.video);
        return this;
    }

    /**
     * removeVideoFromDOM removes the HTMLVideoElement from the DOM.
     */
    public removeVideoFromDOM(): VideoElementManager {
        document.body.removeChild(this.video);
        return this;
    }

    public playVideoWhenLoaded(args: { onError?: (e: Error) => void }): VideoElementManager {
        const playVideo = () => {
            try {
                this.video.play();
            } catch (error: unknown) {
                if (error instanceof Error) args.onError(error as Error);
            }
        };

        if (this.video.readyState >= this.video.HAVE_FUTURE_DATA) {
            playVideo();
        } else {
            // try to play the video when we have enough data
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/loadeddata_event
            this.video.addEventListener('loadeddata', playVideo, { once: true });
        }

        return this;
    }

    /**
     * Safari will pause videos if they are not in the viewport
     * Safari will also pause videos if they are not visible in the viewport
     * So far the only work around I've discovered is to pause and play the video after our first
     * attempt of playing the video
     *
     * Twilio fixed this by pausing and playing the html media element on it's first play event.
     * This fix also works with video
     * https://github.com/twilio/twilio-video.js/issues/922#issuecomment-607038582
     */
    public webkitVideoFix(onVideoFix?: () => void): VideoElementManager {
        const browser = detect();

        if (browser?.name === 'safari' || browser?.name === 'ios') {
            once(this.video, 'play')
                .then(() => {
                    const onPause = once(this.video, 'pause');
                    this.video.pause();
                    return onPause;
                })
                .then(() => this.video.play())
                .then(() => {
                    if (onVideoFix) {
                        onVideoFix();
                    }
                });
        }

        return this;
    }
}
