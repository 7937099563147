/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventVignetteFinishChanged
 */
export interface StreemApiAnalyticEventVignetteFinishChanged {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteFinishChanged
     */
    vignettElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteFinishChanged
     */
    finishId?: string;
}

export function StreemApiAnalyticEventVignetteFinishChangedFromJSON(json: any): StreemApiAnalyticEventVignetteFinishChanged {
    return StreemApiAnalyticEventVignetteFinishChangedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventVignetteFinishChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventVignetteFinishChanged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vignettElementId': !exists(json, 'vignett_element_id') ? undefined : json['vignett_element_id'],
        'finishId': !exists(json, 'finish_id') ? undefined : json['finish_id'],
    };
}

export function StreemApiAnalyticEventVignetteFinishChangedToJSON(value?: StreemApiAnalyticEventVignetteFinishChanged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vignett_element_id': value.vignettElementId,
        'finish_id': value.finishId,
    };
}


