/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiExternalUser,
    StreemApiExternalUserFromJSON,
    StreemApiExternalUserFromJSONTyped,
    StreemApiExternalUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetOrCreateUserResponse
 */
export interface StreemApiGetOrCreateUserResponse {
    /**
     * 
     * @type {StreemApiExternalUser}
     * @memberof StreemApiGetOrCreateUserResponse
     */
    externalUser?: StreemApiExternalUser;
}

export function StreemApiGetOrCreateUserResponseFromJSON(json: any): StreemApiGetOrCreateUserResponse {
    return StreemApiGetOrCreateUserResponseFromJSONTyped(json, false);
}

export function StreemApiGetOrCreateUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetOrCreateUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalUser': !exists(json, 'external_user') ? undefined : StreemApiExternalUserFromJSON(json['external_user']),
    };
}

export function StreemApiGetOrCreateUserResponseToJSON(value?: StreemApiGetOrCreateUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user': StreemApiExternalUserToJSON(value.externalUser),
    };
}


