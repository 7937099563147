/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactRecording
 */
export interface StreemApiArtifactRecording {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    bucket?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    status?: StreemApiArtifactRecordingStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecording
     */
    requestedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecording
     */
    startedTransferringAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecording
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecording
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecording
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    recordingSource?: StreemApiArtifactRecordingRecordingSource;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    previewStorageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    previewUrl?: string;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiArtifactRecording
     */
    duration?: GoogleProtobufDuration;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecording
     */
    recordingStatus?: StreemApiArtifactRecordingRecordingStatus;
}

export function StreemApiArtifactRecordingFromJSON(json: any): StreemApiArtifactRecording {
    return StreemApiArtifactRecordingFromJSONTyped(json, false);
}

export function StreemApiArtifactRecordingFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactRecording {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bucket': !exists(json, 'bucket') ? undefined : json['bucket'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'startedTransferringAt': !exists(json, 'started_transferring_at') ? undefined : (new Date(json['started_transferring_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'recordingSource': !exists(json, 'recording_source') ? undefined : json['recording_source'],
        'previewStorageUrl': !exists(json, 'preview_storage_url') ? undefined : json['preview_storage_url'],
        'previewUrl': !exists(json, 'preview_url') ? undefined : json['preview_url'],
        'duration': !exists(json, 'duration') ? undefined : GoogleProtobufDurationFromJSON(json['duration']),
        'recordingStatus': !exists(json, 'recording_status') ? undefined : json['recording_status'],
    };
}

export function StreemApiArtifactRecordingToJSON(value?: StreemApiArtifactRecording | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bucket': value.bucket,
        'filename': value.filename,
        'storage_url': value.storageUrl,
        'status': value.status,
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'started_transferring_at': value.startedTransferringAt === undefined ? undefined : (value.startedTransferringAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'url': value.url,
        'recording_source': value.recordingSource,
        'preview_storage_url': value.previewStorageUrl,
        'preview_url': value.previewUrl,
        'duration': GoogleProtobufDurationToJSON(value.duration),
        'recording_status': value.recordingStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRecordingStatus {
    REQUESTED = 'STATUS_REQUESTED',
    TRANSFERRING = 'STATUS_TRANSFERRING',
    ERROR = 'STATUS_ERROR',
    FULFILLED = 'STATUS_FULFILLED',
    INVALID = 'STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRecordingRecordingSource {
    TWILIO = 'SOURCE_TWILIO',
    MUXER = 'SOURCE_MUXER',
    ONDEVICE = 'SOURCE_ON_DEVICE',
    ARAAS = 'SOURCE_ARAAS',
    CHIME = 'SOURCE_CHIME',
    INVALID = 'SOURCE_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRecordingRecordingStatus {
    NONE = 'RECORDING_STATUS_NONE',
    PAUSED = 'RECORDING_STATUS_PAUSED',
    RESUMED = 'RECORDING_STATUS_RESUMED',
    TEMPORARYFAILURE = 'RECORDING_STATUS_TEMPORARY_FAILURE',
    INVALID = 'RECORDING_STATUS_INVALID'
}


