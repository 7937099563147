/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCompany,
    StreemApiCompanyFromJSON,
    StreemApiCompanyFromJSONTyped,
    StreemApiCompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetAssociatedCompaniesResponse
 */
export interface StreemApiGetAssociatedCompaniesResponse {
    /**
     * 
     * @type {Array<StreemApiCompany>}
     * @memberof StreemApiGetAssociatedCompaniesResponse
     */
    companies?: Array<StreemApiCompany>;
}

export function StreemApiGetAssociatedCompaniesResponseFromJSON(json: any): StreemApiGetAssociatedCompaniesResponse {
    return StreemApiGetAssociatedCompaniesResponseFromJSONTyped(json, false);
}

export function StreemApiGetAssociatedCompaniesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetAssociatedCompaniesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(StreemApiCompanyFromJSON)),
    };
}

export function StreemApiGetAssociatedCompaniesResponseToJSON(value?: StreemApiGetAssociatedCompaniesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(StreemApiCompanyToJSON)),
    };
}


