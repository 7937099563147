/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiErrorResponseError,
    StreemApiErrorResponseErrorFromJSON,
    StreemApiErrorResponseErrorFromJSONTyped,
    StreemApiErrorResponseErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiErrorResponse
 */
export interface StreemApiErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof StreemApiErrorResponse
     */
    httpCode?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiErrorResponse
     */
    message?: string;
    /**
     * 
     * @type {StreemApiErrorResponseError}
     * @memberof StreemApiErrorResponse
     */
    error?: StreemApiErrorResponseError;
}

export function StreemApiErrorResponseFromJSON(json: any): StreemApiErrorResponse {
    return StreemApiErrorResponseFromJSONTyped(json, false);
}

export function StreemApiErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiErrorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'httpCode': !exists(json, 'http_code') ? undefined : json['http_code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'error': !exists(json, 'error') ? undefined : StreemApiErrorResponseErrorFromJSON(json['error']),
    };
}

export function StreemApiErrorResponseToJSON(value?: StreemApiErrorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'http_code': value.httpCode,
        'message': value.message,
        'error': StreemApiErrorResponseErrorToJSON(value.error),
    };
}


