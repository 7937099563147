/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiGetCurrentInvitationResponse,
    StreemApiGetCurrentInvitationResponseFromJSON,
    StreemApiGetCurrentInvitationResponseToJSON,
    StreemApiGetInvitationResponse,
    StreemApiGetInvitationResponseFromJSON,
    StreemApiGetInvitationResponseToJSON,
    StreemApiGetInvitationSmsStatusesResponse,
    StreemApiGetInvitationSmsStatusesResponseFromJSON,
    StreemApiGetInvitationSmsStatusesResponseToJSON,
    StreemApiSendInvitationRequest,
    StreemApiSendInvitationRequestFromJSON,
    StreemApiSendInvitationRequestToJSON,
    StreemApiSendInvitationResponse,
    StreemApiSendInvitationResponseFromJSON,
    StreemApiSendInvitationResponseToJSON,
} from '../models';

export interface GetInvitationRequest {
    invitationSid: string;
}

export interface GetInvitationSmsStatusesRequest {
    invitationSid: string;
}

export interface SendInvitationSmsRequest {
    streemApiSendInvitationRequest?: StreemApiSendInvitationRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get an invitation from the principal
     * Get Invitation
     */
    async getCurrentInvitationRaw(): Promise<runtime.ApiResponse<StreemApiGetCurrentInvitationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/invitations/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCurrentInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Get an invitation from the principal
     * Get Invitation
     */
    async getCurrentInvitation(): Promise<StreemApiGetCurrentInvitationResponse> {
        const response = await this.getCurrentInvitationRaw();
        return await response.value();
    }

    /**
     * Get an invitation using the invitationId
     * Get Invitation
     */
    async getInvitationRaw(requestParameters: GetInvitationRequest): Promise<runtime.ApiResponse<StreemApiGetInvitationResponse>> {
        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling getInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/invitations/{invitation_sid}`.replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Get an invitation using the invitationId
     * Get Invitation
     */
    async getInvitation(invitationSid: string): Promise<StreemApiGetInvitationResponse> {
        const response = await this.getInvitationRaw({ invitationSid: invitationSid });
        return await response.value();
    }

    /**
     * Get all SMS Delivery Statuses for an invitation using the invitationId
     * Get Invitation SMS Delivery Statuses
     */
    async getInvitationSmsStatusesRaw(requestParameters: GetInvitationSmsStatusesRequest): Promise<runtime.ApiResponse<StreemApiGetInvitationSmsStatusesResponse>> {
        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling getInvitationSmsStatuses.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/invitations/{invitation_sid}/sms-delivery-statuses`.replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetInvitationSmsStatusesResponseFromJSON(jsonValue));
    }

    /**
     * Get all SMS Delivery Statuses for an invitation using the invitationId
     * Get Invitation SMS Delivery Statuses
     */
    async getInvitationSmsStatuses(invitationSid: string): Promise<StreemApiGetInvitationSmsStatusesResponse> {
        const response = await this.getInvitationSmsStatusesRaw({ invitationSid: invitationSid });
        return await response.value();
    }

    /**
     * Generate an invite link and send it to an email or phone number so they can connect.
     * Generate and send invite link
     */
    async sendInvitationSmsRaw(requestParameters: SendInvitationSmsRequest): Promise<runtime.ApiResponse<StreemApiSendInvitationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/invitations/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSendInvitationRequestToJSON(requestParameters.streemApiSendInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSendInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Generate an invite link and send it to an email or phone number so they can connect.
     * Generate and send invite link
     */
    async sendInvitationSms(streemApiSendInvitationRequest?: StreemApiSendInvitationRequest): Promise<StreemApiSendInvitationResponse> {
        const response = await this.sendInvitationSmsRaw({ streemApiSendInvitationRequest: streemApiSendInvitationRequest });
        return await response.value();
    }

}
