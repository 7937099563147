/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiStreemRoomWallCredentials,
    StreemApiStreemRoomWallCredentialsFromJSON,
    StreemApiStreemRoomWallCredentialsFromJSONTyped,
    StreemApiStreemRoomWallCredentialsToJSON,
    StreemApiTwilioRoomWallCredentials,
    StreemApiTwilioRoomWallCredentialsFromJSON,
    StreemApiTwilioRoomWallCredentialsFromJSONTyped,
    StreemApiTwilioRoomWallCredentialsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateRoomWallCredentialsResponse
 */
export interface StreemApiCreateRoomWallCredentialsResponse {
    /**
     * 
     * @type {StreemApiTwilioRoomWallCredentials}
     * @memberof StreemApiCreateRoomWallCredentialsResponse
     */
    twilioRoomWallCredentials?: StreemApiTwilioRoomWallCredentials;
    /**
     * 
     * @type {StreemApiStreemRoomWallCredentials}
     * @memberof StreemApiCreateRoomWallCredentialsResponse
     */
    streemRoomWallCredentials?: StreemApiStreemRoomWallCredentials;
}

export function StreemApiCreateRoomWallCredentialsResponseFromJSON(json: any): StreemApiCreateRoomWallCredentialsResponse {
    return StreemApiCreateRoomWallCredentialsResponseFromJSONTyped(json, false);
}

export function StreemApiCreateRoomWallCredentialsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateRoomWallCredentialsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'twilioRoomWallCredentials': !exists(json, 'twilio_room_wall_credentials') ? undefined : StreemApiTwilioRoomWallCredentialsFromJSON(json['twilio_room_wall_credentials']),
        'streemRoomWallCredentials': !exists(json, 'streem_room_wall_credentials') ? undefined : StreemApiStreemRoomWallCredentialsFromJSON(json['streem_room_wall_credentials']),
    };
}

export function StreemApiCreateRoomWallCredentialsResponseToJSON(value?: StreemApiCreateRoomWallCredentialsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'twilio_room_wall_credentials': StreemApiTwilioRoomWallCredentialsToJSON(value.twilioRoomWallCredentials),
        'streem_room_wall_credentials': StreemApiStreemRoomWallCredentialsToJSON(value.streemRoomWallCredentials),
    };
}


