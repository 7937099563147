/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiInvitation
 */
export interface StreemApiInvitation {
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    invitationSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    fromUserSid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiInvitation
     */
    consumed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    code?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiInvitation
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    toUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    toUserExternalUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiInvitation
     */
    integrationId?: string;
}

export function StreemApiInvitationFromJSON(json: any): StreemApiInvitation {
    return StreemApiInvitationFromJSONTyped(json, false);
}

export function StreemApiInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invitationSid': !exists(json, 'invitation_sid') ? undefined : json['invitation_sid'],
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'fromUserSid': !exists(json, 'from_user_sid') ? undefined : json['from_user_sid'],
        'consumed': !exists(json, 'consumed') ? undefined : json['consumed'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'toUserName': !exists(json, 'to_user_name') ? undefined : json['to_user_name'],
        'toUserExternalUserId': !exists(json, 'to_user_external_user_id') ? undefined : json['to_user_external_user_id'],
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
    };
}

export function StreemApiInvitationToJSON(value?: StreemApiInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invitation_sid': value.invitationSid,
        'company_sid': value.companySid,
        'from_user_sid': value.fromUserSid,
        'consumed': value.consumed,
        'code': value.code,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'to_user_name': value.toUserName,
        'to_user_external_user_id': value.toUserExternalUserId,
        'reference_id': value.referenceId,
        'room_sid': value.roomSid,
        'integration_id': value.integrationId,
    };
}


