/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateSignedDownloadUrlRequest,
    StreemApiCreateSignedDownloadUrlRequestFromJSON,
    StreemApiCreateSignedDownloadUrlRequestToJSON,
    StreemApiCreateSignedDownloadUrlResponse,
    StreemApiCreateSignedDownloadUrlResponseFromJSON,
    StreemApiCreateSignedDownloadUrlResponseToJSON,
} from '../models';

export interface CreateSignedDownloadUrlRequest {
    streemApiCreateSignedDownloadUrlRequest?: StreemApiCreateSignedDownloadUrlRequest;
}

/**
 * 
 */
export class DownloadsApi extends runtime.BaseAPI {

    /**
     * Creates a signed URL that can be used for downloading a file from storage
     * Create Signed URL
     */
    async createSignedDownloadUrlRaw(requestParameters: CreateSignedDownloadUrlRequest): Promise<runtime.ApiResponse<StreemApiCreateSignedDownloadUrlResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/downloads/signed-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateSignedDownloadUrlRequestToJSON(requestParameters.streemApiCreateSignedDownloadUrlRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateSignedDownloadUrlResponseFromJSON(jsonValue));
    }

    /**
     * Creates a signed URL that can be used for downloading a file from storage
     * Create Signed URL
     */
    async createSignedDownloadUrl(streemApiCreateSignedDownloadUrlRequest?: StreemApiCreateSignedDownloadUrlRequest): Promise<StreemApiCreateSignedDownloadUrlResponse> {
        const response = await this.createSignedDownloadUrlRaw({ streemApiCreateSignedDownloadUrlRequest: streemApiCreateSignedDownloadUrlRequest });
        return await response.value();
    }

}
