import { useEffect, createContext, useContext } from 'react';
import { AppStore } from '../stores/app_store';
import { SDKStore } from '../stores/sdk_store';
import { ConfigStore } from '../stores/config_store';

export interface GlobalContext {
    appStore: AppStore;
    sdkStore: SDKStore;
    configStore: ConfigStore;
}

const globalContext = createContext<GlobalContext | undefined>(undefined);

const appStore = new AppStore();
const sdkStore = new SDKStore();
const configStore = new ConfigStore();

export function useInitializeGlobalStores(): GlobalContext {
    useEffect(() => {
        appStore.connect();
        return () => {
            appStore.disconnect();
        };
    }, []);

    return {
        appStore,
        sdkStore,
        configStore,
    };
}

export function useGlobalStore() {
    const context = useContext(globalContext);

    if (!context) {
        throw new Error('Unable to useGlobalStore without parent <GlobalStoreProvider />');
    }

    return context;
}

export const GlobalStoreProvider = globalContext.Provider;
