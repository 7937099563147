/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoomInvitation,
    StreemApiRoomInvitationFromJSON,
    StreemApiRoomInvitationFromJSONTyped,
    StreemApiRoomInvitationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCancelRoomInvitationResponse
 */
export interface StreemApiCancelRoomInvitationResponse {
    /**
     * 
     * @type {StreemApiRoomInvitation}
     * @memberof StreemApiCancelRoomInvitationResponse
     */
    roomInvitation?: StreemApiRoomInvitation;
}

export function StreemApiCancelRoomInvitationResponseFromJSON(json: any): StreemApiCancelRoomInvitationResponse {
    return StreemApiCancelRoomInvitationResponseFromJSONTyped(json, false);
}

export function StreemApiCancelRoomInvitationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCancelRoomInvitationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomInvitation': !exists(json, 'room_invitation') ? undefined : StreemApiRoomInvitationFromJSON(json['room_invitation']),
    };
}

export function StreemApiCancelRoomInvitationResponseToJSON(value?: StreemApiCancelRoomInvitationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_invitation': StreemApiRoomInvitationToJSON(value.roomInvitation),
    };
}


