/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogItem,
    CatalogItemFromJSON,
    CatalogItemFromJSONTyped,
    CatalogItemToJSON,
    Destination,
    DestinationFromJSON,
    DestinationFromJSONTyped,
    DestinationToJSON,
    RoomString,
    RoomStringFromJSON,
    RoomStringFromJSONTyped,
    RoomStringToJSON,
    Step,
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
    TutorialAnchor,
    TutorialAnchorFromJSON,
    TutorialAnchorFromJSONTyped,
    TutorialAnchorToJSON,
    TutorialElement,
    TutorialElementFromJSON,
    TutorialElementFromJSONTyped,
    TutorialElementToJSON,
    TutorialTheme,
    TutorialThemeFromJSON,
    TutorialThemeFromJSONTyped,
    TutorialThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tutorial
 */
export interface Tutorial {
    /**
     * 
     * @type {string}
     * @memberof Tutorial
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutorial
     */
    id?: string;
    /**
     * 
     * @type {TutorialAnchor}
     * @memberof Tutorial
     */
    anchor?: TutorialAnchor;
    /**
     * 
     * @type {Array<Step>}
     * @memberof Tutorial
     */
    steps?: Array<Step>;
    /**
     * 
     * @type {Destination}
     * @memberof Tutorial
     */
    start?: Destination;
    /**
     * 
     * @type {TutorialTheme}
     * @memberof Tutorial
     */
    theme?: TutorialTheme;
    /**
     * 
     * @type {Array<RoomString>}
     * @memberof Tutorial
     */
    strings?: Array<RoomString>;
    /**
     * 
     * @type {Array<CatalogItem>}
     * @memberof Tutorial
     */
    catalog?: Array<CatalogItem>;
    /**
     * 
     * @type {Array<TutorialElement>}
     * @memberof Tutorial
     */
    elements?: Array<TutorialElement>;
}

export function TutorialFromJSON(json: any): Tutorial {
    return TutorialFromJSONTyped(json, false);
}

export function TutorialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tutorial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'anchor': !exists(json, 'anchor') ? undefined : TutorialAnchorFromJSON(json['anchor']),
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(StepFromJSON)),
        'start': !exists(json, 'start') ? undefined : DestinationFromJSON(json['start']),
        'theme': !exists(json, 'theme') ? undefined : TutorialThemeFromJSON(json['theme']),
        'strings': !exists(json, 'strings') ? undefined : ((json['strings'] as Array<any>).map(RoomStringFromJSON)),
        'catalog': !exists(json, 'catalog') ? undefined : ((json['catalog'] as Array<any>).map(CatalogItemFromJSON)),
        'elements': !exists(json, 'elements') ? undefined : ((json['elements'] as Array<any>).map(TutorialElementFromJSON)),
    };
}

export function TutorialToJSON(value?: Tutorial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'id': value.id,
        'anchor': TutorialAnchorToJSON(value.anchor),
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(StepToJSON)),
        'start': DestinationToJSON(value.start),
        'theme': TutorialThemeToJSON(value.theme),
        'strings': value.strings === undefined ? undefined : ((value.strings as Array<any>).map(RoomStringToJSON)),
        'catalog': value.catalog === undefined ? undefined : ((value.catalog as Array<any>).map(CatalogItemToJSON)),
        'elements': value.elements === undefined ? undefined : ((value.elements as Array<any>).map(TutorialElementToJSON)),
    };
}


