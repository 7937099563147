/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiBatchCreateUserResponseFailure,
    StreemApiBatchCreateUserResponseFailureFromJSON,
    StreemApiBatchCreateUserResponseFailureFromJSONTyped,
    StreemApiBatchCreateUserResponseFailureToJSON,
    StreemApiCreateCompanyUserResponse,
    StreemApiCreateCompanyUserResponseFromJSON,
    StreemApiCreateCompanyUserResponseFromJSONTyped,
    StreemApiCreateCompanyUserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiBatchCreateUserResponse
 */
export interface StreemApiBatchCreateUserResponse {
    /**
     * 
     * @type {Array<StreemApiCreateCompanyUserResponse>}
     * @memberof StreemApiBatchCreateUserResponse
     */
    created?: Array<StreemApiCreateCompanyUserResponse>;
    /**
     * 
     * @type {Array<StreemApiBatchCreateUserResponseFailure>}
     * @memberof StreemApiBatchCreateUserResponse
     */
    failed?: Array<StreemApiBatchCreateUserResponseFailure>;
}

export function StreemApiBatchCreateUserResponseFromJSON(json: any): StreemApiBatchCreateUserResponse {
    return StreemApiBatchCreateUserResponseFromJSONTyped(json, false);
}

export function StreemApiBatchCreateUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiBatchCreateUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : ((json['created'] as Array<any>).map(StreemApiCreateCompanyUserResponseFromJSON)),
        'failed': !exists(json, 'failed') ? undefined : ((json['failed'] as Array<any>).map(StreemApiBatchCreateUserResponseFailureFromJSON)),
    };
}

export function StreemApiBatchCreateUserResponseToJSON(value?: StreemApiBatchCreateUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : ((value.created as Array<any>).map(StreemApiCreateCompanyUserResponseToJSON)),
        'failed': value.failed === undefined ? undefined : ((value.failed as Array<any>).map(StreemApiBatchCreateUserResponseFailureToJSON)),
    };
}


