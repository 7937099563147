import { Subscription } from 'rxjs';

export class SubscriptionManager {
    private subscriptions: Subscription[];

    constructor(initialSubscriptions?: Subscription[]) {
        this.subscriptions = initialSubscriptions || [];
    }

    add(subscription: Subscription): void {
        this.subscriptions.push(subscription);
    }

    remove(subscription: Subscription): void {
        const index = this.subscriptions.findIndex(sub => sub === subscription);
        if (index > -1) {
            this.subscriptions[index].unsubscribe();
            this.subscriptions.splice(index, 1);
        }
    }

    addSubscriptions(subscriptions: Subscription[]): void {
        this.subscriptions = this.subscriptions.concat(subscriptions);
    }

    unsubscribeAll(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions.splice(0, this.subscriptions.length);
    }
}
