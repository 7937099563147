/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallLogEntry,
    StreemApiCallLogEntryFromJSON,
    StreemApiCallLogEntryFromJSONTyped,
    StreemApiCallLogEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetUserCallLogEntryResponse
 */
export interface StreemApiGetUserCallLogEntryResponse {
    /**
     * 
     * @type {StreemApiCallLogEntry}
     * @memberof StreemApiGetUserCallLogEntryResponse
     */
    callLogEntry?: StreemApiCallLogEntry;
}

export function StreemApiGetUserCallLogEntryResponseFromJSON(json: any): StreemApiGetUserCallLogEntryResponse {
    return StreemApiGetUserCallLogEntryResponseFromJSONTyped(json, false);
}

export function StreemApiGetUserCallLogEntryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetUserCallLogEntryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callLogEntry': !exists(json, 'call_log_entry') ? undefined : StreemApiCallLogEntryFromJSON(json['call_log_entry']),
    };
}

export function StreemApiGetUserCallLogEntryResponseToJSON(value?: StreemApiGetUserCallLogEntryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_log_entry': StreemApiCallLogEntryToJSON(value.callLogEntry),
    };
}


