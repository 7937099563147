/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemStreemshotRequest
 */
export interface WallItemStreemshotRequest {
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRequest
     */
    streemshotId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRequest
     */
    requestedOfUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRequest
     */
    requestedOfDeviceId?: string;
}

export function WallItemStreemshotRequestFromJSON(json: any): WallItemStreemshotRequest {
    return WallItemStreemshotRequestFromJSONTyped(json, false);
}

export function WallItemStreemshotRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemStreemshotRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streemshotId': !exists(json, 'streemshot_id') ? undefined : json['streemshot_id'],
        'requestedOfUserId': !exists(json, 'requested_of_user_id') ? undefined : json['requested_of_user_id'],
        'requestedOfDeviceId': !exists(json, 'requested_of_device_id') ? undefined : json['requested_of_device_id'],
    };
}

export function WallItemStreemshotRequestToJSON(value?: WallItemStreemshotRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streemshot_id': value.streemshotId,
        'requested_of_user_id': value.requestedOfUserId,
        'requested_of_device_id': value.requestedOfDeviceId,
    };
}


