/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ElementPresentation,
    ElementPresentationFromJSON,
    ElementPresentationFromJSONTyped,
    ElementPresentationToJSON,
    StreemApiTransform,
    StreemApiTransformFromJSON,
    StreemApiTransformFromJSONTyped,
    StreemApiTransformToJSON,
    StreemRoomArTrackableDefinition,
    StreemRoomArTrackableDefinitionFromJSON,
    StreemRoomArTrackableDefinitionFromJSONTyped,
    StreemRoomArTrackableDefinitionToJSON,
    TutorialAnchorInstructions,
    TutorialAnchorInstructionsFromJSON,
    TutorialAnchorInstructionsFromJSONTyped,
    TutorialAnchorInstructionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface TutorialAnchor
 */
export interface TutorialAnchor {
    /**
     * 
     * @type {TutorialAnchorInstructions}
     * @memberof TutorialAnchor
     */
    instructions?: TutorialAnchorInstructions;
    /**
     * 
     * @type {StreemRoomArTrackableDefinition}
     * @memberof TutorialAnchor
     */
    trackable?: StreemRoomArTrackableDefinition;
    /**
     * 
     * @type {StreemApiTransform}
     * @memberof TutorialAnchor
     */
    offset?: StreemApiTransform;
    /**
     * 
     * @type {Array<ElementPresentation>}
     * @memberof TutorialAnchor
     */
    anchorElements?: Array<ElementPresentation>;
    /**
     * 
     * @type {string}
     * @memberof TutorialAnchor
     */
    anchorId?: string;
}

export function TutorialAnchorFromJSON(json: any): TutorialAnchor {
    return TutorialAnchorFromJSONTyped(json, false);
}

export function TutorialAnchorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialAnchor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instructions': !exists(json, 'instructions') ? undefined : TutorialAnchorInstructionsFromJSON(json['instructions']),
        'trackable': !exists(json, 'trackable') ? undefined : StreemRoomArTrackableDefinitionFromJSON(json['trackable']),
        'offset': !exists(json, 'offset') ? undefined : StreemApiTransformFromJSON(json['offset']),
        'anchorElements': !exists(json, 'anchor_elements') ? undefined : ((json['anchor_elements'] as Array<any>).map(ElementPresentationFromJSON)),
        'anchorId': !exists(json, 'anchor_id') ? undefined : json['anchor_id'],
    };
}

export function TutorialAnchorToJSON(value?: TutorialAnchor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instructions': TutorialAnchorInstructionsToJSON(value.instructions),
        'trackable': StreemRoomArTrackableDefinitionToJSON(value.trackable),
        'offset': StreemApiTransformToJSON(value.offset),
        'anchor_elements': value.anchorElements === undefined ? undefined : ((value.anchorElements as Array<any>).map(ElementPresentationToJSON)),
        'anchor_id': value.anchorId,
    };
}


