/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSaveCompanyLicenseRequest
 */
export interface StreemApiSaveCompanyLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSaveCompanyLicenseRequest
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSaveCompanyLicenseRequest
     */
    licenseName?: string;
}

export function StreemApiSaveCompanyLicenseRequestFromJSON(json: any): StreemApiSaveCompanyLicenseRequest {
    return StreemApiSaveCompanyLicenseRequestFromJSONTyped(json, false);
}

export function StreemApiSaveCompanyLicenseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSaveCompanyLicenseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'licenseName': !exists(json, 'license_name') ? undefined : json['license_name'],
    };
}

export function StreemApiSaveCompanyLicenseRequestToJSON(value?: StreemApiSaveCompanyLicenseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_sid': value.companySid,
        'license_name': value.licenseName,
    };
}


