/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiWallWallItemEntry,
    StreemApiWallWallItemEntryFromJSON,
    StreemApiWallWallItemEntryFromJSONTyped,
    StreemApiWallWallItemEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiWallWallItemEntryEvent
 */
export interface StreemApiWallWallItemEntryEvent {
    /**
     * 
     * @type {StreemApiWallWallItemEntry}
     * @memberof StreemApiWallWallItemEntryEvent
     */
    wallItemEntry?: StreemApiWallWallItemEntry;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntryEvent
     */
    eventType?: StreemApiWallWallItemEntryEventEventType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntryEvent
     */
    connectionId?: string;
}

export function StreemApiWallWallItemEntryEventFromJSON(json: any): StreemApiWallWallItemEntryEvent {
    return StreemApiWallWallItemEntryEventFromJSONTyped(json, false);
}

export function StreemApiWallWallItemEntryEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWallWallItemEntryEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wallItemEntry': !exists(json, 'wall_item_entry') ? undefined : StreemApiWallWallItemEntryFromJSON(json['wall_item_entry']),
        'eventType': !exists(json, 'event_type') ? undefined : json['event_type'],
        'connectionId': !exists(json, 'connection_id') ? undefined : json['connection_id'],
    };
}

export function StreemApiWallWallItemEntryEventToJSON(value?: StreemApiWallWallItemEntryEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wall_item_entry': StreemApiWallWallItemEntryToJSON(value.wallItemEntry),
        'event_type': value.eventType,
        'connection_id': value.connectionId,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiWallWallItemEntryEventEventType {
    ADDED = 'EVENT_TYPE_ADDED',
    UPDATED = 'EVENT_TYPE_UPDATED',
    DELETED = 'EVENT_TYPE_DELETED',
    INVALID = 'EVENT_TYPE_INVALID'
}


