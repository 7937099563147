/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ElementGroupElementPresentation,
    ElementGroupElementPresentationFromJSON,
    ElementGroupElementPresentationFromJSONTyped,
    ElementGroupElementPresentationToJSON,
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
    StreemApiTransform,
    StreemApiTransformFromJSON,
    StreemApiTransformFromJSONTyped,
    StreemApiTransformToJSON,
} from './';

/**
 * 
 * @export
 * @interface ElementGroup
 */
export interface ElementGroup {
    /**
     * 
     * @type {string}
     * @memberof ElementGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ElementGroup
     */
    anchorId?: string;
    /**
     * 
     * @type {Array<ElementGroupElementPresentation>}
     * @memberof ElementGroup
     */
    elements?: Array<ElementGroupElementPresentation>;
    /**
     * 
     * @type {StreemApiTransform}
     * @memberof ElementGroup
     */
    offset?: StreemApiTransform;
    /**
     * 
     * @type {StreemApiPoint}
     * @memberof ElementGroup
     */
    focalPoint?: StreemApiPoint;
}

export function ElementGroupFromJSON(json: any): ElementGroup {
    return ElementGroupFromJSONTyped(json, false);
}

export function ElementGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'anchorId': !exists(json, 'anchor_id') ? undefined : json['anchor_id'],
        'elements': !exists(json, 'elements') ? undefined : ((json['elements'] as Array<any>).map(ElementGroupElementPresentationFromJSON)),
        'offset': !exists(json, 'offset') ? undefined : StreemApiTransformFromJSON(json['offset']),
        'focalPoint': !exists(json, 'focal_point') ? undefined : StreemApiPointFromJSON(json['focal_point']),
    };
}

export function ElementGroupToJSON(value?: ElementGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'anchor_id': value.anchorId,
        'elements': value.elements === undefined ? undefined : ((value.elements as Array<any>).map(ElementGroupElementPresentationToJSON)),
        'offset': StreemApiTransformToJSON(value.offset),
        'focal_point': StreemApiPointToJSON(value.focalPoint),
    };
}


