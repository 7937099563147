/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemArAnchorStatus
 */
export interface WallItemArAnchorStatus {
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorStatus
     */
    anchorId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorStatus
     */
    trackingStatus?: WallItemArAnchorStatusTrackingStatus;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorStatus
     */
    anchorUpdatesProvider?: WallItemArAnchorStatusAnchorUpdatesProvider;
}

export function WallItemArAnchorStatusFromJSON(json: any): WallItemArAnchorStatus {
    return WallItemArAnchorStatusFromJSONTyped(json, false);
}

export function WallItemArAnchorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArAnchorStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anchorId': !exists(json, 'anchor_id') ? undefined : json['anchor_id'],
        'trackingStatus': !exists(json, 'tracking_status') ? undefined : json['tracking_status'],
        'anchorUpdatesProvider': !exists(json, 'anchor_updates_provider') ? undefined : json['anchor_updates_provider'],
    };
}

export function WallItemArAnchorStatusToJSON(value?: WallItemArAnchorStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anchor_id': value.anchorId,
        'tracking_status': value.trackingStatus,
        'anchor_updates_provider': value.anchorUpdatesProvider,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArAnchorStatusTrackingStatus {
    ERROR = 'ANCHOR_TRACKING_STATUS_ERROR',
    NORMAL = 'ANCHOR_TRACKING_STATUS_NORMAL',
    PAUSED = 'ANCHOR_TRACKING_STATUS_PAUSED',
    STOPPED = 'ANCHOR_TRACKING_STATUS_STOPPED',
    INVALID = 'ANCHOR_TRACKING_STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArAnchorStatusAnchorUpdatesProvider {
    TWILIOSYNCSTREAM = 'STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM',
    DATATRACK = 'STREAMING_MESSAGE_PROVIDER_DATA_TRACK',
    INVALID = 'STREAMING_MESSAGE_PROVIDER_INVALID'
}


