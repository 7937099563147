/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PickListTheme,
    PickListThemeFromJSON,
    PickListThemeFromJSONTyped,
    PickListThemeToJSON,
    TextTheme,
    TextThemeFromJSON,
    TextThemeFromJSONTyped,
    TextThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TutorialTheme
 */
export interface TutorialTheme {
    /**
     * 
     * @type {TextTheme}
     * @memberof TutorialTheme
     */
    text?: TextTheme;
    /**
     * 
     * @type {PickListTheme}
     * @memberof TutorialTheme
     */
    pickList?: PickListTheme;
}

export function TutorialThemeFromJSON(json: any): TutorialTheme {
    return TutorialThemeFromJSONTyped(json, false);
}

export function TutorialThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialTheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : TextThemeFromJSON(json['text']),
        'pickList': !exists(json, 'pick_list') ? undefined : PickListThemeFromJSON(json['pick_list']),
    };
}

export function TutorialThemeToJSON(value?: TutorialTheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': TextThemeToJSON(value.text),
        'pick_list': PickListThemeToJSON(value.pickList),
    };
}


