/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUpdateAppIosVoipCredentialsRequest
 */
export interface StreemApiUpdateAppIosVoipCredentialsRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateAppIosVoipCredentialsRequest
     */
    sslCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateAppIosVoipCredentialsRequest
     */
    privateKey?: string;
}

export function StreemApiUpdateAppIosVoipCredentialsRequestFromJSON(json: any): StreemApiUpdateAppIosVoipCredentialsRequest {
    return StreemApiUpdateAppIosVoipCredentialsRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateAppIosVoipCredentialsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateAppIosVoipCredentialsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sslCertificate': !exists(json, 'ssl_certificate') ? undefined : json['ssl_certificate'],
        'privateKey': !exists(json, 'private_key') ? undefined : json['private_key'],
    };
}

export function StreemApiUpdateAppIosVoipCredentialsRequestToJSON(value?: StreemApiUpdateAppIosVoipCredentialsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ssl_certificate': value.sslCertificate,
        'private_key': value.privateKey,
    };
}


