/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Model3dAnimation,
    Model3dAnimationFromJSON,
    Model3dAnimationFromJSONTyped,
    Model3dAnimationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Model3dPresentation
 */
export interface Model3dPresentation {
    /**
     * 
     * @type {Model3dAnimation}
     * @memberof Model3dPresentation
     */
    animation?: Model3dAnimation;
}

export function Model3dPresentationFromJSON(json: any): Model3dPresentation {
    return Model3dPresentationFromJSONTyped(json, false);
}

export function Model3dPresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model3dPresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'animation': !exists(json, 'animation') ? undefined : Model3dAnimationFromJSON(json['animation']),
    };
}

export function Model3dPresentationToJSON(value?: Model3dPresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'animation': Model3dAnimationToJSON(value.animation),
    };
}


