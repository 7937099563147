/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CameraIntrinsics
 */
export interface CameraIntrinsics {
    /**
     * 
     * @type {number}
     * @memberof CameraIntrinsics
     */
    focalLengthPxX?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraIntrinsics
     */
    focalLengthPxY?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraIntrinsics
     */
    axisSkew?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraIntrinsics
     */
    principalPointX?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraIntrinsics
     */
    principalPointY?: number;
}

export function CameraIntrinsicsFromJSON(json: any): CameraIntrinsics {
    return CameraIntrinsicsFromJSONTyped(json, false);
}

export function CameraIntrinsicsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CameraIntrinsics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'focalLengthPxX': !exists(json, 'focal_length_px_x') ? undefined : json['focal_length_px_x'],
        'focalLengthPxY': !exists(json, 'focal_length_px_y') ? undefined : json['focal_length_px_y'],
        'axisSkew': !exists(json, 'axis_skew') ? undefined : json['axis_skew'],
        'principalPointX': !exists(json, 'principal_point_x') ? undefined : json['principal_point_x'],
        'principalPointY': !exists(json, 'principal_point_y') ? undefined : json['principal_point_y'],
    };
}

export function CameraIntrinsicsToJSON(value?: CameraIntrinsics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'focal_length_px_x': value.focalLengthPxX,
        'focal_length_px_y': value.focalLengthPxY,
        'axis_skew': value.axisSkew,
        'principal_point_x': value.principalPointX,
        'principal_point_y': value.principalPointY,
    };
}


