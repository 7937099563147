import DetectRTC from 'detectrtc';
import { useEffect } from 'react';
import appLogger from '../util/app_logger';

export const useDetectRtc = () => {
    useEffect(() => {
        DetectRTC.load(() => {
            appLogger.setContextValue('webrtc', () => DetectRTC);
        });
    }, []);
};
