/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAttendeeFeatures
 */
export interface StreemApiAttendeeFeatures {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAttendeeFeatures
     */
    maxCount?: number;
}

export function StreemApiAttendeeFeaturesFromJSON(json: any): StreemApiAttendeeFeatures {
    return StreemApiAttendeeFeaturesFromJSONTyped(json, false);
}

export function StreemApiAttendeeFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAttendeeFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxCount': !exists(json, 'max_count') ? undefined : json['max_count'],
    };
}

export function StreemApiAttendeeFeaturesToJSON(value?: StreemApiAttendeeFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_count': value.maxCount,
    };
}


