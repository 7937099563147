/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeasurePresentation
 */
export interface MeasurePresentation {
    /**
     * 
     * @type {StreemApiPoint}
     * @memberof MeasurePresentation
     */
    vertex2Position?: StreemApiPoint;
    /**
     * 
     * @type {string}
     * @memberof MeasurePresentation
     */
    units?: MeasurePresentationUnits;
}

export function MeasurePresentationFromJSON(json: any): MeasurePresentation {
    return MeasurePresentationFromJSONTyped(json, false);
}

export function MeasurePresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurePresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vertex2Position': !exists(json, 'vertex2_position') ? undefined : StreemApiPointFromJSON(json['vertex2_position']),
        'units': !exists(json, 'units') ? undefined : json['units'],
    };
}

export function MeasurePresentationToJSON(value?: MeasurePresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vertex2_position': StreemApiPointToJSON(value.vertex2Position),
        'units': value.units,
    };
}

/**
* @export
* @enum {string}
*/
export enum MeasurePresentationUnits {
    INCHES = 'UNITS_INCHES',
    FEET = 'UNITS_FEET',
    MILLIMETERS = 'UNITS_MILLIMETERS',
    CENTIMETERS = 'UNITS_CENTIMETERS',
    INVALID = 'UNITS_INVALID'
}


