/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiNote,
    StreemApiNoteFromJSON,
    StreemApiNoteFromJSONTyped,
    StreemApiNoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetRoomNoteResponse
 */
export interface StreemApiGetRoomNoteResponse {
    /**
     * 
     * @type {StreemApiNote}
     * @memberof StreemApiGetRoomNoteResponse
     */
    note?: StreemApiNote;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGetRoomNoteResponse
     */
    roomSid?: string;
}

export function StreemApiGetRoomNoteResponseFromJSON(json: any): StreemApiGetRoomNoteResponse {
    return StreemApiGetRoomNoteResponseFromJSONTyped(json, false);
}

export function StreemApiGetRoomNoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetRoomNoteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : StreemApiNoteFromJSON(json['note']),
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
    };
}

export function StreemApiGetRoomNoteResponseToJSON(value?: StreemApiGetRoomNoteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': StreemApiNoteToJSON(value.note),
        'room_sid': value.roomSid,
    };
}


