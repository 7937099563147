/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemArtifactRequest
 */
export interface WallItemArtifactRequest {
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifactRequest
     */
    artifactId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifactRequest
     */
    requestedForUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifactRequest
     */
    requestedForDeviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifactRequest
     */
    artifactType?: WallItemArtifactRequestArtifactType;
}

export function WallItemArtifactRequestFromJSON(json: any): WallItemArtifactRequest {
    return WallItemArtifactRequestFromJSONTyped(json, false);
}

export function WallItemArtifactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArtifactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactId': !exists(json, 'artifact_id') ? undefined : json['artifact_id'],
        'requestedForUserId': !exists(json, 'requested_for_user_id') ? undefined : json['requested_for_user_id'],
        'requestedForDeviceId': !exists(json, 'requested_for_device_id') ? undefined : json['requested_for_device_id'],
        'artifactType': !exists(json, 'artifact_type') ? undefined : json['artifact_type'],
    };
}

export function WallItemArtifactRequestToJSON(value?: WallItemArtifactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifact_id': value.artifactId,
        'requested_for_user_id': value.requestedForUserId,
        'requested_for_device_id': value.requestedForDeviceId,
        'artifact_type': value.artifactType,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArtifactRequestArtifactType {
    UNKNOWN = 'UNKNOWN',
    GPSPOSITION = 'GPS_POSITION',
    RECORDING = 'RECORDING',
    STREEMSHOT = 'STREEMSHOT',
    MESH = 'MESH',
    TRANSCRIPTION = 'TRANSCRIPTION',
    SCENESHOT = 'SCENESHOT',
    LAYOUTESTIMATION = 'LAYOUT_ESTIMATION',
    TEXTUREDMESH = 'TEXTURED_MESH',
    BOOKMARK = 'BOOKMARK',
    ROOMOUTCOMEREPORT = 'ROOM_OUTCOME_REPORT'
}


