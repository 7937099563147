/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPreflight,
    StreemApiPreflightFromJSON,
    StreemApiPreflightFromJSONTyped,
    StreemApiPreflightToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiTwilioRoomVideoCredentials
 */
export interface StreemApiTwilioRoomVideoCredentials {
    /**
     * 
     * @type {string}
     * @memberof StreemApiTwilioRoomVideoCredentials
     */
    token?: string;
    /**
     * 
     * @type {StreemApiPreflight}
     * @memberof StreemApiTwilioRoomVideoCredentials
     */
    preflight?: StreemApiPreflight;
}

export function StreemApiTwilioRoomVideoCredentialsFromJSON(json: any): StreemApiTwilioRoomVideoCredentials {
    return StreemApiTwilioRoomVideoCredentialsFromJSONTyped(json, false);
}

export function StreemApiTwilioRoomVideoCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiTwilioRoomVideoCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'preflight': !exists(json, 'preflight') ? undefined : StreemApiPreflightFromJSON(json['preflight']),
    };
}

export function StreemApiTwilioRoomVideoCredentialsToJSON(value?: StreemApiTwilioRoomVideoCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'preflight': StreemApiPreflightToJSON(value.preflight),
    };
}


