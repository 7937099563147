/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WallItem,
    WallItemFromJSON,
    WallItemFromJSONTyped,
    WallItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetRoomWallItemsResponse
 */
export interface StreemApiGetRoomWallItemsResponse {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGetRoomWallItemsResponse
     */
    roomSid?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGetRoomWallItemsResponse
     */
    wallItemCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGetRoomWallItemsResponse
     */
    encryptedWallItemCount?: number;
    /**
     * 
     * @type {Array<WallItem>}
     * @memberof StreemApiGetRoomWallItemsResponse
     */
    wallItems?: Array<WallItem>;
    /**
     * 
     * @type {Array<WallItem>}
     * @memberof StreemApiGetRoomWallItemsResponse
     */
    encryptedWallItems?: Array<WallItem>;
}

export function StreemApiGetRoomWallItemsResponseFromJSON(json: any): StreemApiGetRoomWallItemsResponse {
    return StreemApiGetRoomWallItemsResponseFromJSONTyped(json, false);
}

export function StreemApiGetRoomWallItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetRoomWallItemsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'wallItemCount': !exists(json, 'wall_item_count') ? undefined : json['wall_item_count'],
        'encryptedWallItemCount': !exists(json, 'encrypted_wall_item_count') ? undefined : json['encrypted_wall_item_count'],
        'wallItems': !exists(json, 'wall_items') ? undefined : ((json['wall_items'] as Array<any>).map(WallItemFromJSON)),
        'encryptedWallItems': !exists(json, 'encrypted_wall_items') ? undefined : ((json['encrypted_wall_items'] as Array<any>).map(WallItemFromJSON)),
    };
}

export function StreemApiGetRoomWallItemsResponseToJSON(value?: StreemApiGetRoomWallItemsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'wall_item_count': value.wallItemCount,
        'encrypted_wall_item_count': value.encryptedWallItemCount,
        'wall_items': value.wallItems === undefined ? undefined : ((value.wallItems as Array<any>).map(WallItemToJSON)),
        'encrypted_wall_items': value.encryptedWallItems === undefined ? undefined : ((value.encryptedWallItems as Array<any>).map(WallItemToJSON)),
    };
}


