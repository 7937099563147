interface FormatDateColumnProps {
    returnDayAndTime?: boolean;
    dateFormat?: Intl.DateTimeFormatOptions;
}

/**
 * Returns the datetime as a Date object
 * @param time
 */
export const getTimeAsDate = (time: Date | string): Date => {
    return typeof time === 'string' ? new Date(time) : time;
};

/**
 * Returns the duration of the call in HH:MM:SS format
 * @param callLogEntry
 */
export const getDuration = (entryStartTime: Date | undefined, entryEndTime?: Date | undefined) => {
    const startDate = getTimeAsDate(entryStartTime);
    const endDate = getTimeAsDate(entryEndTime);
    if (!endDate || !startDate) {
        return '';
    }

    const time = Math.round((endDate.getTime() - startDate.getTime()) / 1000);
    const hours = Math.floor(time / 3600) % 24;
    const minutes = Math.floor(time / 60) % 60;
    const seconds = time % 60;

    let duration = seconds > 9 ? '' + seconds : '0' + seconds;
    if (!minutes) {
        duration = '00:' + duration;
    }
    if (minutes) {
        const formattedMinutes = minutes > 9 ? '' + minutes : '0' + minutes;
        duration = formattedMinutes + ':' + duration;
    }
    if (hours) {
        const formattedHours = hours > 9 ? '' + hours : '0' + hours;
        duration = formattedHours + ':' + duration;
    }

    return duration.trim();
};

/**
 * Consumes a an ISO 8601 date string to turn HH:MM if date
 * is within the past 24 hours. Returns MM DD if date is older than 24 hours.
 *
 * Optional params allows return of both Day + Time
 *
 * @param dateStr An ISO 8601 date string
 * @param props Options to configure date
 */
export function formatDateColumn(
    dateStr: string,
    props: FormatDateColumnProps = { returnDayAndTime: false, dateFormat: {} },
): string[] {
    const date = new Date(dateStr);

    if (!props.returnDayAndTime) {
        return [
            isToday(date)
                ? create12HourTime(date)
                : date.toLocaleDateString('en-US', props.dateFormat),
        ];
    } else {
        return [
            isToday(date) ? 'Today' : date.toLocaleDateString('en-US', props.dateFormat),
            create12HourTime(date),
        ];
    }
}

/**
 * Turn date object into the time, using the 12 hour system
 * @param date
 */
function create12HourTime(date: Date): string {
    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
}

export function isToday(date: Date): boolean {
    const today = new Date();
    return date.toLocaleDateString() === today.toLocaleDateString();
}
