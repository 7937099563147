/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSON,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSONTyped,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseToJSON,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimate,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimateFromJSON,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimateFromJSONTyped,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimateToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEventGuidedExperiencePhaseUpdated
 */
export interface StreemApiAnalyticEventGuidedExperiencePhaseUpdated {
    /**
     * 
     * @type {StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimate}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdated
     */
    quality?: StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimate;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdated
     */
    introduction?: object;
    /**
     * 
     * @type {StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdated
     */
    assetCapture?: StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdated
     */
    addDetail?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdated
     */
    complete?: object;
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSON(json: any): StreemApiAnalyticEventGuidedExperiencePhaseUpdated {
    return StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventGuidedExperiencePhaseUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quality': !exists(json, 'quality') ? undefined : StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimateFromJSON(json['quality']),
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'assetCapture': !exists(json, 'asset_capture') ? undefined : StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSON(json['asset_capture']),
        'addDetail': !exists(json, 'add_detail') ? undefined : json['add_detail'],
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
    };
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedToJSON(value?: StreemApiAnalyticEventGuidedExperiencePhaseUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quality': StreemApiAnalyticEventGuidedExperiencePhaseUpdatedMeshQualityEstimateToJSON(value.quality),
        'introduction': value.introduction,
        'asset_capture': StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseToJSON(value.assetCapture),
        'add_detail': value.addDetail,
        'complete': value.complete,
    };
}


