/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPublicLoginConfiguration,
    StreemApiPublicLoginConfigurationFromJSON,
    StreemApiPublicLoginConfigurationFromJSONTyped,
    StreemApiPublicLoginConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGlobalGetCentralLoginPublicLoginConfigResponse
 */
export interface StreemApiGlobalGetCentralLoginPublicLoginConfigResponse {
    /**
     * 
     * @type {StreemApiPublicLoginConfiguration}
     * @memberof StreemApiGlobalGetCentralLoginPublicLoginConfigResponse
     */
    loginConfiguration?: StreemApiPublicLoginConfiguration;
}

export function StreemApiGlobalGetCentralLoginPublicLoginConfigResponseFromJSON(json: any): StreemApiGlobalGetCentralLoginPublicLoginConfigResponse {
    return StreemApiGlobalGetCentralLoginPublicLoginConfigResponseFromJSONTyped(json, false);
}

export function StreemApiGlobalGetCentralLoginPublicLoginConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGlobalGetCentralLoginPublicLoginConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loginConfiguration': !exists(json, 'login_configuration') ? undefined : StreemApiPublicLoginConfigurationFromJSON(json['login_configuration']),
    };
}

export function StreemApiGlobalGetCentralLoginPublicLoginConfigResponseToJSON(value?: StreemApiGlobalGetCentralLoginPublicLoginConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'login_configuration': StreemApiPublicLoginConfigurationToJSON(value.loginConfiguration),
    };
}


