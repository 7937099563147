/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleTypeInterval
 */
export interface GoogleTypeInterval {
    /**
     * 
     * @type {Date}
     * @memberof GoogleTypeInterval
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoogleTypeInterval
     */
    endTime?: Date;
}

export function GoogleTypeIntervalFromJSON(json: any): GoogleTypeInterval {
    return GoogleTypeIntervalFromJSONTyped(json, false);
}

export function GoogleTypeIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleTypeInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
    };
}

export function GoogleTypeIntervalToJSON(value?: GoogleTypeInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
    };
}


