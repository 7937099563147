/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPose,
    StreemApiPoseFromJSON,
    StreemApiPoseFromJSONTyped,
    StreemApiPoseToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemArAnchorRequest
 */
export interface WallItemArAnchorRequest {
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorRequest
     */
    forUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorRequest
     */
    forDeviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorRequest
     */
    trackableType?: WallItemArAnchorRequestTrackableType;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorRequest
     */
    trackableId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArAnchorRequest
     */
    anchorId?: string;
    /**
     * 
     * @type {StreemApiPose}
     * @memberof WallItemArAnchorRequest
     */
    requestedPose?: StreemApiPose;
}

export function WallItemArAnchorRequestFromJSON(json: any): WallItemArAnchorRequest {
    return WallItemArAnchorRequestFromJSONTyped(json, false);
}

export function WallItemArAnchorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArAnchorRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forUserId': !exists(json, 'for_user_id') ? undefined : json['for_user_id'],
        'forDeviceId': !exists(json, 'for_device_id') ? undefined : json['for_device_id'],
        'trackableType': !exists(json, 'trackable_type') ? undefined : json['trackable_type'],
        'trackableId': !exists(json, 'trackable_id') ? undefined : json['trackable_id'],
        'anchorId': !exists(json, 'anchor_id') ? undefined : json['anchor_id'],
        'requestedPose': !exists(json, 'requested_pose') ? undefined : StreemApiPoseFromJSON(json['requested_pose']),
    };
}

export function WallItemArAnchorRequestToJSON(value?: WallItemArAnchorRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'for_user_id': value.forUserId,
        'for_device_id': value.forDeviceId,
        'trackable_type': value.trackableType,
        'trackable_id': value.trackableId,
        'anchor_id': value.anchorId,
        'requested_pose': StreemApiPoseToJSON(value.requestedPose),
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArAnchorRequestTrackableType {
    MARKER2D = 'TRACKABLE_TYPE_MARKER_2D',
    MARKER3D = 'TRACKABLE_TYPE_MARKER_3D',
    PLANE = 'TRACKABLE_TYPE_PLANE',
    FEATUREPOINT = 'TRACKABLE_TYPE_FEATURE_POINT',
    MESH = 'TRACKABLE_TYPE_MESH',
    STATIC = 'TRACKABLE_TYPE_STATIC',
    INVALID = 'TRACKABLE_TYPE_INVALID'
}


