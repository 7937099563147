/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateStreemSdkTokenResponse,
    StreemApiCreateStreemSdkTokenResponseFromJSON,
    StreemApiCreateStreemSdkTokenResponseToJSON,
    StreemApiCreateSupportTokenRequest,
    StreemApiCreateSupportTokenRequestFromJSON,
    StreemApiCreateSupportTokenRequestToJSON,
    StreemApiCreateSupportTokenResponse,
    StreemApiCreateSupportTokenResponseFromJSON,
    StreemApiCreateSupportTokenResponseToJSON,
    StreemApiGetCurrentAuthIdentityResponse,
    StreemApiGetCurrentAuthIdentityResponseFromJSON,
    StreemApiGetCurrentAuthIdentityResponseToJSON,
    StreemApiGrantTokenFromInvitationRequest,
    StreemApiGrantTokenFromInvitationRequestFromJSON,
    StreemApiGrantTokenFromInvitationRequestToJSON,
    StreemApiGrantTokenFromInvitationResponse,
    StreemApiGrantTokenFromInvitationResponseFromJSON,
    StreemApiGrantTokenFromInvitationResponseToJSON,
    StreemApiGrantTokenResponse,
    StreemApiGrantTokenResponseFromJSON,
    StreemApiGrantTokenResponseToJSON,
} from '../models';

export interface CreateSupportTokenRequest {
    streemApiCreateSupportTokenRequest?: StreemApiCreateSupportTokenRequest;
}

export interface GrantTokenFromInvitationRequest {
    streemApiGrantTokenFromInvitationRequest?: StreemApiGrantTokenFromInvitationRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Logs out the Streem Auth user.
     * Initiate Logout For A User
     */
    async authLogoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/token/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logs out the Streem Auth user.
     * Initiate Logout For A User
     */
    async authLogout(): Promise<void> {
        await this.authLogoutRaw();
    }

    /**
     * Create a new Streem SDK Token for the authenticated user
     * Create Streem SDK Token
     */
    async createStreemSdkTokenRaw(): Promise<runtime.ApiResponse<StreemApiCreateStreemSdkTokenResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/sdk-tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateStreemSdkTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Streem SDK Token for the authenticated user
     * Create Streem SDK Token
     */
    async createStreemSdkToken(): Promise<StreemApiCreateStreemSdkTokenResponse> {
        const response = await this.createStreemSdkTokenRaw();
        return await response.value();
    }

    /**
     * Create a new SDK Token that can be use as an Embedded SSO Token for viewing the contents of a Room as a Streem Support principal
     * Create Support Token
     */
    async createSupportTokenRaw(requestParameters: CreateSupportTokenRequest): Promise<runtime.ApiResponse<StreemApiCreateSupportTokenResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/support-tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateSupportTokenRequestToJSON(requestParameters.streemApiCreateSupportTokenRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateSupportTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new SDK Token that can be use as an Embedded SSO Token for viewing the contents of a Room as a Streem Support principal
     * Create Support Token
     */
    async createSupportToken(streemApiCreateSupportTokenRequest?: StreemApiCreateSupportTokenRequest): Promise<StreemApiCreateSupportTokenResponse> {
        const response = await this.createSupportTokenRaw({ streemApiCreateSupportTokenRequest: streemApiCreateSupportTokenRequest });
        return await response.value();
    }

    /**
     * Get information about the currently-authenticated entity that is making this request.
     * Get Current Authentication Identity
     */
    async getCurrentAuthIdentityRaw(): Promise<runtime.ApiResponse<StreemApiGetCurrentAuthIdentityResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/current-identity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCurrentAuthIdentityResponseFromJSON(jsonValue));
    }

    /**
     * Get information about the currently-authenticated entity that is making this request.
     * Get Current Authentication Identity
     */
    async getCurrentAuthIdentity(): Promise<StreemApiGetCurrentAuthIdentityResponse> {
        const response = await this.getCurrentAuthIdentityRaw();
        return await response.value();
    }

    /**
     * Get OAuth Access Token using an invitation code.
     */
    async grantTokenFromInvitationRaw(requestParameters: GrantTokenFromInvitationRequest): Promise<runtime.ApiResponse<StreemApiGrantTokenFromInvitationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiGrantTokenFromInvitationRequestToJSON(requestParameters.streemApiGrantTokenFromInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGrantTokenFromInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Get OAuth Access Token using an invitation code.
     */
    async grantTokenFromInvitation(streemApiGrantTokenFromInvitationRequest?: StreemApiGrantTokenFromInvitationRequest): Promise<StreemApiGrantTokenFromInvitationResponse> {
        const response = await this.grantTokenFromInvitationRaw({ streemApiGrantTokenFromInvitationRequest: streemApiGrantTokenFromInvitationRequest });
        return await response.value();
    }

    /**
     * Use either a refresh token or SDK token to login
     * Get OAuth Access Token
     */
    async tokenGrantRaw(): Promise<runtime.ApiResponse<StreemApiGrantTokenResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGrantTokenResponseFromJSON(jsonValue));
    }

    /**
     * Use either a refresh token or SDK token to login
     * Get OAuth Access Token
     */
    async tokenGrant(): Promise<StreemApiGrantTokenResponse> {
        const response = await this.tokenGrantRaw();
        return await response.value();
    }

}
