/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiFulfillRecordingRequest
 */
export interface StreemApiFulfillRecordingRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiFulfillRecordingRequest
     */
    storageUrl?: string;
}

export function StreemApiFulfillRecordingRequestFromJSON(json: any): StreemApiFulfillRecordingRequest {
    return StreemApiFulfillRecordingRequestFromJSONTyped(json, false);
}

export function StreemApiFulfillRecordingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiFulfillRecordingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
    };
}

export function StreemApiFulfillRecordingRequestToJSON(value?: StreemApiFulfillRecordingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storage_url': value.storageUrl,
    };
}


