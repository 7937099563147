/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemARSource
 */
export interface WallItemARSource {
    /**
     * 
     * @type {string}
     * @memberof WallItemARSource
     */
    cameraPoseProvider?: WallItemARSourceCameraPoseProvider;
    /**
     * 
     * @type {string}
     * @memberof WallItemARSource
     */
    streemshotId?: string;
}

export function WallItemARSourceFromJSON(json: any): WallItemARSource {
    return WallItemARSourceFromJSONTyped(json, false);
}

export function WallItemARSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemARSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cameraPoseProvider': !exists(json, 'camera_pose_provider') ? undefined : json['camera_pose_provider'],
        'streemshotId': !exists(json, 'streemshot_id') ? undefined : json['streemshot_id'],
    };
}

export function WallItemARSourceToJSON(value?: WallItemARSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'camera_pose_provider': value.cameraPoseProvider,
        'streemshot_id': value.streemshotId,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemARSourceCameraPoseProvider {
    TWILIODATATRACK = 'TWILIO_DATA_TRACK',
    STREEMSHOTCAMERAPOSE = 'STREEMSHOT_CAMERA_POSE'
}


