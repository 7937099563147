/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCallLogParticipant
 */
export interface StreemApiCallLogParticipant {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogParticipant
     */
    userSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogParticipant
     */
    externalUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogParticipant
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogParticipant
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogParticipant
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCallLogParticipant
     */
    isExpert?: boolean;
}

export function StreemApiCallLogParticipantFromJSON(json: any): StreemApiCallLogParticipant {
    return StreemApiCallLogParticipantFromJSONTyped(json, false);
}

export function StreemApiCallLogParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallLogParticipant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userSid': !exists(json, 'user_sid') ? undefined : json['user_sid'],
        'externalUserId': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'isExpert': !exists(json, 'is_expert') ? undefined : json['is_expert'],
    };
}

export function StreemApiCallLogParticipantToJSON(value?: StreemApiCallLogParticipant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_sid': value.userSid,
        'external_user_id': value.externalUserId,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'phone': value.phone,
        'is_expert': value.isExpert,
    };
}


