/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactStreemshotDepthMap
 */
export interface StreemApiArtifactStreemshotDepthMap {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotDepthMap
     */
    streemshotSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotDepthMap
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotDepthMap
     */
    url?: string;
}

export function StreemApiArtifactStreemshotDepthMapFromJSON(json: any): StreemApiArtifactStreemshotDepthMap {
    return StreemApiArtifactStreemshotDepthMapFromJSONTyped(json, false);
}

export function StreemApiArtifactStreemshotDepthMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactStreemshotDepthMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streemshotSid': !exists(json, 'streemshot_sid') ? undefined : json['streemshot_sid'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function StreemApiArtifactStreemshotDepthMapToJSON(value?: StreemApiArtifactStreemshotDepthMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streemshot_sid': value.streemshotSid,
        'storage_url': value.storageUrl,
        'url': value.url,
    };
}


