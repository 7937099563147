/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventVignetteHotspotClicked
 */
export interface StreemApiAnalyticEventVignetteHotspotClicked {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteHotspotClicked
     */
    hotspotId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteHotspotClicked
     */
    hotspotType?: StreemApiAnalyticEventVignetteHotspotClickedHotspotType;
}

export function StreemApiAnalyticEventVignetteHotspotClickedFromJSON(json: any): StreemApiAnalyticEventVignetteHotspotClicked {
    return StreemApiAnalyticEventVignetteHotspotClickedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventVignetteHotspotClickedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventVignetteHotspotClicked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hotspotId': !exists(json, 'hotspot_id') ? undefined : json['hotspot_id'],
        'hotspotType': !exists(json, 'hotspot_type') ? undefined : json['hotspot_type'],
    };
}

export function StreemApiAnalyticEventVignetteHotspotClickedToJSON(value?: StreemApiAnalyticEventVignetteHotspotClicked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hotspot_id': value.hotspotId,
        'hotspot_type': value.hotspotType,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventVignetteHotspotClickedHotspotType {
    PORTAL = 'HOTSPOT_TYPE_PORTAL',
    MODAL = 'HOTSPOT_TYPE_MODAL',
    INVALID = 'HOTSPOT_TYPE_INVALID'
}


