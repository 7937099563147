/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiRunImageDetectorRequestImageSource
 */
export interface StreemApiRunImageDetectorRequestImageSource {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRunImageDetectorRequestImageSource
     */
    imageBytes?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRunImageDetectorRequestImageSource
     */
    url?: string;
}

export function StreemApiRunImageDetectorRequestImageSourceFromJSON(json: any): StreemApiRunImageDetectorRequestImageSource {
    return StreemApiRunImageDetectorRequestImageSourceFromJSONTyped(json, false);
}

export function StreemApiRunImageDetectorRequestImageSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRunImageDetectorRequestImageSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageBytes': !exists(json, 'image_bytes') ? undefined : json['image_bytes'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function StreemApiRunImageDetectorRequestImageSourceToJSON(value?: StreemApiRunImageDetectorRequestImageSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_bytes': value.imageBytes,
        'url': value.url,
    };
}


