/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiErrorResponse,
    StreemApiErrorResponseFromJSON,
    StreemApiErrorResponseFromJSONTyped,
    StreemApiErrorResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiBatchCreateCompanyResponseFailure
 */
export interface StreemApiBatchCreateCompanyResponseFailure {
    /**
     * 
     * @type {StreemApiErrorResponse}
     * @memberof StreemApiBatchCreateCompanyResponseFailure
     */
    error?: StreemApiErrorResponse;
    /**
     * 
     * @type {string}
     * @memberof StreemApiBatchCreateCompanyResponseFailure
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiBatchCreateCompanyResponseFailure
     */
    ownerEmail?: string;
}

export function StreemApiBatchCreateCompanyResponseFailureFromJSON(json: any): StreemApiBatchCreateCompanyResponseFailure {
    return StreemApiBatchCreateCompanyResponseFailureFromJSONTyped(json, false);
}

export function StreemApiBatchCreateCompanyResponseFailureFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiBatchCreateCompanyResponseFailure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : StreemApiErrorResponseFromJSON(json['error']),
        'companyCode': !exists(json, 'company_code') ? undefined : json['company_code'],
        'ownerEmail': !exists(json, 'owner_email') ? undefined : json['owner_email'],
    };
}

export function StreemApiBatchCreateCompanyResponseFailureToJSON(value?: StreemApiBatchCreateCompanyResponseFailure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': StreemApiErrorResponseToJSON(value.error),
        'company_code': value.companyCode,
        'owner_email': value.ownerEmail,
    };
}


