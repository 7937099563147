/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventIdentifyAttempted
 */
export interface StreemApiAnalyticEventIdentifyAttempted {
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiAnalyticEventIdentifyAttempted
     */
    successful?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventIdentifyAttempted
     */
    groupReservationSid?: string;
}

export function StreemApiAnalyticEventIdentifyAttemptedFromJSON(json: any): StreemApiAnalyticEventIdentifyAttempted {
    return StreemApiAnalyticEventIdentifyAttemptedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventIdentifyAttemptedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventIdentifyAttempted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'successful': !exists(json, 'successful') ? undefined : json['successful'],
        'groupReservationSid': !exists(json, 'group_reservation_sid') ? undefined : json['group_reservation_sid'],
    };
}

export function StreemApiAnalyticEventIdentifyAttemptedToJSON(value?: StreemApiAnalyticEventIdentifyAttempted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'successful': value.successful,
        'group_reservation_sid': value.groupReservationSid,
    };
}


