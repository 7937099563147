import { getIosVersionFromUserAgent } from './get_ios_version_from_user_agent';

// https://bugs.webkit.org/show_bug.cgi?id=230902#c19
// https://streempro.atlassian.net/browse/STREEM-12685
// https://github.com/twilio/twilio-video.js/blob/master/COMMON_ISSUES.md#safari-mobile
//
// Audio for customers is extremely quiet, so we need to bump up the gain on their remote audio track
// As of 12/20/21: This affects all devices on iOS 15 and above
export function webkitAudioFix230902(remoteAudioTrack: MediaStreamTrack) {
    const isIOSDevice = /iP(hone|od|ad)/.test(navigator.userAgent);

    if (isIOSDevice) {
        const iOSVersion = getIosVersionFromUserAgent(navigator.userAgent);
        const iOSVersionIsAffected = iOSVersion.major == 15;

        if (iOSVersionIsAffected) {
            const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
            const audioNode = audioContext.createMediaStreamSource(
                new MediaStream([remoteAudioTrack]),
            );
            const gainNode = audioContext.createGain();
            gainNode.gain.value = 10;

            audioNode.connect(gainNode);
            gainNode.connect(audioContext.destination);
        }
    }
}
