/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextStyle,
    TextStyleFromJSON,
    TextStyleFromJSONTyped,
    TextStyleToJSON,
    ViewStyle,
    ViewStyleFromJSON,
    ViewStyleFromJSONTyped,
    ViewStyleToJSON,
} from './';

/**
 * 
 * @export
 * @interface PickListTheme
 */
export interface PickListTheme {
    /**
     * 
     * @type {ViewStyle}
     * @memberof PickListTheme
     */
    optionViewStyle?: ViewStyle;
    /**
     * 
     * @type {TextStyle}
     * @memberof PickListTheme
     */
    optionTextStyle?: TextStyle;
}

export function PickListThemeFromJSON(json: any): PickListTheme {
    return PickListThemeFromJSONTyped(json, false);
}

export function PickListThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickListTheme {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionViewStyle': !exists(json, 'option_view_style') ? undefined : ViewStyleFromJSON(json['option_view_style']),
        'optionTextStyle': !exists(json, 'option_text_style') ? undefined : TextStyleFromJSON(json['option_text_style']),
    };
}

export function PickListThemeToJSON(value?: PickListTheme | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'option_view_style': ViewStyleToJSON(value.optionViewStyle),
        'option_text_style': TextStyleToJSON(value.optionTextStyle),
    };
}


