/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiRoomInvitation
 */
export interface StreemApiRoomInvitation {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    status?: StreemApiRoomInvitationStatus;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    fromUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    fromDeviceSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    toUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomInvitation
     */
    toDeviceSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomInvitation
     */
    pendingAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomInvitation
     */
    acceptedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomInvitation
     */
    rejectedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomInvitation
     */
    timedOutAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomInvitation
     */
    canceledAt?: Date;
}

export function StreemApiRoomInvitationFromJSON(json: any): StreemApiRoomInvitation {
    return StreemApiRoomInvitationFromJSONTyped(json, false);
}

export function StreemApiRoomInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRoomInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'fromUserSid': !exists(json, 'from_user_sid') ? undefined : json['from_user_sid'],
        'fromDeviceSid': !exists(json, 'from_device_sid') ? undefined : json['from_device_sid'],
        'toUserSid': !exists(json, 'to_user_sid') ? undefined : json['to_user_sid'],
        'toDeviceSid': !exists(json, 'to_device_sid') ? undefined : json['to_device_sid'],
        'pendingAt': !exists(json, 'pending_at') ? undefined : (new Date(json['pending_at'])),
        'acceptedAt': !exists(json, 'accepted_at') ? undefined : (new Date(json['accepted_at'])),
        'rejectedAt': !exists(json, 'rejected_at') ? undefined : (new Date(json['rejected_at'])),
        'timedOutAt': !exists(json, 'timed_out_at') ? undefined : (new Date(json['timed_out_at'])),
        'canceledAt': !exists(json, 'canceled_at') ? undefined : (new Date(json['canceled_at'])),
    };
}

export function StreemApiRoomInvitationToJSON(value?: StreemApiRoomInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'room_sid': value.roomSid,
        'status': value.status,
        'from_user_sid': value.fromUserSid,
        'from_device_sid': value.fromDeviceSid,
        'to_user_sid': value.toUserSid,
        'to_device_sid': value.toDeviceSid,
        'pending_at': value.pendingAt === undefined ? undefined : (value.pendingAt.toISOString()),
        'accepted_at': value.acceptedAt === undefined ? undefined : (value.acceptedAt.toISOString()),
        'rejected_at': value.rejectedAt === undefined ? undefined : (value.rejectedAt.toISOString()),
        'timed_out_at': value.timedOutAt === undefined ? undefined : (value.timedOutAt.toISOString()),
        'canceled_at': value.canceledAt === undefined ? undefined : (value.canceledAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiRoomInvitationStatus {
    PENDING = 'STATUS_PENDING',
    ACCEPTED = 'STATUS_ACCEPTED',
    REJECTED = 'STATUS_REJECTED',
    CANCELED = 'STATUS_CANCELED',
    TIMEDOUT = 'STATUS_TIMED_OUT',
    INVALID = 'STATUS_INVALID'
}


