/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSize,
    StreemApiSizeFromJSON,
    StreemApiSizeFromJSONTyped,
    StreemApiSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemRoomArTrackableDefinitionMarker2d
 */
export interface StreemRoomArTrackableDefinitionMarker2d {
    /**
     * 
     * @type {string}
     * @memberof StreemRoomArTrackableDefinitionMarker2d
     */
    file?: string;
    /**
     * 
     * @type {StreemApiSize}
     * @memberof StreemRoomArTrackableDefinitionMarker2d
     */
    expectedSizeMeters?: StreemApiSize;
}

export function StreemRoomArTrackableDefinitionMarker2dFromJSON(json: any): StreemRoomArTrackableDefinitionMarker2d {
    return StreemRoomArTrackableDefinitionMarker2dFromJSONTyped(json, false);
}

export function StreemRoomArTrackableDefinitionMarker2dFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemRoomArTrackableDefinitionMarker2d {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': !exists(json, 'file') ? undefined : json['file'],
        'expectedSizeMeters': !exists(json, 'expected_size_meters') ? undefined : StreemApiSizeFromJSON(json['expected_size_meters']),
    };
}

export function StreemRoomArTrackableDefinitionMarker2dToJSON(value?: StreemRoomArTrackableDefinitionMarker2d | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'expected_size_meters': StreemApiSizeToJSON(value.expectedSizeMeters),
    };
}


