/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSetting,
    StreemApiSettingFromJSON,
    StreemApiSettingFromJSONTyped,
    StreemApiSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiSaveCompanySettingResponse
 */
export interface StreemApiSaveCompanySettingResponse {
    /**
     * 
     * @type {StreemApiSetting}
     * @memberof StreemApiSaveCompanySettingResponse
     */
    setting?: StreemApiSetting;
}

export function StreemApiSaveCompanySettingResponseFromJSON(json: any): StreemApiSaveCompanySettingResponse {
    return StreemApiSaveCompanySettingResponseFromJSONTyped(json, false);
}

export function StreemApiSaveCompanySettingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSaveCompanySettingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setting': !exists(json, 'setting') ? undefined : StreemApiSettingFromJSON(json['setting']),
    };
}

export function StreemApiSaveCompanySettingResponseToJSON(value?: StreemApiSaveCompanySettingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setting': StreemApiSettingToJSON(value.setting),
    };
}


