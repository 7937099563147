/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiMeetingFeaturesAttendeeCapabilities,
    StreemApiMeetingFeaturesAttendeeCapabilitiesFromJSON,
    StreemApiMeetingFeaturesAttendeeCapabilitiesFromJSONTyped,
    StreemApiMeetingFeaturesAttendeeCapabilitiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAttendee
 */
export interface StreemApiAttendee {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAttendee
     */
    externalUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAttendee
     */
    attendeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAttendee
     */
    joinToken?: string;
    /**
     * 
     * @type {StreemApiMeetingFeaturesAttendeeCapabilities}
     * @memberof StreemApiAttendee
     */
    attendeeCapabilities?: StreemApiMeetingFeaturesAttendeeCapabilities;
}

export function StreemApiAttendeeFromJSON(json: any): StreemApiAttendee {
    return StreemApiAttendeeFromJSONTyped(json, false);
}

export function StreemApiAttendeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAttendee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalUserId': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'attendeeId': !exists(json, 'attendee_id') ? undefined : json['attendee_id'],
        'joinToken': !exists(json, 'join_token') ? undefined : json['join_token'],
        'attendeeCapabilities': !exists(json, 'attendee_capabilities') ? undefined : StreemApiMeetingFeaturesAttendeeCapabilitiesFromJSON(json['attendee_capabilities']),
    };
}

export function StreemApiAttendeeToJSON(value?: StreemApiAttendee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user_id': value.externalUserId,
        'attendee_id': value.attendeeId,
        'join_token': value.joinToken,
        'attendee_capabilities': StreemApiMeetingFeaturesAttendeeCapabilitiesToJSON(value.attendeeCapabilities),
    };
}


