/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemGPSPosition
 */
export interface WallItemGPSPosition {
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    altitudeAccuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    heading?: number;
    /**
     * 
     * @type {number}
     * @memberof WallItemGPSPosition
     */
    speed?: number;
    /**
     * 
     * @type {string}
     * @memberof WallItemGPSPosition
     */
    formattedAddress?: string;
}

export function WallItemGPSPositionFromJSON(json: any): WallItemGPSPosition {
    return WallItemGPSPositionFromJSONTyped(json, false);
}

export function WallItemGPSPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemGPSPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'accuracy': !exists(json, 'accuracy') ? undefined : json['accuracy'],
        'altitude': !exists(json, 'altitude') ? undefined : json['altitude'],
        'altitudeAccuracy': !exists(json, 'altitude_accuracy') ? undefined : json['altitude_accuracy'],
        'heading': !exists(json, 'heading') ? undefined : json['heading'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'formattedAddress': !exists(json, 'formatted_address') ? undefined : json['formatted_address'],
    };
}

export function WallItemGPSPositionToJSON(value?: WallItemGPSPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'accuracy': value.accuracy,
        'altitude': value.altitude,
        'altitude_accuracy': value.altitudeAccuracy,
        'heading': value.heading,
        'speed': value.speed,
        'formatted_address': value.formattedAddress,
    };
}


