/**
 * `once` is a blocking function which will wait for an event to occur on an element.
 * Once the event has been detected, the event listener is removed.
 *
 * @param el And element
 * @param event The event an element has
 * @returns void
 */
export const once = (el: HTMLElement, event: any) => {
    return new Promise<void>(resolve => {
        el.addEventListener(event, function onEvent() {
            el.removeEventListener(event, onEvent);
            resolve();
        });
    });
};
