/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSize,
    StreemApiSizeFromJSON,
    StreemApiSizeFromJSONTyped,
    StreemApiSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEventRemoteVideoStatsMeasured
 */
export interface StreemApiAnalyticEventRemoteVideoStatsMeasured {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    packetsLost?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    codec?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    bytesReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    packetsReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    frameRate?: number;
    /**
     * 
     * @type {StreemApiSize}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    dimensions?: StreemApiSize;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    trackSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteVideoStatsMeasured
     */
    roomSid?: string;
}

export function StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSON(json: any): StreemApiAnalyticEventRemoteVideoStatsMeasured {
    return StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventRemoteVideoStatsMeasured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packetsLost': !exists(json, 'packets_lost') ? undefined : json['packets_lost'],
        'codec': !exists(json, 'codec') ? undefined : json['codec'],
        'bytesReceived': !exists(json, 'bytes_received') ? undefined : json['bytes_received'],
        'packetsReceived': !exists(json, 'packets_received') ? undefined : json['packets_received'],
        'frameRate': !exists(json, 'frame_rate') ? undefined : json['frame_rate'],
        'dimensions': !exists(json, 'dimensions') ? undefined : StreemApiSizeFromJSON(json['dimensions']),
        'trackSid': !exists(json, 'track_sid') ? undefined : json['track_sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
    };
}

export function StreemApiAnalyticEventRemoteVideoStatsMeasuredToJSON(value?: StreemApiAnalyticEventRemoteVideoStatsMeasured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packets_lost': value.packetsLost,
        'codec': value.codec,
        'bytes_received': value.bytesReceived,
        'packets_received': value.packetsReceived,
        'frame_rate': value.frameRate,
        'dimensions': StreemApiSizeToJSON(value.dimensions),
        'track_sid': value.trackSid,
        'room_sid': value.roomSid,
    };
}


