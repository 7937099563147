/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiChangeExternalUserIdRequest
 */
export interface StreemApiChangeExternalUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiChangeExternalUserIdRequest
     */
    updateExternalUserId?: string;
}

export function StreemApiChangeExternalUserIdRequestFromJSON(json: any): StreemApiChangeExternalUserIdRequest {
    return StreemApiChangeExternalUserIdRequestFromJSONTyped(json, false);
}

export function StreemApiChangeExternalUserIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiChangeExternalUserIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateExternalUserId': !exists(json, 'update_external_user_id') ? undefined : json['update_external_user_id'],
    };
}

export function StreemApiChangeExternalUserIdRequestToJSON(value?: StreemApiChangeExternalUserIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'update_external_user_id': value.updateExternalUserId,
    };
}


