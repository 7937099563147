/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
    StreemApiArtifactCounts,
    StreemApiArtifactCountsFromJSON,
    StreemApiArtifactCountsFromJSONTyped,
    StreemApiArtifactCountsToJSON,
    StreemApiArtifactGpsPosition,
    StreemApiArtifactGpsPositionFromJSON,
    StreemApiArtifactGpsPositionFromJSONTyped,
    StreemApiArtifactGpsPositionToJSON,
    StreemApiCallLogParticipant,
    StreemApiCallLogParticipantFromJSON,
    StreemApiCallLogParticipantFromJSONTyped,
    StreemApiCallLogParticipantToJSON,
    StreemApiCallLogRoomInvitation,
    StreemApiCallLogRoomInvitationFromJSON,
    StreemApiCallLogRoomInvitationFromJSONTyped,
    StreemApiCallLogRoomInvitationToJSON,
    StreemApiNote,
    StreemApiNoteFromJSON,
    StreemApiNoteFromJSONTyped,
    StreemApiNoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCallLogEntry
 */
export interface StreemApiCallLogEntry {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    roomSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCallLogEntry
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCallLogEntry
     */
    endTime?: Date;
    /**
     * 
     * @type {Array<StreemApiCallLogParticipant>}
     * @memberof StreemApiCallLogEntry
     */
    participants?: Array<StreemApiCallLogParticipant>;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCallLogEntry
     */
    missed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCallLogEntry
     */
    onsite?: boolean;
    /**
     * 
     * @type {StreemApiArtifactCounts}
     * @memberof StreemApiCallLogEntry
     */
    artifactCounts?: StreemApiArtifactCounts;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    shareUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    createdByUserSid?: string;
    /**
     * 
     * @type {StreemApiNote}
     * @memberof StreemApiCallLogEntry
     */
    note?: StreemApiNote;
    /**
     * 
     * @type {StreemApiArtifactGpsPosition}
     * @memberof StreemApiCallLogEntry
     */
    latestGpsPosition?: StreemApiArtifactGpsPosition;
    /**
     * 
     * @type {Array<StreemApiCallLogRoomInvitation>}
     * @memberof StreemApiCallLogEntry
     */
    invitations?: Array<StreemApiCallLogRoomInvitation>;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCallLogEntry
     */
    favorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    callStatus?: StreemApiCallLogEntryCallStatus;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallLogEntry
     */
    canceledCallRingTimeInSecs?: string;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiCallLogEntry
     */
    callDuration?: GoogleProtobufDuration;
}

export function StreemApiCallLogEntryFromJSON(json: any): StreemApiCallLogEntry {
    return StreemApiCallLogEntryFromJSONTyped(json, false);
}

export function StreemApiCallLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(StreemApiCallLogParticipantFromJSON)),
        'missed': !exists(json, 'missed') ? undefined : json['missed'],
        'onsite': !exists(json, 'onsite') ? undefined : json['onsite'],
        'artifactCounts': !exists(json, 'artifact_counts') ? undefined : StreemApiArtifactCountsFromJSON(json['artifact_counts']),
        'shareUrl': !exists(json, 'share_url') ? undefined : json['share_url'],
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'createdByUserSid': !exists(json, 'created_by_user_sid') ? undefined : json['created_by_user_sid'],
        'note': !exists(json, 'note') ? undefined : StreemApiNoteFromJSON(json['note']),
        'latestGpsPosition': !exists(json, 'latest_gps_position') ? undefined : StreemApiArtifactGpsPositionFromJSON(json['latest_gps_position']),
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(StreemApiCallLogRoomInvitationFromJSON)),
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'callStatus': !exists(json, 'call_status') ? undefined : json['call_status'],
        'canceledCallRingTimeInSecs': !exists(json, 'canceled_call_ring_time_in_secs') ? undefined : json['canceled_call_ring_time_in_secs'],
        'callDuration': !exists(json, 'call_duration') ? undefined : GoogleProtobufDurationFromJSON(json['call_duration']),
    };
}

export function StreemApiCallLogEntryToJSON(value?: StreemApiCallLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(StreemApiCallLogParticipantToJSON)),
        'missed': value.missed,
        'onsite': value.onsite,
        'artifact_counts': StreemApiArtifactCountsToJSON(value.artifactCounts),
        'share_url': value.shareUrl,
        'reference_id': value.referenceId,
        'created_by_user_sid': value.createdByUserSid,
        'note': StreemApiNoteToJSON(value.note),
        'latest_gps_position': StreemApiArtifactGpsPositionToJSON(value.latestGpsPosition),
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(StreemApiCallLogRoomInvitationToJSON)),
        'favorite': value.favorite,
        'integration_id': value.integrationId,
        'call_status': value.callStatus,
        'canceled_call_ring_time_in_secs': value.canceledCallRingTimeInSecs,
        'call_duration': GoogleProtobufDurationToJSON(value.callDuration),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiCallLogEntryCallStatus {
    SUCCESSFUL = 'STATUS_SUCCESSFUL',
    CANCELED = 'STATUS_CANCELED',
    REJECTED = 'STATUS_REJECTED',
    TIMEDOUT = 'STATUS_TIMED_OUT',
    INVALID = 'STATUS_INVALID'
}


