/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LightDirectionalLight
 */
export interface LightDirectionalLight {
    /**
     * 
     * @type {Array<number>}
     * @memberof LightDirectionalLight
     */
    direction?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof LightDirectionalLight
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof LightDirectionalLight
     */
    intensityLux?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LightDirectionalLight
     */
    castsShadows?: boolean;
}

export function LightDirectionalLightFromJSON(json: any): LightDirectionalLight {
    return LightDirectionalLightFromJSONTyped(json, false);
}

export function LightDirectionalLightFromJSONTyped(json: any, ignoreDiscriminator: boolean): LightDirectionalLight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'direction': !exists(json, 'direction') ? undefined : json['direction'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'intensityLux': !exists(json, 'intensity_lux') ? undefined : json['intensity_lux'],
        'castsShadows': !exists(json, 'casts_shadows') ? undefined : json['casts_shadows'],
    };
}

export function LightDirectionalLightToJSON(value?: LightDirectionalLight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'direction': value.direction,
        'color': value.color,
        'intensity_lux': value.intensityLux,
        'casts_shadows': value.castsShadows,
    };
}


