import { useState, useEffect } from 'react';
import { getCompanyCodeFromUrl, getCompanyRegistration } from '@streem/toolbox';

import { config } from '../config';
import appLogger from '../util/app_logger';

const log = appLogger.extend('useGetCompanyRegistration');
export interface UseGetCompanyRegistrationResult {
    error: boolean;
    regionalApiUrl: string;
    regionCode: string;
    wallUrl: string;
}
export const useGetCompanyRegistration = (): UseGetCompanyRegistrationResult => {
    const [companyRegistrationSettings, setCompanyRegistrationSettings] =
        useState<UseGetCompanyRegistrationResult>({
            regionalApiUrl: undefined,
            regionCode: undefined,
            wallUrl: undefined,
            error: false,
        });

    useEffect(() => {
        async function fetchCompanyRegistration(companyCode: string) {
            try {
                const { apiUrl, companyRegistration, wallUrl } = await getCompanyRegistration({
                    companyCode,
                    globalDomain: config.globalDomain,
                });
                setCompanyRegistrationSettings({
                    regionalApiUrl: apiUrl,
                    regionCode: companyRegistration.regionCode,
                    wallUrl,
                    error: false,
                });
            } catch (e) {
                log.error(e);
                setCompanyRegistrationSettings(prevSettings => ({
                    ...prevSettings,
                    error: true,
                }));
            }
        }

        fetchCompanyRegistration(getCompanyCodeFromUrl());
    }, []);

    return { ...companyRegistrationSettings };
};
