/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroupReservation,
    StreemApiGroupReservationFromJSON,
    StreemApiGroupReservationFromJSONTyped,
    StreemApiGroupReservationToJSON,
    StreemApiInvitation,
    StreemApiInvitationFromJSON,
    StreemApiInvitationFromJSONTyped,
    StreemApiInvitationToJSON,
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAuthIdentityUser
 */
export interface StreemApiAuthIdentityUser {
    /**
     * 
     * @type {StreemApiUser}
     * @memberof StreemApiAuthIdentityUser
     */
    user?: StreemApiUser;
    /**
     * 
     * @type {StreemApiInvitation}
     * @memberof StreemApiAuthIdentityUser
     */
    invitation?: StreemApiInvitation;
    /**
     * 
     * @type {StreemApiGroupReservation}
     * @memberof StreemApiAuthIdentityUser
     */
    groupReservation?: StreemApiGroupReservation;
}

export function StreemApiAuthIdentityUserFromJSON(json: any): StreemApiAuthIdentityUser {
    return StreemApiAuthIdentityUserFromJSONTyped(json, false);
}

export function StreemApiAuthIdentityUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAuthIdentityUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : StreemApiUserFromJSON(json['user']),
        'invitation': !exists(json, 'invitation') ? undefined : StreemApiInvitationFromJSON(json['invitation']),
        'groupReservation': !exists(json, 'group_reservation') ? undefined : StreemApiGroupReservationFromJSON(json['group_reservation']),
    };
}

export function StreemApiAuthIdentityUserToJSON(value?: StreemApiAuthIdentityUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': StreemApiUserToJSON(value.user),
        'invitation': StreemApiInvitationToJSON(value.invitation),
        'group_reservation': StreemApiGroupReservationToJSON(value.groupReservation),
    };
}


