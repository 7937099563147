/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiGetTermsUpdatedResponse,
    StreemApiGetTermsUpdatedResponseFromJSON,
    StreemApiGetTermsUpdatedResponseToJSON,
    StreemApiSaveTermsUpdatedResponse,
    StreemApiSaveTermsUpdatedResponseFromJSON,
    StreemApiSaveTermsUpdatedResponseToJSON,
} from '../models';

export interface SaveTermsUpdatedRequest {
    body?: object;
}

/**
 * 
 */
export class TermsApi extends runtime.BaseAPI {

    /**
     * Gets the date Streem\'s terms and conditions were last modified.
     * Get T&Cs Updated
     */
    async getTermsUpdatedRaw(): Promise<runtime.ApiResponse<StreemApiGetTermsUpdatedResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/terms/updated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetTermsUpdatedResponseFromJSON(jsonValue));
    }

    /**
     * Gets the date Streem\'s terms and conditions were last modified.
     * Get T&Cs Updated
     */
    async getTermsUpdated(): Promise<StreemApiGetTermsUpdatedResponse> {
        const response = await this.getTermsUpdatedRaw();
        return await response.value();
    }

    /**
     * Updates the date Streem\'s terms and conditions were last modified to the current date and time.
     * Update T&Cs Date
     */
    async saveTermsUpdatedRaw(requestParameters: SaveTermsUpdatedRequest): Promise<runtime.ApiResponse<StreemApiSaveTermsUpdatedResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/terms/updated`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveTermsUpdatedResponseFromJSON(jsonValue));
    }

    /**
     * Updates the date Streem\'s terms and conditions were last modified to the current date and time.
     * Update T&Cs Date
     */
    async saveTermsUpdated(body?: object): Promise<StreemApiSaveTermsUpdatedResponse> {
        const response = await this.saveTermsUpdatedRaw({ body: body });
        return await response.value();
    }

}
