/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateAppRequest,
    StreemApiCreateAppRequestFromJSON,
    StreemApiCreateAppRequestToJSON,
    StreemApiCreateAppResponse,
    StreemApiCreateAppResponseFromJSON,
    StreemApiCreateAppResponseToJSON,
    StreemApiGetAppResponse,
    StreemApiGetAppResponseFromJSON,
    StreemApiGetAppResponseToJSON,
    StreemApiImportGlobalAppRequest,
    StreemApiImportGlobalAppRequestFromJSON,
    StreemApiImportGlobalAppRequestToJSON,
    StreemApiImportGlobalAppResponse,
    StreemApiImportGlobalAppResponseFromJSON,
    StreemApiImportGlobalAppResponseToJSON,
    StreemApiListAppsResponse,
    StreemApiListAppsResponseFromJSON,
    StreemApiListAppsResponseToJSON,
    StreemApiUpdateAppIosVoipCredentialsRequest,
    StreemApiUpdateAppIosVoipCredentialsRequestFromJSON,
    StreemApiUpdateAppIosVoipCredentialsRequestToJSON,
} from '../models';

export interface CreateAppRequest {
    streemApiCreateAppRequest?: StreemApiCreateAppRequest;
}

export interface GetAppRequest {
    appSid: string;
}

export interface ImportGlobalAppRequest {
    streemApiImportGlobalAppRequest?: StreemApiImportGlobalAppRequest;
}

export interface ListAppsRequest {
    pageSize?: number;
    pageToken?: string;
    companySid?: string;
    multiCompanyLoginAllowed?: boolean;
}

export interface UpdateAppIosVoipCredentialsRequest {
    appSid: string;
    streemApiUpdateAppIosVoipCredentialsRequest?: StreemApiUpdateAppIosVoipCredentialsRequest;
}

/**
 * 
 */
export class AppsApi extends runtime.BaseAPI {

    /**
     * Create an App
     */
    async createAppRaw(requestParameters: CreateAppRequest): Promise<runtime.ApiResponse<StreemApiCreateAppResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/apps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateAppRequestToJSON(requestParameters.streemApiCreateAppRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateAppResponseFromJSON(jsonValue));
    }

    /**
     * Create an App
     */
    async createApp(streemApiCreateAppRequest?: StreemApiCreateAppRequest): Promise<StreemApiCreateAppResponse> {
        const response = await this.createAppRaw({ streemApiCreateAppRequest: streemApiCreateAppRequest });
        return await response.value();
    }

    /**
     * Get an App with the given `app_sid`
     * Get App
     */
    async getAppRaw(requestParameters: GetAppRequest): Promise<runtime.ApiResponse<StreemApiGetAppResponse>> {
        if (requestParameters.appSid === null || requestParameters.appSid === undefined) {
            throw new runtime.RequiredError('appSid','Required parameter requestParameters.appSid was null or undefined when calling getApp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/apps/{app_sid}`.replace(`{${"app_sid"}}`, encodeURIComponent(String(requestParameters.appSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetAppResponseFromJSON(jsonValue));
    }

    /**
     * Get an App with the given `app_sid`
     * Get App
     */
    async getApp(appSid: string): Promise<StreemApiGetAppResponse> {
        const response = await this.getAppRaw({ appSid: appSid });
        return await response.value();
    }

    /**
     * Import an App from the Global Region, which must be multiCompanyLoginAllowed = true
     * Import Global App
     */
    async importGlobalAppRaw(requestParameters: ImportGlobalAppRequest): Promise<runtime.ApiResponse<StreemApiImportGlobalAppResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/apps/~import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiImportGlobalAppRequestToJSON(requestParameters.streemApiImportGlobalAppRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiImportGlobalAppResponseFromJSON(jsonValue));
    }

    /**
     * Import an App from the Global Region, which must be multiCompanyLoginAllowed = true
     * Import Global App
     */
    async importGlobalApp(streemApiImportGlobalAppRequest?: StreemApiImportGlobalAppRequest): Promise<StreemApiImportGlobalAppResponse> {
        const response = await this.importGlobalAppRaw({ streemApiImportGlobalAppRequest: streemApiImportGlobalAppRequest });
        return await response.value();
    }

    /**
     * List all apps for your company.
     * List Apps
     */
    async listAppsRaw(requestParameters: ListAppsRequest): Promise<runtime.ApiResponse<StreemApiListAppsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.companySid !== undefined) {
            queryParameters['company_sid'] = requestParameters.companySid;
        }

        if (requestParameters.multiCompanyLoginAllowed !== undefined) {
            queryParameters['multi_company_login_allowed'] = requestParameters.multiCompanyLoginAllowed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListAppsResponseFromJSON(jsonValue));
    }

    /**
     * List all apps for your company.
     * List Apps
     */
    async listApps(pageSize?: number, pageToken?: string, companySid?: string, multiCompanyLoginAllowed?: boolean): Promise<StreemApiListAppsResponse> {
        const response = await this.listAppsRaw({ pageSize: pageSize, pageToken: pageToken, companySid: companySid, multiCompanyLoginAllowed: multiCompanyLoginAllowed });
        return await response.value();
    }

    /**
     * Update IOS VOIP credentials for the given app
     * Update IOS VOIP Creds
     */
    async updateAppIosVoipCredentialsRaw(requestParameters: UpdateAppIosVoipCredentialsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appSid === null || requestParameters.appSid === undefined) {
            throw new runtime.RequiredError('appSid','Required parameter requestParameters.appSid was null or undefined when calling updateAppIosVoipCredentials.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/apps/{app_sid}/ios-voip-credentials`.replace(`{${"app_sid"}}`, encodeURIComponent(String(requestParameters.appSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateAppIosVoipCredentialsRequestToJSON(requestParameters.streemApiUpdateAppIosVoipCredentialsRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update IOS VOIP credentials for the given app
     * Update IOS VOIP Creds
     */
    async updateAppIosVoipCredentials(appSid: string, streemApiUpdateAppIosVoipCredentialsRequest?: StreemApiUpdateAppIosVoipCredentialsRequest): Promise<void> {
        await this.updateAppIosVoipCredentialsRaw({ appSid: appSid, streemApiUpdateAppIosVoipCredentialsRequest: streemApiUpdateAppIosVoipCredentialsRequest });
    }

}
