/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroup,
    StreemApiGroupFromJSON,
    StreemApiGroupFromJSONTyped,
    StreemApiGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateGroupResponse
 */
export interface StreemApiCreateGroupResponse {
    /**
     * 
     * @type {StreemApiGroup}
     * @memberof StreemApiCreateGroupResponse
     */
    group?: StreemApiGroup;
}

export function StreemApiCreateGroupResponseFromJSON(json: any): StreemApiCreateGroupResponse {
    return StreemApiCreateGroupResponseFromJSONTyped(json, false);
}

export function StreemApiCreateGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group': !exists(json, 'group') ? undefined : StreemApiGroupFromJSON(json['group']),
    };
}

export function StreemApiCreateGroupResponseToJSON(value?: StreemApiCreateGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': StreemApiGroupToJSON(value.group),
    };
}


