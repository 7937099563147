/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiInitiatePasswordResetRequest,
    StreemApiInitiatePasswordResetRequestFromJSON,
    StreemApiInitiatePasswordResetRequestToJSON,
} from '../models';

export interface InitiatePasswordResetRequest {
    streemApiInitiatePasswordResetRequest?: StreemApiInitiatePasswordResetRequest;
}

/**
 * 
 */
export class AuthLoginApi extends runtime.BaseAPI {

    /**
     * Reset the password for Streem Auth user.
     * Initiate Password Reset
     */
    async initiatePasswordResetRaw(requestParameters: InitiatePasswordResetRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/login/initiate-password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiInitiatePasswordResetRequestToJSON(requestParameters.streemApiInitiatePasswordResetRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset the password for Streem Auth user.
     * Initiate Password Reset
     */
    async initiatePasswordReset(streemApiInitiatePasswordResetRequest?: StreemApiInitiatePasswordResetRequest): Promise<void> {
        await this.initiatePasswordResetRaw({ streemApiInitiatePasswordResetRequest: streemApiInitiatePasswordResetRequest });
    }

}
