/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventVignetteElementSelected
 */
export interface StreemApiAnalyticEventVignetteElementSelected {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteElementSelected
     */
    vignettElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteElementSelected
     */
    finishId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteElementSelected
     */
    triggerType?: StreemApiAnalyticEventVignetteElementSelectedTriggerType;
}

export function StreemApiAnalyticEventVignetteElementSelectedFromJSON(json: any): StreemApiAnalyticEventVignetteElementSelected {
    return StreemApiAnalyticEventVignetteElementSelectedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventVignetteElementSelectedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventVignetteElementSelected {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vignettElementId': !exists(json, 'vignett_element_id') ? undefined : json['vignett_element_id'],
        'finishId': !exists(json, 'finish_id') ? undefined : json['finish_id'],
        'triggerType': !exists(json, 'trigger_type') ? undefined : json['trigger_type'],
    };
}

export function StreemApiAnalyticEventVignetteElementSelectedToJSON(value?: StreemApiAnalyticEventVignetteElementSelected | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vignett_element_id': value.vignettElementId,
        'finish_id': value.finishId,
        'trigger_type': value.triggerType,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventVignetteElementSelectedTriggerType {
    SWAP = 'TRIGGER_TYPE_SWAP',
    HOTSPOT = 'TRIGGER_TYPE_HOTSPOT',
    CENTEROFVIEW = 'TRIGGER_TYPE_CENTER_OF_VIEW',
    TAP = 'TRIGGER_TYPE_TAP',
    INVALID = 'TRIGGER_TYPE_INVALID'
}


