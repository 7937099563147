/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiGrantTokenResponse
 */
export interface StreemApiGrantTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    tokenType?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGrantTokenResponse
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    streemCompanyId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenResponse
     */
    streemUserId?: string;
}

export function StreemApiGrantTokenResponseFromJSON(json: any): StreemApiGrantTokenResponse {
    return StreemApiGrantTokenResponseFromJSONTyped(json, false);
}

export function StreemApiGrantTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGrantTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
        'tokenType': !exists(json, 'token_type') ? undefined : json['token_type'],
        'expiresIn': !exists(json, 'expires_in') ? undefined : json['expires_in'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'streemCompanyId': !exists(json, 'streem_company_id') ? undefined : json['streem_company_id'],
        'streemUserId': !exists(json, 'streem_user_id') ? undefined : json['streem_user_id'],
    };
}

export function StreemApiGrantTokenResponseToJSON(value?: StreemApiGrantTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'token_type': value.tokenType,
        'expires_in': value.expiresIn,
        'scope': value.scope,
        'refresh_token': value.refreshToken,
        'streem_company_id': value.streemCompanyId,
        'streem_user_id': value.streemUserId,
    };
}


