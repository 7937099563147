/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoomShareToken,
    StreemApiRoomShareTokenFromJSON,
    StreemApiRoomShareTokenFromJSONTyped,
    StreemApiRoomShareTokenToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListRoomShareTokensResponse
 */
export interface StreemApiListRoomShareTokensResponse {
    /**
     * 
     * @type {Array<StreemApiRoomShareToken>}
     * @memberof StreemApiListRoomShareTokensResponse
     */
    shareTokens?: Array<StreemApiRoomShareToken>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListRoomShareTokensResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListRoomShareTokensResponse
     */
    totalSize?: number;
}

export function StreemApiListRoomShareTokensResponseFromJSON(json: any): StreemApiListRoomShareTokensResponse {
    return StreemApiListRoomShareTokensResponseFromJSONTyped(json, false);
}

export function StreemApiListRoomShareTokensResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListRoomShareTokensResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shareTokens': !exists(json, 'share_tokens') ? undefined : ((json['share_tokens'] as Array<any>).map(StreemApiRoomShareTokenFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListRoomShareTokensResponseToJSON(value?: StreemApiListRoomShareTokensResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'share_tokens': value.shareTokens === undefined ? undefined : ((value.shareTokens as Array<any>).map(StreemApiRoomShareTokenToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


