/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Element,
    ElementFromJSON,
    ElementFromJSONTyped,
    ElementToJSON,
    Presentation,
    PresentationFromJSON,
    PresentationFromJSONTyped,
    PresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface TutorialElement
 */
export interface TutorialElement {
    /**
     * 
     * @type {Element}
     * @memberof TutorialElement
     */
    element?: Element;
    /**
     * 
     * @type {Array<Presentation>}
     * @memberof TutorialElement
     */
    presentations?: Array<Presentation>;
}

export function TutorialElementFromJSON(json: any): TutorialElement {
    return TutorialElementFromJSONTyped(json, false);
}

export function TutorialElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'element': !exists(json, 'element') ? undefined : ElementFromJSON(json['element']),
        'presentations': !exists(json, 'presentations') ? undefined : ((json['presentations'] as Array<any>).map(PresentationFromJSON)),
    };
}

export function TutorialElementToJSON(value?: TutorialElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'element': ElementToJSON(value.element),
        'presentations': value.presentations === undefined ? undefined : ((value.presentations as Array<any>).map(PresentationToJSON)),
    };
}


