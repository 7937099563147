import { getIosVersionFromUserAgent } from '@streem/toolbox';
import { detect } from 'detect-browser';
import { config } from '../config';
import appLogger from './app_logger';

const log = appLogger.extend('validate_browser_allowed');

function isValidSamsungBrowser(name: string, version: string) {
    if (name !== 'samsung') {
        return false;
    }
    const [majorVersion, minorVersion] = parseVersion(version);
    return (majorVersion >= 10 && minorVersion >= 1) || majorVersion > 10;
}

function parseVersion(version: string) {
    const parts = version.split('.').map(part => parseInt(part, 10));
    return parts;
}

export function validateBrowserAllowed(): boolean {
    const browser = detect();

    if (config.isDevelopment) {
        console.warn('Running in development, Skipping browser check');
        return true;
    }

    if (!browser || !browser.os || !browser.name) {
        // We should be able to detect the browser, so if we can't
        // we assume it isn't supported
        // and show BrowserNotSupportedPage;
        log.warn('no browser or no browser OS or no browser name detected');
        return false;
    }

    // @ts-ignore support easy mocking
    if (browser.os === 'test-os') {
        return true;
    }

    const userAgent = navigator.userAgent;
    const isDDG = /DuckDuckGo/.test(userAgent);
    const isIOS = browser.os === 'iOS';
    const isSafari = !isDDG && (browser.name === 'safari' || browser.name === 'ios');

    if (isIOS) {
        /* The browser name supplied by the detect-browser library is listed as ios and
         * doesn't specify the browser utilized by the device. This parses the user agent
         * data to determine if the current browser is chrome
         */
        const isChromeOnIOS = !!userAgent.match(/CriOS/);

        if (!isSafari && !isChromeOnIOS) {
            log.warn('Browser not allowed on iOS version below 14.3', browser);
            return false;
        }

        if (isChromeOnIOS) {
            const { major: platformMajorVersion, minor: platformMinorVersion } =
                getIosVersionFromUserAgent(userAgent);

            if (isNaN(platformMajorVersion) || isNaN(platformMinorVersion)) {
                log.warn('Unable to evaluate iOS version', browser);
                return false;
            }

            /* If the user is on the chrome browser with an iOS version below 14.3
             * the browser is not supported
             */
            if (platformMajorVersion <= 14 && platformMinorVersion < 3) {
                log.warn('Browser not allowed on iOS version below 14.3', browser);
                return false;
            }
        }
    }
    const isChrome = browser.name === 'chrome';
    const isAndroid = browser.os === 'Android OS';
    const validSamsungBrowser = isValidSamsungBrowser(browser.name, browser.version);
    if (isAndroid && !(isChrome || validSamsungBrowser)) {
        log.warn('Browser not allowed on Android', browser);
        return false;
    }
    return true;
}
