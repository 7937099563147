/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiLicense,
    StreemApiLicenseFromJSON,
    StreemApiLicenseFromJSONTyped,
    StreemApiLicenseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListLicensesResponse
 */
export interface StreemApiListLicensesResponse {
    /**
     * 
     * @type {Array<StreemApiLicense>}
     * @memberof StreemApiListLicensesResponse
     */
    licenses?: Array<StreemApiLicense>;
}

export function StreemApiListLicensesResponseFromJSON(json: any): StreemApiListLicensesResponse {
    return StreemApiListLicensesResponseFromJSONTyped(json, false);
}

export function StreemApiListLicensesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListLicensesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenses': !exists(json, 'licenses') ? undefined : ((json['licenses'] as Array<any>).map(StreemApiLicenseFromJSON)),
    };
}

export function StreemApiListLicensesResponseToJSON(value?: StreemApiListLicensesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenses': value.licenses === undefined ? undefined : ((value.licenses as Array<any>).map(StreemApiLicenseToJSON)),
    };
}


