/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CameraIntrinsics,
    CameraIntrinsicsFromJSON,
    CameraIntrinsicsFromJSONTyped,
    CameraIntrinsicsToJSON,
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
    StreemApiPose,
    StreemApiPoseFromJSON,
    StreemApiPoseFromJSONTyped,
    StreemApiPoseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactStreemshotSource
 */
export interface StreemApiArtifactStreemshotSource {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshotSource
     */
    url?: string;
    /**
     * 
     * @type {StreemApiPose}
     * @memberof StreemApiArtifactStreemshotSource
     */
    pose?: StreemApiPose;
    /**
     * 
     * @type {Array<StreemApiPoint>}
     * @memberof StreemApiArtifactStreemshotSource
     */
    featurePoints?: Array<StreemApiPoint>;
    /**
     * 
     * @type {CameraIntrinsics}
     * @memberof StreemApiArtifactStreemshotSource
     */
    cameraIntrinsics?: CameraIntrinsics;
}

export function StreemApiArtifactStreemshotSourceFromJSON(json: any): StreemApiArtifactStreemshotSource {
    return StreemApiArtifactStreemshotSourceFromJSONTyped(json, false);
}

export function StreemApiArtifactStreemshotSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactStreemshotSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'pose': !exists(json, 'pose') ? undefined : StreemApiPoseFromJSON(json['pose']),
        'featurePoints': !exists(json, 'feature_points') ? undefined : ((json['feature_points'] as Array<any>).map(StreemApiPointFromJSON)),
        'cameraIntrinsics': !exists(json, 'camera_intrinsics') ? undefined : CameraIntrinsicsFromJSON(json['camera_intrinsics']),
    };
}

export function StreemApiArtifactStreemshotSourceToJSON(value?: StreemApiArtifactStreemshotSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'pose': StreemApiPoseToJSON(value.pose),
        'feature_points': value.featurePoints === undefined ? undefined : ((value.featurePoints as Array<any>).map(StreemApiPointToJSON)),
        'camera_intrinsics': CameraIntrinsicsToJSON(value.cameraIntrinsics),
    };
}


