declare global {
    interface Window {
        // @ts-ignore
        env: any;
        __ENV: {
            REACT_APP_DEFAULT_COMPANY_CODE: string;
            REACT_APP_DOMAIN: string;
            [key: string]: any;
        };
    }
}

export function getCompanyCodeFromUrl(): string {
    const parts = window.location.host.split('.');
    const subdomain = parts[0];
    let nodeEnv: string = window?.__ENV?.NODE_ENV;
    nodeEnv = nodeEnv ? nodeEnv : process.env.NODE_ENV;
    // this has to be written with an if statement because if you just use || then it gets collapsed at build-time since window doesn't exist.
    let defaultCode = window?.__ENV?.REACT_APP_DEFAULT_COMPANY_CODE;
    if (!defaultCode) {
        defaultCode = process.env.REACT_APP_DEFAULT_COMPANY_CODE;
    }

    if (defaultCode) {
        // Putting this back here and going to error on the sly publish script instead so that this works with Docker.
        return defaultCode;
    }
    if (nodeEnv !== 'production' && parts.length <= 2 && !parts[1]?.includes('localhost')) {
        throw new Error(
            'Could not determine company code because the host path is only two parts\n' +
                'and it is expected to be at least three in the format {companyCode}.{app}.{domain+}\n' +
                'You likely need to set REACT_APP_DEFAULT_COMPANY_CODE in your env file',
        );
    }
    return subdomain;
}
