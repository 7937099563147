/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateInvitationRequest
 */
export interface StreemApiCreateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateInvitationRequest
     */
    fromUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateInvitationRequest
     */
    toUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateInvitationRequest
     */
    toUserExternalUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateInvitationRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateInvitationRequest
     */
    integrationId?: string;
}

export function StreemApiCreateInvitationRequestFromJSON(json: any): StreemApiCreateInvitationRequest {
    return StreemApiCreateInvitationRequestFromJSONTyped(json, false);
}

export function StreemApiCreateInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromUserSid': !exists(json, 'from_user_sid') ? undefined : json['from_user_sid'],
        'toUserName': !exists(json, 'to_user_name') ? undefined : json['to_user_name'],
        'toUserExternalUserId': !exists(json, 'to_user_external_user_id') ? undefined : json['to_user_external_user_id'],
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
    };
}

export function StreemApiCreateInvitationRequestToJSON(value?: StreemApiCreateInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user_sid': value.fromUserSid,
        'to_user_name': value.toUserName,
        'to_user_external_user_id': value.toUserExternalUserId,
        'reference_id': value.referenceId,
        'integration_id': value.integrationId,
    };
}


