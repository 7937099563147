/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCompanyRoomOutcomeConfigSection,
    StreemApiCompanyRoomOutcomeConfigSectionFromJSON,
    StreemApiCompanyRoomOutcomeConfigSectionFromJSONTyped,
    StreemApiCompanyRoomOutcomeConfigSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateCompanyRoomOutcomeConfigRequest
 */
export interface StreemApiCreateCompanyRoomOutcomeConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRoomOutcomeConfigRequest
     */
    companySid?: string;
    /**
     * 
     * @type {StreemApiCompanyRoomOutcomeConfigSection}
     * @memberof StreemApiCreateCompanyRoomOutcomeConfigRequest
     */
    diagnosis?: StreemApiCompanyRoomOutcomeConfigSection;
    /**
     * 
     * @type {StreemApiCompanyRoomOutcomeConfigSection}
     * @memberof StreemApiCreateCompanyRoomOutcomeConfigRequest
     */
    disposition?: StreemApiCompanyRoomOutcomeConfigSection;
    /**
     * 
     * @type {{ [key: string]: StreemApiCompanyRoomOutcomeConfigSection; }}
     * @memberof StreemApiCreateCompanyRoomOutcomeConfigRequest
     */
    customFields?: { [key: string]: StreemApiCompanyRoomOutcomeConfigSection; };
}

export function StreemApiCreateCompanyRoomOutcomeConfigRequestFromJSON(json: any): StreemApiCreateCompanyRoomOutcomeConfigRequest {
    return StreemApiCreateCompanyRoomOutcomeConfigRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyRoomOutcomeConfigRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyRoomOutcomeConfigRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'diagnosis': !exists(json, 'diagnosis') ? undefined : StreemApiCompanyRoomOutcomeConfigSectionFromJSON(json['diagnosis']),
        'disposition': !exists(json, 'disposition') ? undefined : StreemApiCompanyRoomOutcomeConfigSectionFromJSON(json['disposition']),
        'customFields': !exists(json, 'custom_fields') ? undefined : (mapValues(json['custom_fields'], StreemApiCompanyRoomOutcomeConfigSectionFromJSON)),
    };
}

export function StreemApiCreateCompanyRoomOutcomeConfigRequestToJSON(value?: StreemApiCreateCompanyRoomOutcomeConfigRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_sid': value.companySid,
        'diagnosis': StreemApiCompanyRoomOutcomeConfigSectionToJSON(value.diagnosis),
        'disposition': StreemApiCompanyRoomOutcomeConfigSectionToJSON(value.disposition),
        'custom_fields': value.customFields === undefined ? undefined : (mapValues(value.customFields, StreemApiCompanyRoomOutcomeConfigSectionToJSON)),
    };
}


