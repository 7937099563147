/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiStreemRoomCredentials,
    StreemApiStreemRoomCredentialsFromJSON,
    StreemApiStreemRoomCredentialsFromJSONTyped,
    StreemApiStreemRoomCredentialsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateReadonlyRoomTokenResponse
 */
export interface StreemApiCreateReadonlyRoomTokenResponse {
    /**
     * 
     * @type {StreemApiStreemRoomCredentials}
     * @memberof StreemApiCreateReadonlyRoomTokenResponse
     */
    streemRoomCredentials?: StreemApiStreemRoomCredentials;
}

export function StreemApiCreateReadonlyRoomTokenResponseFromJSON(json: any): StreemApiCreateReadonlyRoomTokenResponse {
    return StreemApiCreateReadonlyRoomTokenResponseFromJSONTyped(json, false);
}

export function StreemApiCreateReadonlyRoomTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateReadonlyRoomTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streemRoomCredentials': !exists(json, 'streem_room_credentials') ? undefined : StreemApiStreemRoomCredentialsFromJSON(json['streem_room_credentials']),
    };
}

export function StreemApiCreateReadonlyRoomTokenResponseToJSON(value?: StreemApiCreateReadonlyRoomTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streem_room_credentials': StreemApiStreemRoomCredentialsToJSON(value.streemRoomCredentials),
    };
}


