/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiNote
 */
export interface StreemApiNote {
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    createdByUserSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiNote
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    scope?: StreemApiNoteScope;
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    scopeSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    lastUpdatedByUserSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiNote
     */
    lastUpdatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiNote
     */
    text?: string;
}

export function StreemApiNoteFromJSON(json: any): StreemApiNote {
    return StreemApiNoteFromJSONTyped(json, false);
}

export function StreemApiNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'createdByUserSid': !exists(json, 'created_by_user_sid') ? undefined : json['created_by_user_sid'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'scopeSid': !exists(json, 'scope_sid') ? undefined : json['scope_sid'],
        'lastUpdatedByUserSid': !exists(json, 'last_updated_by_user_sid') ? undefined : json['last_updated_by_user_sid'],
        'lastUpdatedAt': !exists(json, 'last_updated_at') ? undefined : (new Date(json['last_updated_at'])),
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function StreemApiNoteToJSON(value?: StreemApiNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'created_by_user_sid': value.createdByUserSid,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'scope': value.scope,
        'scope_sid': value.scopeSid,
        'last_updated_by_user_sid': value.lastUpdatedByUserSid,
        'last_updated_at': value.lastUpdatedAt === undefined ? undefined : (value.lastUpdatedAt.toISOString()),
        'text': value.text,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiNoteScope {
    ROOM = 'SCOPE_ROOM',
    STREEMSHOT = 'SCOPE_STREEMSHOT',
    INVALID = 'SCOPE_INVALID'
}


