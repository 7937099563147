/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventCameraSubjectSet
 */
export interface StreemApiAnalyticEventCameraSubjectSet {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventCameraSubjectSet
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventCameraSubjectSet
     */
    subject?: StreemApiAnalyticEventCameraSubjectSetSubject;
}

export function StreemApiAnalyticEventCameraSubjectSetFromJSON(json: any): StreemApiAnalyticEventCameraSubjectSet {
    return StreemApiAnalyticEventCameraSubjectSetFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventCameraSubjectSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventCameraSubjectSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
    };
}

export function StreemApiAnalyticEventCameraSubjectSetToJSON(value?: StreemApiAnalyticEventCameraSubjectSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'subject': value.subject,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventCameraSubjectSetSubject {
    WORLD = 'CAMERA_SUBJECT_WORLD',
    PERSON = 'CAMERA_SUBJECT_PERSON',
    SCREEN = 'CAMERA_SUBJECT_SCREEN'
}


