/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiImportGlobalAppRequest
 */
export interface StreemApiImportGlobalAppRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiImportGlobalAppRequest
     */
    globalAppSid?: string;
}

export function StreemApiImportGlobalAppRequestFromJSON(json: any): StreemApiImportGlobalAppRequest {
    return StreemApiImportGlobalAppRequestFromJSONTyped(json, false);
}

export function StreemApiImportGlobalAppRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiImportGlobalAppRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalAppSid': !exists(json, 'global_app_sid') ? undefined : json['global_app_sid'],
    };
}

export function StreemApiImportGlobalAppRequestToJSON(value?: StreemApiImportGlobalAppRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global_app_sid': value.globalAppSid,
    };
}


