/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAraasRoomVideoCredentials,
    StreemApiAraasRoomVideoCredentialsFromJSON,
    StreemApiAraasRoomVideoCredentialsFromJSONTyped,
    StreemApiAraasRoomVideoCredentialsToJSON,
    StreemApiChimeRoomVideoCredentials,
    StreemApiChimeRoomVideoCredentialsFromJSON,
    StreemApiChimeRoomVideoCredentialsFromJSONTyped,
    StreemApiChimeRoomVideoCredentialsToJSON,
    StreemApiTwilioRoomVideoCredentials,
    StreemApiTwilioRoomVideoCredentialsFromJSON,
    StreemApiTwilioRoomVideoCredentialsFromJSONTyped,
    StreemApiTwilioRoomVideoCredentialsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateRoomVideoCredentialsResponse
 */
export interface StreemApiCreateRoomVideoCredentialsResponse {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateRoomVideoCredentialsResponse
     */
    roomSid?: string;
    /**
     * 
     * @type {StreemApiTwilioRoomVideoCredentials}
     * @memberof StreemApiCreateRoomVideoCredentialsResponse
     */
    twilioRoomVideoCredentials?: StreemApiTwilioRoomVideoCredentials;
    /**
     * 
     * @type {StreemApiAraasRoomVideoCredentials}
     * @memberof StreemApiCreateRoomVideoCredentialsResponse
     */
    araasRoomVideoCredentials?: StreemApiAraasRoomVideoCredentials;
    /**
     * 
     * @type {StreemApiChimeRoomVideoCredentials}
     * @memberof StreemApiCreateRoomVideoCredentialsResponse
     */
    chimeRoomVideoCredentials?: StreemApiChimeRoomVideoCredentials;
}

export function StreemApiCreateRoomVideoCredentialsResponseFromJSON(json: any): StreemApiCreateRoomVideoCredentialsResponse {
    return StreemApiCreateRoomVideoCredentialsResponseFromJSONTyped(json, false);
}

export function StreemApiCreateRoomVideoCredentialsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateRoomVideoCredentialsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'twilioRoomVideoCredentials': !exists(json, 'twilio_room_video_credentials') ? undefined : StreemApiTwilioRoomVideoCredentialsFromJSON(json['twilio_room_video_credentials']),
        'araasRoomVideoCredentials': !exists(json, 'araas_room_video_credentials') ? undefined : StreemApiAraasRoomVideoCredentialsFromJSON(json['araas_room_video_credentials']),
        'chimeRoomVideoCredentials': !exists(json, 'chime_room_video_credentials') ? undefined : StreemApiChimeRoomVideoCredentialsFromJSON(json['chime_room_video_credentials']),
    };
}

export function StreemApiCreateRoomVideoCredentialsResponseToJSON(value?: StreemApiCreateRoomVideoCredentialsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'twilio_room_video_credentials': StreemApiTwilioRoomVideoCredentialsToJSON(value.twilioRoomVideoCredentials),
        'araas_room_video_credentials': StreemApiAraasRoomVideoCredentialsToJSON(value.araasRoomVideoCredentials),
        'chime_room_video_credentials': StreemApiChimeRoomVideoCredentialsToJSON(value.chimeRoomVideoCredentials),
    };
}


