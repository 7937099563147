/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAnalyticEventToolArrow,
    StreemApiAnalyticEventToolArrowFromJSON,
    StreemApiAnalyticEventToolArrowFromJSONTyped,
    StreemApiAnalyticEventToolArrowToJSON,
    StreemApiAnalyticEventToolMeasure,
    StreemApiAnalyticEventToolMeasureFromJSON,
    StreemApiAnalyticEventToolMeasureFromJSONTyped,
    StreemApiAnalyticEventToolMeasureToJSON,
    StreemApiAnalyticEventToolMeasurementAnchor,
    StreemApiAnalyticEventToolMeasurementAnchorFromJSON,
    StreemApiAnalyticEventToolMeasurementAnchorFromJSONTyped,
    StreemApiAnalyticEventToolMeasurementAnchorToJSON,
    StreemApiAnalyticEventToolModel3d,
    StreemApiAnalyticEventToolModel3dFromJSON,
    StreemApiAnalyticEventToolModel3dFromJSONTyped,
    StreemApiAnalyticEventToolModel3dToJSON,
    StreemApiAnalyticEventToolText,
    StreemApiAnalyticEventToolTextFromJSON,
    StreemApiAnalyticEventToolTextFromJSONTyped,
    StreemApiAnalyticEventToolTextToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEventToolRemoved
 */
export interface StreemApiAnalyticEventToolRemoved {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    callStatus?: StreemApiAnalyticEventToolRemovedCallStatus;
    /**
     * 
     * @type {StreemApiAnalyticEventToolArrow}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    arrow?: StreemApiAnalyticEventToolArrow;
    /**
     * 
     * @type {StreemApiAnalyticEventToolMeasure}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    measure?: StreemApiAnalyticEventToolMeasure;
    /**
     * 
     * @type {StreemApiAnalyticEventToolText}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    text?: StreemApiAnalyticEventToolText;
    /**
     * 
     * @type {StreemApiAnalyticEventToolModel3d}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    model3d?: StreemApiAnalyticEventToolModel3d;
    /**
     * 
     * @type {StreemApiAnalyticEventToolMeasurementAnchor}
     * @memberof StreemApiAnalyticEventToolRemoved
     */
    measureAnchor?: StreemApiAnalyticEventToolMeasurementAnchor;
}

export function StreemApiAnalyticEventToolRemovedFromJSON(json: any): StreemApiAnalyticEventToolRemoved {
    return StreemApiAnalyticEventToolRemovedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventToolRemovedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventToolRemoved {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'callStatus': !exists(json, 'call_status') ? undefined : json['call_status'],
        'arrow': !exists(json, 'arrow') ? undefined : StreemApiAnalyticEventToolArrowFromJSON(json['arrow']),
        'measure': !exists(json, 'measure') ? undefined : StreemApiAnalyticEventToolMeasureFromJSON(json['measure']),
        'text': !exists(json, 'text') ? undefined : StreemApiAnalyticEventToolTextFromJSON(json['text']),
        'model3d': !exists(json, 'model3d') ? undefined : StreemApiAnalyticEventToolModel3dFromJSON(json['model3d']),
        'measureAnchor': !exists(json, 'measure_anchor') ? undefined : StreemApiAnalyticEventToolMeasurementAnchorFromJSON(json['measure_anchor']),
    };
}

export function StreemApiAnalyticEventToolRemovedToJSON(value?: StreemApiAnalyticEventToolRemoved | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'call_status': value.callStatus,
        'arrow': StreemApiAnalyticEventToolArrowToJSON(value.arrow),
        'measure': StreemApiAnalyticEventToolMeasureToJSON(value.measure),
        'text': StreemApiAnalyticEventToolTextToJSON(value.text),
        'model3d': StreemApiAnalyticEventToolModel3dToJSON(value.model3d),
        'measure_anchor': StreemApiAnalyticEventToolMeasurementAnchorToJSON(value.measureAnchor),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventToolRemovedCallStatus {
    NOCALL = 'CALL_STATUS_NO_CALL',
    INCALL = 'CALL_STATUS_IN_CALL',
    POSTCALL = 'CALL_STATUS_POST_CALL',
    INVALID = 'CALL_STATUS_INVALID'
}


