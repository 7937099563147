/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateSignedUploadUrlRequest
 */
export interface StreemApiCreateSignedUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedUploadUrlRequest
     */
    type?: StreemApiCreateSignedUploadUrlRequestType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedUploadUrlRequest
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedUploadUrlRequest
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedUploadUrlRequest
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedUploadUrlRequest
     */
    companyCode?: string;
}

export function StreemApiCreateSignedUploadUrlRequestFromJSON(json: any): StreemApiCreateSignedUploadUrlRequest {
    return StreemApiCreateSignedUploadUrlRequestFromJSONTyped(json, false);
}

export function StreemApiCreateSignedUploadUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateSignedUploadUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'companyCode': !exists(json, 'company_code') ? undefined : json['company_code'],
    };
}

export function StreemApiCreateSignedUploadUrlRequestToJSON(value?: StreemApiCreateSignedUploadUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'group': value.group,
        'sid': value.sid,
        'filename': value.filename,
        'company_code': value.companyCode,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiCreateSignedUploadUrlRequestType {
    UNKNOWN = 'UPLOAD_TYPE_UNKNOWN',
    STREEMSHOT = 'UPLOAD_TYPE_STREEMSHOT',
    RECORDING = 'UPLOAD_TYPE_RECORDING',
    RECORDINGTRACK = 'UPLOAD_TYPE_RECORDING_TRACK',
    GPSPOSITION = 'UPLOAD_TYPE_GPS_POSITION',
    MESH = 'UPLOAD_TYPE_MESH',
    SCENESHOT = 'UPLOAD_TYPE_SCENESHOT',
    TRANSCRIPTION = 'UPLOAD_TYPE_TRANSCRIPTION',
    LAYOUTESTIMATION = 'UPLOAD_TYPE_LAYOUT_ESTIMATION',
    TEXTUREDMESH = 'UPLOAD_TYPE_TEXTURED_MESH',
    ROOMNOTE = 'UPLOAD_TYPE_ROOM_NOTE',
    STREEMSHOTNOTE = 'UPLOAD_TYPE_STREEMSHOT_NOTE'
}


