/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiBatchCreateUserRequest,
    StreemApiBatchCreateUserRequestFromJSON,
    StreemApiBatchCreateUserRequestToJSON,
    StreemApiBatchCreateUserResponse,
    StreemApiBatchCreateUserResponseFromJSON,
    StreemApiBatchCreateUserResponseToJSON,
    StreemApiBatchDeactivateUserRequest,
    StreemApiBatchDeactivateUserRequestFromJSON,
    StreemApiBatchDeactivateUserRequestToJSON,
    StreemApiCreateCompanyUserRequest,
    StreemApiCreateCompanyUserRequestFromJSON,
    StreemApiCreateCompanyUserRequestToJSON,
    StreemApiCreateCompanyUserResponse,
    StreemApiCreateCompanyUserResponseFromJSON,
    StreemApiCreateCompanyUserResponseToJSON,
    StreemApiCreateUserAssetResponse,
    StreemApiCreateUserAssetResponseFromJSON,
    StreemApiCreateUserAssetResponseToJSON,
    StreemApiCreateUserTokensResponse,
    StreemApiCreateUserTokensResponseFromJSON,
    StreemApiCreateUserTokensResponseToJSON,
    StreemApiCreateUserVoipTokenRequest,
    StreemApiCreateUserVoipTokenRequestFromJSON,
    StreemApiCreateUserVoipTokenRequestToJSON,
    StreemApiGetAssociatedCompaniesResponse,
    StreemApiGetAssociatedCompaniesResponseFromJSON,
    StreemApiGetAssociatedCompaniesResponseToJSON,
    StreemApiGetStreemSurveyStatusResponse,
    StreemApiGetStreemSurveyStatusResponseFromJSON,
    StreemApiGetStreemSurveyStatusResponseToJSON,
    StreemApiGetUserAvailabilityResponse,
    StreemApiGetUserAvailabilityResponseFromJSON,
    StreemApiGetUserAvailabilityResponseToJSON,
    StreemApiGetUserByExternalUserIdResponse,
    StreemApiGetUserByExternalUserIdResponseFromJSON,
    StreemApiGetUserByExternalUserIdResponseToJSON,
    StreemApiGetUserCallLogEntryResponse,
    StreemApiGetUserCallLogEntryResponseFromJSON,
    StreemApiGetUserCallLogEntryResponseToJSON,
    StreemApiGetUserCallLogResponse,
    StreemApiGetUserCallLogResponseFromJSON,
    StreemApiGetUserCallLogResponseToJSON,
    StreemApiGetUserResponse,
    StreemApiGetUserResponseFromJSON,
    StreemApiGetUserResponseToJSON,
    StreemApiGetUserTermsStatusResponse,
    StreemApiGetUserTermsStatusResponseFromJSON,
    StreemApiGetUserTermsStatusResponseToJSON,
    StreemApiIdentifyUserRequest,
    StreemApiIdentifyUserRequestFromJSON,
    StreemApiIdentifyUserRequestToJSON,
    StreemApiListRecentlyIdentifiedUsersResponse,
    StreemApiListRecentlyIdentifiedUsersResponseFromJSON,
    StreemApiListRecentlyIdentifiedUsersResponseToJSON,
    StreemApiListUserDevicesResponse,
    StreemApiListUserDevicesResponseFromJSON,
    StreemApiListUserDevicesResponseToJSON,
    StreemApiListUserInvitationsResponse,
    StreemApiListUserInvitationsResponseFromJSON,
    StreemApiListUserInvitationsResponseToJSON,
    StreemApiSaveUserTermsStatusRequest,
    StreemApiSaveUserTermsStatusRequestFromJSON,
    StreemApiSaveUserTermsStatusRequestToJSON,
    StreemApiSaveUserTermsStatusResponse,
    StreemApiSaveUserTermsStatusResponseFromJSON,
    StreemApiSaveUserTermsStatusResponseToJSON,
    StreemApiUpdateGroupsForUserRequest,
    StreemApiUpdateGroupsForUserRequestFromJSON,
    StreemApiUpdateGroupsForUserRequestToJSON,
    StreemApiUpdateGroupsForUserResponse,
    StreemApiUpdateGroupsForUserResponseFromJSON,
    StreemApiUpdateGroupsForUserResponseToJSON,
    StreemApiUpdateUserAvailabilityRequest,
    StreemApiUpdateUserAvailabilityRequestFromJSON,
    StreemApiUpdateUserAvailabilityRequestToJSON,
    StreemApiUpdateUserAvailabilityResponse,
    StreemApiUpdateUserAvailabilityResponseFromJSON,
    StreemApiUpdateUserAvailabilityResponseToJSON,
    StreemApiUpdateUserCallLogEntryRequest,
    StreemApiUpdateUserCallLogEntryRequestFromJSON,
    StreemApiUpdateUserCallLogEntryRequestToJSON,
    StreemApiUpdateUserCallLogEntryResponse,
    StreemApiUpdateUserCallLogEntryResponseFromJSON,
    StreemApiUpdateUserCallLogEntryResponseToJSON,
    StreemApiUpdateUserRequest,
    StreemApiUpdateUserRequestFromJSON,
    StreemApiUpdateUserRequestToJSON,
    StreemApiUpdateUserResponse,
    StreemApiUpdateUserResponseFromJSON,
    StreemApiUpdateUserResponseToJSON,
    StreemInternalApiCreateUserAssetRequest,
    StreemInternalApiCreateUserAssetRequestFromJSON,
    StreemInternalApiCreateUserAssetRequestToJSON,
    StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponse,
    StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponseFromJSON,
    StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponseToJSON,
} from '../models';

export interface ActivateRequest {
    userSid: string;
}

export interface BatchCreateUserRequest {
    streemApiBatchCreateUserRequest?: StreemApiBatchCreateUserRequest;
}

export interface BatchDeactivateUsersRequest {
    streemApiBatchDeactivateUserRequest?: StreemApiBatchDeactivateUserRequest;
}

export interface CreateCompanyUserRequest {
    companyCodeOrSid: string;
    streemApiCreateCompanyUserRequest?: StreemApiCreateCompanyUserRequest;
}

export interface CreateUserAssetRequest {
    userSid: string;
    type: CreateUserAssetOperationType;
    streemInternalApiCreateUserAssetRequest?: StreemInternalApiCreateUserAssetRequest;
}

export interface CreateUserTokensRequest {
    userSid: string;
}

export interface CreateUserVoipTokenRequest {
    userSid: string;
    streemApiCreateUserVoipTokenRequest?: StreemApiCreateUserVoipTokenRequest;
}

export interface DeactivateRequest {
    userSid: string;
}

export interface DeleteUserRequest {
    userSid: string;
}

export interface GetAssociatedCompaniesRequest {
    userSid: string;
    switchWorkspacesEnabled?: boolean;
}

export interface GetLoginIdentityCompaniesRequest {
    userSid: string;
    switchWorkspacesEnabled?: boolean;
}

export interface GetStreemSurveyStatusRequest {
    userSid: string;
}

export interface GetUserRequest {
    userSid: string;
}

export interface GetUserAvailabilityRequest {
    userSid: string;
}

export interface GetUserByExternalUserIdRequest {
    companyCodeOrSid: string;
    externalUserId: string;
}

export interface GetUserCallLogRequest {
    userSid: string;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
    favoritesOnly?: boolean;
}

export interface GetUserCallLogEntryRequest {
    userSid: string;
    roomSid: string;
}

export interface GetUserTermsStatusRequest {
    userSid: string;
}

export interface IdentifyUserRequest {
    userSid: string;
    streemApiIdentifyUserRequest?: StreemApiIdentifyUserRequest;
}

export interface ListDevicesForUserRequest {
    userSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRecentlyIdentifiedUsersRequest {
    isExpert?: boolean;
    pageSize?: number;
    pageToken?: string;
}

export interface ListUserInvitationsRequest {
    userSid: string;
    deviceSid?: string;
    status?: ListUserInvitationsStatus;
    pageSize?: number;
    pageToken?: string;
}

export interface SaveStreemSurveyStatusRequest {
    userSid: string;
}

export interface SaveUserTermsStatusRequest {
    userSid: string;
    streemApiSaveUserTermsStatusRequest?: StreemApiSaveUserTermsStatusRequest;
}

export interface UpdateGroupsForUserRequest {
    userSid: string;
    streemApiUpdateGroupsForUserRequest?: StreemApiUpdateGroupsForUserRequest;
}

export interface UpdateUserRequest {
    userSid: string;
    streemApiUpdateUserRequest?: StreemApiUpdateUserRequest;
}

export interface UpdateUserAvailabilityRequest {
    userSid: string;
    streemApiUpdateUserAvailabilityRequest?: StreemApiUpdateUserAvailabilityRequest;
}

export interface UpdateUserCallLogEntryRequest {
    userSid: string;
    roomSid: string;
    streemApiUpdateUserCallLogEntryRequest?: StreemApiUpdateUserCallLogEntryRequest;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Activate a user
     * Activate User
     */
    async activateRaw(requestParameters: ActivateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling activate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/activate`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate a user
     * Activate User
     */
    async activate(userSid: string): Promise<void> {
        await this.activateRaw({ userSid: userSid });
    }

    /**
     * Creates users from CSV
     * Batch Create User
     */
    async batchCreateUserRaw(requestParameters: BatchCreateUserRequest): Promise<runtime.ApiResponse<StreemApiBatchCreateUserResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/~batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiBatchCreateUserRequestToJSON(requestParameters.streemApiBatchCreateUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiBatchCreateUserResponseFromJSON(jsonValue));
    }

    /**
     * Creates users from CSV
     * Batch Create User
     */
    async batchCreateUser(streemApiBatchCreateUserRequest?: StreemApiBatchCreateUserRequest): Promise<StreemApiBatchCreateUserResponse> {
        const response = await this.batchCreateUserRaw({ streemApiBatchCreateUserRequest: streemApiBatchCreateUserRequest });
        return await response.value();
    }

    /**
     * Deactivates users from CSV
     * Batch Deactivate User
     */
    async batchDeactivateUsersRaw(requestParameters: BatchDeactivateUsersRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/~batch/deactivate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiBatchDeactivateUserRequestToJSON(requestParameters.streemApiBatchDeactivateUserRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivates users from CSV
     * Batch Deactivate User
     */
    async batchDeactivateUsers(streemApiBatchDeactivateUserRequest?: StreemApiBatchDeactivateUserRequest): Promise<void> {
        await this.batchDeactivateUsersRaw({ streemApiBatchDeactivateUserRequest: streemApiBatchDeactivateUserRequest });
    }

    /**
     * Create a user for log-in for this Company
     * Create a user
     */
    async createCompanyUserRaw(requestParameters: CreateCompanyUserRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyUserResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCompanyUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyUserRequestToJSON(requestParameters.streemApiCreateCompanyUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyUserResponseFromJSON(jsonValue));
    }

    /**
     * Create a user for log-in for this Company
     * Create a user
     */
    async createCompanyUser(companyCodeOrSid: string, streemApiCreateCompanyUserRequest?: StreemApiCreateCompanyUserRequest): Promise<StreemApiCreateCompanyUserResponse> {
        const response = await this.createCompanyUserRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateCompanyUserRequest: streemApiCreateCompanyUserRequest });
        return await response.value();
    }

    /**
     * Upload a user asset
     */
    async createUserAssetRaw(requestParameters: CreateUserAssetRequest): Promise<runtime.ApiResponse<StreemApiCreateUserAssetResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createUserAsset.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createUserAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/assets/{type}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemInternalApiCreateUserAssetRequestToJSON(requestParameters.streemInternalApiCreateUserAssetRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateUserAssetResponseFromJSON(jsonValue));
    }

    /**
     * Upload a user asset
     */
    async createUserAsset(userSid: string, type: CreateUserAssetOperationType, streemInternalApiCreateUserAssetRequest?: StreemInternalApiCreateUserAssetRequest): Promise<StreemApiCreateUserAssetResponse> {
        const response = await this.createUserAssetRaw({ userSid: userSid, type: type, streemInternalApiCreateUserAssetRequest: streemInternalApiCreateUserAssetRequest });
        return await response.value();
    }

    /**
     * Creates a token for accessing a user wall
     * Create Token
     */
    async createUserTokensRaw(requestParameters: CreateUserTokensRequest): Promise<runtime.ApiResponse<StreemApiCreateUserTokensResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createUserTokens.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/tokens`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateUserTokensResponseFromJSON(jsonValue));
    }

    /**
     * Creates a token for accessing a user wall
     * Create Token
     */
    async createUserTokens(userSid: string): Promise<StreemApiCreateUserTokensResponse> {
        const response = await this.createUserTokensRaw({ userSid: userSid });
        return await response.value();
    }

    /**
     * Save VoIP token for the specified user
     * Save VOIP Token
     */
    async createUserVoipTokenRaw(requestParameters: CreateUserVoipTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createUserVoipToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/voip-tokens`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateUserVoipTokenRequestToJSON(requestParameters.streemApiCreateUserVoipTokenRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save VoIP token for the specified user
     * Save VOIP Token
     */
    async createUserVoipToken(userSid: string, streemApiCreateUserVoipTokenRequest?: StreemApiCreateUserVoipTokenRequest): Promise<void> {
        await this.createUserVoipTokenRaw({ userSid: userSid, streemApiCreateUserVoipTokenRequest: streemApiCreateUserVoipTokenRequest });
    }

    /**
     * Deactivate a user
     * Deactivate User
     */
    async deactivateRaw(requestParameters: DeactivateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling deactivate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/deactivate`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate a user
     * Deactivate User
     */
    async deactivate(userSid: string): Promise<void> {
        await this.deactivateRaw({ userSid: userSid });
    }

    /**
     * Delete the specified user
     * Delete User
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling deleteUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified user
     * Delete User
     */
    async deleteUser(userSid: string): Promise<void> {
        await this.deleteUserRaw({ userSid: userSid });
    }

    /**
     * Get all companies associated with the requesting user; currently their membership is associated by email
     * Get companies associated with a user
     */
    async getAssociatedCompaniesRaw(requestParameters: GetAssociatedCompaniesRequest): Promise<runtime.ApiResponse<StreemApiGetAssociatedCompaniesResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getAssociatedCompanies.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.switchWorkspacesEnabled !== undefined) {
            queryParameters['switch_workspaces_enabled'] = requestParameters.switchWorkspacesEnabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/associated-companies`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetAssociatedCompaniesResponseFromJSON(jsonValue));
    }

    /**
     * Get all companies associated with the requesting user; currently their membership is associated by email
     * Get companies associated with a user
     */
    async getAssociatedCompanies(userSid: string, switchWorkspacesEnabled?: boolean): Promise<StreemApiGetAssociatedCompaniesResponse> {
        const response = await this.getAssociatedCompaniesRaw({ userSid: userSid, switchWorkspacesEnabled: switchWorkspacesEnabled });
        return await response.value();
    }

    /**
     */
    async getLoginIdentityCompaniesRaw(requestParameters: GetLoginIdentityCompaniesRequest): Promise<runtime.ApiResponse<StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getLoginIdentityCompanies.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.switchWorkspacesEnabled !== undefined) {
            queryParameters['switch_workspaces_enabled'] = requestParameters.switchWorkspacesEnabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/login-identity-companies`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLoginIdentityCompanies(userSid: string, switchWorkspacesEnabled?: boolean): Promise<StreemInternalApiGlobalGetUserLoginIdentityCompaniesResponse> {
        const response = await this.getLoginIdentityCompaniesRaw({ userSid: userSid, switchWorkspacesEnabled: switchWorkspacesEnabled });
        return await response.value();
    }

    /**
     * Returns true if the user should see the Streem survey.
     * Get Streem Survey Status
     */
    async getStreemSurveyStatusRaw(requestParameters: GetStreemSurveyStatusRequest): Promise<runtime.ApiResponse<StreemApiGetStreemSurveyStatusResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getStreemSurveyStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/streem-survey-status`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetStreemSurveyStatusResponseFromJSON(jsonValue));
    }

    /**
     * Returns true if the user should see the Streem survey.
     * Get Streem Survey Status
     */
    async getStreemSurveyStatus(userSid: string): Promise<StreemApiGetStreemSurveyStatusResponse> {
        const response = await this.getStreemSurveyStatusRaw({ userSid: userSid });
        return await response.value();
    }

    /**
     * Get user by user sid
     * Get User
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<StreemApiGetUserResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserResponseFromJSON(jsonValue));
    }

    /**
     * Get user by user sid
     * Get User
     */
    async getUser(userSid: string): Promise<StreemApiGetUserResponse> {
        const response = await this.getUserRaw({ userSid: userSid });
        return await response.value();
    }

    /**
     * Returns the current Availability Status for the user.  (Only supported if User Availability is enabled)
     * Get User Availability
     */
    async getUserAvailabilityRaw(requestParameters: GetUserAvailabilityRequest): Promise<runtime.ApiResponse<StreemApiGetUserAvailabilityResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getUserAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/availability`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     * Returns the current Availability Status for the user.  (Only supported if User Availability is enabled)
     * Get User Availability
     */
    async getUserAvailability(userSid: string): Promise<StreemApiGetUserAvailabilityResponse> {
        const response = await this.getUserAvailabilityRaw({ userSid: userSid });
        return await response.value();
    }

    /**
     * Get User by `external_user_id`, which should be unique in the scope of the specified Company
     * Get User By External User Id
     */
    async getUserByExternalUserIdRaw(requestParameters: GetUserByExternalUserIdRequest): Promise<runtime.ApiResponse<StreemApiGetUserByExternalUserIdResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getUserByExternalUserId.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling getUserByExternalUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users/{external_user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"external_user_id"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserByExternalUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Get User by `external_user_id`, which should be unique in the scope of the specified Company
     * Get User By External User Id
     */
    async getUserByExternalUserId(companyCodeOrSid: string, externalUserId: string): Promise<StreemApiGetUserByExternalUserIdResponse> {
        const response = await this.getUserByExternalUserIdRaw({ companyCodeOrSid: companyCodeOrSid, externalUserId: externalUserId });
        return await response.value();
    }

    /**
     * Get list of call log entries for user
     * Get Call Log
     */
    async getUserCallLogRaw(requestParameters: GetUserCallLogRequest): Promise<runtime.ApiResponse<StreemApiGetUserCallLogResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getUserCallLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.favoritesOnly !== undefined) {
            queryParameters['favorites_only'] = requestParameters.favoritesOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/call-log`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserCallLogResponseFromJSON(jsonValue));
    }

    /**
     * Get list of call log entries for user
     * Get Call Log
     */
    async getUserCallLog(userSid: string, pageSize?: number, pageToken?: string, filter?: string, favoritesOnly?: boolean): Promise<StreemApiGetUserCallLogResponse> {
        const response = await this.getUserCallLogRaw({ userSid: userSid, pageSize: pageSize, pageToken: pageToken, filter: filter, favoritesOnly: favoritesOnly });
        return await response.value();
    }

    /**
     * Get single call log entry for room
     * Get Call Log Entry
     */
    async getUserCallLogEntryRaw(requestParameters: GetUserCallLogEntryRequest): Promise<runtime.ApiResponse<StreemApiGetUserCallLogEntryResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getUserCallLogEntry.');
        }

        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getUserCallLogEntry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/call-log/{room_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))).replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserCallLogEntryResponseFromJSON(jsonValue));
    }

    /**
     * Get single call log entry for room
     * Get Call Log Entry
     */
    async getUserCallLogEntry(userSid: string, roomSid: string): Promise<StreemApiGetUserCallLogEntryResponse> {
        const response = await this.getUserCallLogEntryRaw({ userSid: userSid, roomSid: roomSid });
        return await response.value();
    }

    /**
     * Returns false if the user has not accepted terms yet, false if the user has accepted but the termhave changed, and true if the user has accepted the most recent terms
     * Get User Terms Status
     */
    async getUserTermsStatusRaw(requestParameters: GetUserTermsStatusRequest): Promise<runtime.ApiResponse<StreemApiGetUserTermsStatusResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling getUserTermsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/terms-status`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserTermsStatusResponseFromJSON(jsonValue));
    }

    /**
     * Returns false if the user has not accepted terms yet, false if the user has accepted but the termhave changed, and true if the user has accepted the most recent terms
     * Get User Terms Status
     */
    async getUserTermsStatus(userSid: string): Promise<StreemApiGetUserTermsStatusResponse> {
        const response = await this.getUserTermsStatusRaw({ userSid: userSid });
        return await response.value();
    }

    /**
     * Identify as the specified user
     * Identify User
     */
    async identifyUserRaw(requestParameters: IdentifyUserRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling identifyUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiIdentifyUserRequestToJSON(requestParameters.streemApiIdentifyUserRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Identify as the specified user
     * Identify User
     */
    async identifyUser(userSid: string, streemApiIdentifyUserRequest?: StreemApiIdentifyUserRequest): Promise<object> {
        const response = await this.identifyUserRaw({ userSid: userSid, streemApiIdentifyUserRequest: streemApiIdentifyUserRequest });
        return await response.value();
    }

    /**
     * Get all Devices for specified User
     * List Devices
     */
    async listDevicesForUserRaw(requestParameters: ListDevicesForUserRequest): Promise<runtime.ApiResponse<StreemApiListUserDevicesResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling listDevicesForUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/devices`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListUserDevicesResponseFromJSON(jsonValue));
    }

    /**
     * Get all Devices for specified User
     * List Devices
     */
    async listDevicesForUser(userSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListUserDevicesResponse> {
        const response = await this.listDevicesForUserRaw({ userSid: userSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Get list of users that have logged in recently
     * Get Recent Users
     */
    async listRecentlyIdentifiedUsersRaw(requestParameters: ListRecentlyIdentifiedUsersRequest): Promise<runtime.ApiResponse<StreemApiListRecentlyIdentifiedUsersResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isExpert !== undefined) {
            queryParameters['is_expert'] = requestParameters.isExpert;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/recently-identified`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRecentlyIdentifiedUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get list of users that have logged in recently
     * Get Recent Users
     */
    async listRecentlyIdentifiedUsers(isExpert?: boolean, pageSize?: number, pageToken?: string): Promise<StreemApiListRecentlyIdentifiedUsersResponse> {
        const response = await this.listRecentlyIdentifiedUsersRaw({ isExpert: isExpert, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Get invitations for this user, optionally filtering on device and status
     * Get Invitations
     */
    async listUserInvitationsRaw(requestParameters: ListUserInvitationsRequest): Promise<runtime.ApiResponse<StreemApiListUserInvitationsResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling listUserInvitations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceSid !== undefined) {
            queryParameters['device_sid'] = requestParameters.deviceSid;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/invitations`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListUserInvitationsResponseFromJSON(jsonValue));
    }

    /**
     * Get invitations for this user, optionally filtering on device and status
     * Get Invitations
     */
    async listUserInvitations(userSid: string, deviceSid?: string, status?: ListUserInvitationsStatus, pageSize?: number, pageToken?: string): Promise<StreemApiListUserInvitationsResponse> {
        const response = await this.listUserInvitationsRaw({ userSid: userSid, deviceSid: deviceSid, status: status, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Updates the user\'s last view of the Streem survey to the current date and time.
     * Save Streem Survey Status
     */
    async saveStreemSurveyStatusRaw(requestParameters: SaveStreemSurveyStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling saveStreemSurveyStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/streem-survey-status`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the user\'s last view of the Streem survey to the current date and time.
     * Save Streem Survey Status
     */
    async saveStreemSurveyStatus(userSid: string): Promise<void> {
        await this.saveStreemSurveyStatusRaw({ userSid: userSid });
    }

    /**
     * Updates the day the user accepted the terms and conditions to the current date and time
     * Save User Terms Status
     */
    async saveUserTermsStatusRaw(requestParameters: SaveUserTermsStatusRequest): Promise<runtime.ApiResponse<StreemApiSaveUserTermsStatusResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling saveUserTermsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/terms-status`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSaveUserTermsStatusRequestToJSON(requestParameters.streemApiSaveUserTermsStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveUserTermsStatusResponseFromJSON(jsonValue));
    }

    /**
     * Updates the day the user accepted the terms and conditions to the current date and time
     * Save User Terms Status
     */
    async saveUserTermsStatus(userSid: string, streemApiSaveUserTermsStatusRequest?: StreemApiSaveUserTermsStatusRequest): Promise<StreemApiSaveUserTermsStatusResponse> {
        const response = await this.saveUserTermsStatusRaw({ userSid: userSid, streemApiSaveUserTermsStatusRequest: streemApiSaveUserTermsStatusRequest });
        return await response.value();
    }

    /**
     * Update the groups a user belongs to
     * Update groups for user
     */
    async updateGroupsForUserRaw(requestParameters: UpdateGroupsForUserRequest): Promise<runtime.ApiResponse<StreemApiUpdateGroupsForUserResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling updateGroupsForUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/groups`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateGroupsForUserRequestToJSON(requestParameters.streemApiUpdateGroupsForUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateGroupsForUserResponseFromJSON(jsonValue));
    }

    /**
     * Update the groups a user belongs to
     * Update groups for user
     */
    async updateGroupsForUser(userSid: string, streemApiUpdateGroupsForUserRequest?: StreemApiUpdateGroupsForUserRequest): Promise<StreemApiUpdateGroupsForUserResponse> {
        const response = await this.updateGroupsForUserRaw({ userSid: userSid, streemApiUpdateGroupsForUserRequest: streemApiUpdateGroupsForUserRequest });
        return await response.value();
    }

    /**
     * Update the user
     * Update user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<StreemApiUpdateUserResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling updateUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateUserRequestToJSON(requestParameters.streemApiUpdateUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateUserResponseFromJSON(jsonValue));
    }

    /**
     * Update the user
     * Update user
     */
    async updateUser(userSid: string, streemApiUpdateUserRequest?: StreemApiUpdateUserRequest): Promise<StreemApiUpdateUserResponse> {
        const response = await this.updateUserRaw({ userSid: userSid, streemApiUpdateUserRequest: streemApiUpdateUserRequest });
        return await response.value();
    }

    /**
     * Updates the availability status of a user (Only supported if User Availability is enabled)
     * Update User Availability
     */
    async updateUserAvailabilityRaw(requestParameters: UpdateUserAvailabilityRequest): Promise<runtime.ApiResponse<StreemApiUpdateUserAvailabilityResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling updateUserAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/availability`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateUserAvailabilityRequestToJSON(requestParameters.streemApiUpdateUserAvailabilityRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateUserAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     * Updates the availability status of a user (Only supported if User Availability is enabled)
     * Update User Availability
     */
    async updateUserAvailability(userSid: string, streemApiUpdateUserAvailabilityRequest?: StreemApiUpdateUserAvailabilityRequest): Promise<StreemApiUpdateUserAvailabilityResponse> {
        const response = await this.updateUserAvailabilityRaw({ userSid: userSid, streemApiUpdateUserAvailabilityRequest: streemApiUpdateUserAvailabilityRequest });
        return await response.value();
    }

    /**
     * Update a call log entry for a room
     * Update Call Log Entry
     */
    async updateUserCallLogEntryRaw(requestParameters: UpdateUserCallLogEntryRequest): Promise<runtime.ApiResponse<StreemApiUpdateUserCallLogEntryResponse>> {
        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling updateUserCallLogEntry.');
        }

        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling updateUserCallLogEntry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{user_sid}/call-log/{room_sid}`.replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))).replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateUserCallLogEntryRequestToJSON(requestParameters.streemApiUpdateUserCallLogEntryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateUserCallLogEntryResponseFromJSON(jsonValue));
    }

    /**
     * Update a call log entry for a room
     * Update Call Log Entry
     */
    async updateUserCallLogEntry(userSid: string, roomSid: string, streemApiUpdateUserCallLogEntryRequest?: StreemApiUpdateUserCallLogEntryRequest): Promise<StreemApiUpdateUserCallLogEntryResponse> {
        const response = await this.updateUserCallLogEntryRaw({ userSid: userSid, roomSid: roomSid, streemApiUpdateUserCallLogEntryRequest: streemApiUpdateUserCallLogEntryRequest });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserAssetOperationType {
    AVATAR = 'USER_UPLOAD_TYPE_AVATAR',
    INVALID = 'USER_UPLOAD_TYPE_INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum ListUserInvitationsStatus {
    PENDING = 'STATUS_PENDING',
    ACCEPTED = 'STATUS_ACCEPTED',
    REJECTED = 'STATUS_REJECTED',
    CANCELED = 'STATUS_CANCELED',
    TIMEDOUT = 'STATUS_TIMED_OUT',
    INVALID = 'STATUS_INVALID'
}
