/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DestinationStep
 */
export interface DestinationStep {
    /**
     * 
     * @type {string}
     * @memberof DestinationStep
     */
    stepId?: string;
}

export function DestinationStepFromJSON(json: any): DestinationStep {
    return DestinationStepFromJSONTyped(json, false);
}

export function DestinationStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepId': !exists(json, 'step_id') ? undefined : json['step_id'],
    };
}

export function DestinationStepToJSON(value?: DestinationStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step_id': value.stepId,
    };
}


