/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WallItemStreemshotDetectionsDetection,
    WallItemStreemshotDetectionsDetectionFromJSON,
    WallItemStreemshotDetectionsDetectionFromJSONTyped,
    WallItemStreemshotDetectionsDetectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemStreemshotDetections
 */
export interface WallItemStreemshotDetections {
    /**
     * 
     * @type {Array<WallItemStreemshotDetectionsDetection>}
     * @memberof WallItemStreemshotDetections
     */
    detections?: Array<WallItemStreemshotDetectionsDetection>;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetections
     */
    streemshotId?: string;
}

export function WallItemStreemshotDetectionsFromJSON(json: any): WallItemStreemshotDetections {
    return WallItemStreemshotDetectionsFromJSONTyped(json, false);
}

export function WallItemStreemshotDetectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemStreemshotDetections {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detections': !exists(json, 'detections') ? undefined : ((json['detections'] as Array<any>).map(WallItemStreemshotDetectionsDetectionFromJSON)),
        'streemshotId': !exists(json, 'streemshot_id') ? undefined : json['streemshot_id'],
    };
}

export function WallItemStreemshotDetectionsToJSON(value?: WallItemStreemshotDetections | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detections': value.detections === undefined ? undefined : ((value.detections as Array<any>).map(WallItemStreemshotDetectionsDetectionToJSON)),
        'streemshot_id': value.streemshotId,
    };
}


