import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import appLogger from '../util/app_logger';
import {
    recordScreenViewed,
    recordElementClicked,
    recordAppBackgrounded,
    recordAppForegrounded,
    ANALYTICS_DATA_ATTRIBUTE,
} from '@streem/analytics';

export const useRecordScreenViewed = () => {
    const location = useLocation();

    useEffect(() => {
        appLogger.info({ msg: `Page navigation`, path: `${location.pathname}` });
        recordScreenViewed(location.pathname);
    }, [location.pathname]);
};

export const useRecordAppFocus = () => {
    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                recordAppForegrounded();
            } else {
                recordAppBackgrounded();
            }
        });
    }, []);
};

export const useRecordElementClicked = () => {
    useEffect(() => {
        document.addEventListener('click', event => {
            const selector = `[${ANALYTICS_DATA_ATTRIBUTE}]`;
            const el = (<HTMLElement>event.target).closest(selector);
            if (el) {
                recordElementClicked(el.getAttribute(ANALYTICS_DATA_ATTRIBUTE));
            }
        });
    }, []);
};
