/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PickListOption
 */
export interface PickListOption {
    /**
     * 
     * @type {string}
     * @memberof PickListOption
     */
    textId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickListOption
     */
    hideInfoButton?: boolean;
}

export function PickListOptionFromJSON(json: any): PickListOption {
    return PickListOptionFromJSONTyped(json, false);
}

export function PickListOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickListOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textId': !exists(json, 'text_id') ? undefined : json['text_id'],
        'hideInfoButton': !exists(json, 'hide_info_button') ? undefined : json['hide_info_button'],
    };
}

export function PickListOptionToJSON(value?: PickListOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text_id': value.textId,
        'hide_info_button': value.hideInfoButton,
    };
}


