/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

 /**
  *
  * @export
  * @interface GoogleProtobufDuration
  */
 export interface GoogleProtobufDuration {
     /**
      *
      * @type {number}
      * @memberof GoogleProtobufDuration
      */
     seconds?: number;
     /**
      *
      * @type {number}
      * @memberof GoogleProtobufDuration
      */
     nanos?: number;
 }

 const NANOS_PER_MILLISECOND = 1000000;
 const NANOS_PER_MICROSECOND = 1000;

 export function GoogleProtobufDurationFromJSON(json: any): GoogleProtobufDuration {
     return GoogleProtobufDurationFromJSONTyped(json, false);
 }

 export function GoogleProtobufDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleProtobufDuration {
     if (json === undefined || json === null) {
         return json;
     }

     // Duration comes in as format: "1.000340012s"
     let str = json as string;
     if (str === '' || !str.endsWith('s')) {
         throw new Error('Invalid duration: expected received value to end with s');
     }

     const negative = str.startsWith('-');
     const signLength = negative ? 1 : 0;
     const nanosPos = str.indexOf('.', signLength) + 1;

     let seconds = 0;
     let nanos = 0;
     if (nanosPos === 0) {
         seconds = parseInt(str.substring(signLength, str.length - 1));
         nanos = 0;
     } else {
         seconds = parseInt(str.substring(signLength, nanosPos - 1));
         let nanosString = str.substring(nanosPos, str.length - 1);
         if (nanosString.length > 9) {
             console.warn(
                 'Received duration more precise then allowed: trimming to 9 fractional digits',
             );
         }
         nanos = parseInt(nanosString.substr(0, 9).padEnd(9, '0'));
     }

     if (negative) {
         seconds = -seconds;
         nanos = -nanos;
     }

     return {
         seconds,
         nanos,
     };
 }

 export function GoogleProtobufDurationToJSON(value?: GoogleProtobufDuration | null): any {
     if (value === undefined) {
         return undefined;
     }
     if (value === null) {
         return null;
     }

     let { seconds = 0, nanos = 0 } = value;
     let formattedDuration = '';

     if (seconds < 0 || nanos < 0) {
         formattedDuration += '-';
         seconds = -seconds;
         nanos = -nanos;
     }

     formattedDuration += seconds;
     if (nanos !== 0) {
         formattedDuration += '.';
         if (nanos % NANOS_PER_MILLISECOND === 0) {
             formattedDuration += (nanos / NANOS_PER_MILLISECOND).toString().padStart(3, '0');
         } else if (nanos % NANOS_PER_MICROSECOND === 0) {
             formattedDuration += (nanos / NANOS_PER_MICROSECOND).toString().padStart(6, '0');
         } else {
             formattedDuration += nanos.toString().padStart(9, '0');
         }
     }

     formattedDuration += 's';
     return formattedDuration;
 }
