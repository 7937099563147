/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSize,
    StreemApiSizeFromJSON,
    StreemApiSizeFromJSONTyped,
    StreemApiSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEventLocalVideoStatsMeasured
 */
export interface StreemApiAnalyticEventLocalVideoStatsMeasured {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    packetsLost?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    codec?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    bytesSent?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    packetsSent?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    roundTripTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    captureFrameRate?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    frameRate?: number;
    /**
     * 
     * @type {StreemApiSize}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    captureDimensions?: StreemApiSize;
    /**
     * 
     * @type {StreemApiSize}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    dimensions?: StreemApiSize;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    trackSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventLocalVideoStatsMeasured
     */
    roomSid?: string;
}

export function StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSON(json: any): StreemApiAnalyticEventLocalVideoStatsMeasured {
    return StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventLocalVideoStatsMeasured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packetsLost': !exists(json, 'packets_lost') ? undefined : json['packets_lost'],
        'codec': !exists(json, 'codec') ? undefined : json['codec'],
        'bytesSent': !exists(json, 'bytes_sent') ? undefined : json['bytes_sent'],
        'packetsSent': !exists(json, 'packets_sent') ? undefined : json['packets_sent'],
        'roundTripTimeMs': !exists(json, 'round_trip_time_ms') ? undefined : json['round_trip_time_ms'],
        'captureFrameRate': !exists(json, 'capture_frame_rate') ? undefined : json['capture_frame_rate'],
        'frameRate': !exists(json, 'frame_rate') ? undefined : json['frame_rate'],
        'captureDimensions': !exists(json, 'capture_dimensions') ? undefined : StreemApiSizeFromJSON(json['capture_dimensions']),
        'dimensions': !exists(json, 'dimensions') ? undefined : StreemApiSizeFromJSON(json['dimensions']),
        'trackSid': !exists(json, 'track_sid') ? undefined : json['track_sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
    };
}

export function StreemApiAnalyticEventLocalVideoStatsMeasuredToJSON(value?: StreemApiAnalyticEventLocalVideoStatsMeasured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packets_lost': value.packetsLost,
        'codec': value.codec,
        'bytes_sent': value.bytesSent,
        'packets_sent': value.packetsSent,
        'round_trip_time_ms': value.roundTripTimeMs,
        'capture_frame_rate': value.captureFrameRate,
        'frame_rate': value.frameRate,
        'capture_dimensions': StreemApiSizeToJSON(value.captureDimensions),
        'dimensions': StreemApiSizeToJSON(value.dimensions),
        'track_sid': value.trackSid,
        'room_sid': value.roomSid,
    };
}


