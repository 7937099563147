/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventRemoteAudioStatsMeasured
 */
export interface StreemApiAnalyticEventRemoteAudioStatsMeasured {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    packetsLost?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    codec?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    bytesReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    packetsReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    audioLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    jitterMs?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    trackSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventRemoteAudioStatsMeasured
     */
    roomSid?: string;
}

export function StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSON(json: any): StreemApiAnalyticEventRemoteAudioStatsMeasured {
    return StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventRemoteAudioStatsMeasured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packetsLost': !exists(json, 'packets_lost') ? undefined : json['packets_lost'],
        'codec': !exists(json, 'codec') ? undefined : json['codec'],
        'bytesReceived': !exists(json, 'bytes_received') ? undefined : json['bytes_received'],
        'packetsReceived': !exists(json, 'packets_received') ? undefined : json['packets_received'],
        'audioLevel': !exists(json, 'audio_level') ? undefined : json['audio_level'],
        'jitterMs': !exists(json, 'jitter_ms') ? undefined : json['jitter_ms'],
        'trackSid': !exists(json, 'track_sid') ? undefined : json['track_sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
    };
}

export function StreemApiAnalyticEventRemoteAudioStatsMeasuredToJSON(value?: StreemApiAnalyticEventRemoteAudioStatsMeasured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packets_lost': value.packetsLost,
        'codec': value.codec,
        'bytes_received': value.bytesReceived,
        'packets_received': value.packetsReceived,
        'audio_level': value.audioLevel,
        'jitter_ms': value.jitterMs,
        'track_sid': value.trackSid,
        'room_sid': value.roomSid,
    };
}


