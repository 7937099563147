import createLogger from '@streem/logger';
import { getCompanyCodeFromUrl } from '@streem/toolbox';
import { config } from '../config';
import { initializeDatadogRum, addCompanyCodeToDatadogRumSession } from './datadog';

const sessionId = initializeDatadogRum();

const appLogger = createLogger('cv', {
    logToConsole: !config.isProduction || false,
    scribeUrl: config.shouldShipLogs ? config.urls.scribe : undefined,
    sessionId,
});
const companyCode = getCompanyCodeFromUrl();
appLogger.setContextValue('companyCode', companyCode);
addCompanyCodeToDatadogRumSession(companyCode);

appLogger.info('Initialized logger' + (sessionId ? ` (datadog session id: ${sessionId})` : ''));

export default appLogger;
