/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventConnectionStatusChanged
 */
export interface StreemApiAnalyticEventConnectionStatusChanged {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventConnectionStatusChanged
     */
    roomSid?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventConnectionStatusChanged
     */
    newStrength?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventConnectionStatusChanged
     */
    method?: StreemApiAnalyticEventConnectionStatusChangedMethod;
}

export function StreemApiAnalyticEventConnectionStatusChangedFromJSON(json: any): StreemApiAnalyticEventConnectionStatusChanged {
    return StreemApiAnalyticEventConnectionStatusChangedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventConnectionStatusChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventConnectionStatusChanged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'newStrength': !exists(json, 'new_strength') ? undefined : json['new_strength'],
        'method': !exists(json, 'method') ? undefined : json['method'],
    };
}

export function StreemApiAnalyticEventConnectionStatusChangedToJSON(value?: StreemApiAnalyticEventConnectionStatusChanged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'new_strength': value.newStrength,
        'method': value.method,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventConnectionStatusChangedMethod {
    WIFI = 'CONNECTION_METHOD_WIFI',
    CELLULAR = 'CONNECTION_METHOD_CELLULAR',
    UNKNOWN = 'CONNECTION_METHOD_UNKNOWN',
    INVALID = 'CONNECTION_METHOD_INVALID'
}


