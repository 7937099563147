/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAcceptRoomInvitationRequest
 */
export interface StreemApiAcceptRoomInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAcceptRoomInvitationRequest
     */
    acceptedByUserSid?: string;
}

export function StreemApiAcceptRoomInvitationRequestFromJSON(json: any): StreemApiAcceptRoomInvitationRequest {
    return StreemApiAcceptRoomInvitationRequestFromJSONTyped(json, false);
}

export function StreemApiAcceptRoomInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAcceptRoomInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedByUserSid': !exists(json, 'accepted_by_user_sid') ? undefined : json['accepted_by_user_sid'],
    };
}

export function StreemApiAcceptRoomInvitationRequestToJSON(value?: StreemApiAcceptRoomInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted_by_user_sid': value.acceptedByUserSid,
    };
}


