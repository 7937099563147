// Set config.appId to the correct app id on load based on the path
const embedPath = '/embed';
const salesforce = 'salesforce';
const appType = 'appType';

export function getAppId(appId: string, embedAppId: string, embedSalesforceAppId: string): string {
    if (window.location.href.includes(embedPath)) {
        const params = new URLSearchParams(window.location.search);
        if (params.get('source') === salesforce) {
            sessionStorage.setItem(appType, 'embedSalesforce');
            return embedSalesforceAppId;
        }
        sessionStorage.setItem(appType, 'embed');
        return embedAppId;
    }
    sessionStorage.setItem(appType, 'ev2');
    return appId;
}
