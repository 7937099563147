/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCompany,
    StreemApiCompanyFromJSON,
    StreemApiCompanyFromJSONTyped,
    StreemApiCompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetCurrentCompanyResponse
 */
export interface StreemApiGetCurrentCompanyResponse {
    /**
     * 
     * @type {StreemApiCompany}
     * @memberof StreemApiGetCurrentCompanyResponse
     */
    company?: StreemApiCompany;
}

export function StreemApiGetCurrentCompanyResponseFromJSON(json: any): StreemApiGetCurrentCompanyResponse {
    return StreemApiGetCurrentCompanyResponseFromJSONTyped(json, false);
}

export function StreemApiGetCurrentCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetCurrentCompanyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : StreemApiCompanyFromJSON(json['company']),
    };
}

export function StreemApiGetCurrentCompanyResponseToJSON(value?: StreemApiGetCurrentCompanyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': StreemApiCompanyToJSON(value.company),
    };
}


