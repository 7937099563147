/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSsoProviderOidc,
    StreemApiSsoProviderOidcFromJSON,
    StreemApiSsoProviderOidcFromJSONTyped,
    StreemApiSsoProviderOidcToJSON,
    StreemApiSsoProviderSamlV2,
    StreemApiSsoProviderSamlV2FromJSON,
    StreemApiSsoProviderSamlV2FromJSONTyped,
    StreemApiSsoProviderSamlV2ToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiSsoProvider
 */
export interface StreemApiSsoProvider {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProvider
     */
    ssoProviderCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProvider
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProvider
     */
    fusionAuthIdentityProviderId?: string;
    /**
     * 
     * @type {StreemApiSsoProviderOidc}
     * @memberof StreemApiSsoProvider
     */
    oidc?: StreemApiSsoProviderOidc;
    /**
     * 
     * @type {StreemApiSsoProviderSamlV2}
     * @memberof StreemApiSsoProvider
     */
    samlV2?: StreemApiSsoProviderSamlV2;
}

export function StreemApiSsoProviderFromJSON(json: any): StreemApiSsoProvider {
    return StreemApiSsoProviderFromJSONTyped(json, false);
}

export function StreemApiSsoProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSsoProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ssoProviderCode': !exists(json, 'sso_provider_code') ? undefined : json['sso_provider_code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fusionAuthIdentityProviderId': !exists(json, 'fusion_auth_identity_provider_id') ? undefined : json['fusion_auth_identity_provider_id'],
        'oidc': !exists(json, 'oidc') ? undefined : StreemApiSsoProviderOidcFromJSON(json['oidc']),
        'samlV2': !exists(json, 'saml_v2') ? undefined : StreemApiSsoProviderSamlV2FromJSON(json['saml_v2']),
    };
}

export function StreemApiSsoProviderToJSON(value?: StreemApiSsoProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sso_provider_code': value.ssoProviderCode,
        'name': value.name,
        'fusion_auth_identity_provider_id': value.fusionAuthIdentityProviderId,
        'oidc': StreemApiSsoProviderOidcToJSON(value.oidc),
        'saml_v2': StreemApiSsoProviderSamlV2ToJSON(value.samlV2),
    };
}


