/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextStyle
 */
export interface TextStyle {
    /**
     * 
     * @type {number}
     * @memberof TextStyle
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof TextStyle
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof TextStyle
     */
    font?: string;
}

export function TextStyleFromJSON(json: any): TextStyle {
    return TextStyleFromJSONTyped(json, false);
}

export function TextStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextStyle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': !exists(json, 'size') ? undefined : json['size'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'font': !exists(json, 'font') ? undefined : json['font'],
    };
}

export function TextStyleToJSON(value?: TextStyle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'color': value.color,
        'font': value.font,
    };
}


