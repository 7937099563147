/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRunImageDetectorRequestImageSource,
    StreemApiRunImageDetectorRequestImageSourceFromJSON,
    StreemApiRunImageDetectorRequestImageSourceFromJSONTyped,
    StreemApiRunImageDetectorRequestImageSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiRunImageDetectorRequest
 */
export interface StreemApiRunImageDetectorRequest {
    /**
     * 
     * @type {StreemApiRunImageDetectorRequestImageSource}
     * @memberof StreemApiRunImageDetectorRequest
     */
    image?: StreemApiRunImageDetectorRequestImageSource;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRunImageDetectorRequest
     */
    callbackUrl?: string;
}

export function StreemApiRunImageDetectorRequestFromJSON(json: any): StreemApiRunImageDetectorRequest {
    return StreemApiRunImageDetectorRequestFromJSONTyped(json, false);
}

export function StreemApiRunImageDetectorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRunImageDetectorRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : StreemApiRunImageDetectorRequestImageSourceFromJSON(json['image']),
        'callbackUrl': !exists(json, 'callback_url') ? undefined : json['callback_url'],
    };
}

export function StreemApiRunImageDetectorRequestToJSON(value?: StreemApiRunImageDetectorRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': StreemApiRunImageDetectorRequestImageSourceToJSON(value.image),
        'callback_url': value.callbackUrl,
    };
}


