import { keyframes } from '@emotion/react';

const blink = keyframes`
    from {
        opacity: .2;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: .2;
    }
`;

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const scale = keyframes`
    0% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
`;

const scaleToNothing = keyframes`
  0% {
    transform: scale(1, 1);
    opacity: 1;
    filter: brightness(3.0);
  }

  15% {
    transform: scale(1, 1);
    filter: brightness(1.0);
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
`;

const slide = keyframes`
 0% {
     transform: translateY(-100px)
 }
 100% {
     transform: translateY: (0px)
 }
`;

const slideDown = keyframes`
  0% {
      transform: translateY(-300px) scale(0);
  }
  40% {
      transform: translateY(-300px) scale(0.75);
  }
  80% {
      transform: translateY(0px) scale(0.75);
  }
  100% {
      transform: translateY(0px) scale(1);
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0
  }
  to {
      opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
      opacity: 1
  }
  to {
      opacity: 0
  }
`;

const flash = keyframes`
    from {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
    to {
        opacity: 1;
    }
`;

const slideUpElastic = keyframes`
        from {
            transform: translateY(100vh);
        }

        40% {
            transform: translateY(-10px);
        }

        70% {
            transform: translateY(-2.5px);
        }

        90% {
            transform: translateY(-1.5px);
        }

        to {
            transform: translateY(0);
        }
    `;

const slideUp = keyframes`
        from {
            transform: translateY(100vh);
        }

        50% {
            transform: translateY(-10px);
        }

        to {
            transform: translateY(0);
        }
    `;

const slideLeft = keyframes`
        from {
            transform: translateX(50vh);
        }

        80% {
            transform: translateX(-10px);
        }

        to {
            transform: translateX(0);
        }
    `;

const popIn = keyframes`
        from {
            opacity: 0.75;
            transform: scale(0);
        }

        50% {
            opacity: 1;
            transform: scale(1.1);
        }

        to {
            transform: scale(1);
        }
    `;

const growOut = keyframes`
        from {
            opacity: 1;
            transform: scale(1);
        }

        to {
            opacity: 0;
            transform: scale(2);
        }
    `;

const growOutLarge = keyframes`
from {
    opacity: 0.15;
    transform: scale(1);
}

50% {
    opacity: 0.4;
}

to {
    opacity: 0;
    transform: scale(30);
}
`;

const shake = keyframes`
        from {
            transform: translateX(0);
        }

        15% {
            transform: translateX(-3px);
        }

        30% {
            transform: translateX(3px);
        }

        45% {
            transform: translateX(-3px);
        }

        60% {
            transform: translateX(3px);
        }

        75% {
            transform: translateX(-1.5px);
        }

        90% {
            transform: translateX(1.5px);
        }

        to {
            transform: translateX(0);
        }
`;

const flex = keyframes`
        from {
            transform: scale(1);
        }

        25% {
            transform: scale(1.25);
        }

        90% {
            transform: scale(1.25);
        }

        to {
            transform: scale(1);
        }
    `;

const flexSmall = keyframes`
from {
  transform: scale(1);
}

25% {
  transform: scale(1.05);
}

90% {
  transform: scale(1.05);
}

to {
  transform: scale(1);
}
`;

const backgroundHorizontalPulseRTL = keyframes`
    0% {background-position:0% 50%}
    50% {background-position:50% 50%}
    100% {background-position:100% 50%}
`;

const backgroundHorizontalPulseLTR = keyframes`
    0% {background-position:100% 50%}
    50% {background-position:50% 50%}
    100% {background-position:0% 50%}
`;

const backgroundHorizontalPulseCentered = keyframes`
    0% {background-size:100% 100%}
    50% {background-size:600% 100%}
    100% {background-size:100% 100%}
`;

const backgroundHorizontalPulseRing = keyframes`
    0% {background-size:100% 100%}
    10% {background-size:100% 100%}
    30% {background-size:300% 100%}
    40% {background-size:150% 100%}
    45% {background-size:150% 100%}
    50% {background-size:450% 100%}
    55% {background-size:150% 100%}
    60% {background-size:150% 100%}
    70% {background-size:300% 100%}
    90% {background-size:100% 100%}
    100% {background-size:100% 100%}
`;

const backgroundVerticalPulse = keyframes`
    0% {background-position:50% 0%}
    50% {background-position:50% 100%}
    100% {background-position:50% 0%}
`;

export const animationKeyframes = {
    backgroundHorizontalPulseRTL,
    backgroundHorizontalPulseLTR,
    backgroundHorizontalPulseRing,
    backgroundHorizontalPulseCentered,
    backgroundVerticalPulse,
    blink,
    rotate,
    scale,
    slide,
    slideDown,
    slideLeft,
    fadeIn,
    fadeOut,
    flash,
    slideUpElastic,
    slideUp,
    popIn,
    growOut,
    growOutLarge,
    shake,
    scaleToNothing,
    flex,
    flexSmall,
};
