/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiInvitation,
    StreemApiInvitationFromJSON,
    StreemApiInvitationFromJSONTyped,
    StreemApiInvitationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiSendInvitationResponse
 */
export interface StreemApiSendInvitationResponse {
    /**
     * 
     * @type {StreemApiInvitation}
     * @memberof StreemApiSendInvitationResponse
     */
    invitation?: StreemApiInvitation;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSendInvitationResponse
     */
    messageDeliveredSuccessfully?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationResponse
     */
    link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSendInvitationResponse
     */
    landline?: boolean;
}

export function StreemApiSendInvitationResponseFromJSON(json: any): StreemApiSendInvitationResponse {
    return StreemApiSendInvitationResponseFromJSONTyped(json, false);
}

export function StreemApiSendInvitationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSendInvitationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invitation': !exists(json, 'invitation') ? undefined : StreemApiInvitationFromJSON(json['invitation']),
        'messageDeliveredSuccessfully': !exists(json, 'message_delivered_successfully') ? undefined : json['message_delivered_successfully'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'landline': !exists(json, 'landline') ? undefined : json['landline'],
    };
}

export function StreemApiSendInvitationResponseToJSON(value?: StreemApiSendInvitationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invitation': StreemApiInvitationToJSON(value.invitation),
        'message_delivered_successfully': value.messageDeliveredSuccessfully,
        'link': value.link,
        'landline': value.landline,
    };
}


