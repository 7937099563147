import { getIosVersionFromUserAgent } from './get_ios_version_from_user_agent';
/*
 *  This utility function was created as a response to a Twilio-Video defect that was discovered while testing the
 *  public iOS 18 bete release. Details on this defect can be found here: https://streempro.atlassian.net/browse/ST-6327
 *  A issue has been filed with Twilio-Video by the eta for a fix is unknown. This function and its associated code in
 *  the HostRoomSession, CallLobby, HostCallFrame and DeviceTroubleShootingContent components should be removed once
 *  the defect has been resolved by Twilio-Video or Streem has fully migrated to Chime as a video platform.
 */
export function checkIfHostIsUsingTwilioAndIOS18(videoPlatform: string): boolean {
    const isIOSDevice = /iP(hone|od|ad)/.test(navigator.userAgent);

    if (isIOSDevice && videoPlatform === 'VIDEO_PLATFORM_TWILIO') {
        const iOSVersion = getIosVersionFromUserAgent(navigator.userAgent);
        const iOSVersionIsAffected = iOSVersion.major === 18;

        if (iOSVersionIsAffected) {
            return true;
        }
    }

    return false;
}
