/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiGetSsoProviderCompanyMappingResponse,
    StreemApiGetSsoProviderCompanyMappingResponseFromJSON,
    StreemApiGetSsoProviderCompanyMappingResponseToJSON,
    StreemApiGetSsoProviderResponse,
    StreemApiGetSsoProviderResponseFromJSON,
    StreemApiGetSsoProviderResponseToJSON,
    StreemApiListSsoProviderCompanyMappingsResponse,
    StreemApiListSsoProviderCompanyMappingsResponseFromJSON,
    StreemApiListSsoProviderCompanyMappingsResponseToJSON,
    StreemApiListSsoProvidersResponse,
    StreemApiListSsoProvidersResponseFromJSON,
    StreemApiListSsoProvidersResponseToJSON,
    StreemApiSaveSsoProviderRequest,
    StreemApiSaveSsoProviderRequestFromJSON,
    StreemApiSaveSsoProviderRequestToJSON,
    StreemApiSaveSsoProviderResponse,
    StreemApiSaveSsoProviderResponseFromJSON,
    StreemApiSaveSsoProviderResponseToJSON,
    StreemApiUpdateSsoProviderCompanyMappingRequest,
    StreemApiUpdateSsoProviderCompanyMappingRequestFromJSON,
    StreemApiUpdateSsoProviderCompanyMappingRequestToJSON,
    StreemApiUpdateSsoProviderCompanyMappingResponse,
    StreemApiUpdateSsoProviderCompanyMappingResponseFromJSON,
    StreemApiUpdateSsoProviderCompanyMappingResponseToJSON,
} from '../models';

export interface CreateSsoProviderRequest {
    ssoProviderCode: string;
    streemApiSaveSsoProviderRequest?: StreemApiSaveSsoProviderRequest;
}

export interface DeleteSsoProviderRequest {
    ssoProviderCode: string;
}

export interface DeleteSsoProviderCompanyMappingRequest {
    ssoProviderCode: string;
    companyCode: string;
}

export interface GetSsoProviderRequest {
    ssoProviderCode: string;
}

export interface GetSsoProviderCompanyMappingRequest {
    ssoProviderCode: string;
    companyCode: string;
}

export interface ListSsoProviderCompanyMappingsRequest {
    ssoProviderCode: string;
}

export interface ListSsoProvidersRequest {
    pageSize?: number;
    pageToken?: string;
}

export interface UpdateSsoProviderCompanyMappingRequest {
    ssoProviderCode: string;
    companyCode: string;
    streemApiUpdateSsoProviderCompanyMappingRequest?: StreemApiUpdateSsoProviderCompanyMappingRequest;
}

/**
 * 
 */
export class SsoApi extends runtime.BaseAPI {

    /**
     * Create/Update SSO configuration. This will not automatically associate it with a Company, but will make it available to do so.
     * Create/Update SSO Config
     */
    async createSsoProviderRaw(requestParameters: CreateSsoProviderRequest): Promise<runtime.ApiResponse<StreemApiSaveSsoProviderResponse>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling createSsoProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSaveSsoProviderRequestToJSON(requestParameters.streemApiSaveSsoProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveSsoProviderResponseFromJSON(jsonValue));
    }

    /**
     * Create/Update SSO configuration. This will not automatically associate it with a Company, but will make it available to do so.
     * Create/Update SSO Config
     */
    async createSsoProvider(ssoProviderCode: string, streemApiSaveSsoProviderRequest?: StreemApiSaveSsoProviderRequest): Promise<StreemApiSaveSsoProviderResponse> {
        const response = await this.createSsoProviderRaw({ ssoProviderCode: ssoProviderCode, streemApiSaveSsoProviderRequest: streemApiSaveSsoProviderRequest });
        return await response.value();
    }

    /**
     * Delete an SSO Provider, which must have no currently mapped Companies
     * Delete SSO Provider
     */
    async deleteSsoProviderRaw(requestParameters: DeleteSsoProviderRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling deleteSsoProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an SSO Provider, which must have no currently mapped Companies
     * Delete SSO Provider
     */
    async deleteSsoProvider(ssoProviderCode: string): Promise<void> {
        await this.deleteSsoProviderRaw({ ssoProviderCode: ssoProviderCode });
    }

    /**
     * Deletes the SSO Provider Mapping for the given Company
     * Delete SSO Provider Mapping
     */
    async deleteSsoProviderCompanyMappingRaw(requestParameters: DeleteSsoProviderCompanyMappingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling deleteSsoProviderCompanyMapping.');
        }

        if (requestParameters.companyCode === null || requestParameters.companyCode === undefined) {
            throw new runtime.RequiredError('companyCode','Required parameter requestParameters.companyCode was null or undefined when calling deleteSsoProviderCompanyMapping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}/companies/{company_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))).replace(`{${"company_code"}}`, encodeURIComponent(String(requestParameters.companyCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the SSO Provider Mapping for the given Company
     * Delete SSO Provider Mapping
     */
    async deleteSsoProviderCompanyMapping(ssoProviderCode: string, companyCode: string): Promise<void> {
        await this.deleteSsoProviderCompanyMappingRaw({ ssoProviderCode: ssoProviderCode, companyCode: companyCode });
    }

    /**
     * Get a single SSO Provider by code
     * Get SSO Provider
     */
    async getSsoProviderRaw(requestParameters: GetSsoProviderRequest): Promise<runtime.ApiResponse<StreemApiGetSsoProviderResponse>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling getSsoProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetSsoProviderResponseFromJSON(jsonValue));
    }

    /**
     * Get a single SSO Provider by code
     * Get SSO Provider
     */
    async getSsoProvider(ssoProviderCode: string): Promise<StreemApiGetSsoProviderResponse> {
        const response = await this.getSsoProviderRaw({ ssoProviderCode: ssoProviderCode });
        return await response.value();
    }

    /**
     * Gets the mapping from this SSO Provider to Company, if exists
     * Get SSO Provider Mapping
     */
    async getSsoProviderCompanyMappingRaw(requestParameters: GetSsoProviderCompanyMappingRequest): Promise<runtime.ApiResponse<StreemApiGetSsoProviderCompanyMappingResponse>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling getSsoProviderCompanyMapping.');
        }

        if (requestParameters.companyCode === null || requestParameters.companyCode === undefined) {
            throw new runtime.RequiredError('companyCode','Required parameter requestParameters.companyCode was null or undefined when calling getSsoProviderCompanyMapping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}/companies/{company_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))).replace(`{${"company_code"}}`, encodeURIComponent(String(requestParameters.companyCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetSsoProviderCompanyMappingResponseFromJSON(jsonValue));
    }

    /**
     * Gets the mapping from this SSO Provider to Company, if exists
     * Get SSO Provider Mapping
     */
    async getSsoProviderCompanyMapping(ssoProviderCode: string, companyCode: string): Promise<StreemApiGetSsoProviderCompanyMappingResponse> {
        const response = await this.getSsoProviderCompanyMappingRaw({ ssoProviderCode: ssoProviderCode, companyCode: companyCode });
        return await response.value();
    }

    /**
     * List all the Companies that this SSO Provider is currently mapped to
     * List SSO Provider Mappings
     */
    async listSsoProviderCompanyMappingsRaw(requestParameters: ListSsoProviderCompanyMappingsRequest): Promise<runtime.ApiResponse<StreemApiListSsoProviderCompanyMappingsResponse>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling listSsoProviderCompanyMappings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}/companies`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListSsoProviderCompanyMappingsResponseFromJSON(jsonValue));
    }

    /**
     * List all the Companies that this SSO Provider is currently mapped to
     * List SSO Provider Mappings
     */
    async listSsoProviderCompanyMappings(ssoProviderCode: string): Promise<StreemApiListSsoProviderCompanyMappingsResponse> {
        const response = await this.listSsoProviderCompanyMappingsRaw({ ssoProviderCode: ssoProviderCode });
        return await response.value();
    }

    /**
     * Lists all SSO Providers currently available.
     * List SSO Providers
     */
    async listSsoProvidersRaw(requestParameters: ListSsoProvidersRequest): Promise<runtime.ApiResponse<StreemApiListSsoProvidersResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListSsoProvidersResponseFromJSON(jsonValue));
    }

    /**
     * Lists all SSO Providers currently available.
     * List SSO Providers
     */
    async listSsoProviders(pageSize?: number, pageToken?: string): Promise<StreemApiListSsoProvidersResponse> {
        const response = await this.listSsoProvidersRaw({ pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Updates the SSO Provider Mapping for the given Company
     * Update SSO Provider Mapping
     */
    async updateSsoProviderCompanyMappingRaw(requestParameters: UpdateSsoProviderCompanyMappingRequest): Promise<runtime.ApiResponse<StreemApiUpdateSsoProviderCompanyMappingResponse>> {
        if (requestParameters.ssoProviderCode === null || requestParameters.ssoProviderCode === undefined) {
            throw new runtime.RequiredError('ssoProviderCode','Required parameter requestParameters.ssoProviderCode was null or undefined when calling updateSsoProviderCompanyMapping.');
        }

        if (requestParameters.companyCode === null || requestParameters.companyCode === undefined) {
            throw new runtime.RequiredError('companyCode','Required parameter requestParameters.companyCode was null or undefined when calling updateSsoProviderCompanyMapping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sso-providers/{sso_provider_code}/companies/{company_code}`.replace(`{${"sso_provider_code"}}`, encodeURIComponent(String(requestParameters.ssoProviderCode))).replace(`{${"company_code"}}`, encodeURIComponent(String(requestParameters.companyCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateSsoProviderCompanyMappingRequestToJSON(requestParameters.streemApiUpdateSsoProviderCompanyMappingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateSsoProviderCompanyMappingResponseFromJSON(jsonValue));
    }

    /**
     * Updates the SSO Provider Mapping for the given Company
     * Update SSO Provider Mapping
     */
    async updateSsoProviderCompanyMapping(ssoProviderCode: string, companyCode: string, streemApiUpdateSsoProviderCompanyMappingRequest?: StreemApiUpdateSsoProviderCompanyMappingRequest): Promise<StreemApiUpdateSsoProviderCompanyMappingResponse> {
        const response = await this.updateSsoProviderCompanyMappingRaw({ ssoProviderCode: ssoProviderCode, companyCode: companyCode, streemApiUpdateSsoProviderCompanyMappingRequest: streemApiUpdateSsoProviderCompanyMappingRequest });
        return await response.value();
    }

}
