/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListRoomOutcomeReportsResponse
 */
export interface StreemApiListRoomOutcomeReportsResponse {
    /**
     * 
     * @type {Array<StreemApiArtifact>}
     * @memberof StreemApiListRoomOutcomeReportsResponse
     */
    roomOutcomeReportArtifacts?: Array<StreemApiArtifact>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListRoomOutcomeReportsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListRoomOutcomeReportsResponse
     */
    totalSize?: number;
}

export function StreemApiListRoomOutcomeReportsResponseFromJSON(json: any): StreemApiListRoomOutcomeReportsResponse {
    return StreemApiListRoomOutcomeReportsResponseFromJSONTyped(json, false);
}

export function StreemApiListRoomOutcomeReportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListRoomOutcomeReportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomOutcomeReportArtifacts': !exists(json, 'room_outcome_report_artifacts') ? undefined : ((json['room_outcome_report_artifacts'] as Array<any>).map(StreemApiArtifactFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListRoomOutcomeReportsResponseToJSON(value?: StreemApiListRoomOutcomeReportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_outcome_report_artifacts': value.roomOutcomeReportArtifacts === undefined ? undefined : ((value.roomOutcomeReportArtifacts as Array<any>).map(StreemApiArtifactToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


