import { FramerateLimiter } from './framerate_limiter';

/**
 * Use requestVideoFrameCallback if available so the video framerate and animation framerate are synchronized.
 * Otherwise we fallback to a 30fps framerate, which is what our in-call video most often uses.
 * returns a function to stop the animation
 * @param videoFrameCallback The function to call per animation frame
 * @param videoElement The video element to synchronizewith
 */
export function startAnimationLoop(
    videoFrameCallback: (videoElement: HTMLVideoElement) => void,
    videoElement: HTMLVideoElement,
): () => void {
    if (
        'requestVideoFrameCallback' in HTMLVideoElement.prototype &&
        'cancelVideoFrameCallback' in HTMLVideoElement.prototype
    ) {
        const requestCallbackLoop = () => {
            videoFrameCallback(videoElement);
            videoElement.requestVideoFrameCallback!(requestCallbackLoop);
        };
        const callbackId = videoElement.requestVideoFrameCallback!(requestCallbackLoop);
        return () => videoElement.cancelVideoFrameCallback!(callbackId);
    } else {
        const frameScheduler = new FramerateLimiter(() => videoFrameCallback(videoElement), 30);
        frameScheduler.start();
        return () => frameScheduler.stop();
    }
}
