/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogItem,
    CatalogItemFromJSON,
    CatalogItemFromJSONTyped,
    CatalogItemToJSON,
    ElementGroup,
    ElementGroupFromJSON,
    ElementGroupFromJSONTyped,
    ElementGroupToJSON,
    Light,
    LightFromJSON,
    LightFromJSONTyped,
    LightToJSON,
    RoomString,
    RoomStringFromJSON,
    RoomStringFromJSONTyped,
    RoomStringToJSON,
    StaticAnchor,
    StaticAnchorFromJSON,
    StaticAnchorFromJSONTyped,
    StaticAnchorToJSON,
    StreemApiDevice,
    StreemApiDeviceFromJSON,
    StreemApiDeviceFromJSONTyped,
    StreemApiDeviceToJSON,
    StreemApiNote,
    StreemApiNoteFromJSON,
    StreemApiNoteFromJSONTyped,
    StreemApiNoteToJSON,
    StreemApiRoom,
    StreemApiRoomFromJSON,
    StreemApiRoomFromJSONTyped,
    StreemApiRoomToJSON,
    StreemApiRoomInvitation,
    StreemApiRoomInvitationFromJSON,
    StreemApiRoomInvitationFromJSONTyped,
    StreemApiRoomInvitationToJSON,
    Tutorial,
    TutorialFromJSON,
    TutorialFromJSONTyped,
    TutorialToJSON,
    TutorialRequest,
    TutorialRequestFromJSON,
    TutorialRequestFromJSONTyped,
    TutorialRequestToJSON,
    TutorialState,
    TutorialStateFromJSON,
    TutorialStateFromJSONTyped,
    TutorialStateToJSON,
    WallItemARSource,
    WallItemARSourceFromJSON,
    WallItemARSourceFromJSONTyped,
    WallItemARSourceToJSON,
    WallItemArAnchorRequest,
    WallItemArAnchorRequestFromJSON,
    WallItemArAnchorRequestFromJSONTyped,
    WallItemArAnchorRequestToJSON,
    WallItemArAnchorStatus,
    WallItemArAnchorStatusFromJSON,
    WallItemArAnchorStatusFromJSONTyped,
    WallItemArAnchorStatusToJSON,
    WallItemArScanningRequest,
    WallItemArScanningRequestFromJSON,
    WallItemArScanningRequestFromJSONTyped,
    WallItemArScanningRequestToJSON,
    WallItemArScanningStatus,
    WallItemArScanningStatusFromJSON,
    WallItemArScanningStatusFromJSONTyped,
    WallItemArScanningStatusToJSON,
    WallItemArSessionStatus,
    WallItemArSessionStatusFromJSON,
    WallItemArSessionStatusFromJSONTyped,
    WallItemArSessionStatusToJSON,
    WallItemArTrackableStatus,
    WallItemArTrackableStatusFromJSON,
    WallItemArTrackableStatusFromJSONTyped,
    WallItemArTrackableStatusToJSON,
    WallItemArrowTool,
    WallItemArrowToolFromJSON,
    WallItemArrowToolFromJSONTyped,
    WallItemArrowToolToJSON,
    WallItemArtifact,
    WallItemArtifactFromJSON,
    WallItemArtifactFromJSONTyped,
    WallItemArtifactToJSON,
    WallItemArtifactCompletionRequest,
    WallItemArtifactCompletionRequestFromJSON,
    WallItemArtifactCompletionRequestFromJSONTyped,
    WallItemArtifactCompletionRequestToJSON,
    WallItemArtifactRequest,
    WallItemArtifactRequestFromJSON,
    WallItemArtifactRequestFromJSONTyped,
    WallItemArtifactRequestToJSON,
    WallItemAudioTrack,
    WallItemAudioTrackFromJSON,
    WallItemAudioTrackFromJSONTyped,
    WallItemAudioTrackToJSON,
    WallItemCameraSubjectRequest,
    WallItemCameraSubjectRequestFromJSON,
    WallItemCameraSubjectRequestFromJSONTyped,
    WallItemCameraSubjectRequestToJSON,
    WallItemCameraSubjectResponse,
    WallItemCameraSubjectResponseFromJSON,
    WallItemCameraSubjectResponseFromJSONTyped,
    WallItemCameraSubjectResponseToJSON,
    WallItemCapabilities,
    WallItemCapabilitiesFromJSON,
    WallItemCapabilitiesFromJSONTyped,
    WallItemCapabilitiesToJSON,
    WallItemDeviceState,
    WallItemDeviceStateFromJSON,
    WallItemDeviceStateFromJSONTyped,
    WallItemDeviceStateToJSON,
    WallItemDrawTool,
    WallItemDrawToolFromJSON,
    WallItemDrawToolFromJSONTyped,
    WallItemDrawToolToJSON,
    WallItemHeartbeat,
    WallItemHeartbeatFromJSON,
    WallItemHeartbeatFromJSONTyped,
    WallItemHeartbeatToJSON,
    WallItemLaserTool,
    WallItemLaserToolFromJSON,
    WallItemLaserToolFromJSONTyped,
    WallItemLaserToolToJSON,
    WallItemMeasureTool,
    WallItemMeasureToolFromJSON,
    WallItemMeasureToolFromJSONTyped,
    WallItemMeasureToolToJSON,
    WallItemModel3dTool,
    WallItemModel3dToolFromJSON,
    WallItemModel3dToolFromJSONTyped,
    WallItemModel3dToolToJSON,
    WallItemStaticAnchorStatus,
    WallItemStaticAnchorStatusFromJSON,
    WallItemStaticAnchorStatusFromJSONTyped,
    WallItemStaticAnchorStatusToJSON,
    WallItemStreemshot,
    WallItemStreemshotFromJSON,
    WallItemStreemshotFromJSONTyped,
    WallItemStreemshotToJSON,
    WallItemStreemshotDepthMap,
    WallItemStreemshotDepthMapFromJSON,
    WallItemStreemshotDepthMapFromJSONTyped,
    WallItemStreemshotDepthMapToJSON,
    WallItemStreemshotDetections,
    WallItemStreemshotDetectionsFromJSON,
    WallItemStreemshotDetectionsFromJSONTyped,
    WallItemStreemshotDetectionsToJSON,
    WallItemStreemshotRequest,
    WallItemStreemshotRequestFromJSON,
    WallItemStreemshotRequestFromJSONTyped,
    WallItemStreemshotRequestToJSON,
    WallItemStreemshotRevision,
    WallItemStreemshotRevisionFromJSON,
    WallItemStreemshotRevisionFromJSONTyped,
    WallItemStreemshotRevisionToJSON,
    WallItemUser,
    WallItemUserFromJSON,
    WallItemUserFromJSONTyped,
    WallItemUserToJSON,
    WallItemUserDetectionFeedback,
    WallItemUserDetectionFeedbackFromJSON,
    WallItemUserDetectionFeedbackFromJSONTyped,
    WallItemUserDetectionFeedbackToJSON,
    WallItemUserFavorite,
    WallItemUserFavoriteFromJSON,
    WallItemUserFavoriteFromJSONTyped,
    WallItemUserFavoriteToJSON,
    WallItemVideoTrack,
    WallItemVideoTrackFromJSON,
    WallItemVideoTrackFromJSONTyped,
    WallItemVideoTrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItem
 */
export interface WallItem {
    /**
     * 
     * @type {string}
     * @memberof WallItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItem
     */
    ownerUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItem
     */
    ownerDeviceId?: string;
    /**
     * 
     * @type {Date}
     * @memberof WallItem
     */
    clientCreatedAt?: Date;
    /**
     * 
     * @type {WallItemUser}
     * @memberof WallItem
     */
    user?: WallItemUser;
    /**
     * 
     * @type {WallItemHeartbeat}
     * @memberof WallItem
     */
    heartbeat?: WallItemHeartbeat;
    /**
     * 
     * @type {RoomString}
     * @memberof WallItem
     */
    roomString?: RoomString;
    /**
     * 
     * @type {StreemApiRoom}
     * @memberof WallItem
     */
    room?: StreemApiRoom;
    /**
     * 
     * @type {StreemApiRoomInvitation}
     * @memberof WallItem
     */
    roomInvitation?: StreemApiRoomInvitation;
    /**
     * 
     * @type {WallItemCapabilities}
     * @memberof WallItem
     */
    capabilities?: WallItemCapabilities;
    /**
     * 
     * @type {WallItemUserFavorite}
     * @memberof WallItem
     */
    userFavorite?: WallItemUserFavorite;
    /**
     * 
     * @type {StreemApiDevice}
     * @memberof WallItem
     */
    device?: StreemApiDevice;
    /**
     * 
     * @type {WallItemDeviceState}
     * @memberof WallItem
     */
    deviceState?: WallItemDeviceState;
    /**
     * 
     * @type {WallItemAudioTrack}
     * @memberof WallItem
     */
    audioTrack?: WallItemAudioTrack;
    /**
     * 
     * @type {WallItemVideoTrack}
     * @memberof WallItem
     */
    videoTrack?: WallItemVideoTrack;
    /**
     * 
     * @type {object}
     * @memberof WallItem
     */
    dataTrack?: object;
    /**
     * 
     * @type {WallItemCameraSubjectRequest}
     * @memberof WallItem
     */
    cameraSubjectRequest?: WallItemCameraSubjectRequest;
    /**
     * 
     * @type {WallItemCameraSubjectResponse}
     * @memberof WallItem
     */
    cameraSubjectResponse?: WallItemCameraSubjectResponse;
    /**
     * 
     * @type {CatalogItem}
     * @memberof WallItem
     */
    catalogItem?: CatalogItem;
    /**
     * 
     * @type {ElementGroup}
     * @memberof WallItem
     */
    elementGroup?: ElementGroup;
    /**
     * 
     * @type {StaticAnchor}
     * @memberof WallItem
     */
    staticAnchor?: StaticAnchor;
    /**
     * 
     * @type {WallItemStaticAnchorStatus}
     * @memberof WallItem
     */
    staticAnchorStatus?: WallItemStaticAnchorStatus;
    /**
     * 
     * @type {Light}
     * @memberof WallItem
     */
    light?: Light;
    /**
     * 
     * @type {WallItemLaserTool}
     * @memberof WallItem
     */
    laserTool?: WallItemLaserTool;
    /**
     * 
     * @type {WallItemArrowTool}
     * @memberof WallItem
     */
    arrowTool?: WallItemArrowTool;
    /**
     * 
     * @type {WallItemMeasureTool}
     * @memberof WallItem
     */
    measureTool?: WallItemMeasureTool;
    /**
     * 
     * @type {WallItemModel3dTool}
     * @memberof WallItem
     */
    model3dTool?: WallItemModel3dTool;
    /**
     * 
     * @type {WallItemDrawTool}
     * @memberof WallItem
     */
    drawTool?: WallItemDrawTool;
    /**
     * 
     * @type {WallItemStreemshotRequest}
     * @memberof WallItem
     */
    streemshotRequest?: WallItemStreemshotRequest;
    /**
     * 
     * @type {WallItemStreemshot}
     * @memberof WallItem
     */
    streemshot?: WallItemStreemshot;
    /**
     * 
     * @type {WallItemStreemshotRevision}
     * @memberof WallItem
     */
    streemshotRevision?: WallItemStreemshotRevision;
    /**
     * 
     * @type {WallItemStreemshotDetections}
     * @memberof WallItem
     */
    streemshotDetections?: WallItemStreemshotDetections;
    /**
     * 
     * @type {WallItemUserDetectionFeedback}
     * @memberof WallItem
     */
    userDetectionFeedback?: WallItemUserDetectionFeedback;
    /**
     * 
     * @type {StreemApiNote}
     * @memberof WallItem
     */
    note?: StreemApiNote;
    /**
     * 
     * @type {WallItemStreemshotDepthMap}
     * @memberof WallItem
     */
    streemshotDepthMap?: WallItemStreemshotDepthMap;
    /**
     * 
     * @type {WallItemARSource}
     * @memberof WallItem
     */
    arSource?: WallItemARSource;
    /**
     * 
     * @type {WallItemArSessionStatus}
     * @memberof WallItem
     */
    arSessionStatus?: WallItemArSessionStatus;
    /**
     * 
     * @type {WallItemArScanningRequest}
     * @memberof WallItem
     */
    arScanningRequest?: WallItemArScanningRequest;
    /**
     * 
     * @type {WallItemArScanningStatus}
     * @memberof WallItem
     */
    arScanningStatus?: WallItemArScanningStatus;
    /**
     * 
     * @type {WallItemArAnchorRequest}
     * @memberof WallItem
     */
    arAnchorRequest?: WallItemArAnchorRequest;
    /**
     * 
     * @type {WallItemArAnchorStatus}
     * @memberof WallItem
     */
    arAnchorStatus?: WallItemArAnchorStatus;
    /**
     * 
     * @type {WallItemArTrackableStatus}
     * @memberof WallItem
     */
    arTrackableStatus?: WallItemArTrackableStatus;
    /**
     * 
     * @type {Tutorial}
     * @memberof WallItem
     */
    tutorial?: Tutorial;
    /**
     * 
     * @type {TutorialState}
     * @memberof WallItem
     */
    tutorialState?: TutorialState;
    /**
     * 
     * @type {TutorialRequest}
     * @memberof WallItem
     */
    tutorialRequest?: TutorialRequest;
    /**
     * 
     * @type {WallItemArtifactRequest}
     * @memberof WallItem
     */
    artifactRequest?: WallItemArtifactRequest;
    /**
     * 
     * @type {WallItemArtifact}
     * @memberof WallItem
     */
    artifact?: WallItemArtifact;
    /**
     * 
     * @type {WallItemArtifactCompletionRequest}
     * @memberof WallItem
     */
    artifactCompletionRequest?: WallItemArtifactCompletionRequest;
}

export function WallItemFromJSON(json: any): WallItem {
    return WallItemFromJSONTyped(json, false);
}

export function WallItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ownerUserId': !exists(json, 'owner_user_id') ? undefined : json['owner_user_id'],
        'ownerDeviceId': !exists(json, 'owner_device_id') ? undefined : json['owner_device_id'],
        'clientCreatedAt': !exists(json, 'client_created_at') ? undefined : (new Date(json['client_created_at'])),
        'user': !exists(json, 'user') ? undefined : WallItemUserFromJSON(json['user']),
        'heartbeat': !exists(json, 'heartbeat') ? undefined : WallItemHeartbeatFromJSON(json['heartbeat']),
        'roomString': !exists(json, 'room_string') ? undefined : RoomStringFromJSON(json['room_string']),
        'room': !exists(json, 'room') ? undefined : StreemApiRoomFromJSON(json['room']),
        'roomInvitation': !exists(json, 'room_invitation') ? undefined : StreemApiRoomInvitationFromJSON(json['room_invitation']),
        'capabilities': !exists(json, 'capabilities') ? undefined : WallItemCapabilitiesFromJSON(json['capabilities']),
        'userFavorite': !exists(json, 'user_favorite') ? undefined : WallItemUserFavoriteFromJSON(json['user_favorite']),
        'device': !exists(json, 'device') ? undefined : StreemApiDeviceFromJSON(json['device']),
        'deviceState': !exists(json, 'device_state') ? undefined : WallItemDeviceStateFromJSON(json['device_state']),
        'audioTrack': !exists(json, 'audio_track') ? undefined : WallItemAudioTrackFromJSON(json['audio_track']),
        'videoTrack': !exists(json, 'video_track') ? undefined : WallItemVideoTrackFromJSON(json['video_track']),
        'dataTrack': !exists(json, 'data_track') ? undefined : json['data_track'],
        'cameraSubjectRequest': !exists(json, 'camera_subject_request') ? undefined : WallItemCameraSubjectRequestFromJSON(json['camera_subject_request']),
        'cameraSubjectResponse': !exists(json, 'camera_subject_response') ? undefined : WallItemCameraSubjectResponseFromJSON(json['camera_subject_response']),
        'catalogItem': !exists(json, 'catalog_item') ? undefined : CatalogItemFromJSON(json['catalog_item']),
        'elementGroup': !exists(json, 'element_group') ? undefined : ElementGroupFromJSON(json['element_group']),
        'staticAnchor': !exists(json, 'static_anchor') ? undefined : StaticAnchorFromJSON(json['static_anchor']),
        'staticAnchorStatus': !exists(json, 'static_anchor_status') ? undefined : WallItemStaticAnchorStatusFromJSON(json['static_anchor_status']),
        'light': !exists(json, 'light') ? undefined : LightFromJSON(json['light']),
        'laserTool': !exists(json, 'laser_tool') ? undefined : WallItemLaserToolFromJSON(json['laser_tool']),
        'arrowTool': !exists(json, 'arrow_tool') ? undefined : WallItemArrowToolFromJSON(json['arrow_tool']),
        'measureTool': !exists(json, 'measure_tool') ? undefined : WallItemMeasureToolFromJSON(json['measure_tool']),
        'model3dTool': !exists(json, 'model3d_tool') ? undefined : WallItemModel3dToolFromJSON(json['model3d_tool']),
        'drawTool': !exists(json, 'draw_tool') ? undefined : WallItemDrawToolFromJSON(json['draw_tool']),
        'streemshotRequest': !exists(json, 'streemshot_request') ? undefined : WallItemStreemshotRequestFromJSON(json['streemshot_request']),
        'streemshot': !exists(json, 'streemshot') ? undefined : WallItemStreemshotFromJSON(json['streemshot']),
        'streemshotRevision': !exists(json, 'streemshot_revision') ? undefined : WallItemStreemshotRevisionFromJSON(json['streemshot_revision']),
        'streemshotDetections': !exists(json, 'streemshot_detections') ? undefined : WallItemStreemshotDetectionsFromJSON(json['streemshot_detections']),
        'userDetectionFeedback': !exists(json, 'user_detection_feedback') ? undefined : WallItemUserDetectionFeedbackFromJSON(json['user_detection_feedback']),
        'note': !exists(json, 'note') ? undefined : StreemApiNoteFromJSON(json['note']),
        'streemshotDepthMap': !exists(json, 'streemshot_depth_map') ? undefined : WallItemStreemshotDepthMapFromJSON(json['streemshot_depth_map']),
        'arSource': !exists(json, 'ar_source') ? undefined : WallItemARSourceFromJSON(json['ar_source']),
        'arSessionStatus': !exists(json, 'ar_session_status') ? undefined : WallItemArSessionStatusFromJSON(json['ar_session_status']),
        'arScanningRequest': !exists(json, 'ar_scanning_request') ? undefined : WallItemArScanningRequestFromJSON(json['ar_scanning_request']),
        'arScanningStatus': !exists(json, 'ar_scanning_status') ? undefined : WallItemArScanningStatusFromJSON(json['ar_scanning_status']),
        'arAnchorRequest': !exists(json, 'ar_anchor_request') ? undefined : WallItemArAnchorRequestFromJSON(json['ar_anchor_request']),
        'arAnchorStatus': !exists(json, 'ar_anchor_status') ? undefined : WallItemArAnchorStatusFromJSON(json['ar_anchor_status']),
        'arTrackableStatus': !exists(json, 'ar_trackable_status') ? undefined : WallItemArTrackableStatusFromJSON(json['ar_trackable_status']),
        'tutorial': !exists(json, 'tutorial') ? undefined : TutorialFromJSON(json['tutorial']),
        'tutorialState': !exists(json, 'tutorial_state') ? undefined : TutorialStateFromJSON(json['tutorial_state']),
        'tutorialRequest': !exists(json, 'tutorial_request') ? undefined : TutorialRequestFromJSON(json['tutorial_request']),
        'artifactRequest': !exists(json, 'artifact_request') ? undefined : WallItemArtifactRequestFromJSON(json['artifact_request']),
        'artifact': !exists(json, 'artifact') ? undefined : WallItemArtifactFromJSON(json['artifact']),
        'artifactCompletionRequest': !exists(json, 'artifact_completion_request') ? undefined : WallItemArtifactCompletionRequestFromJSON(json['artifact_completion_request']),
    };
}

export function WallItemToJSON(value?: WallItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'owner_user_id': value.ownerUserId,
        'owner_device_id': value.ownerDeviceId,
        'client_created_at': value.clientCreatedAt === undefined ? undefined : (value.clientCreatedAt.toISOString()),
        'user': WallItemUserToJSON(value.user),
        'heartbeat': WallItemHeartbeatToJSON(value.heartbeat),
        'room_string': RoomStringToJSON(value.roomString),
        'room': StreemApiRoomToJSON(value.room),
        'room_invitation': StreemApiRoomInvitationToJSON(value.roomInvitation),
        'capabilities': WallItemCapabilitiesToJSON(value.capabilities),
        'user_favorite': WallItemUserFavoriteToJSON(value.userFavorite),
        'device': StreemApiDeviceToJSON(value.device),
        'device_state': WallItemDeviceStateToJSON(value.deviceState),
        'audio_track': WallItemAudioTrackToJSON(value.audioTrack),
        'video_track': WallItemVideoTrackToJSON(value.videoTrack),
        'data_track': value.dataTrack,
        'camera_subject_request': WallItemCameraSubjectRequestToJSON(value.cameraSubjectRequest),
        'camera_subject_response': WallItemCameraSubjectResponseToJSON(value.cameraSubjectResponse),
        'catalog_item': CatalogItemToJSON(value.catalogItem),
        'element_group': ElementGroupToJSON(value.elementGroup),
        'static_anchor': StaticAnchorToJSON(value.staticAnchor),
        'static_anchor_status': WallItemStaticAnchorStatusToJSON(value.staticAnchorStatus),
        'light': LightToJSON(value.light),
        'laser_tool': WallItemLaserToolToJSON(value.laserTool),
        'arrow_tool': WallItemArrowToolToJSON(value.arrowTool),
        'measure_tool': WallItemMeasureToolToJSON(value.measureTool),
        'model3d_tool': WallItemModel3dToolToJSON(value.model3dTool),
        'draw_tool': WallItemDrawToolToJSON(value.drawTool),
        'streemshot_request': WallItemStreemshotRequestToJSON(value.streemshotRequest),
        'streemshot': WallItemStreemshotToJSON(value.streemshot),
        'streemshot_revision': WallItemStreemshotRevisionToJSON(value.streemshotRevision),
        'streemshot_detections': WallItemStreemshotDetectionsToJSON(value.streemshotDetections),
        'user_detection_feedback': WallItemUserDetectionFeedbackToJSON(value.userDetectionFeedback),
        'note': StreemApiNoteToJSON(value.note),
        'streemshot_depth_map': WallItemStreemshotDepthMapToJSON(value.streemshotDepthMap),
        'ar_source': WallItemARSourceToJSON(value.arSource),
        'ar_session_status': WallItemArSessionStatusToJSON(value.arSessionStatus),
        'ar_scanning_request': WallItemArScanningRequestToJSON(value.arScanningRequest),
        'ar_scanning_status': WallItemArScanningStatusToJSON(value.arScanningStatus),
        'ar_anchor_request': WallItemArAnchorRequestToJSON(value.arAnchorRequest),
        'ar_anchor_status': WallItemArAnchorStatusToJSON(value.arAnchorStatus),
        'ar_trackable_status': WallItemArTrackableStatusToJSON(value.arTrackableStatus),
        'tutorial': TutorialToJSON(value.tutorial),
        'tutorial_state': TutorialStateToJSON(value.tutorialState),
        'tutorial_request': TutorialRequestToJSON(value.tutorialRequest),
        'artifact_request': WallItemArtifactRequestToJSON(value.artifactRequest),
        'artifact': WallItemArtifactToJSON(value.artifact),
        'artifact_completion_request': WallItemArtifactCompletionRequestToJSON(value.artifactCompletionRequest),
    };
}


