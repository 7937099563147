/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemUserDetectionFeedbackCreateDetection
 */
export interface WallItemUserDetectionFeedbackCreateDetection {
    /**
     * 
     * @type {string}
     * @memberof WallItemUserDetectionFeedbackCreateDetection
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemUserDetectionFeedbackCreateDetection
     */
    type?: WallItemUserDetectionFeedbackCreateDetectionType;
}

export function WallItemUserDetectionFeedbackCreateDetectionFromJSON(json: any): WallItemUserDetectionFeedbackCreateDetection {
    return WallItemUserDetectionFeedbackCreateDetectionFromJSONTyped(json, false);
}

export function WallItemUserDetectionFeedbackCreateDetectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemUserDetectionFeedbackCreateDetection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function WallItemUserDetectionFeedbackCreateDetectionToJSON(value?: WallItemUserDetectionFeedbackCreateDetection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemUserDetectionFeedbackCreateDetectionType {
    LOGO = 'DETECTION_TYPE_LOGO',
    LABEL = 'DETECTION_TYPE_LABEL',
    TEXT = 'DETECTION_TYPE_TEXT',
    FULLTEXT = 'DETECTION_TYPE_FULL_TEXT',
    SAFESEARCH = 'DETECTION_TYPE_SAFE_SEARCH',
    SERIAL = 'DETECTION_TYPE_SERIAL',
    MODEL = 'DETECTION_TYPE_MODEL',
    OBJECT = 'DETECTION_TYPE_OBJECT',
    INVALID = 'DETECTION_TYPE_INVALID'
}


