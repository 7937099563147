/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufAny,
    GoogleProtobufAnyFromJSON,
    GoogleProtobufAnyFromJSONTyped,
    GoogleProtobufAnyToJSON,
} from './';

/**
 * 
 * @export
 * @interface GoogleApiHttpBody
 */
export interface GoogleApiHttpBody {
    /**
     * 
     * @type {string}
     * @memberof GoogleApiHttpBody
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleApiHttpBody
     */
    data?: string;
    /**
     * 
     * @type {Array<GoogleProtobufAny>}
     * @memberof GoogleApiHttpBody
     */
    extensions?: Array<GoogleProtobufAny>;
}

export function GoogleApiHttpBodyFromJSON(json: any): GoogleApiHttpBody {
    return GoogleApiHttpBodyFromJSONTyped(json, false);
}

export function GoogleApiHttpBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleApiHttpBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'extensions': !exists(json, 'extensions') ? undefined : ((json['extensions'] as Array<any>).map(GoogleProtobufAnyFromJSON)),
    };
}

export function GoogleApiHttpBodyToJSON(value?: GoogleApiHttpBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content_type': value.contentType,
        'data': value.data,
        'extensions': value.extensions === undefined ? undefined : ((value.extensions as Array<any>).map(GoogleProtobufAnyToJSON)),
    };
}


