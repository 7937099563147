import { CompanySettings } from '@streem/sdk-react';

export enum CallStatusTypes {
    DEFAULT = 'DEFAULT',
    GET_STARTED = 'GET_STARTED',
    TS_AND_CS = 'TS_AND_CS',
    CALL_LOBBY = 'CALL_LOBBY',
    IN_CALL = 'IN_CALL',
}

export type ErrorType = 'AppError' | 'InviteCodeNotFoundError';

export type CompanyPublicSettings = Omit<
    CompanySettings,
    'expertAvailabilityEnabled' | 'chimeNetworkAdaptationEnabled' | 'chimeLowBandwidthOptimizationEnabled'
>;
