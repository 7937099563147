export class APIError extends Error {
    readonly response: Response;

    constructor(message: string, response: Response) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);

        this.response = response;
        this.name = 'APIError';
    }

    public get status(): number {
        return this.response.status;
    }
}
