/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateAppRequest
 */
export interface StreemApiCreateAppRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateAppRequest
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateAppRequest
     */
    platform?: StreemApiCreateAppRequestPlatform;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateAppRequest
     */
    platformAppId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCreateAppRequest
     */
    allowMultiCompanyLogin?: boolean;
}

export function StreemApiCreateAppRequestFromJSON(json: any): StreemApiCreateAppRequest {
    return StreemApiCreateAppRequestFromJSONTyped(json, false);
}

export function StreemApiCreateAppRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateAppRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'platformAppId': !exists(json, 'platform_app_id') ? undefined : json['platform_app_id'],
        'allowMultiCompanyLogin': !exists(json, 'allow_multi_company_login') ? undefined : json['allow_multi_company_login'],
    };
}

export function StreemApiCreateAppRequestToJSON(value?: StreemApiCreateAppRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_sid': value.companySid,
        'platform': value.platform,
        'platform_app_id': value.platformAppId,
        'allow_multi_company_login': value.allowMultiCompanyLogin,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiCreateAppRequestPlatform {
    IOS = 'APP_PLATFORM_IOS',
    ANDROID = 'APP_PLATFORM_ANDROID',
    WEB = 'APP_PLATFORM_WEB',
    INVALID = 'APP_PLATFORM_INVALID'
}


