/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiAcquireEmbedReportsSessionResponse,
    StreemApiAcquireEmbedReportsSessionResponseFromJSON,
    StreemApiAcquireEmbedReportsSessionResponseToJSON,
    StreemApiBatchCreateCompanyRequest,
    StreemApiBatchCreateCompanyRequestFromJSON,
    StreemApiBatchCreateCompanyRequestToJSON,
    StreemApiBatchCreateCompanyResponse,
    StreemApiBatchCreateCompanyResponseFromJSON,
    StreemApiBatchCreateCompanyResponseToJSON,
    StreemApiChangeExternalUserIdRequest,
    StreemApiChangeExternalUserIdRequestFromJSON,
    StreemApiChangeExternalUserIdRequestToJSON,
    StreemApiChangeExternalUserIdResponse,
    StreemApiChangeExternalUserIdResponseFromJSON,
    StreemApiChangeExternalUserIdResponseToJSON,
    StreemApiCreateCompanyApiKeyRequest,
    StreemApiCreateCompanyApiKeyRequestFromJSON,
    StreemApiCreateCompanyApiKeyRequestToJSON,
    StreemApiCreateCompanyApiKeyResponse,
    StreemApiCreateCompanyApiKeyResponseFromJSON,
    StreemApiCreateCompanyApiKeyResponseToJSON,
    StreemApiCreateCompanyAssetRequest,
    StreemApiCreateCompanyAssetRequestFromJSON,
    StreemApiCreateCompanyAssetRequestToJSON,
    StreemApiCreateCompanyAssetResponse,
    StreemApiCreateCompanyAssetResponseFromJSON,
    StreemApiCreateCompanyAssetResponseToJSON,
    StreemApiCreateCompanyCallSourceRequest,
    StreemApiCreateCompanyCallSourceRequestFromJSON,
    StreemApiCreateCompanyCallSourceRequestToJSON,
    StreemApiCreateCompanyCallSourceResponse,
    StreemApiCreateCompanyCallSourceResponseFromJSON,
    StreemApiCreateCompanyCallSourceResponseToJSON,
    StreemApiCreateCompanyRequest,
    StreemApiCreateCompanyRequestFromJSON,
    StreemApiCreateCompanyRequestToJSON,
    StreemApiCreateCompanyResponse,
    StreemApiCreateCompanyResponseFromJSON,
    StreemApiCreateCompanyResponseToJSON,
    StreemApiCreateCompanyRoomOutcomeConfigRequest,
    StreemApiCreateCompanyRoomOutcomeConfigRequestFromJSON,
    StreemApiCreateCompanyRoomOutcomeConfigRequestToJSON,
    StreemApiCreateCompanyUserRequest,
    StreemApiCreateCompanyUserRequestFromJSON,
    StreemApiCreateCompanyUserRequestToJSON,
    StreemApiCreateCompanyUserResponse,
    StreemApiCreateCompanyUserResponseFromJSON,
    StreemApiCreateCompanyUserResponseToJSON,
    StreemApiCreateGroupRequest,
    StreemApiCreateGroupRequestFromJSON,
    StreemApiCreateGroupRequestToJSON,
    StreemApiCreateGroupReservationRequest,
    StreemApiCreateGroupReservationRequestFromJSON,
    StreemApiCreateGroupReservationRequestToJSON,
    StreemApiCreateGroupReservationResponse,
    StreemApiCreateGroupReservationResponseFromJSON,
    StreemApiCreateGroupReservationResponseToJSON,
    StreemApiCreateGroupResponse,
    StreemApiCreateGroupResponseFromJSON,
    StreemApiCreateGroupResponseToJSON,
    StreemApiCreateInvitationRequest,
    StreemApiCreateInvitationRequestFromJSON,
    StreemApiCreateInvitationRequestToJSON,
    StreemApiCreateInvitationResponse,
    StreemApiCreateInvitationResponseFromJSON,
    StreemApiCreateInvitationResponseToJSON,
    StreemApiCreatePiiRequestRequest,
    StreemApiCreatePiiRequestRequestFromJSON,
    StreemApiCreatePiiRequestRequestToJSON,
    StreemApiCreatePiiRequestResponse,
    StreemApiCreatePiiRequestResponseFromJSON,
    StreemApiCreatePiiRequestResponseToJSON,
    StreemApiDeleteCompanyAndAssetsRequest,
    StreemApiDeleteCompanyAndAssetsRequestFromJSON,
    StreemApiDeleteCompanyAndAssetsRequestToJSON,
    StreemApiDeleteCompanySettingResponse,
    StreemApiDeleteCompanySettingResponseFromJSON,
    StreemApiDeleteCompanySettingResponseToJSON,
    StreemApiGetAuditLogsResponse,
    StreemApiGetAuditLogsResponseFromJSON,
    StreemApiGetAuditLogsResponseToJSON,
    StreemApiGetCompanyApiKeyResponse,
    StreemApiGetCompanyApiKeyResponseFromJSON,
    StreemApiGetCompanyApiKeyResponseToJSON,
    StreemApiGetCompanyCallLogResponse,
    StreemApiGetCompanyCallLogResponseFromJSON,
    StreemApiGetCompanyCallLogResponseToJSON,
    StreemApiGetCompanyCallSourceResponse,
    StreemApiGetCompanyCallSourceResponseFromJSON,
    StreemApiGetCompanyCallSourceResponseToJSON,
    StreemApiGetCompanyCallSourcesResponse,
    StreemApiGetCompanyCallSourcesResponseFromJSON,
    StreemApiGetCompanyCallSourcesResponseToJSON,
    StreemApiGetCompanyIdentityProviderResponse,
    StreemApiGetCompanyIdentityProviderResponseFromJSON,
    StreemApiGetCompanyIdentityProviderResponseToJSON,
    StreemApiGetCompanyLicenseResponse,
    StreemApiGetCompanyLicenseResponseFromJSON,
    StreemApiGetCompanyLicenseResponseToJSON,
    StreemApiGetCompanyResponse,
    StreemApiGetCompanyResponseFromJSON,
    StreemApiGetCompanyResponseToJSON,
    StreemApiGetCompanyRoomOutcomeConfigResponse,
    StreemApiGetCompanyRoomOutcomeConfigResponseFromJSON,
    StreemApiGetCompanyRoomOutcomeConfigResponseToJSON,
    StreemApiGetCompanyTagResponse,
    StreemApiGetCompanyTagResponseFromJSON,
    StreemApiGetCompanyTagResponseToJSON,
    StreemApiGetCurrentCompanyResponse,
    StreemApiGetCurrentCompanyResponseFromJSON,
    StreemApiGetCurrentCompanyResponseToJSON,
    StreemApiGetGroupReservationResponse,
    StreemApiGetGroupReservationResponseFromJSON,
    StreemApiGetGroupReservationResponseToJSON,
    StreemApiGetGroupReservationWaitTimeResponse,
    StreemApiGetGroupReservationWaitTimeResponseFromJSON,
    StreemApiGetGroupReservationWaitTimeResponseToJSON,
    StreemApiGetGroupResponse,
    StreemApiGetGroupResponseFromJSON,
    StreemApiGetGroupResponseToJSON,
    StreemApiGetOrCreateUserRequest,
    StreemApiGetOrCreateUserRequestFromJSON,
    StreemApiGetOrCreateUserRequestToJSON,
    StreemApiGetOrCreateUserResponse,
    StreemApiGetOrCreateUserResponseFromJSON,
    StreemApiGetOrCreateUserResponseToJSON,
    StreemApiGetPublicLoginConfigurationResponse,
    StreemApiGetPublicLoginConfigurationResponseFromJSON,
    StreemApiGetPublicLoginConfigurationResponseToJSON,
    StreemApiGetSettingDefinitionRequest,
    StreemApiGetSettingDefinitionRequestFromJSON,
    StreemApiGetSettingDefinitionRequestToJSON,
    StreemApiGetSettingDefinitionResponse,
    StreemApiGetSettingDefinitionResponseFromJSON,
    StreemApiGetSettingDefinitionResponseToJSON,
    StreemApiGetUserByExternalUserIdResponse,
    StreemApiGetUserByExternalUserIdResponseFromJSON,
    StreemApiGetUserByExternalUserIdResponseToJSON,
    StreemApiListCompaniesResponse,
    StreemApiListCompaniesResponseFromJSON,
    StreemApiListCompaniesResponseToJSON,
    StreemApiListCompanyApiKeysResponse,
    StreemApiListCompanyApiKeysResponseFromJSON,
    StreemApiListCompanyApiKeysResponseToJSON,
    StreemApiListCompanyPublicSettingsResponse,
    StreemApiListCompanyPublicSettingsResponseFromJSON,
    StreemApiListCompanyPublicSettingsResponseToJSON,
    StreemApiListCompanySettingsResponse,
    StreemApiListCompanySettingsResponseFromJSON,
    StreemApiListCompanySettingsResponseToJSON,
    StreemApiListCompanyTagsResponse,
    StreemApiListCompanyTagsResponseFromJSON,
    StreemApiListCompanyTagsResponseToJSON,
    StreemApiListCompanyUsersResponse,
    StreemApiListCompanyUsersResponseFromJSON,
    StreemApiListCompanyUsersResponseToJSON,
    StreemApiListGroupAvailabilityRequest,
    StreemApiListGroupAvailabilityRequestFromJSON,
    StreemApiListGroupAvailabilityRequestToJSON,
    StreemApiListGroupUsersResponse,
    StreemApiListGroupUsersResponseFromJSON,
    StreemApiListGroupUsersResponseToJSON,
    StreemApiListGroupsResponse,
    StreemApiListGroupsResponseFromJSON,
    StreemApiListGroupsResponseToJSON,
    StreemApiListUserGroupsResponse,
    StreemApiListUserGroupsResponseFromJSON,
    StreemApiListUserGroupsResponseToJSON,
    StreemApiRefreshEmbedReportsSessionRequest,
    StreemApiRefreshEmbedReportsSessionRequestFromJSON,
    StreemApiRefreshEmbedReportsSessionRequestToJSON,
    StreemApiRefreshEmbedReportsSessionResponse,
    StreemApiRefreshEmbedReportsSessionResponseFromJSON,
    StreemApiRefreshEmbedReportsSessionResponseToJSON,
    StreemApiSaveCompanyLicenseRequest,
    StreemApiSaveCompanyLicenseRequestFromJSON,
    StreemApiSaveCompanyLicenseRequestToJSON,
    StreemApiSaveCompanyLicenseResponse,
    StreemApiSaveCompanyLicenseResponseFromJSON,
    StreemApiSaveCompanyLicenseResponseToJSON,
    StreemApiSaveCompanySettingRequest,
    StreemApiSaveCompanySettingRequestFromJSON,
    StreemApiSaveCompanySettingRequestToJSON,
    StreemApiSaveCompanySettingResponse,
    StreemApiSaveCompanySettingResponseFromJSON,
    StreemApiSaveCompanySettingResponseToJSON,
    StreemApiSaveCompanyTagRequest,
    StreemApiSaveCompanyTagRequestFromJSON,
    StreemApiSaveCompanyTagRequestToJSON,
    StreemApiSaveCompanyTagResponse,
    StreemApiSaveCompanyTagResponseFromJSON,
    StreemApiSaveCompanyTagResponseToJSON,
    StreemApiSearchCompaniesForUserEmailRequest,
    StreemApiSearchCompaniesForUserEmailRequestFromJSON,
    StreemApiSearchCompaniesForUserEmailRequestToJSON,
    StreemApiSearchCompaniesForUserEmailResponse,
    StreemApiSearchCompaniesForUserEmailResponseFromJSON,
    StreemApiSearchCompaniesForUserEmailResponseToJSON,
    StreemApiUpdateCompanyCallSourceRequest,
    StreemApiUpdateCompanyCallSourceRequestFromJSON,
    StreemApiUpdateCompanyCallSourceRequestToJSON,
    StreemApiUpdateCompanyCallSourceResponse,
    StreemApiUpdateCompanyCallSourceResponseFromJSON,
    StreemApiUpdateCompanyCallSourceResponseToJSON,
    StreemApiUpdateCompanyIdentityProviderRequest,
    StreemApiUpdateCompanyIdentityProviderRequestFromJSON,
    StreemApiUpdateCompanyIdentityProviderRequestToJSON,
    StreemApiUpdateCompanyIdentityProviderResponse,
    StreemApiUpdateCompanyIdentityProviderResponseFromJSON,
    StreemApiUpdateCompanyIdentityProviderResponseToJSON,
    StreemApiUpdateCompanyRequest,
    StreemApiUpdateCompanyRequestFromJSON,
    StreemApiUpdateCompanyRequestToJSON,
    StreemApiUpdateCompanyResponse,
    StreemApiUpdateCompanyResponseFromJSON,
    StreemApiUpdateCompanyResponseToJSON,
    StreemApiUploadCompanyCallSourceLogoRequest,
    StreemApiUploadCompanyCallSourceLogoRequestFromJSON,
    StreemApiUploadCompanyCallSourceLogoRequestToJSON,
    StreemApiUploadCompanyCallSourceLogoResponse,
    StreemApiUploadCompanyCallSourceLogoResponseFromJSON,
    StreemApiUploadCompanyCallSourceLogoResponseToJSON,
} from '../models';

export interface AcquireEmbedReportsSessionRequest {
    companyCodeOrSid: string;
    body?: object;
}

export interface AddGroupUserRequest {
    companyCodeOrSid: string;
    groupName: string;
    userId: string;
}

export interface BatchCreateCompanyRequest {
    streemApiBatchCreateCompanyRequest?: StreemApiBatchCreateCompanyRequest;
}

export interface CancelGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface ChangeExternalUserIdRequest {
    companyCodeOrSid: string;
    externalUserId: string;
    streemApiChangeExternalUserIdRequest?: StreemApiChangeExternalUserIdRequest;
}

export interface ConfirmMatchedGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface CreateCallSourceRequest {
    companyCodeOrSid: string;
    streemApiCreateCompanyCallSourceRequest?: StreemApiCreateCompanyCallSourceRequest;
}

export interface CreateCompanyRequest {
    streemApiCreateCompanyRequest?: StreemApiCreateCompanyRequest;
}

export interface CreateCompanyApiKeyRequest {
    companyCodeOrSid: string;
    streemApiCreateCompanyApiKeyRequest?: StreemApiCreateCompanyApiKeyRequest;
}

export interface CreateCompanyAssetRequest {
    companyCodeOrSid: string;
    type: CreateCompanyAssetOperationType;
    streemApiCreateCompanyAssetRequest?: StreemApiCreateCompanyAssetRequest;
}

export interface CreateCompanyRoomOutcomeConfigRequest {
    companyCodeOrSid: string;
    fileName: string;
    streemApiCreateCompanyRoomOutcomeConfigRequest?: StreemApiCreateCompanyRoomOutcomeConfigRequest;
}

export interface CreateCompanyUserRequest {
    companyCodeOrSid: string;
    streemApiCreateCompanyUserRequest?: StreemApiCreateCompanyUserRequest;
}

export interface CreateGroupRequest {
    companyCodeOrSid: string;
    streemApiCreateGroupRequest?: StreemApiCreateGroupRequest;
}

export interface CreateGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    streemApiCreateGroupReservationRequest?: StreemApiCreateGroupReservationRequest;
}

export interface CreateInvitationRequest {
    companyCodeOrSid: string;
    streemApiCreateInvitationRequest?: StreemApiCreateInvitationRequest;
}

export interface CreatePiiRequestRequest {
    companyCodeOrSid: string;
    streemApiCreatePiiRequestRequest?: StreemApiCreatePiiRequestRequest;
}

export interface DeleteCallSourceRequest {
    companyCodeOrSid: string;
    callSourceSid: string;
}

export interface DeleteCompanyAndAssetsRequest {
    companyCodeOrSid: string;
    streemApiDeleteCompanyAndAssetsRequest?: StreemApiDeleteCompanyAndAssetsRequest;
}

export interface DeleteCompanyRoomOutcomeConfigRequest {
    companyCodeOrSid: string;
}

export interface DeleteCompanySettingRequest {
    companyCodeOrSid: string;
    settingName: string;
}

export interface DeleteCompanyTagRequest {
    companyCodeOrSid: string;
    tagName: string;
}

export interface DeleteGroupRequest {
    companyCodeOrSid: string;
    groupName: string;
}

export interface DeleteGroupUserRequest {
    companyCodeOrSid: string;
    groupName: string;
    userId: string;
}

export interface GetAuditLogRequest {
    companyCodeOrSid: string;
    day?: Date;
}

export interface GetCallSourceRequest {
    companyCodeOrSid: string;
    callSourceSid: string;
}

export interface GetCallSourcesRequest {
    companyCodeOrSid: string;
}

export interface GetCompanyRequest {
    companyCodeOrSid: string;
}

export interface GetCompanyApiKeyRequest {
    companyCodeOrSid: string;
    apiKeyId: string;
}

export interface GetCompanyCallLogRequest {
    companyCodeOrSid: string;
    pageSize?: number;
    pageToken?: string;
    filter?: string;
    startTime?: Date;
    endTime?: Date;
    groupNames?: Array<string>;
    callStatuses?: Array<string>;
    hasRoomNotes?: boolean;
    callSourceCodes?: Array<string>;
}

export interface GetCompanyIdentityProviderRequest {
    companyCodeOrSid: string;
}

export interface GetCompanyLicenseRequest {
    companyCodeOrSid: string;
}

export interface GetCompanyRoomOutcomeConfigRequest {
    companyCodeOrSid: string;
}

export interface GetCompanyTagRequest {
    companyCodeOrSid: string;
    tagName: string;
}

export interface GetGroupRequest {
    companyCodeOrSid: string;
    groupName: string;
}

export interface GetGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface GetGroupReservationWaitTimeRequest {
    companyCodeOrSid: string;
    groupName: string;
    priority: number;
}

export interface GetOrCreateUserRequest {
    companyCodeOrSid: string;
    externalUserId: string;
    streemApiGetOrCreateUserRequest?: StreemApiGetOrCreateUserRequest;
}

export interface GetPublicLoginConfigurationRequest {
    companyCodeOrSid: string;
}

export interface GetSettingDefinitionRequest {
    companyCodeOrSid: string;
    streemApiGetSettingDefinitionRequest?: StreemApiGetSettingDefinitionRequest;
}

export interface GetUserByExternalUserIdRequest {
    companyCodeOrSid: string;
    externalUserId: string;
}

export interface ListCompaniesRequest {
    pageSize?: number;
    pageToken?: string;
    sort?: string;
    filter?: string;
    active?: boolean;
    parentCompanySid?: string;
}

export interface ListCompanyApiKeysRequest {
    companyCodeOrSid: string;
    includeExpired?: boolean;
    expires?: ListCompanyApiKeysExpires;
    pageSize?: number;
    pageToken?: string;
}

export interface ListCompanyPublicSettingsRequest {
    companyCodeOrSid: string;
}

export interface ListCompanySettingsRequest {
    companyCodeOrSid: string;
}

export interface ListCompanyTagsRequest {
    companyCodeOrSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListGroupAvailabilityRequest {
    companyCodeOrSid: string;
    groupName: string;
    status?: ListGroupAvailabilityStatus;
    pageSize?: number;
    pageToken?: string;
}

export interface ListGroupUsersRequest {
    companyCodeOrSid: string;
    groupName: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListGroupsRequest {
    companyCodeOrSid: string;
}

export interface ListUserGroupsRequest {
    companyCodeOrSid: string;
    userId: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListUsersForCompanyRequest {
    companyCodeOrSid: string;
    pageSize?: number;
    pageToken?: string;
    active?: boolean;
    deleted?: boolean;
    filter?: string;
    sort?: string;
    userTypes?: Array<ListUsersForCompanyUserTypes>;
    includeAvailabilityStatus?: boolean;
}

export interface RefreshEmbedReportsSessionRequest {
    companyCodeOrSid: string;
    streemApiRefreshEmbedReportsSessionRequest?: StreemApiRefreshEmbedReportsSessionRequest;
}

export interface RevokeCompanyApiKeyRequest {
    companyCodeOrSid: string;
    apiKeyId: string;
}

export interface SaveCompanyLicenseRequest {
    companyCodeOrSid: string;
    streemApiSaveCompanyLicenseRequest?: StreemApiSaveCompanyLicenseRequest;
}

export interface SaveCompanySettingRequest {
    companyCodeOrSid: string;
    settingName: string;
    streemApiSaveCompanySettingRequest?: StreemApiSaveCompanySettingRequest;
}

export interface SaveCompanyTagRequest {
    companyCodeOrSid: string;
    tagName: string;
    streemApiSaveCompanyTagRequest?: StreemApiSaveCompanyTagRequest;
}

export interface SearchCompaniesForUserEmailRequest {
    streemApiSearchCompaniesForUserEmailRequest?: StreemApiSearchCompaniesForUserEmailRequest;
}

export interface UpdateCompanyRequest {
    companyCodeOrSid: string;
    streemApiUpdateCompanyRequest?: StreemApiUpdateCompanyRequest;
}

export interface UpdateCompanyCallSourceRequest {
    companyCodeOrSid: string;
    callSourceSid: string;
    streemApiUpdateCompanyCallSourceRequest?: StreemApiUpdateCompanyCallSourceRequest;
}

export interface UpdateCompanyIdentityProviderRequest {
    companyCodeOrSid: string;
    streemApiUpdateCompanyIdentityProviderRequest?: StreemApiUpdateCompanyIdentityProviderRequest;
}

export interface UploadCompanyCallSourceLogoRequest {
    companyCodeOrSid: string;
    callSourceSid: string;
    type: UploadCompanyCallSourceLogoType;
    streemApiUploadCompanyCallSourceLogoRequest?: StreemApiUploadCompanyCallSourceLogoRequest;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async acquireEmbedReportsSessionRaw(requestParameters: AcquireEmbedReportsSessionRequest): Promise<runtime.ApiResponse<StreemApiAcquireEmbedReportsSessionResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling acquireEmbedReportsSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/reports/~acquire-embed-session`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiAcquireEmbedReportsSessionResponseFromJSON(jsonValue));
    }

    /**
     */
    async acquireEmbedReportsSession(companyCodeOrSid: string, body?: object): Promise<StreemApiAcquireEmbedReportsSessionResponse> {
        const response = await this.acquireEmbedReportsSessionRaw({ companyCodeOrSid: companyCodeOrSid, body: body });
        return await response.value();
    }

    /**
     * Add a user to a group for a specific company.
     * Add user to group for company
     */
    async addGroupUserRaw(requestParameters: AddGroupUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling addGroupUser.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling addGroupUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addGroupUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a user to a group for a specific company.
     * Add user to group for company
     */
    async addGroupUser(companyCodeOrSid: string, groupName: string, userId: string): Promise<void> {
        await this.addGroupUserRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, userId: userId });
    }

    /**
     * Create batch of companies. This endpoint fails forward even if some entries fail.See the single Create Company endpoint for details about required fields.
     * Batch create company
     */
    async batchCreateCompanyRaw(requestParameters: BatchCreateCompanyRequest): Promise<runtime.ApiResponse<StreemApiBatchCreateCompanyResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/~batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiBatchCreateCompanyRequestToJSON(requestParameters.streemApiBatchCreateCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiBatchCreateCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Create batch of companies. This endpoint fails forward even if some entries fail.See the single Create Company endpoint for details about required fields.
     * Batch create company
     */
    async batchCreateCompany(streemApiBatchCreateCompanyRequest?: StreemApiBatchCreateCompanyRequest): Promise<StreemApiBatchCreateCompanyResponse> {
        const response = await this.batchCreateCompanyRaw({ streemApiBatchCreateCompanyRequest: streemApiBatchCreateCompanyRequest });
        return await response.value();
    }

    /**
     * Cancel a group reservation for a specific company and group
     * Cancel group reservation
     */
    async cancelGroupReservationRaw(requestParameters: CancelGroupReservationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling cancelGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling cancelGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling cancelGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}/cancel`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a group reservation for a specific company and group
     * Cancel group reservation
     */
    async cancelGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<void> {
        await this.cancelGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
    }

    /**
     * Change the `external_user_id`, which should be unique in the scope of the specified Company
     * Change User ID
     */
    async changeExternalUserIdRaw(requestParameters: ChangeExternalUserIdRequest): Promise<runtime.ApiResponse<StreemApiChangeExternalUserIdResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling changeExternalUserId.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling changeExternalUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users/{external_user_id}/~change-user-id`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"external_user_id"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiChangeExternalUserIdRequestToJSON(requestParameters.streemApiChangeExternalUserIdRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiChangeExternalUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Change the `external_user_id`, which should be unique in the scope of the specified Company
     * Change User ID
     */
    async changeExternalUserId(companyCodeOrSid: string, externalUserId: string, streemApiChangeExternalUserIdRequest?: StreemApiChangeExternalUserIdRequest): Promise<StreemApiChangeExternalUserIdResponse> {
        const response = await this.changeExternalUserIdRaw({ companyCodeOrSid: companyCodeOrSid, externalUserId: externalUserId, streemApiChangeExternalUserIdRequest: streemApiChangeExternalUserIdRequest });
        return await response.value();
    }

    /**
     * Confirm a group reservation for a specific company and group
     * Confirm group reservation
     */
    async confirmMatchedGroupReservationRaw(requestParameters: ConfirmMatchedGroupReservationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling confirmMatchedGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling confirmMatchedGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling confirmMatchedGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}/confirm`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm a group reservation for a specific company and group
     * Confirm group reservation
     */
    async confirmMatchedGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<void> {
        await this.confirmMatchedGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
    }

    /**
     */
    async createCallSourceRaw(requestParameters: CreateCallSourceRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyCallSourceResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCallSource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyCallSourceRequestToJSON(requestParameters.streemApiCreateCompanyCallSourceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyCallSourceResponseFromJSON(jsonValue));
    }

    /**
     */
    async createCallSource(companyCodeOrSid: string, streemApiCreateCompanyCallSourceRequest?: StreemApiCreateCompanyCallSourceRequest): Promise<StreemApiCreateCompanyCallSourceResponse> {
        const response = await this.createCallSourceRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateCompanyCallSourceRequest: streemApiCreateCompanyCallSourceRequest });
        return await response.value();
    }

    /**
     * Create a new Company.  Provide `parent_company_sid` to create a Sub-Company.  The number of Sub-Companies allowed is limited to 10 initially, but you can request to have this limit increased.  Sub-Companies cannot themselves have Sub-Companies.
     * Create Company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyRequestToJSON(requestParameters.streemApiCreateCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Company.  Provide `parent_company_sid` to create a Sub-Company.  The number of Sub-Companies allowed is limited to 10 initially, but you can request to have this limit increased.  Sub-Companies cannot themselves have Sub-Companies.
     * Create Company
     */
    async createCompany(streemApiCreateCompanyRequest?: StreemApiCreateCompanyRequest): Promise<StreemApiCreateCompanyResponse> {
        const response = await this.createCompanyRaw({ streemApiCreateCompanyRequest: streemApiCreateCompanyRequest });
        return await response.value();
    }

    /**
     * Create a new API Key for the specified Company
     * Create API Key
     */
    async createCompanyApiKeyRaw(requestParameters: CreateCompanyApiKeyRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyApiKeyResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCompanyApiKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/api-keys`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyApiKeyRequestToJSON(requestParameters.streemApiCreateCompanyApiKeyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyApiKeyResponseFromJSON(jsonValue));
    }

    /**
     * Create a new API Key for the specified Company
     * Create API Key
     */
    async createCompanyApiKey(companyCodeOrSid: string, streemApiCreateCompanyApiKeyRequest?: StreemApiCreateCompanyApiKeyRequest): Promise<StreemApiCreateCompanyApiKeyResponse> {
        const response = await this.createCompanyApiKeyRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateCompanyApiKeyRequest: streemApiCreateCompanyApiKeyRequest });
        return await response.value();
    }

    /**
     * Upload a Company asset
     * Upload Asset
     */
    async createCompanyAssetRaw(requestParameters: CreateCompanyAssetRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyAssetResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCompanyAsset.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createCompanyAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/assets/{type}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyAssetRequestToJSON(requestParameters.streemApiCreateCompanyAssetRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyAssetResponseFromJSON(jsonValue));
    }

    /**
     * Upload a Company asset
     * Upload Asset
     */
    async createCompanyAsset(companyCodeOrSid: string, type: CreateCompanyAssetOperationType, streemApiCreateCompanyAssetRequest?: StreemApiCreateCompanyAssetRequest): Promise<StreemApiCreateCompanyAssetResponse> {
        const response = await this.createCompanyAssetRaw({ companyCodeOrSid: companyCodeOrSid, type: type, streemApiCreateCompanyAssetRequest: streemApiCreateCompanyAssetRequest });
        return await response.value();
    }

    /**
     * Create the configuration for post call outcomes dialog
     * Create a configuration for Room Outcomes
     */
    async createCompanyRoomOutcomeConfigRaw(requestParameters: CreateCompanyRoomOutcomeConfigRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCompanyRoomOutcomeConfig.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling createCompanyRoomOutcomeConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/room-outcome-config/{fileName}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"fileName"}}`, encodeURIComponent(String(requestParameters.fileName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyRoomOutcomeConfigRequestToJSON(requestParameters.streemApiCreateCompanyRoomOutcomeConfigRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create the configuration for post call outcomes dialog
     * Create a configuration for Room Outcomes
     */
    async createCompanyRoomOutcomeConfig(companyCodeOrSid: string, fileName: string, streemApiCreateCompanyRoomOutcomeConfigRequest?: StreemApiCreateCompanyRoomOutcomeConfigRequest): Promise<void> {
        await this.createCompanyRoomOutcomeConfigRaw({ companyCodeOrSid: companyCodeOrSid, fileName: fileName, streemApiCreateCompanyRoomOutcomeConfigRequest: streemApiCreateCompanyRoomOutcomeConfigRequest });
    }

    /**
     * Create a user for log-in for this Company
     * Create a user
     */
    async createCompanyUserRaw(requestParameters: CreateCompanyUserRequest): Promise<runtime.ApiResponse<StreemApiCreateCompanyUserResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createCompanyUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCompanyUserRequestToJSON(requestParameters.streemApiCreateCompanyUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateCompanyUserResponseFromJSON(jsonValue));
    }

    /**
     * Create a user for log-in for this Company
     * Create a user
     */
    async createCompanyUser(companyCodeOrSid: string, streemApiCreateCompanyUserRequest?: StreemApiCreateCompanyUserRequest): Promise<StreemApiCreateCompanyUserResponse> {
        const response = await this.createCompanyUserRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateCompanyUserRequest: streemApiCreateCompanyUserRequest });
        return await response.value();
    }

    /**
     * Create a group for a specific company
     * Create group for company
     */
    async createGroupRaw(requestParameters: CreateGroupRequest): Promise<runtime.ApiResponse<StreemApiCreateGroupResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateGroupRequestToJSON(requestParameters.streemApiCreateGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateGroupResponseFromJSON(jsonValue));
    }

    /**
     * Create a group for a specific company
     * Create group for company
     */
    async createGroup(companyCodeOrSid: string, streemApiCreateGroupRequest?: StreemApiCreateGroupRequest): Promise<StreemApiCreateGroupResponse> {
        const response = await this.createGroupRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateGroupRequest: streemApiCreateGroupRequest });
        return await response.value();
    }

    /**
     * Create a group reservation for a specific company and group
     * Create group reservation for company
     */
    async createGroupReservationRaw(requestParameters: CreateGroupReservationRequest): Promise<runtime.ApiResponse<StreemApiCreateGroupReservationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling createGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateGroupReservationRequestToJSON(requestParameters.streemApiCreateGroupReservationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateGroupReservationResponseFromJSON(jsonValue));
    }

    /**
     * Create a group reservation for a specific company and group
     * Create group reservation for company
     */
    async createGroupReservation(companyCodeOrSid: string, groupName: string, streemApiCreateGroupReservationRequest?: StreemApiCreateGroupReservationRequest): Promise<StreemApiCreateGroupReservationResponse> {
        const response = await this.createGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, streemApiCreateGroupReservationRequest: streemApiCreateGroupReservationRequest });
        return await response.value();
    }

    /**
     * Create an Invitation from/to the provided Users
     * Create Invitation
     */
    async createInvitationRaw(requestParameters: CreateInvitationRequest): Promise<runtime.ApiResponse<StreemApiCreateInvitationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/invitations`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateInvitationRequestToJSON(requestParameters.streemApiCreateInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Create an Invitation from/to the provided Users
     * Create Invitation
     */
    async createInvitation(companyCodeOrSid: string, streemApiCreateInvitationRequest?: StreemApiCreateInvitationRequest): Promise<StreemApiCreateInvitationResponse> {
        const response = await this.createInvitationRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateInvitationRequest: streemApiCreateInvitationRequest });
        return await response.value();
    }

    /**
     * Requests all Personally Identifiable Information (PII) associated with a user
     * Create user PII request
     */
    async createPiiRequestRaw(requestParameters: CreatePiiRequestRequest): Promise<runtime.ApiResponse<StreemApiCreatePiiRequestResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createPiiRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/pii-requests`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreatePiiRequestRequestToJSON(requestParameters.streemApiCreatePiiRequestRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreatePiiRequestResponseFromJSON(jsonValue));
    }

    /**
     * Requests all Personally Identifiable Information (PII) associated with a user
     * Create user PII request
     */
    async createPiiRequest(companyCodeOrSid: string, streemApiCreatePiiRequestRequest?: StreemApiCreatePiiRequestRequest): Promise<StreemApiCreatePiiRequestResponse> {
        const response = await this.createPiiRequestRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreatePiiRequestRequest: streemApiCreatePiiRequestRequest });
        return await response.value();
    }

    /**
     */
    async deleteCallSourceRaw(requestParameters: DeleteCallSourceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteCallSource.');
        }

        if (requestParameters.callSourceSid === null || requestParameters.callSourceSid === undefined) {
            throw new runtime.RequiredError('callSourceSid','Required parameter requestParameters.callSourceSid was null or undefined when calling deleteCallSource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source/{call_source_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"call_source_sid"}}`, encodeURIComponent(String(requestParameters.callSourceSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCallSource(companyCodeOrSid: string, callSourceSid: string): Promise<void> {
        await this.deleteCallSourceRaw({ companyCodeOrSid: companyCodeOrSid, callSourceSid: callSourceSid });
    }

    /**
     * Deactivate Company/Users and optionally delete assets
     * Deactivate Company and assets
     */
    async deleteCompanyAndAssetsRaw(requestParameters: DeleteCompanyAndAssetsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteCompanyAndAssets.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiDeleteCompanyAndAssetsRequestToJSON(requestParameters.streemApiDeleteCompanyAndAssetsRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate Company/Users and optionally delete assets
     * Deactivate Company and assets
     */
    async deleteCompanyAndAssets(companyCodeOrSid: string, streemApiDeleteCompanyAndAssetsRequest?: StreemApiDeleteCompanyAndAssetsRequest): Promise<void> {
        await this.deleteCompanyAndAssetsRaw({ companyCodeOrSid: companyCodeOrSid, streemApiDeleteCompanyAndAssetsRequest: streemApiDeleteCompanyAndAssetsRequest });
    }

    /**
     * Delete Company Room Outcome Configuration for a specific Company
     * Delete Company Room Outcome Configuration
     */
    async deleteCompanyRoomOutcomeConfigRaw(requestParameters: DeleteCompanyRoomOutcomeConfigRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteCompanyRoomOutcomeConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/room-outcome-config`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Company Room Outcome Configuration for a specific Company
     * Delete Company Room Outcome Configuration
     */
    async deleteCompanyRoomOutcomeConfig(companyCodeOrSid: string): Promise<void> {
        await this.deleteCompanyRoomOutcomeConfigRaw({ companyCodeOrSid: companyCodeOrSid });
    }

    /**
     * Delete a Setting for the specified Company, resetting it back to the default
     * Delete Setting
     */
    async deleteCompanySettingRaw(requestParameters: DeleteCompanySettingRequest): Promise<runtime.ApiResponse<StreemApiDeleteCompanySettingResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteCompanySetting.');
        }

        if (requestParameters.settingName === null || requestParameters.settingName === undefined) {
            throw new runtime.RequiredError('settingName','Required parameter requestParameters.settingName was null or undefined when calling deleteCompanySetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/settings/{setting_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"setting_name"}}`, encodeURIComponent(String(requestParameters.settingName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiDeleteCompanySettingResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Setting for the specified Company, resetting it back to the default
     * Delete Setting
     */
    async deleteCompanySetting(companyCodeOrSid: string, settingName: string): Promise<StreemApiDeleteCompanySettingResponse> {
        const response = await this.deleteCompanySettingRaw({ companyCodeOrSid: companyCodeOrSid, settingName: settingName });
        return await response.value();
    }

    /**
     * Deletes a specified tag for the specified Company
     * Delete Company Tag
     */
    async deleteCompanyTagRaw(requestParameters: DeleteCompanyTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteCompanyTag.');
        }

        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling deleteCompanyTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/tags/{tag_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"tag_name"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a specified tag for the specified Company
     * Delete Company Tag
     */
    async deleteCompanyTag(companyCodeOrSid: string, tagName: string): Promise<void> {
        await this.deleteCompanyTagRaw({ companyCodeOrSid: companyCodeOrSid, tagName: tagName });
    }

    /**
     * Delete a group from a company
     * Delete group from company
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteGroup.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling deleteGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a group from a company
     * Delete group from company
     */
    async deleteGroup(companyCodeOrSid: string, groupName: string): Promise<void> {
        await this.deleteGroupRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName });
    }

    /**
     * Remove a user from a group for a specific company.
     * Remove user from group for company
     */
    async deleteGroupUserRaw(requestParameters: DeleteGroupUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteGroupUser.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling deleteGroupUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteGroupUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a user from a group for a specific company.
     * Remove user from group for company
     */
    async deleteGroupUser(companyCodeOrSid: string, groupName: string, userId: string): Promise<void> {
        await this.deleteGroupUserRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, userId: userId });
    }

    /**
     * Returns one or more links to download audit logs for the given YYYY-MM-DD day. The file contains all audit events occurring on that day in JSON format.
     * Fetch link(s) to audit logs for a given day
     */
    async getAuditLogRaw(requestParameters: GetAuditLogRequest): Promise<runtime.ApiResponse<StreemApiGetAuditLogsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getAuditLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.day !== undefined) {
            queryParameters['day'] = (requestParameters.day as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/audit-logs`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetAuditLogsResponseFromJSON(jsonValue));
    }

    /**
     * Returns one or more links to download audit logs for the given YYYY-MM-DD day. The file contains all audit events occurring on that day in JSON format.
     * Fetch link(s) to audit logs for a given day
     */
    async getAuditLog(companyCodeOrSid: string, day?: Date): Promise<StreemApiGetAuditLogsResponse> {
        const response = await this.getAuditLogRaw({ companyCodeOrSid: companyCodeOrSid, day: day });
        return await response.value();
    }

    /**
     */
    async getCallSourceRaw(requestParameters: GetCallSourceRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyCallSourceResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCallSource.');
        }

        if (requestParameters.callSourceSid === null || requestParameters.callSourceSid === undefined) {
            throw new runtime.RequiredError('callSourceSid','Required parameter requestParameters.callSourceSid was null or undefined when calling getCallSource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source/{call_source_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"call_source_sid"}}`, encodeURIComponent(String(requestParameters.callSourceSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyCallSourceResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCallSource(companyCodeOrSid: string, callSourceSid: string): Promise<StreemApiGetCompanyCallSourceResponse> {
        const response = await this.getCallSourceRaw({ companyCodeOrSid: companyCodeOrSid, callSourceSid: callSourceSid });
        return await response.value();
    }

    /**
     */
    async getCallSourcesRaw(requestParameters: GetCallSourcesRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyCallSourcesResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCallSources.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyCallSourcesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCallSources(companyCodeOrSid: string): Promise<StreemApiGetCompanyCallSourcesResponse> {
        const response = await this.getCallSourcesRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get info for a specific Company
     * Get Company
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Get info for a specific Company
     * Get Company
     */
    async getCompany(companyCodeOrSid: string): Promise<StreemApiGetCompanyResponse> {
        const response = await this.getCompanyRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get an API Key by `api_key_id` for the specified Company
     * Get API Key
     */
    async getCompanyApiKeyRaw(requestParameters: GetCompanyApiKeyRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyApiKeyResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyApiKey.');
        }

        if (requestParameters.apiKeyId === null || requestParameters.apiKeyId === undefined) {
            throw new runtime.RequiredError('apiKeyId','Required parameter requestParameters.apiKeyId was null or undefined when calling getCompanyApiKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/api-keys/{api_key_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"api_key_id"}}`, encodeURIComponent(String(requestParameters.apiKeyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyApiKeyResponseFromJSON(jsonValue));
    }

    /**
     * Get an API Key by `api_key_id` for the specified Company
     * Get API Key
     */
    async getCompanyApiKey(companyCodeOrSid: string, apiKeyId: string): Promise<StreemApiGetCompanyApiKeyResponse> {
        const response = await this.getCompanyApiKeyRaw({ companyCodeOrSid: companyCodeOrSid, apiKeyId: apiKeyId });
        return await response.value();
    }

    /**
     * Get the Call Log for the specified Company, which are all calls made by Users of the Company
     * Get Call Log
     */
    async getCompanyCallLogRaw(requestParameters: GetCompanyCallLogRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyCallLogResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyCallLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['end_time'] = (requestParameters.endTime as any).toISOString();
        }

        if (requestParameters.groupNames) {
            queryParameters['group_names'] = requestParameters.groupNames;
        }

        if (requestParameters.callStatuses) {
            queryParameters['call_statuses'] = requestParameters.callStatuses;
        }

        if (requestParameters.hasRoomNotes !== undefined) {
            queryParameters['has_room_notes'] = requestParameters.hasRoomNotes;
        }

        if (requestParameters.callSourceCodes) {
            queryParameters['call_source_codes'] = requestParameters.callSourceCodes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-log`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyCallLogResponseFromJSON(jsonValue));
    }

    /**
     * Get the Call Log for the specified Company, which are all calls made by Users of the Company
     * Get Call Log
     */
    async getCompanyCallLog(companyCodeOrSid: string, pageSize?: number, pageToken?: string, filter?: string, startTime?: Date, endTime?: Date, groupNames?: Array<string>, callStatuses?: Array<string>, hasRoomNotes?: boolean, callSourceCodes?: Array<string>): Promise<StreemApiGetCompanyCallLogResponse> {
        const response = await this.getCompanyCallLogRaw({ companyCodeOrSid: companyCodeOrSid, pageSize: pageSize, pageToken: pageToken, filter: filter, startTime: startTime, endTime: endTime, groupNames: groupNames, callStatuses: callStatuses, hasRoomNotes: hasRoomNotes, callSourceCodes: callSourceCodes });
        return await response.value();
    }

    /**
     * Get the current Identity Provider for this Company
     * Get Identity Provider
     */
    async getCompanyIdentityProviderRaw(requestParameters: GetCompanyIdentityProviderRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyIdentityProviderResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyIdentityProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/identity-provider`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyIdentityProviderResponseFromJSON(jsonValue));
    }

    /**
     * Get the current Identity Provider for this Company
     * Get Identity Provider
     */
    async getCompanyIdentityProvider(companyCodeOrSid: string): Promise<StreemApiGetCompanyIdentityProviderResponse> {
        const response = await this.getCompanyIdentityProviderRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get the current License associated with this Company
     * Get Company License
     */
    async getCompanyLicenseRaw(requestParameters: GetCompanyLicenseRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyLicenseResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyLicense.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/license`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyLicenseResponseFromJSON(jsonValue));
    }

    /**
     * Get the current License associated with this Company
     * Get Company License
     */
    async getCompanyLicense(companyCodeOrSid: string): Promise<StreemApiGetCompanyLicenseResponse> {
        const response = await this.getCompanyLicenseRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get Company Room Outcome Configuration for a specific Company
     * Get Company Room Outcome Configuration
     */
    async getCompanyRoomOutcomeConfigRaw(requestParameters: GetCompanyRoomOutcomeConfigRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyRoomOutcomeConfigResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyRoomOutcomeConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/room-outcome-config`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyRoomOutcomeConfigResponseFromJSON(jsonValue));
    }

    /**
     * Get Company Room Outcome Configuration for a specific Company
     * Get Company Room Outcome Configuration
     */
    async getCompanyRoomOutcomeConfig(companyCodeOrSid: string): Promise<StreemApiGetCompanyRoomOutcomeConfigResponse> {
        const response = await this.getCompanyRoomOutcomeConfigRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get a specified tag and its values for the specified Company
     * Get Company Tag
     */
    async getCompanyTagRaw(requestParameters: GetCompanyTagRequest): Promise<runtime.ApiResponse<StreemApiGetCompanyTagResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getCompanyTag.');
        }

        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling getCompanyTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/tags/{tag_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"tag_name"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCompanyTagResponseFromJSON(jsonValue));
    }

    /**
     * Get a specified tag and its values for the specified Company
     * Get Company Tag
     */
    async getCompanyTag(companyCodeOrSid: string, tagName: string): Promise<StreemApiGetCompanyTagResponse> {
        const response = await this.getCompanyTagRaw({ companyCodeOrSid: companyCodeOrSid, tagName: tagName });
        return await response.value();
    }

    /**
     * Returns the company associated with this account
     * Get Current Company
     */
    async getCurrentCompanyRaw(): Promise<runtime.ApiResponse<StreemApiGetCurrentCompanyResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCurrentCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Returns the company associated with this account
     * Get Current Company
     */
    async getCurrentCompany(): Promise<StreemApiGetCurrentCompanyResponse> {
        const response = await this.getCurrentCompanyRaw();
        return await response.value();
    }

    /**
     * Get a group for a company by its name
     * Get group for company
     */
    async getGroupRaw(requestParameters: GetGroupRequest): Promise<runtime.ApiResponse<StreemApiGetGroupResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroup.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupResponseFromJSON(jsonValue));
    }

    /**
     * Get a group for a company by its name
     * Get group for company
     */
    async getGroup(companyCodeOrSid: string, groupName: string): Promise<StreemApiGetGroupResponse> {
        const response = await this.getGroupRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName });
        return await response.value();
    }

    /**
     * Get a specified group reservation
     * Get Group Reservation
     */
    async getGroupReservationRaw(requestParameters: GetGroupReservationRequest): Promise<runtime.ApiResponse<StreemApiGetGroupReservationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling getGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupReservationResponseFromJSON(jsonValue));
    }

    /**
     * Get a specified group reservation
     * Get Group Reservation
     */
    async getGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<StreemApiGetGroupReservationResponse> {
        const response = await this.getGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
        return await response.value();
    }

    /**
     * Get group reservation wait time for a specific group
     * Get group reservation wait time
     */
    async getGroupReservationWaitTimeRaw(requestParameters: GetGroupReservationWaitTimeRequest): Promise<runtime.ApiResponse<StreemApiGetGroupReservationWaitTimeResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroupReservationWaitTime.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroupReservationWaitTime.');
        }

        if (requestParameters.priority === null || requestParameters.priority === undefined) {
            throw new runtime.RequiredError('priority','Required parameter requestParameters.priority was null or undefined when calling getGroupReservationWaitTime.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{priority}/wait_time`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"priority"}}`, encodeURIComponent(String(requestParameters.priority))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupReservationWaitTimeResponseFromJSON(jsonValue));
    }

    /**
     * Get group reservation wait time for a specific group
     * Get group reservation wait time
     */
    async getGroupReservationWaitTime(companyCodeOrSid: string, groupName: string, priority: number): Promise<StreemApiGetGroupReservationWaitTimeResponse> {
        const response = await this.getGroupReservationWaitTimeRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, priority: priority });
        return await response.value();
    }

    /**
     * Get or create a User for this Company with the provided `external_user_id`, returning their internal `user_sid`
     * Get/Create User
     */
    async getOrCreateUserRaw(requestParameters: GetOrCreateUserRequest): Promise<runtime.ApiResponse<StreemApiGetOrCreateUserResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getOrCreateUser.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling getOrCreateUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users/{external_user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"external_user_id"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiGetOrCreateUserRequestToJSON(requestParameters.streemApiGetOrCreateUserRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetOrCreateUserResponseFromJSON(jsonValue));
    }

    /**
     * Get or create a User for this Company with the provided `external_user_id`, returning their internal `user_sid`
     * Get/Create User
     */
    async getOrCreateUser(companyCodeOrSid: string, externalUserId: string, streemApiGetOrCreateUserRequest?: StreemApiGetOrCreateUserRequest): Promise<StreemApiGetOrCreateUserResponse> {
        const response = await this.getOrCreateUserRaw({ companyCodeOrSid: companyCodeOrSid, externalUserId: externalUserId, streemApiGetOrCreateUserRequest: streemApiGetOrCreateUserRequest });
        return await response.value();
    }

    /**
     * Get the public login configuration, determining how to login to this company
     * Get Public Login Config
     */
    async getPublicLoginConfigurationRaw(requestParameters: GetPublicLoginConfigurationRequest): Promise<runtime.ApiResponse<StreemApiGetPublicLoginConfigurationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getPublicLoginConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/public-login-configuration`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetPublicLoginConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * Get the public login configuration, determining how to login to this company
     * Get Public Login Config
     */
    async getPublicLoginConfiguration(companyCodeOrSid: string): Promise<StreemApiGetPublicLoginConfigurationResponse> {
        const response = await this.getPublicLoginConfigurationRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get any setting name\'s definition
     * Get Setting Definition
     */
    async getSettingDefinitionRaw(requestParameters: GetSettingDefinitionRequest): Promise<runtime.ApiResponse<StreemApiGetSettingDefinitionResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getSettingDefinition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/~default-setting`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiGetSettingDefinitionRequestToJSON(requestParameters.streemApiGetSettingDefinitionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetSettingDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get any setting name\'s definition
     * Get Setting Definition
     */
    async getSettingDefinition(companyCodeOrSid: string, streemApiGetSettingDefinitionRequest?: StreemApiGetSettingDefinitionRequest): Promise<StreemApiGetSettingDefinitionResponse> {
        const response = await this.getSettingDefinitionRaw({ companyCodeOrSid: companyCodeOrSid, streemApiGetSettingDefinitionRequest: streemApiGetSettingDefinitionRequest });
        return await response.value();
    }

    /**
     * Get User by `external_user_id`, which should be unique in the scope of the specified Company
     * Get User By External User Id
     */
    async getUserByExternalUserIdRaw(requestParameters: GetUserByExternalUserIdRequest): Promise<runtime.ApiResponse<StreemApiGetUserByExternalUserIdResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getUserByExternalUserId.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling getUserByExternalUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users/{external_user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"external_user_id"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetUserByExternalUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Get User by `external_user_id`, which should be unique in the scope of the specified Company
     * Get User By External User Id
     */
    async getUserByExternalUserId(companyCodeOrSid: string, externalUserId: string): Promise<StreemApiGetUserByExternalUserIdResponse> {
        const response = await this.getUserByExternalUserIdRaw({ companyCodeOrSid: companyCodeOrSid, externalUserId: externalUserId });
        return await response.value();
    }

    /**
     * List companies or sub companies. Can filter based on parent company sid, active status, name, and/or company code.
     * List Companies
     */
    async listCompaniesRaw(requestParameters: ListCompaniesRequest): Promise<runtime.ApiResponse<StreemApiListCompaniesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.parentCompanySid !== undefined) {
            queryParameters['parent_company_sid'] = requestParameters.parentCompanySid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompaniesResponseFromJSON(jsonValue));
    }

    /**
     * List companies or sub companies. Can filter based on parent company sid, active status, name, and/or company code.
     * List Companies
     */
    async listCompanies(pageSize?: number, pageToken?: string, sort?: string, filter?: string, active?: boolean, parentCompanySid?: string): Promise<StreemApiListCompaniesResponse> {
        const response = await this.listCompaniesRaw({ pageSize: pageSize, pageToken: pageToken, sort: sort, filter: filter, active: active, parentCompanySid: parentCompanySid });
        return await response.value();
    }

    /**
     * List API keys for the specified Company
     * List API Keys
     */
    async listCompanyApiKeysRaw(requestParameters: ListCompanyApiKeysRequest): Promise<runtime.ApiResponse<StreemApiListCompanyApiKeysResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listCompanyApiKeys.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeExpired !== undefined) {
            queryParameters['include_expired'] = requestParameters.includeExpired;
        }

        if (requestParameters.expires !== undefined) {
            queryParameters['expires'] = requestParameters.expires;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/api-keys`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompanyApiKeysResponseFromJSON(jsonValue));
    }

    /**
     * List API keys for the specified Company
     * List API Keys
     */
    async listCompanyApiKeys(companyCodeOrSid: string, includeExpired?: boolean, expires?: ListCompanyApiKeysExpires, pageSize?: number, pageToken?: string): Promise<StreemApiListCompanyApiKeysResponse> {
        const response = await this.listCompanyApiKeysRaw({ companyCodeOrSid: companyCodeOrSid, includeExpired: includeExpired, expires: expires, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Get those settings for the specified Company that are available without authentication
     * Get Public Settings
     */
    async listCompanyPublicSettingsRaw(requestParameters: ListCompanyPublicSettingsRequest): Promise<runtime.ApiResponse<StreemApiListCompanyPublicSettingsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listCompanyPublicSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/public-settings`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompanyPublicSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Get those settings for the specified Company that are available without authentication
     * Get Public Settings
     */
    async listCompanyPublicSettings(companyCodeOrSid: string): Promise<StreemApiListCompanyPublicSettingsResponse> {
        const response = await this.listCompanyPublicSettingsRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get a list of Settings for the specified Company
     * Get Settings
     */
    async listCompanySettingsRaw(requestParameters: ListCompanySettingsRequest): Promise<runtime.ApiResponse<StreemApiListCompanySettingsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listCompanySettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/settings`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompanySettingsResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of Settings for the specified Company
     * Get Settings
     */
    async listCompanySettings(companyCodeOrSid: string): Promise<StreemApiListCompanySettingsResponse> {
        const response = await this.listCompanySettingsRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * Get all tags and their values for the specified Company
     * Get Company Tags
     */
    async listCompanyTagsRaw(requestParameters: ListCompanyTagsRequest): Promise<runtime.ApiResponse<StreemApiListCompanyTagsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listCompanyTags.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/tags`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompanyTagsResponseFromJSON(jsonValue));
    }

    /**
     * Get all tags and their values for the specified Company
     * Get Company Tags
     */
    async listCompanyTags(companyCodeOrSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListCompanyTagsResponse> {
        const response = await this.listCompanyTagsRaw({ companyCodeOrSid: companyCodeOrSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List the users in the group that have a certain availability status
     * List Group Availability
     */
    async listGroupAvailabilityRaw(requestParameters: ListGroupAvailabilityRequest): Promise<runtime.ApiResponse<StreemApiListGroupAvailabilityRequest>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroupAvailability.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling listGroupAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/availability`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupAvailabilityRequestFromJSON(jsonValue));
    }

    /**
     * List the users in the group that have a certain availability status
     * List Group Availability
     */
    async listGroupAvailability(companyCodeOrSid: string, groupName: string, status?: ListGroupAvailabilityStatus, pageSize?: number, pageToken?: string): Promise<StreemApiListGroupAvailabilityRequest> {
        const response = await this.listGroupAvailabilityRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, status: status, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List the users that belong to a group.
     * List users in group
     */
    async listGroupUsersRaw(requestParameters: ListGroupUsersRequest): Promise<runtime.ApiResponse<StreemApiListGroupUsersResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroupUsers.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling listGroupUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupUsersResponseFromJSON(jsonValue));
    }

    /**
     * List the users that belong to a group.
     * List users in group
     */
    async listGroupUsers(companyCodeOrSid: string, groupName: string, pageSize?: number, pageToken?: string): Promise<StreemApiListGroupUsersResponse> {
        const response = await this.listGroupUsersRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List the groups associated with the company
     * List groups for company
     */
    async listGroupsRaw(requestParameters: ListGroupsRequest): Promise<runtime.ApiResponse<StreemApiListGroupsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupsResponseFromJSON(jsonValue));
    }

    /**
     * List the groups associated with the company
     * List groups for company
     */
    async listGroups(companyCodeOrSid: string): Promise<StreemApiListGroupsResponse> {
        const response = await this.listGroupsRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * List the groups that a user belongs to.
     * List user\'s groups
     */
    async listUserGroupsRaw(requestParameters: ListUserGroupsRequest): Promise<runtime.ApiResponse<StreemApiListUserGroupsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listUserGroups.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listUserGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListUserGroupsResponseFromJSON(jsonValue));
    }

    /**
     * List the groups that a user belongs to.
     * List user\'s groups
     */
    async listUserGroups(companyCodeOrSid: string, userId: string, pageSize?: number, pageToken?: string): Promise<StreemApiListUserGroupsResponse> {
        const response = await this.listUserGroupsRaw({ companyCodeOrSid: companyCodeOrSid, userId: userId, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Get all Users associated with the specified Company
     * Get Users
     */
    async listUsersForCompanyRaw(requestParameters: ListUsersForCompanyRequest): Promise<runtime.ApiResponse<StreemApiListCompanyUsersResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listUsersForCompany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.userTypes) {
            queryParameters['user_types'] = requestParameters.userTypes;
        }

        if (requestParameters.includeAvailabilityStatus !== undefined) {
            queryParameters['include_availability_status'] = requestParameters.includeAvailabilityStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/users`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompanyUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get all Users associated with the specified Company
     * Get Users
     */
    async listUsersForCompany(companyCodeOrSid: string, pageSize?: number, pageToken?: string, active?: boolean, deleted?: boolean, filter?: string, sort?: string, userTypes?: Array<ListUsersForCompanyUserTypes>, includeAvailabilityStatus?: boolean): Promise<StreemApiListCompanyUsersResponse> {
        const response = await this.listUsersForCompanyRaw({ companyCodeOrSid: companyCodeOrSid, pageSize: pageSize, pageToken: pageToken, active: active, deleted: deleted, filter: filter, sort: sort, userTypes: userTypes, includeAvailabilityStatus: includeAvailabilityStatus });
        return await response.value();
    }

    /**
     */
    async refreshEmbedReportsSessionRaw(requestParameters: RefreshEmbedReportsSessionRequest): Promise<runtime.ApiResponse<StreemApiRefreshEmbedReportsSessionResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling refreshEmbedReportsSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/reports/~refresh-embed-session`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiRefreshEmbedReportsSessionRequestToJSON(requestParameters.streemApiRefreshEmbedReportsSessionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiRefreshEmbedReportsSessionResponseFromJSON(jsonValue));
    }

    /**
     */
    async refreshEmbedReportsSession(companyCodeOrSid: string, streemApiRefreshEmbedReportsSessionRequest?: StreemApiRefreshEmbedReportsSessionRequest): Promise<StreemApiRefreshEmbedReportsSessionResponse> {
        const response = await this.refreshEmbedReportsSessionRaw({ companyCodeOrSid: companyCodeOrSid, streemApiRefreshEmbedReportsSessionRequest: streemApiRefreshEmbedReportsSessionRequest });
        return await response.value();
    }

    /**
     * Revokes an API Key, which sets the `expires_at` date to now
     * Revoke API Key
     */
    async revokeCompanyApiKeyRaw(requestParameters: RevokeCompanyApiKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling revokeCompanyApiKey.');
        }

        if (requestParameters.apiKeyId === null || requestParameters.apiKeyId === undefined) {
            throw new runtime.RequiredError('apiKeyId','Required parameter requestParameters.apiKeyId was null or undefined when calling revokeCompanyApiKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/api-keys/{api_key_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"api_key_id"}}`, encodeURIComponent(String(requestParameters.apiKeyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revokes an API Key, which sets the `expires_at` date to now
     * Revoke API Key
     */
    async revokeCompanyApiKey(companyCodeOrSid: string, apiKeyId: string): Promise<void> {
        await this.revokeCompanyApiKeyRaw({ companyCodeOrSid: companyCodeOrSid, apiKeyId: apiKeyId });
    }

    /**
     * Saves or updates the current License assigned to this Company
     * Save Company License
     */
    async saveCompanyLicenseRaw(requestParameters: SaveCompanyLicenseRequest): Promise<runtime.ApiResponse<StreemApiSaveCompanyLicenseResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling saveCompanyLicense.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/license`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSaveCompanyLicenseRequestToJSON(requestParameters.streemApiSaveCompanyLicenseRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveCompanyLicenseResponseFromJSON(jsonValue));
    }

    /**
     * Saves or updates the current License assigned to this Company
     * Save Company License
     */
    async saveCompanyLicense(companyCodeOrSid: string, streemApiSaveCompanyLicenseRequest?: StreemApiSaveCompanyLicenseRequest): Promise<StreemApiSaveCompanyLicenseResponse> {
        const response = await this.saveCompanyLicenseRaw({ companyCodeOrSid: companyCodeOrSid, streemApiSaveCompanyLicenseRequest: streemApiSaveCompanyLicenseRequest });
        return await response.value();
    }

    /**
     * Save a Setting for the specified Company
     * Save Setting
     */
    async saveCompanySettingRaw(requestParameters: SaveCompanySettingRequest): Promise<runtime.ApiResponse<StreemApiSaveCompanySettingResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling saveCompanySetting.');
        }

        if (requestParameters.settingName === null || requestParameters.settingName === undefined) {
            throw new runtime.RequiredError('settingName','Required parameter requestParameters.settingName was null or undefined when calling saveCompanySetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/settings/{setting_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"setting_name"}}`, encodeURIComponent(String(requestParameters.settingName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSaveCompanySettingRequestToJSON(requestParameters.streemApiSaveCompanySettingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveCompanySettingResponseFromJSON(jsonValue));
    }

    /**
     * Save a Setting for the specified Company
     * Save Setting
     */
    async saveCompanySetting(companyCodeOrSid: string, settingName: string, streemApiSaveCompanySettingRequest?: StreemApiSaveCompanySettingRequest): Promise<StreemApiSaveCompanySettingResponse> {
        const response = await this.saveCompanySettingRaw({ companyCodeOrSid: companyCodeOrSid, settingName: settingName, streemApiSaveCompanySettingRequest: streemApiSaveCompanySettingRequest });
        return await response.value();
    }

    /**
     * Creates/updates a specified tag for the specified Company
     * Save Company Tag
     */
    async saveCompanyTagRaw(requestParameters: SaveCompanyTagRequest): Promise<runtime.ApiResponse<StreemApiSaveCompanyTagResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling saveCompanyTag.');
        }

        if (requestParameters.tagName === null || requestParameters.tagName === undefined) {
            throw new runtime.RequiredError('tagName','Required parameter requestParameters.tagName was null or undefined when calling saveCompanyTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/tags/{tag_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"tag_name"}}`, encodeURIComponent(String(requestParameters.tagName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSaveCompanyTagRequestToJSON(requestParameters.streemApiSaveCompanyTagRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSaveCompanyTagResponseFromJSON(jsonValue));
    }

    /**
     * Creates/updates a specified tag for the specified Company
     * Save Company Tag
     */
    async saveCompanyTag(companyCodeOrSid: string, tagName: string, streemApiSaveCompanyTagRequest?: StreemApiSaveCompanyTagRequest): Promise<StreemApiSaveCompanyTagResponse> {
        const response = await this.saveCompanyTagRaw({ companyCodeOrSid: companyCodeOrSid, tagName: tagName, streemApiSaveCompanyTagRequest: streemApiSaveCompanyTagRequest });
        return await response.value();
    }

    /**
     * List all Companies that have a member with the given email
     * List Companies For User Email
     */
    async searchCompaniesForUserEmailRaw(requestParameters: SearchCompaniesForUserEmailRequest): Promise<runtime.ApiResponse<StreemApiSearchCompaniesForUserEmailResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/user-email-search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiSearchCompaniesForUserEmailRequestToJSON(requestParameters.streemApiSearchCompaniesForUserEmailRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiSearchCompaniesForUserEmailResponseFromJSON(jsonValue));
    }

    /**
     * List all Companies that have a member with the given email
     * List Companies For User Email
     */
    async searchCompaniesForUserEmail(streemApiSearchCompaniesForUserEmailRequest?: StreemApiSearchCompaniesForUserEmailRequest): Promise<StreemApiSearchCompaniesForUserEmailResponse> {
        const response = await this.searchCompaniesForUserEmailRaw({ streemApiSearchCompaniesForUserEmailRequest: streemApiSearchCompaniesForUserEmailRequest });
        return await response.value();
    }

    /**
     * Update certain properties of the specified Company
     * Update Company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest): Promise<runtime.ApiResponse<StreemApiUpdateCompanyResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling updateCompany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateCompanyRequestToJSON(requestParameters.streemApiUpdateCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Update certain properties of the specified Company
     * Update Company
     */
    async updateCompany(companyCodeOrSid: string, streemApiUpdateCompanyRequest?: StreemApiUpdateCompanyRequest): Promise<StreemApiUpdateCompanyResponse> {
        const response = await this.updateCompanyRaw({ companyCodeOrSid: companyCodeOrSid, streemApiUpdateCompanyRequest: streemApiUpdateCompanyRequest });
        return await response.value();
    }

    /**
     */
    async updateCompanyCallSourceRaw(requestParameters: UpdateCompanyCallSourceRequest): Promise<runtime.ApiResponse<StreemApiUpdateCompanyCallSourceResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling updateCompanyCallSource.');
        }

        if (requestParameters.callSourceSid === null || requestParameters.callSourceSid === undefined) {
            throw new runtime.RequiredError('callSourceSid','Required parameter requestParameters.callSourceSid was null or undefined when calling updateCompanyCallSource.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source/{call_source_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"call_source_sid"}}`, encodeURIComponent(String(requestParameters.callSourceSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateCompanyCallSourceRequestToJSON(requestParameters.streemApiUpdateCompanyCallSourceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateCompanyCallSourceResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateCompanyCallSource(companyCodeOrSid: string, callSourceSid: string, streemApiUpdateCompanyCallSourceRequest?: StreemApiUpdateCompanyCallSourceRequest): Promise<StreemApiUpdateCompanyCallSourceResponse> {
        const response = await this.updateCompanyCallSourceRaw({ companyCodeOrSid: companyCodeOrSid, callSourceSid: callSourceSid, streemApiUpdateCompanyCallSourceRequest: streemApiUpdateCompanyCallSourceRequest });
        return await response.value();
    }

    /**
     * Change the current Identity Provider for this Company.  WARNING: this will mean a clean slate of accounts, so previous logins will no longer work
     * Change Identity Provider
     */
    async updateCompanyIdentityProviderRaw(requestParameters: UpdateCompanyIdentityProviderRequest): Promise<runtime.ApiResponse<StreemApiUpdateCompanyIdentityProviderResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling updateCompanyIdentityProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/identity-provider`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateCompanyIdentityProviderRequestToJSON(requestParameters.streemApiUpdateCompanyIdentityProviderRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateCompanyIdentityProviderResponseFromJSON(jsonValue));
    }

    /**
     * Change the current Identity Provider for this Company.  WARNING: this will mean a clean slate of accounts, so previous logins will no longer work
     * Change Identity Provider
     */
    async updateCompanyIdentityProvider(companyCodeOrSid: string, streemApiUpdateCompanyIdentityProviderRequest?: StreemApiUpdateCompanyIdentityProviderRequest): Promise<StreemApiUpdateCompanyIdentityProviderResponse> {
        const response = await this.updateCompanyIdentityProviderRaw({ companyCodeOrSid: companyCodeOrSid, streemApiUpdateCompanyIdentityProviderRequest: streemApiUpdateCompanyIdentityProviderRequest });
        return await response.value();
    }

    /**
     */
    async uploadCompanyCallSourceLogoRaw(requestParameters: UploadCompanyCallSourceLogoRequest): Promise<runtime.ApiResponse<StreemApiUploadCompanyCallSourceLogoResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling uploadCompanyCallSourceLogo.');
        }

        if (requestParameters.callSourceSid === null || requestParameters.callSourceSid === undefined) {
            throw new runtime.RequiredError('callSourceSid','Required parameter requestParameters.callSourceSid was null or undefined when calling uploadCompanyCallSourceLogo.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadCompanyCallSourceLogo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/call-source/{call_source_sid}/asset/{type}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"call_source_sid"}}`, encodeURIComponent(String(requestParameters.callSourceSid))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUploadCompanyCallSourceLogoRequestToJSON(requestParameters.streemApiUploadCompanyCallSourceLogoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUploadCompanyCallSourceLogoResponseFromJSON(jsonValue));
    }

    /**
     */
    async uploadCompanyCallSourceLogo(companyCodeOrSid: string, callSourceSid: string, type: UploadCompanyCallSourceLogoType, streemApiUploadCompanyCallSourceLogoRequest?: StreemApiUploadCompanyCallSourceLogoRequest): Promise<StreemApiUploadCompanyCallSourceLogoResponse> {
        const response = await this.uploadCompanyCallSourceLogoRaw({ companyCodeOrSid: companyCodeOrSid, callSourceSid: callSourceSid, type: type, streemApiUploadCompanyCallSourceLogoRequest: streemApiUploadCompanyCallSourceLogoRequest });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateCompanyAssetOperationType {
    LOGO = 'COMPANY_UPLOAD_TYPE_LOGO',
    WATERMARK = 'COMPANY_UPLOAD_TYPE_WATERMARK',
    ROOMOUTCOMECONFIG = 'COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG',
    CALLSOURCELOGO = 'COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO',
    INVALID = 'COMPANY_UPLOAD_TYPE_INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum ListCompanyApiKeysExpires {
    ANY = 'EXPIRES_ANY',
    NO = 'EXPIRES_NO',
    YES = 'EXPIRES_YES'
}
/**
    * @export
    * @enum {string}
    */
export enum ListGroupAvailabilityStatus {
    AVAILABLE = 'AVAILABILITY_STATUS_AVAILABLE',
    NOTAVAILABLE = 'AVAILABILITY_STATUS_NOT_AVAILABLE',
    RESERVED = 'AVAILABILITY_STATUS_RESERVED',
    INCALL = 'AVAILABILITY_STATUS_IN_CALL',
    CONFIRMING = 'AVAILABILITY_STATUS_CONFIRMING',
    OFFLINE = 'AVAILABILITY_STATUS_OFFLINE',
    INVALID = 'AVAILABILITY_STATUS_INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum ListUsersForCompanyUserTypes {
    UNSPECIFIED = 'USER_TYPE_UNSPECIFIED',
    LOGIN = 'USER_TYPE_LOGIN',
    SDK = 'USER_TYPE_SDK',
    INVITED = 'USER_TYPE_INVITED'
}
/**
    * @export
    * @enum {string}
    */
export enum UploadCompanyCallSourceLogoType {
    LOGO = 'COMPANY_UPLOAD_TYPE_LOGO',
    WATERMARK = 'COMPANY_UPLOAD_TYPE_WATERMARK',
    ROOMOUTCOMECONFIG = 'COMPANY_UPLOAD_TYPE_ROOM_OUTCOME_CONFIG',
    CALLSOURCELOGO = 'COMPANY_UPLOAD_TYPE_CALL_SOURCE_LOGO',
    INVALID = 'COMPANY_UPLOAD_TYPE_INVALID'
}
