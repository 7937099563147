/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventVignetteDrawerExpanded
 */
export interface StreemApiAnalyticEventVignetteDrawerExpanded {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteDrawerExpanded
     */
    vignettElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteDrawerExpanded
     */
    finishId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventVignetteDrawerExpanded
     */
    snapPoint?: StreemApiAnalyticEventVignetteDrawerExpandedSnapPoint;
}

export function StreemApiAnalyticEventVignetteDrawerExpandedFromJSON(json: any): StreemApiAnalyticEventVignetteDrawerExpanded {
    return StreemApiAnalyticEventVignetteDrawerExpandedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventVignetteDrawerExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventVignetteDrawerExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vignettElementId': !exists(json, 'vignett_element_id') ? undefined : json['vignett_element_id'],
        'finishId': !exists(json, 'finish_id') ? undefined : json['finish_id'],
        'snapPoint': !exists(json, 'snap_point') ? undefined : json['snap_point'],
    };
}

export function StreemApiAnalyticEventVignetteDrawerExpandedToJSON(value?: StreemApiAnalyticEventVignetteDrawerExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vignett_element_id': value.vignettElementId,
        'finish_id': value.finishId,
        'snap_point': value.snapPoint,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventVignetteDrawerExpandedSnapPoint {
    PEEKHEIGHT = 'SNAP_POINT_PEEK_HEIGHT',
    HALFEXTENDED = 'SNAP_POINT_HALF_EXTENDED',
    FULLHEIGHT = 'SNAP_POINT_FULL_HEIGHT',
    INVALID = 'SNAP_POINT_INVALID'
}


