const audioContext =
    typeof window !== 'undefined' && window.AudioContext ? new window.AudioContext() : null;

interface PollAudioLevelConfig {
    track: MediaStreamTrack;
    onLevelChanged: (level: number) => void;
    onError: (message: string, err: any) => void;
}

export const pollAudioLevel = async (config: PollAudioLevelConfig) => {
    if (!audioContext) {
        return;
    }

    // Due to browsers' autoplay policy, the AudioContext is only active after
    // the user has interacted with your app, after which the Promise returned
    // here is resolved.
    try {
        await audioContext.resume();
    } catch (err) {
        config.onError('Error resuming AudioContext', err);
        return;
    }

    function rootMeanSquare(samples: Uint8Array) {
        const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
        return Math.sqrt(sumSq / samples.length);
    }

    // Create an analyser to access the raw audio samples from the microphone.
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 1024;
    analyser.smoothingTimeConstant = 0.5;

    // Connect the LocalAudioTrack's media source to the analyser.
    const stream = new MediaStream([config.track]);
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const samples: Uint8Array = new Uint8Array(analyser.frequencyBinCount);
    let level: number | null = null;

    // Periodically calculate the audio level from the captured samples,
    // and if changed, call the callback with the new audio level.
    const checkLevel = () => {
        analyser.getByteFrequencyData(samples);
        const rms = rootMeanSquare(samples);
        const log2Rms = rms && Math.log2(rms);

        // Audio level ranges from 0 (silence) to 10 (loudest).
        const newLevel = Math.ceil((10 * log2Rms) / 8);

        if (level !== newLevel) {
            level = newLevel;
            config.onLevelChanged(level);
        }

        // Continue calculating the level only if the audio track is live.
        if (config.track.readyState === 'live') {
            requestAnimationFrame(checkLevel);
        } else {
            requestAnimationFrame(() => config.onLevelChanged(0));
        }
    };

    requestAnimationFrame(checkLevel);
};
