/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemArSessionStatus
 */
export interface WallItemArSessionStatus {
    /**
     * 
     * @type {string}
     * @memberof WallItemArSessionStatus
     */
    cameraTrackingStatus?: WallItemArSessionStatusCameraTrackingStatus;
    /**
     * 
     * @type {string}
     * @memberof WallItemArSessionStatus
     */
    cameraTrackingPausedReason?: WallItemArSessionStatusCameraTrackingPausedReason;
    /**
     * 
     * @type {string}
     * @memberof WallItemArSessionStatus
     */
    worldMappingStatus?: WallItemArSessionStatusWorldMappingStatus;
}

export function WallItemArSessionStatusFromJSON(json: any): WallItemArSessionStatus {
    return WallItemArSessionStatusFromJSONTyped(json, false);
}

export function WallItemArSessionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArSessionStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cameraTrackingStatus': !exists(json, 'camera_tracking_status') ? undefined : json['camera_tracking_status'],
        'cameraTrackingPausedReason': !exists(json, 'camera_tracking_paused_reason') ? undefined : json['camera_tracking_paused_reason'],
        'worldMappingStatus': !exists(json, 'world_mapping_status') ? undefined : json['world_mapping_status'],
    };
}

export function WallItemArSessionStatusToJSON(value?: WallItemArSessionStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'camera_tracking_status': value.cameraTrackingStatus,
        'camera_tracking_paused_reason': value.cameraTrackingPausedReason,
        'world_mapping_status': value.worldMappingStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArSessionStatusCameraTrackingStatus {
    STOPPED = 'CAMERA_TRACKING_STATUS_STOPPED',
    NORMAL = 'CAMERA_TRACKING_STATUS_NORMAL',
    PAUSED = 'CAMERA_TRACKING_STATUS_PAUSED'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArSessionStatusCameraTrackingPausedReason {
    UNKNOWN = 'CAMERA_TRACKING_PAUSED_REASON_UNKNOWN',
    INITIALIZING = 'CAMERA_TRACKING_PAUSED_REASON_INITIALIZING',
    RELOCALIZING = 'CAMERA_TRACKING_PAUSED_REASON_RELOCALIZING',
    EXCESSIVEMOTION = 'CAMERA_TRACKING_PAUSED_REASON_EXCESSIVE_MOTION',
    INSUFFICIENTFEATURES = 'CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_FEATURES',
    INSUFFICIENTLIGHT = 'CAMERA_TRACKING_PAUSED_REASON_INSUFFICIENT_LIGHT',
    CAMERAUNAVAILABLE = 'CAMERA_TRACKING_PAUSED_REASON_CAMERA_UNAVAILABLE',
    BADSTATE = 'CAMERA_TRACKING_PAUSED_REASON_BAD_STATE'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArSessionStatusWorldMappingStatus {
    UNKNOWN = 'WORLD_MAPPING_STATUS_UNKNOWN',
    INITIALIZING = 'WORLD_MAPPING_STATUS_INITIALIZING',
    LIMITED = 'WORLD_MAPPING_STATUS_LIMITED',
    EXTENDING = 'WORLD_MAPPING_STATUS_EXTENDING',
    MAPPED = 'WORLD_MAPPING_STATUS_MAPPED'
}


