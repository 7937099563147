/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSsoProviderOidcExplicitEndpoints
 */
export interface StreemApiSsoProviderOidcExplicitEndpoints {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidcExplicitEndpoints
     */
    authorization?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidcExplicitEndpoints
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidcExplicitEndpoints
     */
    userInfo?: string;
}

export function StreemApiSsoProviderOidcExplicitEndpointsFromJSON(json: any): StreemApiSsoProviderOidcExplicitEndpoints {
    return StreemApiSsoProviderOidcExplicitEndpointsFromJSONTyped(json, false);
}

export function StreemApiSsoProviderOidcExplicitEndpointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSsoProviderOidcExplicitEndpoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorization': !exists(json, 'authorization') ? undefined : json['authorization'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'userInfo': !exists(json, 'user_info') ? undefined : json['user_info'],
    };
}

export function StreemApiSsoProviderOidcExplicitEndpointsToJSON(value?: StreemApiSsoProviderOidcExplicitEndpoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorization': value.authorization,
        'token': value.token,
        'user_info': value.userInfo,
    };
}


