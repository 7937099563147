/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufListValue,
    GoogleProtobufListValueFromJSON,
    GoogleProtobufListValueFromJSONTyped,
    GoogleProtobufListValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface GoogleProtobufValue
 */
export interface GoogleProtobufValue {
    /**
     * 
     * @type {string}
     * @memberof GoogleProtobufValue
     */
    nullValue?: GoogleProtobufValueNullValue;
    /**
     * 
     * @type {number}
     * @memberof GoogleProtobufValue
     */
    numberValue?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleProtobufValue
     */
    stringValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GoogleProtobufValue
     */
    boolValue?: boolean;
    /**
     * 
     * @type {object}
     * @memberof GoogleProtobufValue
     */
    structValue?: object;
    /**
     * 
     * @type {GoogleProtobufListValue}
     * @memberof GoogleProtobufValue
     */
    listValue?: GoogleProtobufListValue;
}

export function GoogleProtobufValueFromJSON(json: any): GoogleProtobufValue {
    return GoogleProtobufValueFromJSONTyped(json, false);
}

export function GoogleProtobufValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleProtobufValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nullValue': !exists(json, 'null_value') ? undefined : json['null_value'],
        'numberValue': !exists(json, 'number_value') ? undefined : json['number_value'],
        'stringValue': !exists(json, 'string_value') ? undefined : json['string_value'],
        'boolValue': !exists(json, 'bool_value') ? undefined : json['bool_value'],
        'structValue': !exists(json, 'struct_value') ? undefined : json['struct_value'],
        'listValue': !exists(json, 'list_value') ? undefined : GoogleProtobufListValueFromJSON(json['list_value']),
    };
}

export function GoogleProtobufValueToJSON(value?: GoogleProtobufValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'null_value': value.nullValue,
        'number_value': value.numberValue,
        'string_value': value.stringValue,
        'bool_value': value.boolValue,
        'struct_value': value.structValue,
        'list_value': GoogleProtobufListValueToJSON(value.listValue),
    };
}

/**
* @export
* @enum {string}
*/
export enum GoogleProtobufValueNullValue {
    NULLVALUE = 'NULL_VALUE'
}


