/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListMeshesResponse
 */
export interface StreemApiListMeshesResponse {
    /**
     * 
     * @type {Array<StreemApiArtifact>}
     * @memberof StreemApiListMeshesResponse
     */
    meshArtifacts?: Array<StreemApiArtifact>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListMeshesResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListMeshesResponse
     */
    totalSize?: number;
}

export function StreemApiListMeshesResponseFromJSON(json: any): StreemApiListMeshesResponse {
    return StreemApiListMeshesResponseFromJSONTyped(json, false);
}

export function StreemApiListMeshesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListMeshesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meshArtifacts': !exists(json, 'mesh_artifacts') ? undefined : ((json['mesh_artifacts'] as Array<any>).map(StreemApiArtifactFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListMeshesResponseToJSON(value?: StreemApiListMeshesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mesh_artifacts': value.meshArtifacts === undefined ? undefined : ((value.meshArtifacts as Array<any>).map(StreemApiArtifactToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


