/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiRefreshEmbedReportsSessionResponse
 */
export interface StreemApiRefreshEmbedReportsSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRefreshEmbedReportsSessionResponse
     */
    apiToken?: string;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiRefreshEmbedReportsSessionResponse
     */
    apiTokenTtl?: GoogleProtobufDuration;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRefreshEmbedReportsSessionResponse
     */
    navigationToken?: string;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiRefreshEmbedReportsSessionResponse
     */
    navigationTokenTtl?: GoogleProtobufDuration;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiRefreshEmbedReportsSessionResponse
     */
    sessionReferenceTokenTtl?: GoogleProtobufDuration;
}

export function StreemApiRefreshEmbedReportsSessionResponseFromJSON(json: any): StreemApiRefreshEmbedReportsSessionResponse {
    return StreemApiRefreshEmbedReportsSessionResponseFromJSONTyped(json, false);
}

export function StreemApiRefreshEmbedReportsSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRefreshEmbedReportsSessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiToken': !exists(json, 'api_token') ? undefined : json['api_token'],
        'apiTokenTtl': !exists(json, 'api_token_ttl') ? undefined : GoogleProtobufDurationFromJSON(json['api_token_ttl']),
        'navigationToken': !exists(json, 'navigation_token') ? undefined : json['navigation_token'],
        'navigationTokenTtl': !exists(json, 'navigation_token_ttl') ? undefined : GoogleProtobufDurationFromJSON(json['navigation_token_ttl']),
        'sessionReferenceTokenTtl': !exists(json, 'session_reference_token_ttl') ? undefined : GoogleProtobufDurationFromJSON(json['session_reference_token_ttl']),
    };
}

export function StreemApiRefreshEmbedReportsSessionResponseToJSON(value?: StreemApiRefreshEmbedReportsSessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'api_token': value.apiToken,
        'api_token_ttl': GoogleProtobufDurationToJSON(value.apiTokenTtl),
        'navigation_token': value.navigationToken,
        'navigation_token_ttl': GoogleProtobufDurationToJSON(value.navigationTokenTtl),
        'session_reference_token_ttl': GoogleProtobufDurationToJSON(value.sessionReferenceTokenTtl),
    };
}


