/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiGrantTokenFromInvitationRequest
 */
export interface StreemApiGrantTokenFromInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenFromInvitationRequest
     */
    invitationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenFromInvitationRequest
     */
    appSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGrantTokenFromInvitationRequest
     */
    resource?: string;
}

export function StreemApiGrantTokenFromInvitationRequestFromJSON(json: any): StreemApiGrantTokenFromInvitationRequest {
    return StreemApiGrantTokenFromInvitationRequestFromJSONTyped(json, false);
}

export function StreemApiGrantTokenFromInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGrantTokenFromInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invitationCode': !exists(json, 'invitation_code') ? undefined : json['invitation_code'],
        'appSid': !exists(json, 'app_sid') ? undefined : json['app_sid'],
        'resource': !exists(json, 'resource') ? undefined : json['resource'],
    };
}

export function StreemApiGrantTokenFromInvitationRequestToJSON(value?: StreemApiGrantTokenFromInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invitation_code': value.invitationCode,
        'app_sid': value.appSid,
        'resource': value.resource,
    };
}


