/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiBatchCreateCompanyResponseFailure,
    StreemApiBatchCreateCompanyResponseFailureFromJSON,
    StreemApiBatchCreateCompanyResponseFailureFromJSONTyped,
    StreemApiBatchCreateCompanyResponseFailureToJSON,
    StreemApiCreateCompanyResponse,
    StreemApiCreateCompanyResponseFromJSON,
    StreemApiCreateCompanyResponseFromJSONTyped,
    StreemApiCreateCompanyResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiBatchCreateCompanyResponse
 */
export interface StreemApiBatchCreateCompanyResponse {
    /**
     * 
     * @type {Array<StreemApiCreateCompanyResponse>}
     * @memberof StreemApiBatchCreateCompanyResponse
     */
    created?: Array<StreemApiCreateCompanyResponse>;
    /**
     * 
     * @type {Array<StreemApiBatchCreateCompanyResponseFailure>}
     * @memberof StreemApiBatchCreateCompanyResponse
     */
    failed?: Array<StreemApiBatchCreateCompanyResponseFailure>;
}

export function StreemApiBatchCreateCompanyResponseFromJSON(json: any): StreemApiBatchCreateCompanyResponse {
    return StreemApiBatchCreateCompanyResponseFromJSONTyped(json, false);
}

export function StreemApiBatchCreateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiBatchCreateCompanyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : ((json['created'] as Array<any>).map(StreemApiCreateCompanyResponseFromJSON)),
        'failed': !exists(json, 'failed') ? undefined : ((json['failed'] as Array<any>).map(StreemApiBatchCreateCompanyResponseFailureFromJSON)),
    };
}

export function StreemApiBatchCreateCompanyResponseToJSON(value?: StreemApiBatchCreateCompanyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : ((value.created as Array<any>).map(StreemApiCreateCompanyResponseToJSON)),
        'failed': value.failed === undefined ? undefined : ((value.failed as Array<any>).map(StreemApiBatchCreateCompanyResponseFailureToJSON)),
    };
}


