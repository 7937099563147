/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUpdateUserRequest
 */
export interface StreemApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserRequest
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserRequest
     */
    bio?: string;
}

export function StreemApiUpdateUserRequestFromJSON(json: any): StreemApiUpdateUserRequest {
    return StreemApiUpdateUserRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'bio': !exists(json, 'bio') ? undefined : json['bio'],
    };
}

export function StreemApiUpdateUserRequestToJSON(value?: StreemApiUpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'avatar_url': value.avatarUrl,
        'bio': value.bio,
    };
}


