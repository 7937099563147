/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LightAmbient
 */
export interface LightAmbient {
    /**
     * 
     * @type {number}
     * @memberof LightAmbient
     */
    temperatureKelvin?: number;
    /**
     * 
     * @type {number}
     * @memberof LightAmbient
     */
    intensityLumens?: number;
}

export function LightAmbientFromJSON(json: any): LightAmbient {
    return LightAmbientFromJSONTyped(json, false);
}

export function LightAmbientFromJSONTyped(json: any, ignoreDiscriminator: boolean): LightAmbient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'temperatureKelvin': !exists(json, 'temperature_kelvin') ? undefined : json['temperature_kelvin'],
        'intensityLumens': !exists(json, 'intensity_lumens') ? undefined : json['intensity_lumens'],
    };
}

export function LightAmbientToJSON(value?: LightAmbient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'temperature_kelvin': value.temperatureKelvin,
        'intensity_lumens': value.intensityLumens,
    };
}


