/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSearchCompaniesForUserEmailRequest
 */
export interface StreemApiSearchCompaniesForUserEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    companyIsActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    userIsActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    userIsExpert?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    userIsTypeLogin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSearchCompaniesForUserEmailRequest
     */
    switchWorkspacesEnabled?: boolean;
}

export function StreemApiSearchCompaniesForUserEmailRequestFromJSON(json: any): StreemApiSearchCompaniesForUserEmailRequest {
    return StreemApiSearchCompaniesForUserEmailRequestFromJSONTyped(json, false);
}

export function StreemApiSearchCompaniesForUserEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSearchCompaniesForUserEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'companyIsActive': !exists(json, 'company_is_active') ? undefined : json['company_is_active'],
        'userIsActive': !exists(json, 'user_is_active') ? undefined : json['user_is_active'],
        'userIsExpert': !exists(json, 'user_is_expert') ? undefined : json['user_is_expert'],
        'userIsTypeLogin': !exists(json, 'user_is_type_login') ? undefined : json['user_is_type_login'],
        'switchWorkspacesEnabled': !exists(json, 'switch_workspaces_enabled') ? undefined : json['switch_workspaces_enabled'],
    };
}

export function StreemApiSearchCompaniesForUserEmailRequestToJSON(value?: StreemApiSearchCompaniesForUserEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'company_is_active': value.companyIsActive,
        'user_is_active': value.userIsActive,
        'user_is_expert': value.userIsExpert,
        'user_is_type_login': value.userIsTypeLogin,
        'switch_workspaces_enabled': value.switchWorkspacesEnabled,
    };
}


