import { useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function VideoNotSupportedPage() {
    useEffect(() => {
        appLogger.info('User arrived at Video Not Supported Page');
    }, []);

    return (
        <ErrorBox
            testid="video-not-supported"
            headerText="Video Not Supported"
            messageText="It looks like your browser does not support WebRTC which is required to use video.
        Please open your invite link in another browser."
        />
    );
}
