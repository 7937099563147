/**
 * This utility function assumes that there may be multiple URL hash params joined with '&'
 * For example: https://zarya.com/embed#token=123&context=abc
 *
 * @param key string The hash param key
 * @returns string: The hash param value if it exists or null if it does not
 */
export function getHashParamValue(key: string): string | null {
    const hashParamMatch = new RegExp(`${key}=(.+)`).exec(window.location.hash);
    if (hashParamMatch) {
        return hashParamMatch[1]?.split('&')[0];
    }
    return null;
}
