type ErrorType = new (message: string, ...args: any[]) => Error;

/**
 * Throw an error if the condition fails
 */
export function invariant(condition: any, message = 'no reason specified', ErrorClass: ErrorType = Error): asserts condition {
    if (!condition) {
        throw new ErrorClass(`Invariant failed: ${message}`);
    }
}
