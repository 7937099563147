/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiDetectionBoundingBox
 */
export interface StreemApiDetectionBoundingBox {
    /**
     * 
     * @type {number}
     * @memberof StreemApiDetectionBoundingBox
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDetectionBoundingBox
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDetectionBoundingBox
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDetectionBoundingBox
     */
    height?: number;
}

export function StreemApiDetectionBoundingBoxFromJSON(json: any): StreemApiDetectionBoundingBox {
    return StreemApiDetectionBoundingBoxFromJSONTyped(json, false);
}

export function StreemApiDetectionBoundingBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiDetectionBoundingBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function StreemApiDetectionBoundingBoxToJSON(value?: StreemApiDetectionBoundingBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
        'width': value.width,
        'height': value.height,
    };
}


