/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Model3dAnimation
 */
export interface Model3dAnimation {
    /**
     * 
     * @type {boolean}
     * @memberof Model3dAnimation
     */
    autoStart?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Model3dAnimation
     */
    animationIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof Model3dAnimation
     */
    runCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Model3dAnimation
     */
    delayMs?: number;
    /**
     * 
     * @type {number}
     * @memberof Model3dAnimation
     */
    durationMs?: number;
}

export function Model3dAnimationFromJSON(json: any): Model3dAnimation {
    return Model3dAnimationFromJSONTyped(json, false);
}

export function Model3dAnimationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model3dAnimation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoStart': !exists(json, 'auto_start') ? undefined : json['auto_start'],
        'animationIndex': !exists(json, 'animation_index') ? undefined : json['animation_index'],
        'runCount': !exists(json, 'run_count') ? undefined : json['run_count'],
        'delayMs': !exists(json, 'delay_ms') ? undefined : json['delay_ms'],
        'durationMs': !exists(json, 'duration_ms') ? undefined : json['duration_ms'],
    };
}

export function Model3dAnimationToJSON(value?: Model3dAnimation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_start': value.autoStart,
        'animation_index': value.animationIndex,
        'run_count': value.runCount,
        'delay_ms': value.delayMs,
        'duration_ms': value.durationMs,
    };
}


