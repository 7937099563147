/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiAggregateRoomMetricsForCompanyRequest,
    StreemApiAggregateRoomMetricsForCompanyRequestFromJSON,
    StreemApiAggregateRoomMetricsForCompanyRequestToJSON,
    StreemApiAggregateRoomMetricsForCompanyResponse,
    StreemApiAggregateRoomMetricsForCompanyResponseFromJSON,
    StreemApiAggregateRoomMetricsForCompanyResponseToJSON,
    StreemApiRecordEventsRequest,
    StreemApiRecordEventsRequestFromJSON,
    StreemApiRecordEventsRequestToJSON,
    StreemApiRecordEventsResponse,
    StreemApiRecordEventsResponseFromJSON,
    StreemApiRecordEventsResponseToJSON,
} from '../models';

export interface AggregateRoomMetricsForCompanyRequest {
    companySid: string;
    streemApiAggregateRoomMetricsForCompanyRequest?: StreemApiAggregateRoomMetricsForCompanyRequest;
}

export interface RecordEventsRequest {
    streemApiRecordEventsRequest?: StreemApiRecordEventsRequest;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     * Aggregates room metrics based on various filters for a company.
     * Aggregate Room Metrics for a Company
     */
    async aggregateRoomMetricsForCompanyRaw(requestParameters: AggregateRoomMetricsForCompanyRequest): Promise<runtime.ApiResponse<StreemApiAggregateRoomMetricsForCompanyResponse>> {
        if (requestParameters.companySid === null || requestParameters.companySid === undefined) {
            throw new runtime.RequiredError('companySid','Required parameter requestParameters.companySid was null or undefined when calling aggregateRoomMetricsForCompany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/analytics/companies/{company_sid}/room-metrics/~aggregate`.replace(`{${"company_sid"}}`, encodeURIComponent(String(requestParameters.companySid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiAggregateRoomMetricsForCompanyRequestToJSON(requestParameters.streemApiAggregateRoomMetricsForCompanyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiAggregateRoomMetricsForCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Aggregates room metrics based on various filters for a company.
     * Aggregate Room Metrics for a Company
     */
    async aggregateRoomMetricsForCompany(companySid: string, streemApiAggregateRoomMetricsForCompanyRequest?: StreemApiAggregateRoomMetricsForCompanyRequest): Promise<StreemApiAggregateRoomMetricsForCompanyResponse> {
        const response = await this.aggregateRoomMetricsForCompanyRaw({ companySid: companySid, streemApiAggregateRoomMetricsForCompanyRequest: streemApiAggregateRoomMetricsForCompanyRequest });
        return await response.value();
    }

    /**
     * Forwards analytic events to AWS Firehose, to be further processed in Snowflake
     * Record new analytic events
     */
    async recordEventsRaw(requestParameters: RecordEventsRequest): Promise<runtime.ApiResponse<StreemApiRecordEventsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/analytics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiRecordEventsRequestToJSON(requestParameters.streemApiRecordEventsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiRecordEventsResponseFromJSON(jsonValue));
    }

    /**
     * Forwards analytic events to AWS Firehose, to be further processed in Snowflake
     * Record new analytic events
     */
    async recordEvents(streemApiRecordEventsRequest?: StreemApiRecordEventsRequest): Promise<StreemApiRecordEventsResponse> {
        const response = await this.recordEventsRaw({ streemApiRecordEventsRequest: streemApiRecordEventsRequest });
        return await response.value();
    }

}
