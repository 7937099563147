/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiWallWallItemEntry
 */
export interface StreemApiWallWallItemEntry {
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntry
     */
    entryId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntry
     */
    wallId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntry
     */
    wallType?: StreemApiWallWallItemEntryWallType;
    /**
     * 
     * @type {number}
     * @memberof StreemApiWallWallItemEntry
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWallWallItemEntry
     */
    wallItemEncoded?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiWallWallItemEntry
     */
    expiresAt?: Date;
}

export function StreemApiWallWallItemEntryFromJSON(json: any): StreemApiWallWallItemEntry {
    return StreemApiWallWallItemEntryFromJSONTyped(json, false);
}

export function StreemApiWallWallItemEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWallWallItemEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entryId': !exists(json, 'entry_id') ? undefined : json['entry_id'],
        'wallId': !exists(json, 'wall_id') ? undefined : json['wall_id'],
        'wallType': !exists(json, 'wall_type') ? undefined : json['wall_type'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'wallItemEncoded': !exists(json, 'wall_item_encoded') ? undefined : json['wall_item_encoded'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
    };
}

export function StreemApiWallWallItemEntryToJSON(value?: StreemApiWallWallItemEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry_id': value.entryId,
        'wall_id': value.wallId,
        'wall_type': value.wallType,
        'version': value.version,
        'wall_item_encoded': value.wallItemEncoded,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiWallWallItemEntryWallType {
    ROOM = 'WALL_TYPE_ROOM',
    USER = 'WALL_TYPE_USER',
    INVALID = 'WALL_TYPE_INVALID'
}


