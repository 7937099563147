/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoomDetail,
    StreemApiRoomDetailFromJSON,
    StreemApiRoomDetailFromJSONTyped,
    StreemApiRoomDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiUpdateRoomRequest
 */
export interface StreemApiUpdateRoomRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateRoomRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {Array<StreemApiRoomDetail>}
     * @memberof StreemApiUpdateRoomRequest
     */
    details?: Array<StreemApiRoomDetail>;
}

export function StreemApiUpdateRoomRequestFromJSON(json: any): StreemApiUpdateRoomRequest {
    return StreemApiUpdateRoomRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateRoomRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateRoomRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(StreemApiRoomDetailFromJSON)),
    };
}

export function StreemApiUpdateRoomRequestToJSON(value?: StreemApiUpdateRoomRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference_id': value.referenceId,
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(StreemApiRoomDetailToJSON)),
    };
}


