/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateCompanyRequest
 */
export interface StreemApiCreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRequest
     */
    parentCompanySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRequest
     */
    ownerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyRequest
     */
    ownerName?: string;
}

export function StreemApiCreateCompanyRequestFromJSON(json: any): StreemApiCreateCompanyRequest {
    return StreemApiCreateCompanyRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parentCompanySid': !exists(json, 'parent_company_sid') ? undefined : json['parent_company_sid'],
        'ownerEmail': !exists(json, 'owner_email') ? undefined : json['owner_email'],
        'ownerName': !exists(json, 'owner_name') ? undefined : json['owner_name'],
    };
}

export function StreemApiCreateCompanyRequestToJSON(value?: StreemApiCreateCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'parent_company_sid': value.parentCompanySid,
        'owner_email': value.ownerEmail,
        'owner_name': value.ownerName,
    };
}


