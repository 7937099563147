/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAttendeeFeatures,
    StreemApiAttendeeFeaturesFromJSON,
    StreemApiAttendeeFeaturesFromJSONTyped,
    StreemApiAttendeeFeaturesToJSON,
    StreemApiMeetingFeaturesAudio,
    StreemApiMeetingFeaturesAudioFromJSON,
    StreemApiMeetingFeaturesAudioFromJSONTyped,
    StreemApiMeetingFeaturesAudioToJSON,
    StreemApiMeetingFeaturesContent,
    StreemApiMeetingFeaturesContentFromJSON,
    StreemApiMeetingFeaturesContentFromJSONTyped,
    StreemApiMeetingFeaturesContentToJSON,
    StreemApiMeetingFeaturesVideo,
    StreemApiMeetingFeaturesVideoFromJSON,
    StreemApiMeetingFeaturesVideoFromJSONTyped,
    StreemApiMeetingFeaturesVideoToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiMeetingFeatures
 */
export interface StreemApiMeetingFeatures {
    /**
     * 
     * @type {StreemApiAttendeeFeatures}
     * @memberof StreemApiMeetingFeatures
     */
    attendee?: StreemApiAttendeeFeatures;
    /**
     * 
     * @type {StreemApiMeetingFeaturesAudio}
     * @memberof StreemApiMeetingFeatures
     */
    audio?: StreemApiMeetingFeaturesAudio;
    /**
     * 
     * @type {StreemApiMeetingFeaturesContent}
     * @memberof StreemApiMeetingFeatures
     */
    content?: StreemApiMeetingFeaturesContent;
    /**
     * 
     * @type {StreemApiMeetingFeaturesVideo}
     * @memberof StreemApiMeetingFeatures
     */
    video?: StreemApiMeetingFeaturesVideo;
}

export function StreemApiMeetingFeaturesFromJSON(json: any): StreemApiMeetingFeatures {
    return StreemApiMeetingFeaturesFromJSONTyped(json, false);
}

export function StreemApiMeetingFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiMeetingFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attendee': !exists(json, 'attendee') ? undefined : StreemApiAttendeeFeaturesFromJSON(json['attendee']),
        'audio': !exists(json, 'audio') ? undefined : StreemApiMeetingFeaturesAudioFromJSON(json['audio']),
        'content': !exists(json, 'content') ? undefined : StreemApiMeetingFeaturesContentFromJSON(json['content']),
        'video': !exists(json, 'video') ? undefined : StreemApiMeetingFeaturesVideoFromJSON(json['video']),
    };
}

export function StreemApiMeetingFeaturesToJSON(value?: StreemApiMeetingFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attendee': StreemApiAttendeeFeaturesToJSON(value.attendee),
        'audio': StreemApiMeetingFeaturesAudioToJSON(value.audio),
        'content': StreemApiMeetingFeaturesContentToJSON(value.content),
        'video': StreemApiMeetingFeaturesVideoToJSON(value.video),
    };
}


