import React, { useState, useEffect } from 'react';
import { styled, BaseInput, Button, Box, Column, Theme, Label, Header } from '@streem/ui-react';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from './loading_page';
import { InvalidLinkPage } from './invalid_link_page';
import { PoweredByStreemFooter } from '../components/powered_by_streem_footer';
import { formatInviteCode } from '../util/format_invite_code';
import appLogger from '../util/app_logger';
import { useInviteCode } from '../hooks/use_invite_code';

const log = appLogger.extend('InviteCodePage');

export interface InviteCodePageProps {
    configuredPackages: boolean;
    getCompanyRegistrationError: boolean;
}

export const InviteCodePage: React.FC<InviteCodePageProps> = ({
    configuredPackages,
    getCompanyRegistrationError,
}: InviteCodePageProps) => {
    const [inviteCode, setInviteCode] = useState('');
    const [inputFocused, setInputFocused] = useState(false);
    const history = useHistory();
    const searchParamInviteCode = useInviteCode();

    useEffect(() => {
        // Analytics events are not set up at this point
        // Relying on logs to track views of this page
        log.info('User arrived at Invite Code Page');

        // Some customers still use the old invite url format
        if (searchParamInviteCode) {
            history.push(`/i/${searchParamInviteCode}`);
        }
    }, [searchParamInviteCode, history]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = formatInviteCode(e.target.value);
        setInviteCode(formatted);
    };
    const submit = (e: React.FormEvent) => {
        e.preventDefault();
        const unformatted = inviteCode.replace(/\D/g, '');
        history.push(`/i/${unformatted}`, { manuallyEnteredInviteCode: true });
    };

    if (getCompanyRegistrationError) {
        return <InvalidLinkPage />;
    }

    if (!configuredPackages) {
        return <LoadingPage testid="invite-code-loading" message="Loading..." />;
    }

    return (
        <Container width={['90%', '45%']}>
            <form onSubmit={submit}>
                <Column minHeight="150px">
                    <Box mb={4}>
                        <Header data-testid="join-call-header">Join Call</Header>
                    </Box>
                    <Label
                        size="medium"
                        as="label"
                        htmlFor="invite code"
                        color={inputFocused ? 'azure' : 'medium'}
                    >
                        Invitation Code
                    </Label>
                    <Input
                        inputMode="numeric"
                        mt={2}
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        placeholder="123-456-789"
                        name="invite code"
                        value={inviteCode}
                        onChange={onChange}
                        style={{
                            borderColor: inputFocused ? Theme.colors.azure : Theme.colors.black,
                        }}
                        data-testid="invite-code-input"
                    />
                </Column>
                <Button
                    type="submit"
                    width="100%"
                    disabled={inviteCode.replace(/\D/g, '').length !== 9}
                    data-testid="join-call-button"
                >
                    Join
                </Button>
            </form>
            <PoweredByStreemFooter />
        </Container>
    );
};

const Container = styled(Box)({
    margin: '10vh auto',
});

const Input = styled(BaseInput)`
    height: 40px;
    border: 1px solid;
    border-radius: 8px;
    font-size: 16px;
    line-height: 18px;
    padding: 0px 10px;
`;
