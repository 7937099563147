/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiBatchCreateUserRequest
 */
export interface StreemApiBatchCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiBatchCreateUserRequest
     */
    csvData?: string;
}

export function StreemApiBatchCreateUserRequestFromJSON(json: any): StreemApiBatchCreateUserRequest {
    return StreemApiBatchCreateUserRequestFromJSONTyped(json, false);
}

export function StreemApiBatchCreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiBatchCreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'csvData': !exists(json, 'csv_data') ? undefined : json['csv_data'],
    };
}

export function StreemApiBatchCreateUserRequestToJSON(value?: StreemApiBatchCreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'csv_data': value.csvData,
    };
}


