/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufValue,
    GoogleProtobufValueFromJSON,
    GoogleProtobufValueFromJSONTyped,
    GoogleProtobufValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface GoogleProtobufListValue
 */
export interface GoogleProtobufListValue {
    /**
     * 
     * @type {Array<GoogleProtobufValue>}
     * @memberof GoogleProtobufListValue
     */
    values?: Array<GoogleProtobufValue>;
}

export function GoogleProtobufListValueFromJSON(json: any): GoogleProtobufListValue {
    return GoogleProtobufListValueFromJSONTyped(json, false);
}

export function GoogleProtobufListValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleProtobufListValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(GoogleProtobufValueFromJSON)),
    };
}

export function GoogleProtobufListValueToJSON(value?: GoogleProtobufListValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(GoogleProtobufValueToJSON)),
    };
}


