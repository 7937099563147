/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiDevice
 */
export interface StreemApiDevice {
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    appSid?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDevice
     */
    apiLevel?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    applicationName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    appVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    buildNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    bundleId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    deviceCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    deviceLocale?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    deviceName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    manufacturer?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDevice
     */
    maxMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    modelCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    systemName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    systemVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    timezone?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDevice
     */
    totalMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDevice
     */
    vendorId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiDevice
     */
    supportedVideoCodecs?: Array<StreemApiDeviceSupportedVideoCodecs>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiDevice
     */
    supportedVideoPlatforms?: Array<StreemApiDeviceSupportedVideoPlatforms>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiDevice
     */
    supportedWallPlatforms?: Array<StreemApiDeviceSupportedWallPlatforms>;
}

export function StreemApiDeviceFromJSON(json: any): StreemApiDevice {
    return StreemApiDeviceFromJSONTyped(json, false);
}

export function StreemApiDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'appSid': !exists(json, 'app_sid') ? undefined : json['app_sid'],
        'apiLevel': !exists(json, 'api_level') ? undefined : json['api_level'],
        'applicationName': !exists(json, 'application_name') ? undefined : json['application_name'],
        'appVersion': !exists(json, 'app_version') ? undefined : json['app_version'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'buildNumber': !exists(json, 'build_number') ? undefined : json['build_number'],
        'bundleId': !exists(json, 'bundle_id') ? undefined : json['bundle_id'],
        'deviceCountry': !exists(json, 'device_country') ? undefined : json['device_country'],
        'deviceLocale': !exists(json, 'device_locale') ? undefined : json['device_locale'],
        'deviceName': !exists(json, 'device_name') ? undefined : json['device_name'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'maxMemory': !exists(json, 'max_memory') ? undefined : json['max_memory'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'modelCode': !exists(json, 'model_code') ? undefined : json['model_code'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'systemName': !exists(json, 'system_name') ? undefined : json['system_name'],
        'systemVersion': !exists(json, 'system_version') ? undefined : json['system_version'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'totalMemory': !exists(json, 'total_memory') ? undefined : json['total_memory'],
        'userAgent': !exists(json, 'user_agent') ? undefined : json['user_agent'],
        'vendorId': !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
        'supportedVideoCodecs': !exists(json, 'supported_video_codecs') ? undefined : json['supported_video_codecs'],
        'supportedVideoPlatforms': !exists(json, 'supported_video_platforms') ? undefined : json['supported_video_platforms'],
        'supportedWallPlatforms': !exists(json, 'supported_wall_platforms') ? undefined : json['supported_wall_platforms'],
    };
}

export function StreemApiDeviceToJSON(value?: StreemApiDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'app_sid': value.appSid,
        'api_level': value.apiLevel,
        'application_name': value.applicationName,
        'app_version': value.appVersion,
        'brand': value.brand,
        'build_number': value.buildNumber,
        'bundle_id': value.bundleId,
        'device_country': value.deviceCountry,
        'device_locale': value.deviceLocale,
        'device_name': value.deviceName,
        'manufacturer': value.manufacturer,
        'max_memory': value.maxMemory,
        'model': value.model,
        'model_code': value.modelCode,
        'platform': value.platform,
        'system_name': value.systemName,
        'system_version': value.systemVersion,
        'timezone': value.timezone,
        'total_memory': value.totalMemory,
        'user_agent': value.userAgent,
        'vendor_id': value.vendorId,
        'supported_video_codecs': value.supportedVideoCodecs,
        'supported_video_platforms': value.supportedVideoPlatforms,
        'supported_wall_platforms': value.supportedWallPlatforms,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiDeviceSupportedVideoCodecs {
    H264 = 'VIDEO_CODEC_H264',
    VP8 = 'VIDEO_CODEC_VP8',
    VP9 = 'VIDEO_CODEC_VP9',
    INVALID = 'VIDEO_CODEC_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiDeviceSupportedVideoPlatforms {
    TWILIO = 'VIDEO_PLATFORM_TWILIO',
    ARAAS = 'VIDEO_PLATFORM_ARAAS',
    CHIME = 'VIDEO_PLATFORM_CHIME',
    INVALID = 'VIDEO_PLATFORM_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiDeviceSupportedWallPlatforms {
    TWILIO = 'WALL_PLATFORM_TWILIO',
    STREEM = 'WALL_PLATFORM_STREEM',
    INVALID = 'WALL_PLATFORM_INVALID'
}


