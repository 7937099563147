/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiOAuthUrlProperties
 */
export interface StreemApiOAuthUrlProperties {
    /**
     * 
     * @type {string}
     * @memberof StreemApiOAuthUrlProperties
     */
    grantType?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiOAuthUrlProperties
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiOAuthUrlProperties
     */
    clientSecret?: string;
}

export function StreemApiOAuthUrlPropertiesFromJSON(json: any): StreemApiOAuthUrlProperties {
    return StreemApiOAuthUrlPropertiesFromJSONTyped(json, false);
}

export function StreemApiOAuthUrlPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiOAuthUrlProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantType': !exists(json, 'grant_type') ? undefined : json['grant_type'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}

export function StreemApiOAuthUrlPropertiesToJSON(value?: StreemApiOAuthUrlProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grant_type': value.grantType,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}


