/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
    StreemApiCallDetail,
    StreemApiCallDetailFromJSON,
    StreemApiCallDetailFromJSONTyped,
    StreemApiCallDetailToJSON,
    StreemApiGroup,
    StreemApiGroupFromJSON,
    StreemApiGroupFromJSONTyped,
    StreemApiGroupToJSON,
    StreemApiRoomDetail,
    StreemApiRoomDetailFromJSON,
    StreemApiRoomDetailFromJSONTyped,
    StreemApiRoomDetailToJSON,
    StreemApiRoomInvitation,
    StreemApiRoomInvitationFromJSON,
    StreemApiRoomInvitationFromJSONTyped,
    StreemApiRoomInvitationToJSON,
    StreemApiRoomParticipant,
    StreemApiRoomParticipantFromJSON,
    StreemApiRoomParticipantFromJSONTyped,
    StreemApiRoomParticipantToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiRoom
 */
export interface StreemApiRoom {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    createdByUserSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoom
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoom
     */
    openedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    preferredVideoCodec?: StreemApiRoomPreferredVideoCodec;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    status?: StreemApiRoomStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoom
     */
    closedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    twilioRoomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    referenceId?: string;
    /**
     * 
     * @type {Array<StreemApiRoomParticipant>}
     * @memberof StreemApiRoom
     */
    participants?: Array<StreemApiRoomParticipant>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    videoPlatform?: StreemApiRoomVideoPlatform;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiRoom
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoom
     */
    deletedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiRoom
     */
    encryptedWallItems?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    wallPlatform?: StreemApiRoomWallPlatform;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    integrationId?: string;
    /**
     * 
     * @type {Array<StreemApiRoomDetail>}
     * @memberof StreemApiRoom
     */
    details?: Array<StreemApiRoomDetail>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    reservationSid?: string;
    /**
     * 
     * @type {Array<StreemApiRoomInvitation>}
     * @memberof StreemApiRoom
     */
    roomInvitations?: Array<StreemApiRoomInvitation>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoom
     */
    chimeRoomSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoom
     */
    recordingStartedAt?: Date;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiRoom
     */
    callDuration?: GoogleProtobufDuration;
    /**
     * 
     * @type {StreemApiCallDetail}
     * @memberof StreemApiRoom
     */
    callDetail?: StreemApiCallDetail;
    /**
     * 
     * @type {StreemApiGroup}
     * @memberof StreemApiRoom
     */
    reservationGroup?: StreemApiGroup;
}

export function StreemApiRoomFromJSON(json: any): StreemApiRoom {
    return StreemApiRoomFromJSONTyped(json, false);
}

export function StreemApiRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRoom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'createdByUserSid': !exists(json, 'created_by_user_sid') ? undefined : json['created_by_user_sid'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'openedAt': !exists(json, 'opened_at') ? undefined : (new Date(json['opened_at'])),
        'preferredVideoCodec': !exists(json, 'preferred_video_codec') ? undefined : json['preferred_video_codec'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'closedAt': !exists(json, 'closed_at') ? undefined : (new Date(json['closed_at'])),
        'twilioRoomSid': !exists(json, 'twilio_room_sid') ? undefined : json['twilio_room_sid'],
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(StreemApiRoomParticipantFromJSON)),
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'videoPlatform': !exists(json, 'video_platform') ? undefined : json['video_platform'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
        'encryptedWallItems': !exists(json, 'encrypted_wall_items') ? undefined : json['encrypted_wall_items'],
        'wallPlatform': !exists(json, 'wall_platform') ? undefined : json['wall_platform'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(StreemApiRoomDetailFromJSON)),
        'reservationSid': !exists(json, 'reservation_sid') ? undefined : json['reservation_sid'],
        'roomInvitations': !exists(json, 'room_invitations') ? undefined : ((json['room_invitations'] as Array<any>).map(StreemApiRoomInvitationFromJSON)),
        'chimeRoomSid': !exists(json, 'chime_room_sid') ? undefined : json['chime_room_sid'],
        'recordingStartedAt': !exists(json, 'recording_started_at') ? undefined : (new Date(json['recording_started_at'])),
        'callDuration': !exists(json, 'call_duration') ? undefined : GoogleProtobufDurationFromJSON(json['call_duration']),
        'callDetail': !exists(json, 'call_detail') ? undefined : StreemApiCallDetailFromJSON(json['call_detail']),
        'reservationGroup': !exists(json, 'reservation_group') ? undefined : StreemApiGroupFromJSON(json['reservation_group']),
    };
}

export function StreemApiRoomToJSON(value?: StreemApiRoom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'created_by_user_sid': value.createdByUserSid,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'opened_at': value.openedAt === undefined ? undefined : (value.openedAt.toISOString()),
        'preferred_video_codec': value.preferredVideoCodec,
        'status': value.status,
        'closed_at': value.closedAt === undefined ? undefined : (value.closedAt.toISOString()),
        'twilio_room_sid': value.twilioRoomSid,
        'reference_id': value.referenceId,
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(StreemApiRoomParticipantToJSON)),
        'company_sid': value.companySid,
        'video_platform': value.videoPlatform,
        'deleted': value.deleted,
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'encrypted_wall_items': value.encryptedWallItems,
        'wall_platform': value.wallPlatform,
        'integration_id': value.integrationId,
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(StreemApiRoomDetailToJSON)),
        'reservation_sid': value.reservationSid,
        'room_invitations': value.roomInvitations === undefined ? undefined : ((value.roomInvitations as Array<any>).map(StreemApiRoomInvitationToJSON)),
        'chime_room_sid': value.chimeRoomSid,
        'recording_started_at': value.recordingStartedAt === undefined ? undefined : (value.recordingStartedAt.toISOString()),
        'call_duration': GoogleProtobufDurationToJSON(value.callDuration),
        'call_detail': StreemApiCallDetailToJSON(value.callDetail),
        'reservation_group': StreemApiGroupToJSON(value.reservationGroup),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiRoomPreferredVideoCodec {
    H264 = 'VIDEO_CODEC_H264',
    VP8 = 'VIDEO_CODEC_VP8',
    VP9 = 'VIDEO_CODEC_VP9',
    INVALID = 'VIDEO_CODEC_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiRoomStatus {
    OPEN = 'STATUS_OPEN',
    CLOSED = 'STATUS_CLOSED',
    INVALID = 'STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiRoomVideoPlatform {
    TWILIO = 'VIDEO_PLATFORM_TWILIO',
    ARAAS = 'VIDEO_PLATFORM_ARAAS',
    CHIME = 'VIDEO_PLATFORM_CHIME',
    INVALID = 'VIDEO_PLATFORM_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiRoomWallPlatform {
    TWILIO = 'WALL_PLATFORM_TWILIO',
    STREEM = 'WALL_PLATFORM_STREEM',
    INVALID = 'WALL_PLATFORM_INVALID'
}


