/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiGroupReservationDetail
 */
export interface StreemApiGroupReservationDetail {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservationDetail
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservationDetail
     */
    value?: string;
}

export function StreemApiGroupReservationDetailFromJSON(json: any): StreemApiGroupReservationDetail {
    return StreemApiGroupReservationDetailFromJSONTyped(json, false);
}

export function StreemApiGroupReservationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGroupReservationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function StreemApiGroupReservationDetailToJSON(value?: StreemApiGroupReservationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
    };
}


