/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArrowPresentation,
    ArrowPresentationFromJSON,
    ArrowPresentationFromJSONTyped,
    ArrowPresentationToJSON,
    MeasurePresentation,
    MeasurePresentationFromJSON,
    MeasurePresentationFromJSONTyped,
    MeasurePresentationToJSON,
    Model3dPresentation,
    Model3dPresentationFromJSON,
    Model3dPresentationFromJSONTyped,
    Model3dPresentationToJSON,
    PickListPresentation,
    PickListPresentationFromJSON,
    PickListPresentationFromJSONTyped,
    PickListPresentationToJSON,
    StreemApiTransform,
    StreemApiTransformFromJSON,
    StreemApiTransformFromJSONTyped,
    StreemApiTransformToJSON,
    TextPresentation,
    TextPresentationFromJSON,
    TextPresentationFromJSONTyped,
    TextPresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Presentation
 */
export interface Presentation {
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    id?: string;
    /**
     * 
     * @type {StreemApiTransform}
     * @memberof Presentation
     */
    transform?: StreemApiTransform;
    /**
     * 
     * @type {Model3dPresentation}
     * @memberof Presentation
     */
    model3d?: Model3dPresentation;
    /**
     * 
     * @type {TextPresentation}
     * @memberof Presentation
     */
    text?: TextPresentation;
    /**
     * 
     * @type {ArrowPresentation}
     * @memberof Presentation
     */
    arrow?: ArrowPresentation;
    /**
     * 
     * @type {PickListPresentation}
     * @memberof Presentation
     */
    pickList?: PickListPresentation;
    /**
     * 
     * @type {MeasurePresentation}
     * @memberof Presentation
     */
    measure?: MeasurePresentation;
}

export function PresentationFromJSON(json: any): Presentation {
    return PresentationFromJSONTyped(json, false);
}

export function PresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Presentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'transform': !exists(json, 'transform') ? undefined : StreemApiTransformFromJSON(json['transform']),
        'model3d': !exists(json, 'model3d') ? undefined : Model3dPresentationFromJSON(json['model3d']),
        'text': !exists(json, 'text') ? undefined : TextPresentationFromJSON(json['text']),
        'arrow': !exists(json, 'arrow') ? undefined : ArrowPresentationFromJSON(json['arrow']),
        'pickList': !exists(json, 'pick_list') ? undefined : PickListPresentationFromJSON(json['pick_list']),
        'measure': !exists(json, 'measure') ? undefined : MeasurePresentationFromJSON(json['measure']),
    };
}

export function PresentationToJSON(value?: Presentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'transform': StreemApiTransformToJSON(value.transform),
        'model3d': Model3dPresentationToJSON(value.model3d),
        'text': TextPresentationToJSON(value.text),
        'arrow': ArrowPresentationToJSON(value.arrow),
        'pick_list': PickListPresentationToJSON(value.pickList),
        'measure': MeasurePresentationToJSON(value.measure),
    };
}


