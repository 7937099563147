import React from 'react';
import { Box, Spinner } from '@streem/ui-react';
import { ErrorBox } from '../components/error_box';

interface LoadingPageProps {
    message: string;
    testid?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({ message, ...rest }) => {
    return <ErrorBox headerText={message} messageText={<CenteredSpinner />} {...rest} />;
};

const CenteredSpinner = () => {
    return (
        <Box style={{ textAlign: 'center' }}>
            <Spinner />
        </Box>
    );
};
