/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemUser
 */
export interface WallItemUser {
    /**
     * 
     * @type {string}
     * @memberof WallItemUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemUser
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemUser
     */
    phoneNumber?: string;
}

export function WallItemUserFromJSON(json: any): WallItemUser {
    return WallItemUserFromJSONTyped(json, false);
}

export function WallItemUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
    };
}

export function WallItemUserToJSON(value?: WallItemUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'phone_number': value.phoneNumber,
    };
}


