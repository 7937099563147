import { FC } from 'react';
import { detect } from 'detect-browser';
import * as DetectRTC from 'detectrtc';
import appLogger from '../util/app_logger';
import { validateBrowserAllowed } from '../util/validate_browser_allowed';
import { getIosVersionFromUserAgent } from '@streem/toolbox';
import { BrowserNotSupportedPage } from '../pages/browser_not_supported_page';
import { CookiesDisabledPage } from '../pages/cookies_disabled_page';
import { DesktopNotSupportedPage } from '../pages/desktop_not_supported_page';
import { VideoNotSupportedPage } from '../pages/video_not_supported_page';
import { IOSUpdateRequiredPage } from '../pages/ios_update_required_page';
import { config } from '../config';

const log = appLogger.extend('validate_browser_allowed');

export const ViewValidator: FC = ({ children }) => {
    if (!window.navigator.cookieEnabled) {
        log.info('Cookies disabled');
        return <CookiesDisabledPage />;
    }

    if (!isMobile() && !config.isDevelopment) {
        log.info('Desktop browser not supported, redirecting');
        return <DesktopNotSupportedPage />;
    }

    if (!validateBrowserAllowed()) {
        log.info('Invalid browser, redirecting');
        return <BrowserNotSupportedPage />;
    }

    if (!DetectRTC.isWebRTCSupported) {
        log.info('WebRTC not supported, redirecting');
        return <VideoNotSupportedPage />;
    }

    const isIOS = detect().os === 'iOS';
    const iOSVersion = isIOS ? getIosVersionFromUserAgent(navigator.userAgent) : null;
    // if between iOS 15.0.0 and 15.0.9
    if (iOSVersion && iOSVersion.major === 15 && iOSVersion.minor === 0) {
        const iOSVersionString = `${iOSVersion.major}.${iOSVersion.minor}.${iOSVersion.patch}`;
        if (config.isDevelopment) {
            console.error(
                `iOS ${iOSVersionString} detected. Set 'GPU Process: Canvas Rendering' to 'OFF' in Safari Experimental Settings to avoid total app annihilation.`,
            );
        }

        log.warn(`iOS ${iOSVersionString} version not supported. Showing OS Not Supported page`);
        return <IOSUpdateRequiredPage />;
    }

    return <>{children}</>;
};

function isMobile() {
    const userAgent = navigator.userAgent;
    const mobileMatch = userAgent.match(/Mobi/gi);
    // https://stackoverflow.com/a/58064481/7432692
    const iPadOS13Match = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    return !!(mobileMatch && mobileMatch[0]) || iPadOS13Match;
}
