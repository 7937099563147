/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAggregatedRoomMetricsMetrics
 */
export interface StreemApiAggregatedRoomMetricsMetrics {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    totalCallCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    missedCallCount?: number;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    avgCallDuration?: GoogleProtobufDuration;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    activeDuration?: GoogleProtobufDuration;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    avgCallCountPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAggregatedRoomMetricsMetrics
     */
    unavailabilityPercentage?: number;
}

export function StreemApiAggregatedRoomMetricsMetricsFromJSON(json: any): StreemApiAggregatedRoomMetricsMetrics {
    return StreemApiAggregatedRoomMetricsMetricsFromJSONTyped(json, false);
}

export function StreemApiAggregatedRoomMetricsMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAggregatedRoomMetricsMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCallCount': !exists(json, 'total_call_count') ? undefined : json['total_call_count'],
        'missedCallCount': !exists(json, 'missed_call_count') ? undefined : json['missed_call_count'],
        'avgCallDuration': !exists(json, 'avg_call_duration') ? undefined : GoogleProtobufDurationFromJSON(json['avg_call_duration']),
        'activeDuration': !exists(json, 'active_duration') ? undefined : GoogleProtobufDurationFromJSON(json['active_duration']),
        'avgCallCountPerHour': !exists(json, 'avg_call_count_per_hour') ? undefined : json['avg_call_count_per_hour'],
        'unavailabilityPercentage': !exists(json, 'unavailability_percentage') ? undefined : json['unavailability_percentage'],
    };
}

export function StreemApiAggregatedRoomMetricsMetricsToJSON(value?: StreemApiAggregatedRoomMetricsMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_call_count': value.totalCallCount,
        'missed_call_count': value.missedCallCount,
        'avg_call_duration': GoogleProtobufDurationToJSON(value.avgCallDuration),
        'active_duration': GoogleProtobufDurationToJSON(value.activeDuration),
        'avg_call_count_per_hour': value.avgCallCountPerHour,
        'unavailability_percentage': value.unavailabilityPercentage,
    };
}


