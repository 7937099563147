/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiListGroupAvailabilityRequest
 */
export interface StreemApiListGroupAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiListGroupAvailabilityRequest
     */
    companyCodeOrSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListGroupAvailabilityRequest
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListGroupAvailabilityRequest
     */
    status?: StreemApiListGroupAvailabilityRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListGroupAvailabilityRequest
     */
    pageToken?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListGroupAvailabilityRequest
     */
    pageSize?: string;
}

export function StreemApiListGroupAvailabilityRequestFromJSON(json: any): StreemApiListGroupAvailabilityRequest {
    return StreemApiListGroupAvailabilityRequestFromJSONTyped(json, false);
}

export function StreemApiListGroupAvailabilityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListGroupAvailabilityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyCodeOrSid': !exists(json, 'company_code_or_sid') ? undefined : json['company_code_or_sid'],
        'groupName': !exists(json, 'group_name') ? undefined : json['group_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'pageToken': !exists(json, 'page_token') ? undefined : json['page_token'],
        'pageSize': !exists(json, 'page_size') ? undefined : json['page_size'],
    };
}

export function StreemApiListGroupAvailabilityRequestToJSON(value?: StreemApiListGroupAvailabilityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_code_or_sid': value.companyCodeOrSid,
        'group_name': value.groupName,
        'status': value.status,
        'page_token': value.pageToken,
        'page_size': value.pageSize,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiListGroupAvailabilityRequestStatus {
    AVAILABLE = 'AVAILABILITY_STATUS_AVAILABLE',
    NOTAVAILABLE = 'AVAILABILITY_STATUS_NOT_AVAILABLE',
    RESERVED = 'AVAILABILITY_STATUS_RESERVED',
    INCALL = 'AVAILABILITY_STATUS_IN_CALL',
    CONFIRMING = 'AVAILABILITY_STATUS_CONFIRMING',
    OFFLINE = 'AVAILABILITY_STATUS_OFFLINE',
    INVALID = 'AVAILABILITY_STATUS_INVALID'
}


