/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifactRecordingTrack,
    StreemApiArtifactRecordingTrackFromJSON,
    StreemApiArtifactRecordingTrackFromJSONTyped,
    StreemApiArtifactRecordingTrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAcceptExternalAudioResponse
 */
export interface StreemApiAcceptExternalAudioResponse {
    /**
     * 
     * @type {StreemApiArtifactRecordingTrack}
     * @memberof StreemApiAcceptExternalAudioResponse
     */
    recordingTrack?: StreemApiArtifactRecordingTrack;
}

export function StreemApiAcceptExternalAudioResponseFromJSON(json: any): StreemApiAcceptExternalAudioResponse {
    return StreemApiAcceptExternalAudioResponseFromJSONTyped(json, false);
}

export function StreemApiAcceptExternalAudioResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAcceptExternalAudioResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordingTrack': !exists(json, 'recording_track') ? undefined : StreemApiArtifactRecordingTrackFromJSON(json['recording_track']),
    };
}

export function StreemApiAcceptExternalAudioResponseToJSON(value?: StreemApiAcceptExternalAudioResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recording_track': StreemApiArtifactRecordingTrackToJSON(value.recordingTrack),
    };
}


