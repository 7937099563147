/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventCameraSubjectChangeRequested
 */
export interface StreemApiAnalyticEventCameraSubjectChangeRequested {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventCameraSubjectChangeRequested
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventCameraSubjectChangeRequested
     */
    toSubject?: StreemApiAnalyticEventCameraSubjectChangeRequestedToSubject;
}

export function StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSON(json: any): StreemApiAnalyticEventCameraSubjectChangeRequested {
    return StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventCameraSubjectChangeRequested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'toSubject': !exists(json, 'to_subject') ? undefined : json['to_subject'],
    };
}

export function StreemApiAnalyticEventCameraSubjectChangeRequestedToJSON(value?: StreemApiAnalyticEventCameraSubjectChangeRequested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'to_subject': value.toSubject,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventCameraSubjectChangeRequestedToSubject {
    WORLD = 'CAMERA_SUBJECT_WORLD',
    PERSON = 'CAMERA_SUBJECT_PERSON',
    SCREEN = 'CAMERA_SUBJECT_SCREEN'
}


