/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemRoomArTrackableDefinition,
    StreemRoomArTrackableDefinitionFromJSON,
    StreemRoomArTrackableDefinitionFromJSONTyped,
    StreemRoomArTrackableDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemArTrackableStatus
 */
export interface WallItemArTrackableStatus {
    /**
     * 
     * @type {string}
     * @memberof WallItemArTrackableStatus
     */
    trackableId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArTrackableStatus
     */
    trackableStatus?: WallItemArTrackableStatusTrackableStatus;
    /**
     * 
     * @type {string}
     * @memberof WallItemArTrackableStatus
     */
    trackableUpdatesProvider?: WallItemArTrackableStatusTrackableUpdatesProvider;
    /**
     * 
     * @type {string}
     * @memberof WallItemArTrackableStatus
     */
    trackableType?: WallItemArTrackableStatusTrackableType;
    /**
     * 
     * @type {StreemRoomArTrackableDefinition}
     * @memberof WallItemArTrackableStatus
     */
    trackableDefinition?: StreemRoomArTrackableDefinition;
}

export function WallItemArTrackableStatusFromJSON(json: any): WallItemArTrackableStatus {
    return WallItemArTrackableStatusFromJSONTyped(json, false);
}

export function WallItemArTrackableStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArTrackableStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackableId': !exists(json, 'trackable_id') ? undefined : json['trackable_id'],
        'trackableStatus': !exists(json, 'trackable_status') ? undefined : json['trackable_status'],
        'trackableUpdatesProvider': !exists(json, 'trackable_updates_provider') ? undefined : json['trackable_updates_provider'],
        'trackableType': !exists(json, 'trackable_type') ? undefined : json['trackable_type'],
        'trackableDefinition': !exists(json, 'trackable_definition') ? undefined : StreemRoomArTrackableDefinitionFromJSON(json['trackable_definition']),
    };
}

export function WallItemArTrackableStatusToJSON(value?: WallItemArTrackableStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackable_id': value.trackableId,
        'trackable_status': value.trackableStatus,
        'trackable_updates_provider': value.trackableUpdatesProvider,
        'trackable_type': value.trackableType,
        'trackable_definition': StreemRoomArTrackableDefinitionToJSON(value.trackableDefinition),
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArTrackableStatusTrackableStatus {
    NORMAL = 'TRACKABLE_STATUS_NORMAL',
    PAUSED = 'TRACKABLE_STATUS_PAUSED',
    STOPPED = 'TRACKABLE_STATUS_STOPPED',
    INVALID = 'TRACKABLE_STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArTrackableStatusTrackableUpdatesProvider {
    TWILIOSYNCSTREAM = 'STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM',
    DATATRACK = 'STREAMING_MESSAGE_PROVIDER_DATA_TRACK',
    INVALID = 'STREAMING_MESSAGE_PROVIDER_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArTrackableStatusTrackableType {
    MARKER2D = 'TRACKABLE_TYPE_MARKER_2D',
    MARKER3D = 'TRACKABLE_TYPE_MARKER_3D',
    PLANE = 'TRACKABLE_TYPE_PLANE',
    FEATUREPOINT = 'TRACKABLE_TYPE_FEATURE_POINT',
    MESH = 'TRACKABLE_TYPE_MESH',
    STATIC = 'TRACKABLE_TYPE_STATIC',
    INVALID = 'TRACKABLE_TYPE_INVALID'
}


