/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifactBookmark,
    StreemApiArtifactBookmarkFromJSON,
    StreemApiArtifactBookmarkFromJSONTyped,
    StreemApiArtifactBookmarkToJSON,
    StreemApiArtifactGpsPosition,
    StreemApiArtifactGpsPositionFromJSON,
    StreemApiArtifactGpsPositionFromJSONTyped,
    StreemApiArtifactGpsPositionToJSON,
    StreemApiArtifactLayoutEstimation,
    StreemApiArtifactLayoutEstimationFromJSON,
    StreemApiArtifactLayoutEstimationFromJSONTyped,
    StreemApiArtifactLayoutEstimationToJSON,
    StreemApiArtifactMesh,
    StreemApiArtifactMeshFromJSON,
    StreemApiArtifactMeshFromJSONTyped,
    StreemApiArtifactMeshToJSON,
    StreemApiArtifactRecording,
    StreemApiArtifactRecordingFromJSON,
    StreemApiArtifactRecordingFromJSONTyped,
    StreemApiArtifactRecordingToJSON,
    StreemApiArtifactRecordingTrack,
    StreemApiArtifactRecordingTrackFromJSON,
    StreemApiArtifactRecordingTrackFromJSONTyped,
    StreemApiArtifactRecordingTrackToJSON,
    StreemApiArtifactRoomOutcomeReport,
    StreemApiArtifactRoomOutcomeReportFromJSON,
    StreemApiArtifactRoomOutcomeReportFromJSONTyped,
    StreemApiArtifactRoomOutcomeReportToJSON,
    StreemApiArtifactSceneshot,
    StreemApiArtifactSceneshotFromJSON,
    StreemApiArtifactSceneshotFromJSONTyped,
    StreemApiArtifactSceneshotToJSON,
    StreemApiArtifactStreemshot,
    StreemApiArtifactStreemshotFromJSON,
    StreemApiArtifactStreemshotFromJSONTyped,
    StreemApiArtifactStreemshotToJSON,
    StreemApiArtifactTexturedMesh,
    StreemApiArtifactTexturedMeshFromJSON,
    StreemApiArtifactTexturedMeshFromJSONTyped,
    StreemApiArtifactTexturedMeshToJSON,
    StreemApiArtifactTranscription,
    StreemApiArtifactTranscriptionFromJSON,
    StreemApiArtifactTranscriptionFromJSONTyped,
    StreemApiArtifactTranscriptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifact
 */
export interface StreemApiArtifact {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifact
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifact
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifact
     */
    type?: StreemApiArtifactType;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiArtifact
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifact
     */
    scheduledForDeletionAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifact
     */
    deletedAt?: Date;
    /**
     * 
     * @type {StreemApiArtifactGpsPosition}
     * @memberof StreemApiArtifact
     */
    gpsPosition?: StreemApiArtifactGpsPosition;
    /**
     * 
     * @type {StreemApiArtifactMesh}
     * @memberof StreemApiArtifact
     */
    mesh?: StreemApiArtifactMesh;
    /**
     * 
     * @type {StreemApiArtifactRecordingTrack}
     * @memberof StreemApiArtifact
     */
    recordingTrack?: StreemApiArtifactRecordingTrack;
    /**
     * 
     * @type {StreemApiArtifactRecording}
     * @memberof StreemApiArtifact
     */
    recording?: StreemApiArtifactRecording;
    /**
     * 
     * @type {StreemApiArtifactStreemshot}
     * @memberof StreemApiArtifact
     */
    streemshot?: StreemApiArtifactStreemshot;
    /**
     * 
     * @type {StreemApiArtifactTranscription}
     * @memberof StreemApiArtifact
     */
    transcription?: StreemApiArtifactTranscription;
    /**
     * 
     * @type {StreemApiArtifactSceneshot}
     * @memberof StreemApiArtifact
     */
    sceneshot?: StreemApiArtifactSceneshot;
    /**
     * 
     * @type {StreemApiArtifactLayoutEstimation}
     * @memberof StreemApiArtifact
     */
    layoutEstimation?: StreemApiArtifactLayoutEstimation;
    /**
     * 
     * @type {StreemApiArtifactTexturedMesh}
     * @memberof StreemApiArtifact
     */
    texturedMesh?: StreemApiArtifactTexturedMesh;
    /**
     * 
     * @type {StreemApiArtifactBookmark}
     * @memberof StreemApiArtifact
     */
    bookmark?: StreemApiArtifactBookmark;
    /**
     * 
     * @type {StreemApiArtifactRoomOutcomeReport}
     * @memberof StreemApiArtifact
     */
    roomOutcomeReport?: StreemApiArtifactRoomOutcomeReport;
}

export function StreemApiArtifactFromJSON(json: any): StreemApiArtifact {
    return StreemApiArtifactFromJSONTyped(json, false);
}

export function StreemApiArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'scheduledForDeletionAt': !exists(json, 'scheduled_for_deletion_at') ? undefined : (new Date(json['scheduled_for_deletion_at'])),
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
        'gpsPosition': !exists(json, 'gps_position') ? undefined : StreemApiArtifactGpsPositionFromJSON(json['gps_position']),
        'mesh': !exists(json, 'mesh') ? undefined : StreemApiArtifactMeshFromJSON(json['mesh']),
        'recordingTrack': !exists(json, 'recording_track') ? undefined : StreemApiArtifactRecordingTrackFromJSON(json['recording_track']),
        'recording': !exists(json, 'recording') ? undefined : StreemApiArtifactRecordingFromJSON(json['recording']),
        'streemshot': !exists(json, 'streemshot') ? undefined : StreemApiArtifactStreemshotFromJSON(json['streemshot']),
        'transcription': !exists(json, 'transcription') ? undefined : StreemApiArtifactTranscriptionFromJSON(json['transcription']),
        'sceneshot': !exists(json, 'sceneshot') ? undefined : StreemApiArtifactSceneshotFromJSON(json['sceneshot']),
        'layoutEstimation': !exists(json, 'layout_estimation') ? undefined : StreemApiArtifactLayoutEstimationFromJSON(json['layout_estimation']),
        'texturedMesh': !exists(json, 'textured_mesh') ? undefined : StreemApiArtifactTexturedMeshFromJSON(json['textured_mesh']),
        'bookmark': !exists(json, 'bookmark') ? undefined : StreemApiArtifactBookmarkFromJSON(json['bookmark']),
        'roomOutcomeReport': !exists(json, 'room_outcome_report') ? undefined : StreemApiArtifactRoomOutcomeReportFromJSON(json['room_outcome_report']),
    };
}

export function StreemApiArtifactToJSON(value?: StreemApiArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'room_sid': value.roomSid,
        'type': value.type,
        'deleted': value.deleted,
        'scheduled_for_deletion_at': value.scheduledForDeletionAt === undefined ? undefined : (value.scheduledForDeletionAt.toISOString()),
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'gps_position': StreemApiArtifactGpsPositionToJSON(value.gpsPosition),
        'mesh': StreemApiArtifactMeshToJSON(value.mesh),
        'recording_track': StreemApiArtifactRecordingTrackToJSON(value.recordingTrack),
        'recording': StreemApiArtifactRecordingToJSON(value.recording),
        'streemshot': StreemApiArtifactStreemshotToJSON(value.streemshot),
        'transcription': StreemApiArtifactTranscriptionToJSON(value.transcription),
        'sceneshot': StreemApiArtifactSceneshotToJSON(value.sceneshot),
        'layout_estimation': StreemApiArtifactLayoutEstimationToJSON(value.layoutEstimation),
        'textured_mesh': StreemApiArtifactTexturedMeshToJSON(value.texturedMesh),
        'bookmark': StreemApiArtifactBookmarkToJSON(value.bookmark),
        'room_outcome_report': StreemApiArtifactRoomOutcomeReportToJSON(value.roomOutcomeReport),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactType {
    UNKNOWN = 'UNKNOWN',
    STREEMSHOT = 'STREEMSHOT',
    RECORDING = 'RECORDING',
    RECORDINGTRACK = 'RECORDING_TRACK',
    GPSPOSITION = 'GPS_POSITION',
    MESH = 'MESH',
    TRANSCRIPTION = 'TRANSCRIPTION',
    SCENESHOT = 'SCENESHOT',
    LAYOUTESTIMATION = 'LAYOUT_ESTIMATION',
    TEXTUREDMESH = 'TEXTURED_MESH',
    BOOKMARK = 'BOOKMARK',
    ROOMOUTCOMEREPORT = 'ROOM_OUTCOME_REPORT'
}


