/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateSigningKeyRequest,
    StreemApiCreateSigningKeyRequestFromJSON,
    StreemApiCreateSigningKeyRequestToJSON,
    StreemApiCreateSigningKeyResponse,
    StreemApiCreateSigningKeyResponseFromJSON,
    StreemApiCreateSigningKeyResponseToJSON,
    StreemApiCreateWebhookRequest,
    StreemApiCreateWebhookRequestFromJSON,
    StreemApiCreateWebhookRequestToJSON,
    StreemApiCreateWebhookResponse,
    StreemApiCreateWebhookResponseFromJSON,
    StreemApiCreateWebhookResponseToJSON,
    StreemApiGetSigningKeyResponse,
    StreemApiGetSigningKeyResponseFromJSON,
    StreemApiGetSigningKeyResponseToJSON,
    StreemApiGetWebhookResponse,
    StreemApiGetWebhookResponseFromJSON,
    StreemApiGetWebhookResponseToJSON,
    StreemApiListSigningKeysResponse,
    StreemApiListSigningKeysResponseFromJSON,
    StreemApiListSigningKeysResponseToJSON,
    StreemApiListWebhooksResponse,
    StreemApiListWebhooksResponseFromJSON,
    StreemApiListWebhooksResponseToJSON,
    StreemApiUpdateSigningKeyRequest,
    StreemApiUpdateSigningKeyRequestFromJSON,
    StreemApiUpdateSigningKeyRequestToJSON,
    StreemApiUpdateSigningKeyResponse,
    StreemApiUpdateSigningKeyResponseFromJSON,
    StreemApiUpdateSigningKeyResponseToJSON,
    StreemApiUpdateWebhookRequest,
    StreemApiUpdateWebhookRequestFromJSON,
    StreemApiUpdateWebhookRequestToJSON,
    StreemApiUpdateWebhookResponse,
    StreemApiUpdateWebhookResponseFromJSON,
    StreemApiUpdateWebhookResponseToJSON,
    StreemApiWallIngressRequest,
    StreemApiWallIngressRequestFromJSON,
    StreemApiWallIngressRequestToJSON,
} from '../models';

export interface AraasRoomEventIngressRequest {
    roomId: string;
    body?: object;
}

export interface CreateSigningKeyRequest {
    webhookSid: string;
    streemApiCreateSigningKeyRequest?: StreemApiCreateSigningKeyRequest;
}

export interface CreateWebhookRequest {
    streemApiCreateWebhookRequest?: StreemApiCreateWebhookRequest;
}

export interface DeleteSigningKeyRequest {
    webhookSid: string;
    signingKeySid: string;
}

export interface DeleteWebhookRequest {
    webhookSid: string;
}

export interface FusionAuthIngressRequest {
    body?: object;
}

export interface GetSigningKeyRequest {
    webhookSid: string;
    signingKeySid: string;
}

export interface GetWebhookRequest {
    webhookSid: string;
}

export interface ListSigningKeysRequest {
    webhookSid: string;
    pageSize?: number;
    pageToken?: string;
    sort?: string;
    expired?: boolean;
}

export interface ListWebhooksRequest {
    pageSize?: number;
    pageToken?: string;
    sort?: string;
    companySid?: string;
}

export interface RoomEventIngressRequest {
    roomId: string;
}

export interface TestWebhookRequest {
    webhookSid: string;
}

export interface TypeformResponseIngressRequest {
    body?: object;
}

export interface UpdateSigningKeyRequest {
    webhookSid: string;
    signingKeySid: string;
    streemApiUpdateSigningKeyRequest?: StreemApiUpdateSigningKeyRequest;
}

export interface UpdateWebhookRequest {
    webhookSid: string;
    streemApiUpdateWebhookRequest?: StreemApiUpdateWebhookRequest;
}

export interface WallIngressRequest {
    streemApiWallIngressRequest?: StreemApiWallIngressRequest;
}

/**
 * 
 */
export class WebhooksApi extends runtime.BaseAPI {

    /**
     * Incoming Araas Room events, which tell us when Rooms have been created, ended, etc. 
     * Araas Room Event Ingress
     */
    async araasRoomEventIngressRaw(requestParameters: AraasRoomEventIngressRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling araasRoomEventIngress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/araas/rooms/{room_id}`.replace(`{${"room_id"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming Araas Room events, which tell us when Rooms have been created, ended, etc. 
     * Araas Room Event Ingress
     */
    async araasRoomEventIngress(roomId: string, body?: object): Promise<void> {
        await this.araasRoomEventIngressRaw({ roomId: roomId, body: body });
    }

    /**
     * Create a new webhook signing key, which will be used to generate webhook signature headers
     * Create Signing Key
     */
    async createSigningKeyRaw(requestParameters: CreateSigningKeyRequest): Promise<runtime.ApiResponse<StreemApiCreateSigningKeyResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling createSigningKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/signing-keys`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateSigningKeyRequestToJSON(requestParameters.streemApiCreateSigningKeyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateSigningKeyResponseFromJSON(jsonValue));
    }

    /**
     * Create a new webhook signing key, which will be used to generate webhook signature headers
     * Create Signing Key
     */
    async createSigningKey(webhookSid: string, streemApiCreateSigningKeyRequest?: StreemApiCreateSigningKeyRequest): Promise<StreemApiCreateSigningKeyResponse> {
        const response = await this.createSigningKeyRaw({ webhookSid: webhookSid, streemApiCreateSigningKeyRequest: streemApiCreateSigningKeyRequest });
        return await response.value();
    }

    /**
     * Create a new webhook, which will be notified of events that occur in the system
     * Create Webhook
     */
    async createWebhookRaw(requestParameters: CreateWebhookRequest): Promise<runtime.ApiResponse<StreemApiCreateWebhookResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateWebhookRequestToJSON(requestParameters.streemApiCreateWebhookRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateWebhookResponseFromJSON(jsonValue));
    }

    /**
     * Create a new webhook, which will be notified of events that occur in the system
     * Create Webhook
     */
    async createWebhook(streemApiCreateWebhookRequest?: StreemApiCreateWebhookRequest): Promise<StreemApiCreateWebhookResponse> {
        const response = await this.createWebhookRaw({ streemApiCreateWebhookRequest: streemApiCreateWebhookRequest });
        return await response.value();
    }

    /**
     * Delete this signing key for the given webhook
     * Delete Signing Key
     */
    async deleteSigningKeyRaw(requestParameters: DeleteSigningKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling deleteSigningKey.');
        }

        if (requestParameters.signingKeySid === null || requestParameters.signingKeySid === undefined) {
            throw new runtime.RequiredError('signingKeySid','Required parameter requestParameters.signingKeySid was null or undefined when calling deleteSigningKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/signing-keys/{signing_key_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))).replace(`{${"signing_key_sid"}}`, encodeURIComponent(String(requestParameters.signingKeySid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete this signing key for the given webhook
     * Delete Signing Key
     */
    async deleteSigningKey(webhookSid: string, signingKeySid: string): Promise<void> {
        await this.deleteSigningKeyRaw({ webhookSid: webhookSid, signingKeySid: signingKeySid });
    }

    /**
     * Delete this webhook
     * Delete Webhook
     */
    async deleteWebhookRaw(requestParameters: DeleteWebhookRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling deleteWebhook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete this webhook
     * Delete Webhook
     */
    async deleteWebhook(webhookSid: string): Promise<void> {
        await this.deleteWebhookRaw({ webhookSid: webhookSid });
    }

    /**
     * Incoming FusionAuth events, informing us when users login, are created, etc.
     * FusionAuth Ingress
     */
    async fusionAuthIngressRaw(requestParameters: FusionAuthIngressRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/fusion-auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming FusionAuth events, informing us when users login, are created, etc.
     * FusionAuth Ingress
     */
    async fusionAuthIngress(body?: object): Promise<void> {
        await this.fusionAuthIngressRaw({ body: body });
    }

    /**
     * Retrieve a signing key by id, for this webhook
     * Get Signing Key
     */
    async getSigningKeyRaw(requestParameters: GetSigningKeyRequest): Promise<runtime.ApiResponse<StreemApiGetSigningKeyResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling getSigningKey.');
        }

        if (requestParameters.signingKeySid === null || requestParameters.signingKeySid === undefined) {
            throw new runtime.RequiredError('signingKeySid','Required parameter requestParameters.signingKeySid was null or undefined when calling getSigningKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/signing-keys/{signing_key_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))).replace(`{${"signing_key_sid"}}`, encodeURIComponent(String(requestParameters.signingKeySid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetSigningKeyResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a signing key by id, for this webhook
     * Get Signing Key
     */
    async getSigningKey(webhookSid: string, signingKeySid: string): Promise<StreemApiGetSigningKeyResponse> {
        const response = await this.getSigningKeyRaw({ webhookSid: webhookSid, signingKeySid: signingKeySid });
        return await response.value();
    }

    /**
     * Retrieve a webhook by id
     * Get Webhook
     */
    async getWebhookRaw(requestParameters: GetWebhookRequest): Promise<runtime.ApiResponse<StreemApiGetWebhookResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling getWebhook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetWebhookResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a webhook by id
     * Get Webhook
     */
    async getWebhook(webhookSid: string): Promise<StreemApiGetWebhookResponse> {
        const response = await this.getWebhookRaw({ webhookSid: webhookSid });
        return await response.value();
    }

    /**
     * Get a list of all signing keys for this webhook
     * List Signing Keys
     */
    async listSigningKeysRaw(requestParameters: ListSigningKeysRequest): Promise<runtime.ApiResponse<StreemApiListSigningKeysResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling listSigningKeys.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.expired !== undefined) {
            queryParameters['expired'] = requestParameters.expired;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/signing-keys`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListSigningKeysResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of all signing keys for this webhook
     * List Signing Keys
     */
    async listSigningKeys(webhookSid: string, pageSize?: number, pageToken?: string, sort?: string, expired?: boolean): Promise<StreemApiListSigningKeysResponse> {
        const response = await this.listSigningKeysRaw({ webhookSid: webhookSid, pageSize: pageSize, pageToken: pageToken, sort: sort, expired: expired });
        return await response.value();
    }

    /**
     * Get a list of all webhooks
     * List Webhooks
     */
    async listWebhooksRaw(requestParameters: ListWebhooksRequest): Promise<runtime.ApiResponse<StreemApiListWebhooksResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.companySid !== undefined) {
            queryParameters['company_sid'] = requestParameters.companySid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListWebhooksResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of all webhooks
     * List Webhooks
     */
    async listWebhooks(pageSize?: number, pageToken?: string, sort?: string, companySid?: string): Promise<StreemApiListWebhooksResponse> {
        const response = await this.listWebhooksRaw({ pageSize: pageSize, pageToken: pageToken, sort: sort, companySid: companySid });
        return await response.value();
    }

    /**
     * Incoming Twilio Room events, which tell us when Rooms have been created, ended, etc. 
     * Twilio Room Event Ingress
     */
    async roomEventIngressRaw(requestParameters: RoomEventIngressRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId','Required parameter requestParameters.roomId was null or undefined when calling roomEventIngress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/rooms/{roomId}`.replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming Twilio Room events, which tell us when Rooms have been created, ended, etc. 
     * Twilio Room Event Ingress
     */
    async roomEventIngress(roomId: string): Promise<void> {
        await this.roomEventIngressRaw({ roomId: roomId });
    }

    /**
     * Incoming Twilio SMS events, which provide delivery status of text messages
     * Twilio SMS Event Ingress
     */
    async smsEventIngressRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming Twilio SMS events, which provide delivery status of text messages
     * Twilio SMS Event Ingress
     */
    async smsEventIngress(): Promise<void> {
        await this.smsEventIngressRaw();
    }

    /**
     * Incoming Twilio Sync events, informing us when things are added, updated, or deleted
     * Twilio Sync Ingress
     */
    async syncIngressRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming Twilio Sync events, informing us when things are added, updated, or deleted
     * Twilio Sync Ingress
     */
    async syncIngress(): Promise<void> {
        await this.syncIngressRaw();
    }

    /**
     * Incoming Telnyx SMS events, which provide delivery status of text messages
     * Telnyx SMS Event Ingress
     */
    async telnyxSmsEventIngressRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/sms/telnyx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming Telnyx SMS events, which provide delivery status of text messages
     * Telnyx SMS Event Ingress
     */
    async telnyxSmsEventIngress(): Promise<void> {
        await this.telnyxSmsEventIngressRaw();
    }

    /**
     * Send a test event to this webhook
     * Test Webhook
     */
    async testWebhookRaw(requestParameters: TestWebhookRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling testWebhook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/~test`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a test event to this webhook
     * Test Webhook
     */
    async testWebhook(webhookSid: string): Promise<void> {
        await this.testWebhookRaw({ webhookSid: webhookSid });
    }

    /**
     * Incoming responses for various Typeform surveys
     * Typeform Response Ingress
     */
    async typeformResponseIngressRaw(requestParameters: TypeformResponseIngressRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/typeform/response`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Incoming responses for various Typeform surveys
     * Typeform Response Ingress
     */
    async typeformResponseIngress(body?: object): Promise<void> {
        await this.typeformResponseIngressRaw({ body: body });
    }

    /**
     * Update the details of a webhook signing key
     * Update Signing Key
     */
    async updateSigningKeyRaw(requestParameters: UpdateSigningKeyRequest): Promise<runtime.ApiResponse<StreemApiUpdateSigningKeyResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling updateSigningKey.');
        }

        if (requestParameters.signingKeySid === null || requestParameters.signingKeySid === undefined) {
            throw new runtime.RequiredError('signingKeySid','Required parameter requestParameters.signingKeySid was null or undefined when calling updateSigningKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}/signing-keys/{signing_key_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))).replace(`{${"signing_key_sid"}}`, encodeURIComponent(String(requestParameters.signingKeySid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateSigningKeyRequestToJSON(requestParameters.streemApiUpdateSigningKeyRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateSigningKeyResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of a webhook signing key
     * Update Signing Key
     */
    async updateSigningKey(webhookSid: string, signingKeySid: string, streemApiUpdateSigningKeyRequest?: StreemApiUpdateSigningKeyRequest): Promise<StreemApiUpdateSigningKeyResponse> {
        const response = await this.updateSigningKeyRaw({ webhookSid: webhookSid, signingKeySid: signingKeySid, streemApiUpdateSigningKeyRequest: streemApiUpdateSigningKeyRequest });
        return await response.value();
    }

    /**
     * Update the details of a webhook
     * Update Webhook
     */
    async updateWebhookRaw(requestParameters: UpdateWebhookRequest): Promise<runtime.ApiResponse<StreemApiUpdateWebhookResponse>> {
        if (requestParameters.webhookSid === null || requestParameters.webhookSid === undefined) {
            throw new runtime.RequiredError('webhookSid','Required parameter requestParameters.webhookSid was null or undefined when calling updateWebhook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/webhooks/{webhook_sid}`.replace(`{${"webhook_sid"}}`, encodeURIComponent(String(requestParameters.webhookSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateWebhookRequestToJSON(requestParameters.streemApiUpdateWebhookRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateWebhookResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of a webhook
     * Update Webhook
     */
    async updateWebhook(webhookSid: string, streemApiUpdateWebhookRequest?: StreemApiUpdateWebhookRequest): Promise<StreemApiUpdateWebhookResponse> {
        const response = await this.updateWebhookRaw({ webhookSid: webhookSid, streemApiUpdateWebhookRequest: streemApiUpdateWebhookRequest });
        return await response.value();
    }

    /**
     * Incoming Streem Wall events, informing us when things are added, updated, or deleted
     * Streem Wall Ingress
     */
    async wallIngressRaw(requestParameters: WallIngressRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/wall`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiWallIngressRequestToJSON(requestParameters.streemApiWallIngressRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Incoming Streem Wall events, informing us when things are added, updated, or deleted
     * Streem Wall Ingress
     */
    async wallIngress(streemApiWallIngressRequest?: StreemApiWallIngressRequest): Promise<object> {
        const response = await this.wallIngressRaw({ streemApiWallIngressRequest: streemApiWallIngressRequest });
        return await response.value();
    }

}
