/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAcceptExternalAudioRequest
 */
export interface StreemApiAcceptExternalAudioRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAcceptExternalAudioRequest
     */
    filename?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiAcceptExternalAudioRequest
     */
    startTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAcceptExternalAudioRequest
     */
    audioFileUrl?: string;
}

export function StreemApiAcceptExternalAudioRequestFromJSON(json: any): StreemApiAcceptExternalAudioRequest {
    return StreemApiAcceptExternalAudioRequestFromJSONTyped(json, false);
}

export function StreemApiAcceptExternalAudioRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAcceptExternalAudioRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'audioFileUrl': !exists(json, 'audio_file_url') ? undefined : json['audio_file_url'],
    };
}

export function StreemApiAcceptExternalAudioRequestToJSON(value?: StreemApiAcceptExternalAudioRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'audio_file_url': value.audioFileUrl,
    };
}


