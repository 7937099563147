/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewStyle
 */
export interface ViewStyle {
    /**
     * 
     * @type {string}
     * @memberof ViewStyle
     */
    backgroundColor?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewStyle
     */
    padding?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewStyle
     */
    borderRadius?: Array<number>;
}

export function ViewStyleFromJSON(json: any): ViewStyle {
    return ViewStyleFromJSONTyped(json, false);
}

export function ViewStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewStyle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': !exists(json, 'background_color') ? undefined : json['background_color'],
        'padding': !exists(json, 'padding') ? undefined : json['padding'],
        'borderRadius': !exists(json, 'border_radius') ? undefined : json['border_radius'],
    };
}

export function ViewStyleToJSON(value?: ViewStyle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'background_color': value.backgroundColor,
        'padding': value.padding,
        'border_radius': value.borderRadius,
    };
}


