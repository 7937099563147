import { generateStreemEndeavorId } from '@streem/logger';
import { observable, action, computed, runInAction } from 'mobx';
import { now } from 'mobx-utils';
import StreemAuth, {
    AnyUser,
    StreemAuthError,
    isInvitedUser,
    InvitedUser,
    isGroupReservationUser,
    GroupReservationUser,
} from '@streem/auth';
import { StreemAPI, APITypes } from '@streem/api';
import appLogger from '../util/app_logger';
import { CompanySettings, InvitationDetails } from '@streem/sdk-react';
import { fetchCompanySettings } from '../util/fetch_company_settings';
import { addUserToDatadogRumSession } from '../util/datadog';
import { recordIdentifyAttempted, recordLoginAttempted } from '@streem/analytics';
import { invariant } from '@streem/toolbox';
import { UserId } from '@streem/domain-id';

const log = appLogger.extend('AppStore');

interface RemoteStreemConfigDetails {
    withUserId: UserId;
    referenceId?: string;
    integrationId?: string;
}

export class AppStore {
    private unsubscriber?: () => void;

    @observable
    public acceptedLatestTCs = false;

    @observable
    private company: APITypes.StreemApiCompany | undefined;

    // To clear up confusion between authStore and userStore, we only publicly
    // expose properties of user which the authStore should be responsible for.
    @observable
    private user: InvitedUser | GroupReservationUser | undefined;

    @observable
    private agent: APITypes.StreemApiUser | undefined;

    @observable
    private _companySettings: CompanySettings | undefined;

    // The initialized property indicates whether we've received the initial
    // response from @streem/auth which indicates our initial auth state.
    @observable
    public initialized = false;

    // The user's sid for making network calls to API. The userSid is obtained from
    // an API Access Token.
    @observable
    public userSid: string | undefined;

    // For async OAuth flows it's possible for errors to be returned from the
    // OAuth provider, in which case they enter via the setAuthUser callback.
    @observable
    public error?: StreemAuthError;

    constructor(user?: InvitedUser) {
        // User for testing
        if (user) {
            this.setAuthUser(user);
        }
    }

    @computed
    public get invitationDetails(): InvitationDetails {
        return {
            companyName: this.companyName,
            agentName: this.agentName,
            agentBio: this.agentBio,
            agentAvatar: this.agentAvatar,
            expiresIn: () => this.invitationExpiresIn,
        };
    }

    @computed
    public get isUserSignedIn(): boolean {
        return this.user !== undefined;
    }

    @computed
    public get companyName(): string {
        return this.company?.name;
    }

    @computed
    public get companyId(): string | undefined {
        return this.user?.companyId;
    }

    @computed
    public get companySettings(): CompanySettings | undefined {
        return this._companySettings;
    }

    @computed
    public get invitationFrom(): string | undefined {
        return this.agent?.sid;
    }

    @computed
    public get invitationId(): string | undefined {
        return isInvitedUser(this.user) ? this.user.invitation.invitationSid : undefined;
    }

    @computed
    public get invitationExpiresIn(): number {
        const diff = now(1000) - this.invitationExpiresAt;
        const minutes = Math.abs(diff) / 1000 / 60;
        return minutes;
    }

    @computed
    public get invitationExpired(): boolean {
        return now(1000) >= this.invitationExpiresAt;
    }

    @computed
    public get invitationExpiresAt(): number {
        if (isInvitedUser(this.user)) {
            return this.user.invitation.expiresAt.getTime();
        }
        if (isGroupReservationUser(this.user)) {
            return this.user.groupReservation.reservedUntil.getTime();
        }
        return 0;
    }

    @computed
    public get agentName(): string | undefined {
        return this.agent?.name;
    }

    @computed
    public get agentBio(): string | undefined {
        return this.agent?.bio;
    }

    @computed
    public get agentAvatar(): string | undefined {
        return this.agent?.avatarUrl;
    }

    @computed
    public get roomId(): string {
        if (isInvitedUser(this.user)) {
            return this.user.invitation.roomSid;
        }
        if (isGroupReservationUser(this.user)) {
            return this.user.groupReservation.roomSid;
        }
        return '';
    }

    @computed
    public get groupReservationSid(): string | undefined {
        return isGroupReservationUser(this.user)
            ? this.user.groupReservation.reservationSid
            : undefined;
    }

    @computed
    public get remoteStreemConfigDetails(): RemoteStreemConfigDetails | undefined {
        if (isGroupReservationUser(this.user)) {
            return {
                withUserId: new UserId(this.agent.sid),
                referenceId: this.user.groupReservation.referenceId,
                integrationId: this.user.groupReservation.integrationId,
            };
        }

        if (isInvitedUser(this.user)) {
            return {
                withUserId: new UserId(this.agent.sid),
                referenceId: this.user.invitation.referenceId,
                integrationId: this.user.invitation.integrationId,
            };
        }

        return undefined;
    }

    public async logout(): Promise<void> {
        try {
            log.info('Logging out the user via API');
            await StreemAPI.auth.authLogout();
        } catch (e) {
            log.error('Error logging out the user via API', e);
        }
        await StreemAuth.logout();
    }

    public async loginWithInviteCode(inviteCode: string): Promise<void> {
        let successful = false;
        let invitationId: string | undefined;
        try {
            const user = await StreemAuth.loginWithInviteCode(inviteCode);
            successful = true;
            invitationId = user.invitation.invitationSid;
            log.info('Successful login, invitation id: ' + invitationId);
        } catch (err: unknown) {
            if (err instanceof Error) {
                await this.setAuthUser(undefined, err);
            }
        } finally {
            recordLoginAttempted('INVITATION', successful, invitationId);
        }
    }

    public async loginWithSdkToken(sdkToken: string, companyCode: string): Promise<void> {
        let successful = false;
        let reservationId: string | undefined;
        try {
            const user = await StreemAuth.loginWithSDKToken(sdkToken, companyCode);
            invariant(
                isGroupReservationUser(user),
                'SDK Token did not identify a Group Reservation user',
            );
            successful = true;
            reservationId = user.groupReservation.reservationSid;
            log.info('Successful login, reservation id: ' + reservationId);
        } catch (err: unknown) {
            if (err instanceof Error) {
                await this.setAuthUser(undefined, err);
            }
        } finally {
            recordIdentifyAttempted(successful, reservationId);
        }
    }

    public async loginWithAccessToken(companyCode: string): Promise<void> {
        let successful = false;
        let reservationId: string | undefined;
        try {
            const user = await StreemAuth.loginSDKUserWithAccessToken(companyCode);
            invariant(
                isGroupReservationUser(user),
                'SDK Token did not identify a Group Reservation user',
            );
            successful = true;
            reservationId = user.groupReservation.reservationSid;
            log.info('Successful login, reservation id: ' + reservationId);
        } catch (err: unknown) {
            if (err instanceof Error) {
                await this.setAuthUser(undefined, err);
            }
        } finally {
            recordIdentifyAttempted(successful, reservationId);
        }
    }

    public async acceptLatestTCs(): Promise<void> {
        try {
            invariant(
                this.userSid,
                'User must be logged in before accepting terms and conditions.',
            );
            await StreemAPI.users.saveUserTermsStatus(this.userSid, {
                acceptedLatest: true,
            });
            log.info({ msg: 'User accepted terms' });
            runInAction(() => (this.acceptedLatestTCs = true));
        } catch (error) {
            log.error({ msg: 'Error updating user terms status', error: error });
            throw error;
        }
    }

    /**
     * disconnect invokes the callback on StreemAuth's onAuthStateChanged
     */
    public disconnect(): void {
        if (this.unsubscriber) {
            this.unsubscriber();
        }
    }

    /**
     * connect registers a callback to StreemAuth's onAuthStateChanged
     */
    public connect(): void {
        this.unsubscriber = StreemAuth.onAuthStateChanged(this.setAuthUser);
    }

    private async getAgent(userSid: string) {
        try {
            const { user } = await StreemAPI.users.getUser(userSid);
            log.setContextValue('proUserId', user.sid);
            return user;
        } catch (error) {
            log.error({
                msg: 'Error getting pro',
                userSid: userSid,
                error: error,
            });
            throw error;
        }
    }

    private async getCompany(companyCode: string) {
        try {
            const { company } = await StreemAPI.companies.getCompany(companyCode);
            log.setContextValue('company', company);
            return company;
        } catch (error) {
            log.error({
                msg: 'Error getting company',
                companyCode: companyCode,
                error: error,
            });
            throw error;
        }
    }

    private async getUserTermsStatus(userId: string) {
        try {
            const {
                status: { acceptedLatest },
            } = await StreemAPI.users.getUserTermsStatus(userId);
            return acceptedLatest;
        } catch (error) {
            log.error({
                msg: 'Error getting user terms status',
                userId: userId,
                error: error,
            });
            throw error;
        }
    }

    private setEndeavorId(userId: string, agentId: string) {
        const endeavorId = generateStreemEndeavorId(userId, agentId);
        log.setContextValue('streemEndeavorId', endeavorId);
        log.info({ msg: `Streem endeavor started` });
    }

    @action.bound
    private async setAuthUser(user: AnyUser | undefined, error?: StreemAuthError) {
        if (!user || !(isInvitedUser(user) || isGroupReservationUser(user))) {
            this.user = undefined;
            this.agent = undefined;
            this.company = undefined;
            this.error = error;
            this.initialized = true;
            return;
        }

        try {
            const acceptedLatest = await this.getUserTermsStatus(user.id);
            const company = await this.getCompany(user.companyId);
            const companySettings = await fetchCompanySettings(user.companyId);

            let agent: APITypes.StreemApiUser;
            if (isInvitedUser(user)) {
                agent = await this.getAgent(user.invitation.fromUserSid);
            } else if (isGroupReservationUser(user)) {
                agent = user.groupReservation.reservedUser;
            }

            this.setEndeavorId(user.id, agent.sid);
            addUserToDatadogRumSession(user.id);

            runInAction(() => {
                this._companySettings = companySettings;
                this.acceptedLatestTCs = acceptedLatest;
                this.user = user;
                this.userSid = user.id;
                this.agent = agent;
                this.company = company;
                this.initialized = true;
            });
        } catch (err) {
            runInAction(() => {
                this.user = undefined;
                this.agent = undefined;
                this.company = undefined;
                this.initialized = true;
            });
        }
    }
}
