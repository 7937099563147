/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifact,
    StreemApiArtifactFromJSON,
    StreemApiArtifactFromJSONTyped,
    StreemApiArtifactToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateRoomOutcomeReportResponse
 */
export interface StreemApiCreateRoomOutcomeReportResponse {
    /**
     * 
     * @type {StreemApiArtifact}
     * @memberof StreemApiCreateRoomOutcomeReportResponse
     */
    roomOutcomeReportArtifact?: StreemApiArtifact;
}

export function StreemApiCreateRoomOutcomeReportResponseFromJSON(json: any): StreemApiCreateRoomOutcomeReportResponse {
    return StreemApiCreateRoomOutcomeReportResponseFromJSONTyped(json, false);
}

export function StreemApiCreateRoomOutcomeReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateRoomOutcomeReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomOutcomeReportArtifact': !exists(json, 'room_outcome_report_artifact') ? undefined : StreemApiArtifactFromJSON(json['room_outcome_report_artifact']),
    };
}

export function StreemApiCreateRoomOutcomeReportResponseToJSON(value?: StreemApiCreateRoomOutcomeReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_outcome_report_artifact': StreemApiArtifactToJSON(value.roomOutcomeReportArtifact),
    };
}


