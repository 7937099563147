/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSettingDefinition
 */
export interface StreemApiSettingDefinition {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSettingDefinition
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSettingDefinition
     */
    type?: StreemApiSettingDefinitionType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSettingDefinition
     */
    _default?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSettingDefinition
     */
    publicRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSettingDefinition
     */
    companyWrite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSettingDefinition
     */
    scope?: StreemApiSettingDefinitionScope;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiSettingDefinition
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiSettingDefinition
     */
    modifiedAt?: Date;
}

export function StreemApiSettingDefinitionFromJSON(json: any): StreemApiSettingDefinition {
    return StreemApiSettingDefinitionFromJSONTyped(json, false);
}

export function StreemApiSettingDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSettingDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'publicRead': !exists(json, 'public_read') ? undefined : json['public_read'],
        'companyWrite': !exists(json, 'company_write') ? undefined : json['company_write'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'modifiedAt': !exists(json, 'modified_at') ? undefined : (new Date(json['modified_at'])),
    };
}

export function StreemApiSettingDefinitionToJSON(value?: StreemApiSettingDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'default': value._default,
        'public_read': value.publicRead,
        'company_write': value.companyWrite,
        'scope': value.scope,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modified_at': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiSettingDefinitionType {
    STRING = 'TYPE_STRING',
    BOOLEAN = 'TYPE_BOOLEAN',
    INTEGER = 'TYPE_INTEGER',
    DOUBLE = 'TYPE_DOUBLE',
    INVALID = 'TYPE_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiSettingDefinitionScope {
    PLATFORM = 'SCOPE_PLATFORM',
    LICENSE = 'SCOPE_LICENSE',
    INTERNAL = 'SCOPE_INTERNAL',
    INVALID = 'SCOPE_INVALID'
}


