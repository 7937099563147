import { useEffect } from 'react';
import { useGlobalStore } from '../hooks/use_global_context';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function ExpiredInvitePage() {
    const { appStore } = useGlobalStore();
    const timedOut = Boolean(appStore.invitationId && appStore.invitationExpired);

    useEffect(() => {
        appLogger.info({ msg: `User arrived at Expired Invite Page (Timed out: ${timedOut})` });
        // TODO: Figure out how to log out without creating confusion because of FA.
        // appStore.logout();
    }, [timedOut]);

    return (
        <ErrorBox
            testid="invitation-expired"
            headerText="This invitation has expired"
            messageText={
                timedOut
                    ? `It looks like this is an old invitation. If you are expecting a new one, please
                check your messages again, then tell your professional that you didn't receive a new
                invite.`
                    : `Your invitation has timed out. Please contact your professional to request a
        new invitation.`
            }
        />
    );
}
