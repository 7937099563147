type AnimationFunction = (delta: number) => void;

export class FramerateLimiter {
    private requestId = 0;

    public running = false;

    constructor(
        private readonly animationFunction: AnimationFunction,
        private readonly fps: number = 30,
    ) {}

    start() {
        if (this.running) {
            throw new Error('animationLoop is already running');
        }

        let then = performance.now();
        const interval = 1000 / this.fps;
        const tolerance = 0.1;

        const animateLoop = (now: number) => {
            this.requestId = requestAnimationFrame(animateLoop);
            const delta = now - then;

            if (delta >= interval - tolerance) {
                then = now - (delta % interval);
                this.animationFunction(delta);
            }
        };
        this.running = true;
        this.requestId = requestAnimationFrame(animateLoop);
    }

    stop() {
        cancelAnimationFrame(this.requestId);
    }
}
