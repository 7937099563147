/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventWorkflowStarted
 */
export interface StreemApiAnalyticEventWorkflowStarted {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventWorkflowStarted
     */
    workflowSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventWorkflowStarted
     */
    type?: string;
}

export function StreemApiAnalyticEventWorkflowStartedFromJSON(json: any): StreemApiAnalyticEventWorkflowStarted {
    return StreemApiAnalyticEventWorkflowStartedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventWorkflowStartedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventWorkflowStarted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowSid': !exists(json, 'workflow_sid') ? undefined : json['workflow_sid'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function StreemApiAnalyticEventWorkflowStartedToJSON(value?: StreemApiAnalyticEventWorkflowStarted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow_sid': value.workflowSid,
        'type': value.type,
    };
}


