import React from 'react';
import {
    useRecordAppFocus,
    useRecordScreenViewed,
    useRecordElementClicked,
} from '../hooks/analytics_hooks';

export const AnalyticsListener: React.FC = ({ children }) => {
    useRecordAppFocus();
    useRecordScreenViewed();
    useRecordElementClicked();
    return <>{children}</>;
};
