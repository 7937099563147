/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifactBookmark,
    StreemApiArtifactBookmarkFromJSON,
    StreemApiArtifactBookmarkFromJSONTyped,
    StreemApiArtifactBookmarkToJSON,
    StreemApiArtifactLayoutEstimation,
    StreemApiArtifactLayoutEstimationFromJSON,
    StreemApiArtifactLayoutEstimationFromJSONTyped,
    StreemApiArtifactLayoutEstimationToJSON,
    StreemApiArtifactRoomOutcomeReport,
    StreemApiArtifactRoomOutcomeReportFromJSON,
    StreemApiArtifactRoomOutcomeReportFromJSONTyped,
    StreemApiArtifactRoomOutcomeReportToJSON,
    StreemApiArtifactTexturedMesh,
    StreemApiArtifactTexturedMeshFromJSON,
    StreemApiArtifactTexturedMeshFromJSONTyped,
    StreemApiArtifactTexturedMeshToJSON,
    StreemApiArtifactTranscription,
    StreemApiArtifactTranscriptionFromJSON,
    StreemApiArtifactTranscriptionFromJSONTyped,
    StreemApiArtifactTranscriptionToJSON,
    WallItemGPSPosition,
    WallItemGPSPositionFromJSON,
    WallItemGPSPositionFromJSONTyped,
    WallItemGPSPositionToJSON,
    WallItemMesh,
    WallItemMeshFromJSON,
    WallItemMeshFromJSONTyped,
    WallItemMeshToJSON,
    WallItemRecording,
    WallItemRecordingFromJSON,
    WallItemRecordingFromJSONTyped,
    WallItemRecordingToJSON,
    WallItemSceneshot,
    WallItemSceneshotFromJSON,
    WallItemSceneshotFromJSONTyped,
    WallItemSceneshotToJSON,
    WallItemStreemshot,
    WallItemStreemshotFromJSON,
    WallItemStreemshotFromJSONTyped,
    WallItemStreemshotToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemArtifact
 */
export interface WallItemArtifact {
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifact
     */
    inResponseToWallItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifact
     */
    status?: WallItemArtifactStatus;
    /**
     * 
     * @type {string}
     * @memberof WallItemArtifact
     */
    artifactType?: WallItemArtifactArtifactType;
    /**
     * 
     * @type {WallItemGPSPosition}
     * @memberof WallItemArtifact
     */
    gpsPosition?: WallItemGPSPosition;
    /**
     * 
     * @type {WallItemRecording}
     * @memberof WallItemArtifact
     */
    recording?: WallItemRecording;
    /**
     * 
     * @type {WallItemStreemshot}
     * @memberof WallItemArtifact
     */
    streemshot?: WallItemStreemshot;
    /**
     * 
     * @type {WallItemMesh}
     * @memberof WallItemArtifact
     */
    mesh?: WallItemMesh;
    /**
     * 
     * @type {StreemApiArtifactTranscription}
     * @memberof WallItemArtifact
     */
    transcription?: StreemApiArtifactTranscription;
    /**
     * 
     * @type {WallItemSceneshot}
     * @memberof WallItemArtifact
     */
    sceneshot?: WallItemSceneshot;
    /**
     * 
     * @type {StreemApiArtifactLayoutEstimation}
     * @memberof WallItemArtifact
     */
    layoutEstimation?: StreemApiArtifactLayoutEstimation;
    /**
     * 
     * @type {StreemApiArtifactTexturedMesh}
     * @memberof WallItemArtifact
     */
    texturedMesh?: StreemApiArtifactTexturedMesh;
    /**
     * 
     * @type {StreemApiArtifactBookmark}
     * @memberof WallItemArtifact
     */
    bookmark?: StreemApiArtifactBookmark;
    /**
     * 
     * @type {StreemApiArtifactRoomOutcomeReport}
     * @memberof WallItemArtifact
     */
    roomOutcomeReport?: StreemApiArtifactRoomOutcomeReport;
}

export function WallItemArtifactFromJSON(json: any): WallItemArtifact {
    return WallItemArtifactFromJSONTyped(json, false);
}

export function WallItemArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inResponseToWallItemId': !exists(json, 'in_response_to_wall_item_id') ? undefined : json['in_response_to_wall_item_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'artifactType': !exists(json, 'artifact_type') ? undefined : json['artifact_type'],
        'gpsPosition': !exists(json, 'gps_position') ? undefined : WallItemGPSPositionFromJSON(json['gps_position']),
        'recording': !exists(json, 'recording') ? undefined : WallItemRecordingFromJSON(json['recording']),
        'streemshot': !exists(json, 'streemshot') ? undefined : WallItemStreemshotFromJSON(json['streemshot']),
        'mesh': !exists(json, 'mesh') ? undefined : WallItemMeshFromJSON(json['mesh']),
        'transcription': !exists(json, 'transcription') ? undefined : StreemApiArtifactTranscriptionFromJSON(json['transcription']),
        'sceneshot': !exists(json, 'sceneshot') ? undefined : WallItemSceneshotFromJSON(json['sceneshot']),
        'layoutEstimation': !exists(json, 'layout_estimation') ? undefined : StreemApiArtifactLayoutEstimationFromJSON(json['layout_estimation']),
        'texturedMesh': !exists(json, 'textured_mesh') ? undefined : StreemApiArtifactTexturedMeshFromJSON(json['textured_mesh']),
        'bookmark': !exists(json, 'bookmark') ? undefined : StreemApiArtifactBookmarkFromJSON(json['bookmark']),
        'roomOutcomeReport': !exists(json, 'room_outcome_report') ? undefined : StreemApiArtifactRoomOutcomeReportFromJSON(json['room_outcome_report']),
    };
}

export function WallItemArtifactToJSON(value?: WallItemArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'in_response_to_wall_item_id': value.inResponseToWallItemId,
        'status': value.status,
        'artifact_type': value.artifactType,
        'gps_position': WallItemGPSPositionToJSON(value.gpsPosition),
        'recording': WallItemRecordingToJSON(value.recording),
        'streemshot': WallItemStreemshotToJSON(value.streemshot),
        'mesh': WallItemMeshToJSON(value.mesh),
        'transcription': StreemApiArtifactTranscriptionToJSON(value.transcription),
        'sceneshot': WallItemSceneshotToJSON(value.sceneshot),
        'layout_estimation': StreemApiArtifactLayoutEstimationToJSON(value.layoutEstimation),
        'textured_mesh': StreemApiArtifactTexturedMeshToJSON(value.texturedMesh),
        'bookmark': StreemApiArtifactBookmarkToJSON(value.bookmark),
        'room_outcome_report': StreemApiArtifactRoomOutcomeReportToJSON(value.roomOutcomeReport),
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArtifactStatus {
    UNKNOWN = 'UNKNOWN',
    FULFILLED = 'FULFILLED',
    REJECTED = 'REJECTED',
    ERROR = 'ERROR',
    STARTED = 'STARTED',
    CAPTURED = 'CAPTURED'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArtifactArtifactType {
    UNKNOWN = 'UNKNOWN',
    GPSPOSITION = 'GPS_POSITION',
    RECORDING = 'RECORDING',
    STREEMSHOT = 'STREEMSHOT',
    MESH = 'MESH',
    TRANSCRIPTION = 'TRANSCRIPTION',
    SCENESHOT = 'SCENESHOT',
    LAYOUTESTIMATION = 'LAYOUT_ESTIMATION',
    TEXTUREDMESH = 'TEXTURED_MESH',
    BOOKMARK = 'BOOKMARK',
    ROOMOUTCOMEREPORT = 'ROOM_OUTCOME_REPORT'
}


