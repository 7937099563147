import { computed } from 'mobx';
import { fromStream, IStreamListener } from 'mobx-utils';
import StreemSDK, { UserSession, RoomSession } from '@streem/sdk-core';

export class SDKStore {
    public readonly userSession = fromStream<UserSession | undefined>(StreemSDK.userSession);
    private roomSessionStream?: IStreamListener<RoomSession | undefined>;

    @computed
    public get roomSession(): IStreamListener<RoomSession | undefined> | undefined {
        if (this.roomSessionStream) {
            this.roomSessionStream.dispose();
            this.roomSessionStream = undefined;
        }

        if (this.userSession.current) {
            this.roomSessionStream = fromStream(this.userSession.current.roomSession);
            return this.roomSessionStream;
        }

        return undefined;
    }
}
