/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CameraIntrinsics,
    CameraIntrinsicsFromJSON,
    CameraIntrinsicsFromJSONTyped,
    CameraIntrinsicsToJSON,
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
    StreemApiPose,
    StreemApiPoseFromJSON,
    StreemApiPoseFromJSONTyped,
    StreemApiPoseToJSON,
    StreemApiSize,
    StreemApiSizeFromJSON,
    StreemApiSizeFromJSONTyped,
    StreemApiSizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemStreemshot
 */
export interface WallItemStreemshot {
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshot
     */
    inResponseToWallItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshot
     */
    downloadUrl?: string;
    /**
     * 
     * @type {StreemApiPose}
     * @memberof WallItemStreemshot
     */
    pose?: StreemApiPose;
    /**
     * 
     * @type {Array<StreemApiPoint>}
     * @memberof WallItemStreemshot
     */
    featurePoints?: Array<StreemApiPoint>;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshot
     */
    storageUrl?: string;
    /**
     * 
     * @type {CameraIntrinsics}
     * @memberof WallItemStreemshot
     */
    cameraIntrinsics?: CameraIntrinsics;
    /**
     * 
     * @type {StreemApiSize}
     * @memberof WallItemStreemshot
     */
    imageDimensions?: StreemApiSize;
}

export function WallItemStreemshotFromJSON(json: any): WallItemStreemshot {
    return WallItemStreemshotFromJSONTyped(json, false);
}

export function WallItemStreemshotFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemStreemshot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inResponseToWallItemId': !exists(json, 'in_response_to_wall_item_id') ? undefined : json['in_response_to_wall_item_id'],
        'downloadUrl': !exists(json, 'download_url') ? undefined : json['download_url'],
        'pose': !exists(json, 'pose') ? undefined : StreemApiPoseFromJSON(json['pose']),
        'featurePoints': !exists(json, 'feature_points') ? undefined : ((json['feature_points'] as Array<any>).map(StreemApiPointFromJSON)),
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'cameraIntrinsics': !exists(json, 'camera_intrinsics') ? undefined : CameraIntrinsicsFromJSON(json['camera_intrinsics']),
        'imageDimensions': !exists(json, 'image_dimensions') ? undefined : StreemApiSizeFromJSON(json['image_dimensions']),
    };
}

export function WallItemStreemshotToJSON(value?: WallItemStreemshot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'in_response_to_wall_item_id': value.inResponseToWallItemId,
        'download_url': value.downloadUrl,
        'pose': StreemApiPoseToJSON(value.pose),
        'feature_points': value.featurePoints === undefined ? undefined : ((value.featurePoints as Array<any>).map(StreemApiPointToJSON)),
        'storage_url': value.storageUrl,
        'camera_intrinsics': CameraIntrinsicsToJSON(value.cameraIntrinsics),
        'image_dimensions': StreemApiSizeToJSON(value.imageDimensions),
    };
}


