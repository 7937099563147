import { useObserver } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalStore } from '../hooks/use_global_context';
import { ExpiredInvitePage } from '../pages/expired_invite_page';
import { useInviteCode } from '../hooks/use_invite_code';
import { LoadingPage } from '../pages/loading_page';
import { InvalidLinkPage } from '../pages/invalid_link_page';
import appLogger from '../util/app_logger';

const log = appLogger.extend('InviteValidator');

interface InviteValidatorProps {
    configuredPackages: boolean;
    getCompanyRegistrationError: boolean;
}

interface InviteHistoryState {
    manuallyEnteredInviteCode?: boolean;
}

export const InviteValidator: React.FC<InviteValidatorProps> = ({
    getCompanyRegistrationError,
    configuredPackages,
    children,
}) => {
    const inviteCode = useInviteCode();
    const { appStore, sdkStore } = useGlobalStore();
    const loadedAt = useRef(new Date());
    const history = useHistory();

    useEffect(() => {
        if (inviteCode && configuredPackages) {
            appStore
                .loginWithInviteCode(inviteCode)
                .catch(reason => log.error('Error logging in with invitation code', reason));
        }
    }, [appStore, inviteCode, configuredPackages]);

    return useObserver(() => {
        // This is almost always due to invalid invitations
        if (appStore.error || getCompanyRegistrationError) {
            const state = history.location.state as InviteHistoryState;
            log.warn(
                `Error validating invitation. Manually entered invite code: ${!!state?.manuallyEnteredInviteCode}`,
            );
            return <InvalidLinkPage />;
        }

        const inviteExpired =
            appStore.invitationId && loadedAt.current.getTime() - appStore.invitationExpiresAt >= 0;
        if (inviteExpired) {
            return <ExpiredInvitePage />;
        }

        const appReady = !!appStore.isUserSignedIn;
        const sdkReady = !!sdkStore.userSession?.current;
        if (!appReady || !sdkReady || !configuredPackages) {
            return <LoadingPage message="Validating invitation..." />;
        }

        return <>{children}</>;
    });
};
