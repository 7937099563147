/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateSignedUploadUrlRequest,
    StreemApiCreateSignedUploadUrlRequestFromJSON,
    StreemApiCreateSignedUploadUrlRequestToJSON,
    StreemApiCreateSignedUploadUrlResponse,
    StreemApiCreateSignedUploadUrlResponseFromJSON,
    StreemApiCreateSignedUploadUrlResponseToJSON,
} from '../models';

export interface CreateSignedUploadUrlRequest {
    streemApiCreateSignedUploadUrlRequest?: StreemApiCreateSignedUploadUrlRequest;
}

/**
 * 
 */
export class UploadsApi extends runtime.BaseAPI {

    /**
     * Creates a signed URL that can be used for uploading a file to storage.
     * Create Signed URL
     */
    async createSignedUploadUrlRaw(requestParameters: CreateSignedUploadUrlRequest): Promise<runtime.ApiResponse<StreemApiCreateSignedUploadUrlResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/uploads/signed-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateSignedUploadUrlRequestToJSON(requestParameters.streemApiCreateSignedUploadUrlRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateSignedUploadUrlResponseFromJSON(jsonValue));
    }

    /**
     * Creates a signed URL that can be used for uploading a file to storage.
     * Create Signed URL
     */
    async createSignedUploadUrl(streemApiCreateSignedUploadUrlRequest?: StreemApiCreateSignedUploadUrlRequest): Promise<StreemApiCreateSignedUploadUrlResponse> {
        const response = await this.createSignedUploadUrlRaw({ streemApiCreateSignedUploadUrlRequest: streemApiCreateSignedUploadUrlRequest });
        return await response.value();
    }

}
