/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAggregateRoomMetricsForCompanyRequestFilter,
    StreemApiAggregateRoomMetricsForCompanyRequestFilterFromJSON,
    StreemApiAggregateRoomMetricsForCompanyRequestFilterFromJSONTyped,
    StreemApiAggregateRoomMetricsForCompanyRequestFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAggregateRoomMetricsForCompanyRequest
 */
export interface StreemApiAggregateRoomMetricsForCompanyRequest {
    /**
     * 
     * @type {StreemApiAggregateRoomMetricsForCompanyRequestFilter}
     * @memberof StreemApiAggregateRoomMetricsForCompanyRequest
     */
    filter?: StreemApiAggregateRoomMetricsForCompanyRequestFilter;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAggregateRoomMetricsForCompanyRequest
     */
    aggregateBy?: StreemApiAggregateRoomMetricsForCompanyRequestAggregateBy;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAggregateRoomMetricsForCompanyRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAggregateRoomMetricsForCompanyRequest
     */
    pageToken?: string;
}

export function StreemApiAggregateRoomMetricsForCompanyRequestFromJSON(json: any): StreemApiAggregateRoomMetricsForCompanyRequest {
    return StreemApiAggregateRoomMetricsForCompanyRequestFromJSONTyped(json, false);
}

export function StreemApiAggregateRoomMetricsForCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAggregateRoomMetricsForCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter': !exists(json, 'filter') ? undefined : StreemApiAggregateRoomMetricsForCompanyRequestFilterFromJSON(json['filter']),
        'aggregateBy': !exists(json, 'aggregate_by') ? undefined : json['aggregate_by'],
        'pageSize': !exists(json, 'page_size') ? undefined : json['page_size'],
        'pageToken': !exists(json, 'page_token') ? undefined : json['page_token'],
    };
}

export function StreemApiAggregateRoomMetricsForCompanyRequestToJSON(value?: StreemApiAggregateRoomMetricsForCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter': StreemApiAggregateRoomMetricsForCompanyRequestFilterToJSON(value.filter),
        'aggregate_by': value.aggregateBy,
        'page_size': value.pageSize,
        'page_token': value.pageToken,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAggregateRoomMetricsForCompanyRequestAggregateBy {
    USERSID = 'AGGREGATE_BY_USER_SID',
    GROUPSID = 'AGGREGATE_BY_GROUP_SID',
    COMPANYSID = 'AGGREGATE_BY_COMPANY_SID',
    GROUPNAME = 'AGGREGATE_BY_GROUP_NAME'
}


