/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCompanyRoomOutcomeConfigEntry,
    StreemApiCompanyRoomOutcomeConfigEntryFromJSON,
    StreemApiCompanyRoomOutcomeConfigEntryFromJSONTyped,
    StreemApiCompanyRoomOutcomeConfigEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCompanyRoomOutcomeConfigOption
 */
export interface StreemApiCompanyRoomOutcomeConfigOption {
    /**
     * 
     * @type {StreemApiCompanyRoomOutcomeConfigEntry}
     * @memberof StreemApiCompanyRoomOutcomeConfigOption
     */
    entry?: StreemApiCompanyRoomOutcomeConfigEntry;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StreemApiCompanyRoomOutcomeConfigOption
     */
    properties?: { [key: string]: string; };
}

export function StreemApiCompanyRoomOutcomeConfigOptionFromJSON(json: any): StreemApiCompanyRoomOutcomeConfigOption {
    return StreemApiCompanyRoomOutcomeConfigOptionFromJSONTyped(json, false);
}

export function StreemApiCompanyRoomOutcomeConfigOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCompanyRoomOutcomeConfigOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : StreemApiCompanyRoomOutcomeConfigEntryFromJSON(json['entry']),
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function StreemApiCompanyRoomOutcomeConfigOptionToJSON(value?: StreemApiCompanyRoomOutcomeConfigOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': StreemApiCompanyRoomOutcomeConfigEntryToJSON(value.entry),
        'properties': value.properties,
    };
}


