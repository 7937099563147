/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemCapabilities
 */
export interface WallItemCapabilities {
    /**
     * 
     * @type {Array<string>}
     * @memberof WallItemCapabilities
     */
    canSendTrackableTypes?: Array<WallItemCapabilitiesCanSendTrackableTypes>;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    canReceiveNonUprightCameraImages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    sendsArScanningRequests?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    canReceiveCompressedDataTrackMessages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    supportsLightshowV2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    acceptsInsertableStreams?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemCapabilities
     */
    canReceiveVideo?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WallItemCapabilities
     */
    canSendCameraSubjects?: Array<WallItemCapabilitiesCanSendCameraSubjects>;
}

export function WallItemCapabilitiesFromJSON(json: any): WallItemCapabilities {
    return WallItemCapabilitiesFromJSONTyped(json, false);
}

export function WallItemCapabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemCapabilities {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canSendTrackableTypes': !exists(json, 'can_send_trackable_types') ? undefined : json['can_send_trackable_types'],
        'canReceiveNonUprightCameraImages': !exists(json, 'can_receive_non_upright_camera_images') ? undefined : json['can_receive_non_upright_camera_images'],
        'sendsArScanningRequests': !exists(json, 'sends_ar_scanning_requests') ? undefined : json['sends_ar_scanning_requests'],
        'canReceiveCompressedDataTrackMessages': !exists(json, 'can_receive_compressed_data_track_messages') ? undefined : json['can_receive_compressed_data_track_messages'],
        'supportsLightshowV2': !exists(json, 'supports_lightshow_v2') ? undefined : json['supports_lightshow_v2'],
        'acceptsInsertableStreams': !exists(json, 'accepts_insertable_streams') ? undefined : json['accepts_insertable_streams'],
        'canReceiveVideo': !exists(json, 'can_receive_video') ? undefined : json['can_receive_video'],
        'canSendCameraSubjects': !exists(json, 'can_send_camera_subjects') ? undefined : json['can_send_camera_subjects'],
    };
}

export function WallItemCapabilitiesToJSON(value?: WallItemCapabilities | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_send_trackable_types': value.canSendTrackableTypes,
        'can_receive_non_upright_camera_images': value.canReceiveNonUprightCameraImages,
        'sends_ar_scanning_requests': value.sendsArScanningRequests,
        'can_receive_compressed_data_track_messages': value.canReceiveCompressedDataTrackMessages,
        'supports_lightshow_v2': value.supportsLightshowV2,
        'accepts_insertable_streams': value.acceptsInsertableStreams,
        'can_receive_video': value.canReceiveVideo,
        'can_send_camera_subjects': value.canSendCameraSubjects,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemCapabilitiesCanSendTrackableTypes {
    MARKER2D = 'TRACKABLE_TYPE_MARKER_2D',
    MARKER3D = 'TRACKABLE_TYPE_MARKER_3D',
    PLANE = 'TRACKABLE_TYPE_PLANE',
    FEATUREPOINT = 'TRACKABLE_TYPE_FEATURE_POINT',
    MESH = 'TRACKABLE_TYPE_MESH',
    STATIC = 'TRACKABLE_TYPE_STATIC',
    INVALID = 'TRACKABLE_TYPE_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemCapabilitiesCanSendCameraSubjects {
    WORLD = 'CAMERA_SUBJECT_WORLD',
    PERSON = 'CAMERA_SUBJECT_PERSON',
    SCREEN = 'CAMERA_SUBJECT_SCREEN'
}


