/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemArScanningStatus
 */
export interface WallItemArScanningStatus {
    /**
     * 
     * @type {string}
     * @memberof WallItemArScanningStatus
     */
    inResponseToWallItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemArScanningStatus
     */
    status?: WallItemArScanningStatusStatus;
    /**
     * 
     * @type {string}
     * @memberof WallItemArScanningStatus
     */
    trackableUpdatesProvider?: WallItemArScanningStatusTrackableUpdatesProvider;
}

export function WallItemArScanningStatusFromJSON(json: any): WallItemArScanningStatus {
    return WallItemArScanningStatusFromJSONTyped(json, false);
}

export function WallItemArScanningStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemArScanningStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inResponseToWallItemId': !exists(json, 'in_response_to_wall_item_id') ? undefined : json['in_response_to_wall_item_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'trackableUpdatesProvider': !exists(json, 'trackable_updates_provider') ? undefined : json['trackable_updates_provider'],
    };
}

export function WallItemArScanningStatusToJSON(value?: WallItemArScanningStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'in_response_to_wall_item_id': value.inResponseToWallItemId,
        'status': value.status,
        'trackable_updates_provider': value.trackableUpdatesProvider,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemArScanningStatusStatus {
    ERROR = 'TRACKABLE_SCANNING_STATUS_ERROR',
    SCANNING = 'TRACKABLE_SCANNING_STATUS_SCANNING',
    INVALID = 'TRACKABLE_SCANNING_STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemArScanningStatusTrackableUpdatesProvider {
    TWILIOSYNCSTREAM = 'STREAMING_MESSAGE_PROVIDER_TWILIO_SYNC_STREAM',
    DATATRACK = 'STREAMING_MESSAGE_PROVIDER_DATA_TRACK',
    INVALID = 'STREAMING_MESSAGE_PROVIDER_INVALID'
}


