/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiUpdateDeviceRequest,
    StreemApiUpdateDeviceRequestFromJSON,
    StreemApiUpdateDeviceRequestToJSON,
    StreemApiUpdateDeviceResponse,
    StreemApiUpdateDeviceResponseFromJSON,
    StreemApiUpdateDeviceResponseToJSON,
} from '../models';

export interface DeleteDeviceRequest {
    deviceSid: string;
}

export interface UpdateDeviceRequest {
    deviceSid: string;
    streemApiUpdateDeviceRequest?: StreemApiUpdateDeviceRequest;
}

/**
 * 
 */
export class DevicesApi extends runtime.BaseAPI {

    /**
     * Delete the specified device
     * Delete Device
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceSid === null || requestParameters.deviceSid === undefined) {
            throw new runtime.RequiredError('deviceSid','Required parameter requestParameters.deviceSid was null or undefined when calling deleteDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/devices/{device_sid}`.replace(`{${"device_sid"}}`, encodeURIComponent(String(requestParameters.deviceSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified device
     * Delete Device
     */
    async deleteDevice(deviceSid: string): Promise<void> {
        await this.deleteDeviceRaw({ deviceSid: deviceSid });
    }

    /**
     * Update information about the specified Device
     * Update Device
     */
    async updateDeviceRaw(requestParameters: UpdateDeviceRequest): Promise<runtime.ApiResponse<StreemApiUpdateDeviceResponse>> {
        if (requestParameters.deviceSid === null || requestParameters.deviceSid === undefined) {
            throw new runtime.RequiredError('deviceSid','Required parameter requestParameters.deviceSid was null or undefined when calling updateDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/devices/{device_sid}`.replace(`{${"device_sid"}}`, encodeURIComponent(String(requestParameters.deviceSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateDeviceRequestToJSON(requestParameters.streemApiUpdateDeviceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateDeviceResponseFromJSON(jsonValue));
    }

    /**
     * Update information about the specified Device
     * Update Device
     */
    async updateDevice(deviceSid: string, streemApiUpdateDeviceRequest?: StreemApiUpdateDeviceRequest): Promise<StreemApiUpdateDeviceResponse> {
        const response = await this.updateDeviceRaw({ deviceSid: deviceSid, streemApiUpdateDeviceRequest: streemApiUpdateDeviceRequest });
        return await response.value();
    }

}
