/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactBookmark
 */
export interface StreemApiArtifactBookmark {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactBookmark
     */
    label?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactBookmark
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactBookmark
     */
    status?: StreemApiArtifactBookmarkStatus;
}

export function StreemApiArtifactBookmarkFromJSON(json: any): StreemApiArtifactBookmark {
    return StreemApiArtifactBookmarkFromJSONTyped(json, false);
}

export function StreemApiArtifactBookmarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactBookmark {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function StreemApiArtifactBookmarkToJSON(value?: StreemApiArtifactBookmark | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactBookmarkStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}


