import { useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function BrowserNotSupportedPage() {
    useEffect(() => {
        appLogger.info('User arrived at Browser Not Supported Page');
    }, []);

    return (
        <ErrorBox
            testid="browser-not-supported"
            headerText="Browser Not Supported"
            messageText="This browser is not currently supported by Streem. Please try again using the Safari
        browser on iOS, or the Chrome browser on Android."
        />
    );
}
