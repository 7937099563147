import { APIError } from './errors/api_error';
import { ListLoginIdentityCompaniesResponseInternal } from './types/global_api.types';

export interface ListLoginIdentityCompaniesResponse {
    companies: CompanyRegistration[];
    nextPageToken: string | null;
    totalSize: number;
}

interface CompanyRegistration {
    companyCode?: string | null;
    regionCode?: string | null;
    companyName?: string | null;
    companyLogoUrl?: string | null;
    switchWorkspacesEnabled?: boolean;
}

interface Options {
    loginIdentityId: string;
    globalDomain: string;
    token: string;
}
export async function listLoginIdentityCompanies({
    loginIdentityId,
    globalDomain,
    token,
}: Options): Promise<ListLoginIdentityCompaniesResponse> {
    const globalApiUrl = `https://global-api.${globalDomain}/v1`;
    const response = await fetch(`${globalApiUrl}/login-identities/${loginIdentityId}/companies`, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new APIError(
            `Could not fetch companies for loginIdentityId: '${loginIdentityId}'`,
            response,
        );
    }

    try {
        const data = (await response.json()) as ListLoginIdentityCompaniesResponseInternal;
        return {
            companies: data.companies.map(company => ({
                companyCode: company.company_code,
                regionCode: company.region_code,
                companyName: company.company_name,
                companyLogoUrl: company.company_logo_url,
                switchWorkspacesEnabled: company.switch_workspaces_enabled,
            })),
            nextPageToken: data.next_page_token,
            totalSize: data.total_size,
        };
    } catch (error) {
        throw new APIError(
            `Error fetching companies for loginIdentityId: '${loginIdentityId}'`,
            response,
        );
    }
}
