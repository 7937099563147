/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiWebhookHeader,
    StreemApiWebhookHeaderFromJSON,
    StreemApiWebhookHeaderFromJSONTyped,
    StreemApiWebhookHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiUpdateWebhookRequest
 */
export interface StreemApiUpdateWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateWebhookRequest
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateWebhookRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateWebhookRequest
     */
    method?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiUpdateWebhookRequest
     */
    timeoutMs?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiUpdateWebhookRequest
     */
    maxAttempts?: number;
    /**
     * 
     * @type {Array<StreemApiWebhookHeader>}
     * @memberof StreemApiUpdateWebhookRequest
     */
    headers?: Array<StreemApiWebhookHeader>;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiUpdateWebhookRequest
     */
    clearHeaders?: boolean;
}

export function StreemApiUpdateWebhookRequestFromJSON(json: any): StreemApiUpdateWebhookRequest {
    return StreemApiUpdateWebhookRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateWebhookRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateWebhookRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'timeoutMs': !exists(json, 'timeout_ms') ? undefined : json['timeout_ms'],
        'maxAttempts': !exists(json, 'max_attempts') ? undefined : json['max_attempts'],
        'headers': !exists(json, 'headers') ? undefined : ((json['headers'] as Array<any>).map(StreemApiWebhookHeaderFromJSON)),
        'clearHeaders': !exists(json, 'clear_headers') ? undefined : json['clear_headers'],
    };
}

export function StreemApiUpdateWebhookRequestToJSON(value?: StreemApiUpdateWebhookRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'url': value.url,
        'method': value.method,
        'timeout_ms': value.timeoutMs,
        'max_attempts': value.maxAttempts,
        'headers': value.headers === undefined ? undefined : ((value.headers as Array<any>).map(StreemApiWebhookHeaderToJSON)),
        'clear_headers': value.clearHeaders,
    };
}


