/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemRoomArTrackableDefinitionMarker2d,
    StreemRoomArTrackableDefinitionMarker2dFromJSON,
    StreemRoomArTrackableDefinitionMarker2dFromJSONTyped,
    StreemRoomArTrackableDefinitionMarker2dToJSON,
    StreemRoomArTrackableDefinitionMarker3d,
    StreemRoomArTrackableDefinitionMarker3dFromJSON,
    StreemRoomArTrackableDefinitionMarker3dFromJSONTyped,
    StreemRoomArTrackableDefinitionMarker3dToJSON,
    StreemRoomArTrackableDefinitionPlane,
    StreemRoomArTrackableDefinitionPlaneFromJSON,
    StreemRoomArTrackableDefinitionPlaneFromJSONTyped,
    StreemRoomArTrackableDefinitionPlaneToJSON,
    StreemRoomArTrackableDefinitionStatic,
    StreemRoomArTrackableDefinitionStaticFromJSON,
    StreemRoomArTrackableDefinitionStaticFromJSONTyped,
    StreemRoomArTrackableDefinitionStaticToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemRoomArTrackableDefinition
 */
export interface StreemRoomArTrackableDefinition {
    /**
     * 
     * @type {StreemRoomArTrackableDefinitionMarker2d}
     * @memberof StreemRoomArTrackableDefinition
     */
    marker2d?: StreemRoomArTrackableDefinitionMarker2d;
    /**
     * 
     * @type {StreemRoomArTrackableDefinitionMarker3d}
     * @memberof StreemRoomArTrackableDefinition
     */
    marker3d?: StreemRoomArTrackableDefinitionMarker3d;
    /**
     * 
     * @type {StreemRoomArTrackableDefinitionStatic}
     * @memberof StreemRoomArTrackableDefinition
     */
    _static?: StreemRoomArTrackableDefinitionStatic;
    /**
     * 
     * @type {StreemRoomArTrackableDefinitionPlane}
     * @memberof StreemRoomArTrackableDefinition
     */
    plane?: StreemRoomArTrackableDefinitionPlane;
    /**
     * 
     * @type {object}
     * @memberof StreemRoomArTrackableDefinition
     */
    featurePoint?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemRoomArTrackableDefinition
     */
    mesh?: object;
}

export function StreemRoomArTrackableDefinitionFromJSON(json: any): StreemRoomArTrackableDefinition {
    return StreemRoomArTrackableDefinitionFromJSONTyped(json, false);
}

export function StreemRoomArTrackableDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemRoomArTrackableDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marker2d': !exists(json, 'marker2d') ? undefined : StreemRoomArTrackableDefinitionMarker2dFromJSON(json['marker2d']),
        'marker3d': !exists(json, 'marker3d') ? undefined : StreemRoomArTrackableDefinitionMarker3dFromJSON(json['marker3d']),
        '_static': !exists(json, 'static') ? undefined : StreemRoomArTrackableDefinitionStaticFromJSON(json['static']),
        'plane': !exists(json, 'plane') ? undefined : StreemRoomArTrackableDefinitionPlaneFromJSON(json['plane']),
        'featurePoint': !exists(json, 'feature_point') ? undefined : json['feature_point'],
        'mesh': !exists(json, 'mesh') ? undefined : json['mesh'],
    };
}

export function StreemRoomArTrackableDefinitionToJSON(value?: StreemRoomArTrackableDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marker2d': StreemRoomArTrackableDefinitionMarker2dToJSON(value.marker2d),
        'marker3d': StreemRoomArTrackableDefinitionMarker3dToJSON(value.marker3d),
        'static': StreemRoomArTrackableDefinitionStaticToJSON(value._static),
        'plane': StreemRoomArTrackableDefinitionPlaneToJSON(value.plane),
        'feature_point': value.featurePoint,
        'mesh': value.mesh,
    };
}


