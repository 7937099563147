/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextTheme,
    TextThemeFromJSON,
    TextThemeFromJSONTyped,
    TextThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextPresentation
 */
export interface TextPresentation {
    /**
     * 
     * @type {string}
     * @memberof TextPresentation
     */
    titleId?: string;
    /**
     * 
     * @type {string}
     * @memberof TextPresentation
     */
    contentId?: string;
    /**
     * 
     * @type {TextTheme}
     * @memberof TextPresentation
     */
    themeOverride?: TextTheme;
}

export function TextPresentationFromJSON(json: any): TextPresentation {
    return TextPresentationFromJSONTyped(json, false);
}

export function TextPresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextPresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titleId': !exists(json, 'title_id') ? undefined : json['title_id'],
        'contentId': !exists(json, 'content_id') ? undefined : json['content_id'],
        'themeOverride': !exists(json, 'theme_override') ? undefined : TextThemeFromJSON(json['theme_override']),
    };
}

export function TextPresentationToJSON(value?: TextPresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title_id': value.titleId,
        'content_id': value.contentId,
        'theme_override': TextThemeToJSON(value.themeOverride),
    };
}


