// import { StreemGlyphBrandColors, Box, styled } from '@streem/ui-react';
import { useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function DesktopNotSupportedPage() {
    useEffect(() => {
        appLogger.info('User arrived at Desktop Not Supported Page');
    }, []);

    return (
        <ErrorBox
            testid="desktop-not-supported"
            headerText="Desktop Not Supported"
            messageText="It looks like you are using a desktop browser, please open the link on your mobile
                device."
        />
    );
}
