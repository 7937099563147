/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemARElementMeasurement
 */
export interface WallItemARElementMeasurement {
    /**
     * 
     * @type {StreemApiPoint}
     * @memberof WallItemARElementMeasurement
     */
    from?: StreemApiPoint;
    /**
     * 
     * @type {StreemApiPoint}
     * @memberof WallItemARElementMeasurement
     */
    to?: StreemApiPoint;
}

export function WallItemARElementMeasurementFromJSON(json: any): WallItemARElementMeasurement {
    return WallItemARElementMeasurementFromJSONTyped(json, false);
}

export function WallItemARElementMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemARElementMeasurement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : StreemApiPointFromJSON(json['from']),
        'to': !exists(json, 'to') ? undefined : StreemApiPointFromJSON(json['to']),
    };
}

export function WallItemARElementMeasurementToJSON(value?: WallItemARElementMeasurement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': StreemApiPointToJSON(value.from),
        'to': StreemApiPointToJSON(value.to),
    };
}


