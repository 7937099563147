/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransitionLabeledDestination,
    TransitionLabeledDestinationFromJSON,
    TransitionLabeledDestinationFromJSONTyped,
    TransitionLabeledDestinationToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransitionYesNo
 */
export interface TransitionYesNo {
    /**
     * 
     * @type {TransitionLabeledDestination}
     * @memberof TransitionYesNo
     */
    yes?: TransitionLabeledDestination;
    /**
     * 
     * @type {TransitionLabeledDestination}
     * @memberof TransitionYesNo
     */
    no?: TransitionLabeledDestination;
}

export function TransitionYesNoFromJSON(json: any): TransitionYesNo {
    return TransitionYesNoFromJSONTyped(json, false);
}

export function TransitionYesNoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransitionYesNo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yes': !exists(json, 'yes') ? undefined : TransitionLabeledDestinationFromJSON(json['yes']),
        'no': !exists(json, 'no') ? undefined : TransitionLabeledDestinationFromJSON(json['no']),
    };
}

export function TransitionYesNoToJSON(value?: TransitionYesNo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yes': TransitionLabeledDestinationToJSON(value.yes),
        'no': TransitionLabeledDestinationToJSON(value.no),
    };
}


