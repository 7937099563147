/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufValue,
    GoogleProtobufValueFromJSON,
    GoogleProtobufValueFromJSONTyped,
    GoogleProtobufValueToJSON,
    StreemApiArtifactRoomOutcomeReportEntry,
    StreemApiArtifactRoomOutcomeReportEntryFromJSON,
    StreemApiArtifactRoomOutcomeReportEntryFromJSONTyped,
    StreemApiArtifactRoomOutcomeReportEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactRoomOutcomeReport
 */
export interface StreemApiArtifactRoomOutcomeReport {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRoomOutcomeReport
     */
    status?: StreemApiArtifactRoomOutcomeReportStatus;
    /**
     * 
     * @type {Array<StreemApiArtifactRoomOutcomeReportEntry>}
     * @memberof StreemApiArtifactRoomOutcomeReport
     */
    diagnoses?: Array<StreemApiArtifactRoomOutcomeReportEntry>;
    /**
     * 
     * @type {StreemApiArtifactRoomOutcomeReportEntry}
     * @memberof StreemApiArtifactRoomOutcomeReport
     */
    disposition?: StreemApiArtifactRoomOutcomeReportEntry;
    /**
     * 
     * @type {{ [key: string]: GoogleProtobufValue; }}
     * @memberof StreemApiArtifactRoomOutcomeReport
     */
    customFields?: { [key: string]: GoogleProtobufValue; };
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRoomOutcomeReport
     */
    createdAt?: Date;
}

export function StreemApiArtifactRoomOutcomeReportFromJSON(json: any): StreemApiArtifactRoomOutcomeReport {
    return StreemApiArtifactRoomOutcomeReportFromJSONTyped(json, false);
}

export function StreemApiArtifactRoomOutcomeReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactRoomOutcomeReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'diagnoses': !exists(json, 'diagnoses') ? undefined : ((json['diagnoses'] as Array<any>).map(StreemApiArtifactRoomOutcomeReportEntryFromJSON)),
        'disposition': !exists(json, 'disposition') ? undefined : StreemApiArtifactRoomOutcomeReportEntryFromJSON(json['disposition']),
        'customFields': !exists(json, 'custom_fields') ? undefined : (mapValues(json['custom_fields'], GoogleProtobufValueFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function StreemApiArtifactRoomOutcomeReportToJSON(value?: StreemApiArtifactRoomOutcomeReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'diagnoses': value.diagnoses === undefined ? undefined : ((value.diagnoses as Array<any>).map(StreemApiArtifactRoomOutcomeReportEntryToJSON)),
        'disposition': StreemApiArtifactRoomOutcomeReportEntryToJSON(value.disposition),
        'custom_fields': value.customFields === undefined ? undefined : (mapValues(value.customFields, GoogleProtobufValueToJSON)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRoomOutcomeReportStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}


