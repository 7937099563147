/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventNoteSaved
 */
export interface StreemApiAnalyticEventNoteSaved {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventNoteSaved
     */
    charCount?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventNoteSaved
     */
    scopeSid?: string;
}

export function StreemApiAnalyticEventNoteSavedFromJSON(json: any): StreemApiAnalyticEventNoteSaved {
    return StreemApiAnalyticEventNoteSavedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventNoteSavedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventNoteSaved {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charCount': !exists(json, 'char_count') ? undefined : json['char_count'],
        'scopeSid': !exists(json, 'scope_sid') ? undefined : json['scope_sid'],
    };
}

export function StreemApiAnalyticEventNoteSavedToJSON(value?: StreemApiAnalyticEventNoteSaved | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'char_count': value.charCount,
        'scope_sid': value.scopeSid,
    };
}


