/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSsoProviderSamlV2
 */
export interface StreemApiSsoProviderSamlV2 {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    loginEndpoint?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiSsoProviderSamlV2
     */
    managedDomains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiSsoProviderSamlV2
     */
    useNameIdForEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    emailClaim?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    fusionAuthKeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    verificationPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    verificationCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    fusionAuthLambdaId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderSamlV2
     */
    usernameClaim?: string;
}

export function StreemApiSsoProviderSamlV2FromJSON(json: any): StreemApiSsoProviderSamlV2 {
    return StreemApiSsoProviderSamlV2FromJSONTyped(json, false);
}

export function StreemApiSsoProviderSamlV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSsoProviderSamlV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loginEndpoint': !exists(json, 'login_endpoint') ? undefined : json['login_endpoint'],
        'managedDomains': !exists(json, 'managed_domains') ? undefined : json['managed_domains'],
        'useNameIdForEmail': !exists(json, 'use_name_id_for_email') ? undefined : json['use_name_id_for_email'],
        'emailClaim': !exists(json, 'email_claim') ? undefined : json['email_claim'],
        'fusionAuthKeyId': !exists(json, 'fusion_auth_key_id') ? undefined : json['fusion_auth_key_id'],
        'verificationPublicKey': !exists(json, 'verification_public_key') ? undefined : json['verification_public_key'],
        'verificationCertificate': !exists(json, 'verification_certificate') ? undefined : json['verification_certificate'],
        'fusionAuthLambdaId': !exists(json, 'fusion_auth_lambda_id') ? undefined : json['fusion_auth_lambda_id'],
        'usernameClaim': !exists(json, 'username_claim') ? undefined : json['username_claim'],
    };
}

export function StreemApiSsoProviderSamlV2ToJSON(value?: StreemApiSsoProviderSamlV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'login_endpoint': value.loginEndpoint,
        'managed_domains': value.managedDomains,
        'use_name_id_for_email': value.useNameIdForEmail,
        'email_claim': value.emailClaim,
        'fusion_auth_key_id': value.fusionAuthKeyId,
        'verification_public_key': value.verificationPublicKey,
        'verification_certificate': value.verificationCertificate,
        'fusion_auth_lambda_id': value.fusionAuthLambdaId,
        'username_claim': value.usernameClaim,
    };
}


