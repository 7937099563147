import { styled, Card, Header, Box } from '@streem/ui-react';

interface CenteredCardProps {
    header: string;
    children: any;
    height?: string;
}

export function CenteredCard(props: CenteredCardProps) {
    return (
        <Box margin="0 20px">
            <ErrorCard>
                <PageHeader>{props.header}</PageHeader>
                <PageContent>{props.children}</PageContent>
            </ErrorCard>
        </Box>
    );
}

const PageHeader = styled(Header)`
    margin-bottom: 2vh;
    font-size: 20px;
`;

const PageContent = styled.div`
    font-size: 16px;
    line-height: 25px;
    font-family: ${props => props.theme.text.bodyFontFamily};
`;
const ErrorCard = styled(Card)`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.3);
    min-height: 250px;
    max-width: 366px;
`;
