/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Step,
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
    TutorialAnchor,
    TutorialAnchorFromJSON,
    TutorialAnchorFromJSONTyped,
    TutorialAnchorToJSON,
    TutorialStateExited,
    TutorialStateExitedFromJSON,
    TutorialStateExitedFromJSONTyped,
    TutorialStateExitedToJSON,
} from './';

/**
 * 
 * @export
 * @interface TutorialState
 */
export interface TutorialState {
    /**
     * 
     * @type {string}
     * @memberof TutorialState
     */
    tutorialId?: string;
    /**
     * 
     * @type {string}
     * @memberof TutorialState
     */
    inResponseToWallItemId?: string;
    /**
     * 
     * @type {TutorialAnchor}
     * @memberof TutorialState
     */
    anchor?: TutorialAnchor;
    /**
     * 
     * @type {Step}
     * @memberof TutorialState
     */
    step?: Step;
    /**
     * 
     * @type {TutorialStateExited}
     * @memberof TutorialState
     */
    exited?: TutorialStateExited;
}

export function TutorialStateFromJSON(json: any): TutorialState {
    return TutorialStateFromJSONTyped(json, false);
}

export function TutorialStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tutorialId': !exists(json, 'tutorial_id') ? undefined : json['tutorial_id'],
        'inResponseToWallItemId': !exists(json, 'in_response_to_wall_item_id') ? undefined : json['in_response_to_wall_item_id'],
        'anchor': !exists(json, 'anchor') ? undefined : TutorialAnchorFromJSON(json['anchor']),
        'step': !exists(json, 'step') ? undefined : StepFromJSON(json['step']),
        'exited': !exists(json, 'exited') ? undefined : TutorialStateExitedFromJSON(json['exited']),
    };
}

export function TutorialStateToJSON(value?: TutorialState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tutorial_id': value.tutorialId,
        'in_response_to_wall_item_id': value.inResponseToWallItemId,
        'anchor': TutorialAnchorToJSON(value.anchor),
        'step': StepToJSON(value.step),
        'exited': TutorialStateExitedToJSON(value.exited),
    };
}


