/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiModelNo,
    StreemApiModelNoFromJSON,
    StreemApiModelNoFromJSONTyped,
    StreemApiModelNoToJSON,
    StreemApiSerialNo,
    StreemApiSerialNoFromJSON,
    StreemApiSerialNoFromJSONTyped,
    StreemApiSerialNoToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiDetectionResult
 */
export interface StreemApiDetectionResult {
    /**
     * 
     * @type {StreemApiModelNo}
     * @memberof StreemApiDetectionResult
     */
    modelNo?: StreemApiModelNo;
    /**
     * 
     * @type {StreemApiSerialNo}
     * @memberof StreemApiDetectionResult
     */
    serialNo?: StreemApiSerialNo;
}

export function StreemApiDetectionResultFromJSON(json: any): StreemApiDetectionResult {
    return StreemApiDetectionResultFromJSONTyped(json, false);
}

export function StreemApiDetectionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiDetectionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelNo': !exists(json, 'model_no') ? undefined : StreemApiModelNoFromJSON(json['model_no']),
        'serialNo': !exists(json, 'serial_no') ? undefined : StreemApiSerialNoFromJSON(json['serial_no']),
    };
}

export function StreemApiDetectionResultToJSON(value?: StreemApiDetectionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'model_no': StreemApiModelNoToJSON(value.modelNo),
        'serial_no': StreemApiSerialNoToJSON(value.serialNo),
    };
}


