/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactMesh
 */
export interface StreemApiArtifactMesh {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactMesh
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactMesh
     */
    status?: StreemApiArtifactMeshStatus;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactMesh
     */
    meshStorageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactMesh
     */
    metadataStorageUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactMesh
     */
    requestedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactMesh
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactMesh
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactMesh
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactMesh
     */
    url?: string;
}

export function StreemApiArtifactMeshFromJSON(json: any): StreemApiArtifactMesh {
    return StreemApiArtifactMeshFromJSONTyped(json, false);
}

export function StreemApiArtifactMeshFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactMesh {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'meshStorageUrl': !exists(json, 'mesh_storage_url') ? undefined : json['mesh_storage_url'],
        'metadataStorageUrl': !exists(json, 'metadata_storage_url') ? undefined : json['metadata_storage_url'],
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function StreemApiArtifactMeshToJSON(value?: StreemApiArtifactMesh | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
        'mesh_storage_url': value.meshStorageUrl,
        'metadata_storage_url': value.metadataStorageUrl,
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'url': value.url,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactMeshStatus {
    REQUESTED = 'STATUS_REQUESTED',
    STARTED = 'STATUS_STARTED',
    ERROR = 'STATUS_ERROR',
    FULFILLED = 'STATUS_FULFILLED',
    INVALID = 'STATUS_INVALID'
}


