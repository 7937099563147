/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAnalyticEvent,
    StreemApiAnalyticEventFromJSON,
    StreemApiAnalyticEventFromJSONTyped,
    StreemApiAnalyticEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiRecordEventsRequest
 */
export interface StreemApiRecordEventsRequest {
    /**
     * 
     * @type {Array<StreemApiAnalyticEvent>}
     * @memberof StreemApiRecordEventsRequest
     */
    events?: Array<StreemApiAnalyticEvent>;
}

export function StreemApiRecordEventsRequestFromJSON(json: any): StreemApiRecordEventsRequest {
    return StreemApiRecordEventsRequestFromJSONTyped(json, false);
}

export function StreemApiRecordEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRecordEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(StreemApiAnalyticEventFromJSON)),
    };
}

export function StreemApiRecordEventsRequestToJSON(value?: StreemApiRecordEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(StreemApiAnalyticEventToJSON)),
    };
}


