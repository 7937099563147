/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactTranscription
 */
export interface StreemApiArtifactTranscription {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTranscription
     */
    status?: StreemApiArtifactTranscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTranscription
     */
    storageUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTranscription
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTranscription
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTranscription
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTranscription
     */
    type?: StreemApiArtifactTranscriptionType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTranscription
     */
    url?: string;
}

export function StreemApiArtifactTranscriptionFromJSON(json: any): StreemApiArtifactTranscription {
    return StreemApiArtifactTranscriptionFromJSONTyped(json, false);
}

export function StreemApiArtifactTranscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactTranscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function StreemApiArtifactTranscriptionToJSON(value?: StreemApiArtifactTranscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'storage_url': value.storageUrl,
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'type': value.type,
        'url': value.url,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactTranscriptionStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactTranscriptionType {
    VTT = 'VTT'
}


