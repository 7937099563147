/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiWallWallItemEntryEvent,
    StreemApiWallWallItemEntryEventFromJSON,
    StreemApiWallWallItemEntryEventFromJSONTyped,
    StreemApiWallWallItemEntryEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiWallIngressRequest
 */
export interface StreemApiWallIngressRequest {
    /**
     * 
     * @type {StreemApiWallWallItemEntryEvent}
     * @memberof StreemApiWallIngressRequest
     */
    event?: StreemApiWallWallItemEntryEvent;
}

export function StreemApiWallIngressRequestFromJSON(json: any): StreemApiWallIngressRequest {
    return StreemApiWallIngressRequestFromJSONTyped(json, false);
}

export function StreemApiWallIngressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWallIngressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': !exists(json, 'event') ? undefined : StreemApiWallWallItemEntryEventFromJSON(json['event']),
    };
}

export function StreemApiWallIngressRequestToJSON(value?: StreemApiWallIngressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': StreemApiWallWallItemEntryEventToJSON(value.event),
    };
}


