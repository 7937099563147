import { useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function CookiesDisabledPage() {
    useEffect(() => {
        appLogger.info('User arrived at Cookies Disabled Page');
    }, []);

    return (
        <ErrorBox
            testid="cookies-disabled"
            headerText="Allow Cookies to Connect"
            messageText="Please adjust your browser settings or preferences to allow cookies, then refresh this page. Cookie preferences may be located in the privacy or security tab of your browser."
        />
    );
}
