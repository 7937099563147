/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateStreemshotRequest
 */
export interface StreemApiCreateStreemshotRequest {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCreateStreemshotRequest
     */
    requestedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateStreemshotRequest
     */
    requestedByUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateStreemshotRequest
     */
    requestedByDeviceSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateStreemshotRequest
     */
    requestedForUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateStreemshotRequest
     */
    requestedForDeviceSid?: string;
}

export function StreemApiCreateStreemshotRequestFromJSON(json: any): StreemApiCreateStreemshotRequest {
    return StreemApiCreateStreemshotRequestFromJSONTyped(json, false);
}

export function StreemApiCreateStreemshotRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateStreemshotRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'requestedByUserSid': !exists(json, 'requested_by_user_sid') ? undefined : json['requested_by_user_sid'],
        'requestedByDeviceSid': !exists(json, 'requested_by_device_sid') ? undefined : json['requested_by_device_sid'],
        'requestedForUserSid': !exists(json, 'requested_for_user_sid') ? undefined : json['requested_for_user_sid'],
        'requestedForDeviceSid': !exists(json, 'requested_for_device_sid') ? undefined : json['requested_for_device_sid'],
    };
}

export function StreemApiCreateStreemshotRequestToJSON(value?: StreemApiCreateStreemshotRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'requested_by_user_sid': value.requestedByUserSid,
        'requested_by_device_sid': value.requestedByDeviceSid,
        'requested_for_user_sid': value.requestedForUserSid,
        'requested_for_device_sid': value.requestedForDeviceSid,
    };
}


