/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAnalyticEventArtifactRecording,
    StreemApiAnalyticEventArtifactRecordingFromJSON,
    StreemApiAnalyticEventArtifactRecordingFromJSONTyped,
    StreemApiAnalyticEventArtifactRecordingToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEventArtifact
 */
export interface StreemApiAnalyticEventArtifact {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventArtifact
     */
    artifactSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventArtifact
     */
    roomSid?: string;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    streemshot?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    mesh?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    gps?: object;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactRecording}
     * @memberof StreemApiAnalyticEventArtifact
     */
    recording?: StreemApiAnalyticEventArtifactRecording;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    sceneshot?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    layoutEstimation?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    recordingTrack?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    transcription?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    texturedMesh?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEventArtifact
     */
    bookmark?: object;
}

export function StreemApiAnalyticEventArtifactFromJSON(json: any): StreemApiAnalyticEventArtifact {
    return StreemApiAnalyticEventArtifactFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactSid': !exists(json, 'artifact_sid') ? undefined : json['artifact_sid'],
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'streemshot': !exists(json, 'streemshot') ? undefined : json['streemshot'],
        'mesh': !exists(json, 'mesh') ? undefined : json['mesh'],
        'gps': !exists(json, 'gps') ? undefined : json['gps'],
        'recording': !exists(json, 'recording') ? undefined : StreemApiAnalyticEventArtifactRecordingFromJSON(json['recording']),
        'sceneshot': !exists(json, 'sceneshot') ? undefined : json['sceneshot'],
        'layoutEstimation': !exists(json, 'layout_estimation') ? undefined : json['layout_estimation'],
        'recordingTrack': !exists(json, 'recording_track') ? undefined : json['recording_track'],
        'transcription': !exists(json, 'transcription') ? undefined : json['transcription'],
        'texturedMesh': !exists(json, 'textured_mesh') ? undefined : json['textured_mesh'],
        'bookmark': !exists(json, 'bookmark') ? undefined : json['bookmark'],
    };
}

export function StreemApiAnalyticEventArtifactToJSON(value?: StreemApiAnalyticEventArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifact_sid': value.artifactSid,
        'room_sid': value.roomSid,
        'streemshot': value.streemshot,
        'mesh': value.mesh,
        'gps': value.gps,
        'recording': StreemApiAnalyticEventArtifactRecordingToJSON(value.recording),
        'sceneshot': value.sceneshot,
        'layout_estimation': value.layoutEstimation,
        'recording_track': value.recordingTrack,
        'transcription': value.transcription,
        'textured_mesh': value.texturedMesh,
        'bookmark': value.bookmark,
    };
}


