/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiPose
 */
export interface StreemApiPose {
    /**
     * 
     * @type {Array<number>}
     * @memberof StreemApiPose
     */
    position?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof StreemApiPose
     */
    rotation?: Array<number>;
}

export function StreemApiPoseFromJSON(json: any): StreemApiPose {
    return StreemApiPoseFromJSONTyped(json, false);
}

export function StreemApiPoseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiPose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': !exists(json, 'position') ? undefined : json['position'],
        'rotation': !exists(json, 'rotation') ? undefined : json['rotation'],
    };
}

export function StreemApiPoseToJSON(value?: StreemApiPose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'rotation': value.rotation,
    };
}


