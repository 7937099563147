/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSize
 */
export interface StreemApiSize {
    /**
     * 
     * @type {number}
     * @memberof StreemApiSize
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiSize
     */
    height?: number;
}

export function StreemApiSizeFromJSON(json: any): StreemApiSize {
    return StreemApiSizeFromJSONTyped(json, false);
}

export function StreemApiSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSize {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function StreemApiSizeToJSON(value?: StreemApiSize | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
    };
}


