/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiTagDefinition,
    StreemApiTagDefinitionFromJSON,
    StreemApiTagDefinitionFromJSONTyped,
    StreemApiTagDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetTagDefinitionResponse
 */
export interface StreemApiGetTagDefinitionResponse {
    /**
     * 
     * @type {StreemApiTagDefinition}
     * @memberof StreemApiGetTagDefinitionResponse
     */
    tag?: StreemApiTagDefinition;
}

export function StreemApiGetTagDefinitionResponseFromJSON(json: any): StreemApiGetTagDefinitionResponse {
    return StreemApiGetTagDefinitionResponseFromJSONTyped(json, false);
}

export function StreemApiGetTagDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetTagDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': !exists(json, 'tag') ? undefined : StreemApiTagDefinitionFromJSON(json['tag']),
    };
}

export function StreemApiGetTagDefinitionResponseToJSON(value?: StreemApiGetTagDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': StreemApiTagDefinitionToJSON(value.tag),
    };
}


