/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiTagDefinition,
    StreemApiTagDefinitionFromJSON,
    StreemApiTagDefinitionFromJSONTyped,
    StreemApiTagDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListTagDefinitionsResponse
 */
export interface StreemApiListTagDefinitionsResponse {
    /**
     * 
     * @type {Array<StreemApiTagDefinition>}
     * @memberof StreemApiListTagDefinitionsResponse
     */
    tags?: Array<StreemApiTagDefinition>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListTagDefinitionsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListTagDefinitionsResponse
     */
    totalSize?: number;
}

export function StreemApiListTagDefinitionsResponseFromJSON(json: any): StreemApiListTagDefinitionsResponse {
    return StreemApiListTagDefinitionsResponseFromJSONTyped(json, false);
}

export function StreemApiListTagDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListTagDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(StreemApiTagDefinitionFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListTagDefinitionsResponseToJSON(value?: StreemApiListTagDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(StreemApiTagDefinitionToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


