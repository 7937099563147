/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiPublicLoginConfigurationOpenId
 */
export interface StreemApiPublicLoginConfigurationOpenId {
    /**
     * 
     * @type {string}
     * @memberof StreemApiPublicLoginConfigurationOpenId
     */
    tokenEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiPublicLoginConfigurationOpenId
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiPublicLoginConfigurationOpenId
     */
    authorizationEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiPublicLoginConfigurationOpenId
     */
    idpHint?: string;
}

export function StreemApiPublicLoginConfigurationOpenIdFromJSON(json: any): StreemApiPublicLoginConfigurationOpenId {
    return StreemApiPublicLoginConfigurationOpenIdFromJSONTyped(json, false);
}

export function StreemApiPublicLoginConfigurationOpenIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiPublicLoginConfigurationOpenId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tokenEndpoint': !exists(json, 'token_endpoint') ? undefined : json['token_endpoint'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'authorizationEndpoint': !exists(json, 'authorization_endpoint') ? undefined : json['authorization_endpoint'],
        'idpHint': !exists(json, 'idp_hint') ? undefined : json['idp_hint'],
    };
}

export function StreemApiPublicLoginConfigurationOpenIdToJSON(value?: StreemApiPublicLoginConfigurationOpenId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token_endpoint': value.tokenEndpoint,
        'client_id': value.clientId,
        'authorization_endpoint': value.authorizationEndpoint,
        'idp_hint': value.idpHint,
    };
}


