import { datadogRum } from '@datadog/browser-rum';
import { config } from '../config';

export function initializeDatadogRum(): string | undefined {
    if (config.datadogRumEnabled) {
        // Not using app_logger here, since that would make a circular dependency
        console.log('[datadog] Initializing...');

        datadogRum.init({
            applicationId: config.datadogApplicationId,
            clientToken: config.datadogClientToken,
            env: config.reactAppEnv,
            site: 'datadoghq.com',
            service: 'customer-view', // must match project-data datadogService for sourcemaps
            sampleRate: 100,
            trackInteractions: true,
            allowedTracingOrigins: [/https:\/\/.*\.streem\.cloud/],
            useSecureSessionCookie: true,
            useCrossSiteSessionCookie: true,
            trackSessionAcrossSubdomains: true,
            version: config.buildTag,
        });

        // eslint-disable-next-line camelcase
        return datadogRum.getInternalContext()?.session_id;
    }
}

export function addUserToDatadogRumSession(internalUserId: string) {
    if (config.datadogRumEnabled) {
        datadogRum.setUser({
            id: internalUserId,
        });
    }
}

export function addRegionCodeToDatadogRumSession(regionCode: string) {
    if (config.datadogRumEnabled) {
        datadogRum.addRumGlobalContext('region_code', regionCode);
    }
}

export function addCompanyCodeToDatadogRumSession(companyCode: string) {
    if (config.datadogRumEnabled) {
        datadogRum.addRumGlobalContext('company_code', companyCode);
    }
}
