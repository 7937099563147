/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactRecordingTrack
 */
export interface StreemApiArtifactRecordingTrack {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    bucket?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    userSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    trackType?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    codec?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    status?: StreemApiArtifactRecordingTrackStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    requestedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    startedRecordingAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    finishedRecordingAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    startedTransferringAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactRecordingTrack
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    recordingSource?: StreemApiArtifactRecordingTrackRecordingSource;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactRecordingTrack
     */
    sourceRecordingTrackSid?: string;
}

export function StreemApiArtifactRecordingTrackFromJSON(json: any): StreemApiArtifactRecordingTrack {
    return StreemApiArtifactRecordingTrackFromJSONTyped(json, false);
}

export function StreemApiArtifactRecordingTrackFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactRecordingTrack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bucket': !exists(json, 'bucket') ? undefined : json['bucket'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'userSid': !exists(json, 'user_sid') ? undefined : json['user_sid'],
        'trackType': !exists(json, 'track_type') ? undefined : json['track_type'],
        'codec': !exists(json, 'codec') ? undefined : json['codec'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'startedRecordingAt': !exists(json, 'started_recording_at') ? undefined : (new Date(json['started_recording_at'])),
        'finishedRecordingAt': !exists(json, 'finished_recording_at') ? undefined : (new Date(json['finished_recording_at'])),
        'startedTransferringAt': !exists(json, 'started_transferring_at') ? undefined : (new Date(json['started_transferring_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'recordingSource': !exists(json, 'recording_source') ? undefined : json['recording_source'],
        'sourceRecordingTrackSid': !exists(json, 'source_recording_track_sid') ? undefined : json['source_recording_track_sid'],
    };
}

export function StreemApiArtifactRecordingTrackToJSON(value?: StreemApiArtifactRecordingTrack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bucket': value.bucket,
        'filename': value.filename,
        'user_sid': value.userSid,
        'track_type': value.trackType,
        'codec': value.codec,
        'storage_url': value.storageUrl,
        'status': value.status,
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'started_recording_at': value.startedRecordingAt === undefined ? undefined : (value.startedRecordingAt.toISOString()),
        'finished_recording_at': value.finishedRecordingAt === undefined ? undefined : (value.finishedRecordingAt.toISOString()),
        'started_transferring_at': value.startedTransferringAt === undefined ? undefined : (value.startedTransferringAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'url': value.url,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'recording_source': value.recordingSource,
        'source_recording_track_sid': value.sourceRecordingTrackSid,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRecordingTrackStatus {
    STARTEDRECORDING = 'STATUS_STARTED_RECORDING',
    FINISHEDRECORDING = 'STATUS_FINISHED_RECORDING',
    TRANSFERRING = 'STATUS_TRANSFERRING',
    ERROR = 'STATUS_ERROR',
    FULFILLED = 'STATUS_FULFILLED',
    INVALID = 'STATUS_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactRecordingTrackRecordingSource {
    TWILIO = 'SOURCE_TWILIO',
    EXTERNAL = 'SOURCE_EXTERNAL',
    MUXER = 'SOURCE_MUXER',
    ARAAS = 'SOURCE_ARAAS',
    CHIME = 'SOURCE_CHIME',
    INVALID = 'SOURCE_INVALID'
}


