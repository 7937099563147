/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase
 */
export interface StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase {
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase
     */
    assetsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase
     */
    assetsTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase
     */
    status?: StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseStatus;
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSON(json: any): StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase {
    return StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetsCount': !exists(json, 'assets_count') ? undefined : json['assets_count'],
        'assetsTotal': !exists(json, 'assets_total') ? undefined : json['assets_total'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseToJSON(value?: StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets_count': value.assetsCount,
        'assets_total': value.assetsTotal,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventGuidedExperiencePhaseUpdatedAssetCapturePhaseStatus {
    PLACED = 'ASSET_STATUS_PLACED',
    MOVED = 'ASSET_STATUS_MOVED',
    CAPTURED = 'ASSET_STATUS_CAPTURED',
    REMOVED = 'ASSET_STATUS_REMOVED',
    INVALID = 'ASSET_STATUS_INVALID'
}


