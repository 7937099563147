/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAttendee,
    StreemApiAttendeeFromJSON,
    StreemApiAttendeeFromJSONTyped,
    StreemApiAttendeeToJSON,
    StreemApiMeeting,
    StreemApiMeetingFromJSON,
    StreemApiMeetingFromJSONTyped,
    StreemApiMeetingToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiChimeRoomVideoCredentials
 */
export interface StreemApiChimeRoomVideoCredentials {
    /**
     * 
     * @type {StreemApiMeeting}
     * @memberof StreemApiChimeRoomVideoCredentials
     */
    meeting?: StreemApiMeeting;
    /**
     * 
     * @type {StreemApiAttendee}
     * @memberof StreemApiChimeRoomVideoCredentials
     */
    attendee?: StreemApiAttendee;
}

export function StreemApiChimeRoomVideoCredentialsFromJSON(json: any): StreemApiChimeRoomVideoCredentials {
    return StreemApiChimeRoomVideoCredentialsFromJSONTyped(json, false);
}

export function StreemApiChimeRoomVideoCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiChimeRoomVideoCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meeting': !exists(json, 'meeting') ? undefined : StreemApiMeetingFromJSON(json['meeting']),
        'attendee': !exists(json, 'attendee') ? undefined : StreemApiAttendeeFromJSON(json['attendee']),
    };
}

export function StreemApiChimeRoomVideoCredentialsToJSON(value?: StreemApiChimeRoomVideoCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meeting': StreemApiMeetingToJSON(value.meeting),
        'attendee': StreemApiAttendeeToJSON(value.attendee),
    };
}


