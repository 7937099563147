/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemCameraSubjectRequest
 */
export interface WallItemCameraSubjectRequest {
    /**
     * 
     * @type {string}
     * @memberof WallItemCameraSubjectRequest
     */
    requestedForUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemCameraSubjectRequest
     */
    requestedForDeviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemCameraSubjectRequest
     */
    requestedCameraSubject?: WallItemCameraSubjectRequestRequestedCameraSubject;
}

export function WallItemCameraSubjectRequestFromJSON(json: any): WallItemCameraSubjectRequest {
    return WallItemCameraSubjectRequestFromJSONTyped(json, false);
}

export function WallItemCameraSubjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemCameraSubjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedForUserId': !exists(json, 'requested_for_user_id') ? undefined : json['requested_for_user_id'],
        'requestedForDeviceId': !exists(json, 'requested_for_device_id') ? undefined : json['requested_for_device_id'],
        'requestedCameraSubject': !exists(json, 'requested_camera_subject') ? undefined : json['requested_camera_subject'],
    };
}

export function WallItemCameraSubjectRequestToJSON(value?: WallItemCameraSubjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requested_for_user_id': value.requestedForUserId,
        'requested_for_device_id': value.requestedForDeviceId,
        'requested_camera_subject': value.requestedCameraSubject,
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemCameraSubjectRequestRequestedCameraSubject {
    WORLD = 'CAMERA_SUBJECT_WORLD',
    PERSON = 'CAMERA_SUBJECT_PERSON',
    SCREEN = 'CAMERA_SUBJECT_SCREEN'
}


