/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventInviteClicked
 */
export interface StreemApiAnalyticEventInviteClicked {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventInviteClicked
     */
    inviteSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventInviteClicked
     */
    userAgent?: string;
}

export function StreemApiAnalyticEventInviteClickedFromJSON(json: any): StreemApiAnalyticEventInviteClicked {
    return StreemApiAnalyticEventInviteClickedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventInviteClickedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventInviteClicked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inviteSid': !exists(json, 'invite_sid') ? undefined : json['invite_sid'],
        'userAgent': !exists(json, 'user_agent') ? undefined : json['user_agent'],
    };
}

export function StreemApiAnalyticEventInviteClickedToJSON(value?: StreemApiAnalyticEventInviteClicked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invite_sid': value.inviteSid,
        'user_agent': value.userAgent,
    };
}


