/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactLayoutEstimation
 */
export interface StreemApiArtifactLayoutEstimation {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    meshSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    status?: StreemApiArtifactLayoutEstimationStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactLayoutEstimation
     */
    capturedAt?: Date;
}

export function StreemApiArtifactLayoutEstimationFromJSON(json: any): StreemApiArtifactLayoutEstimation {
    return StreemApiArtifactLayoutEstimationFromJSONTyped(json, false);
}

export function StreemApiArtifactLayoutEstimationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactLayoutEstimation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meshSid': !exists(json, 'mesh_sid') ? undefined : json['mesh_sid'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'capturedAt': !exists(json, 'captured_at') ? undefined : (new Date(json['captured_at'])),
    };
}

export function StreemApiArtifactLayoutEstimationToJSON(value?: StreemApiArtifactLayoutEstimation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mesh_sid': value.meshSid,
        'storage_url': value.storageUrl,
        'status': value.status,
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'url': value.url,
        'captured_at': value.capturedAt === undefined ? undefined : (value.capturedAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactLayoutEstimationStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}


