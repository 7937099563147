/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallSource,
    StreemApiCallSourceFromJSON,
    StreemApiCallSourceFromJSONTyped,
    StreemApiCallSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetCompanyCallSourcesResponse
 */
export interface StreemApiGetCompanyCallSourcesResponse {
    /**
     * 
     * @type {Array<StreemApiCallSource>}
     * @memberof StreemApiGetCompanyCallSourcesResponse
     */
    callSources?: Array<StreemApiCallSource>;
}

export function StreemApiGetCompanyCallSourcesResponseFromJSON(json: any): StreemApiGetCompanyCallSourcesResponse {
    return StreemApiGetCompanyCallSourcesResponseFromJSONTyped(json, false);
}

export function StreemApiGetCompanyCallSourcesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetCompanyCallSourcesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callSources': !exists(json, 'call_sources') ? undefined : ((json['call_sources'] as Array<any>).map(StreemApiCallSourceFromJSON)),
    };
}

export function StreemApiGetCompanyCallSourcesResponseToJSON(value?: StreemApiGetCompanyCallSourcesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_sources': value.callSources === undefined ? undefined : ((value.callSources as Array<any>).map(StreemApiCallSourceToJSON)),
    };
}


