/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCountry
 */
export interface StreemApiCountry {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCountry
     */
    callingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCountry
     */
    isoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCountry
     */
    countryName?: string;
}

export function StreemApiCountryFromJSON(json: any): StreemApiCountry {
    return StreemApiCountryFromJSONTyped(json, false);
}

export function StreemApiCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callingCode': !exists(json, 'calling_code') ? undefined : json['calling_code'],
        'isoCode': !exists(json, 'iso_code') ? undefined : json['iso_code'],
        'countryName': !exists(json, 'country_name') ? undefined : json['country_name'],
    };
}

export function StreemApiCountryToJSON(value?: StreemApiCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calling_code': value.callingCode,
        'iso_code': value.isoCode,
        'country_name': value.countryName,
    };
}


