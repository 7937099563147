/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAuthIdentity,
    StreemApiAuthIdentityFromJSON,
    StreemApiAuthIdentityFromJSONTyped,
    StreemApiAuthIdentityToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetCurrentAuthIdentityResponse
 */
export interface StreemApiGetCurrentAuthIdentityResponse {
    /**
     * 
     * @type {StreemApiAuthIdentity}
     * @memberof StreemApiGetCurrentAuthIdentityResponse
     */
    authIdentity?: StreemApiAuthIdentity;
}

export function StreemApiGetCurrentAuthIdentityResponseFromJSON(json: any): StreemApiGetCurrentAuthIdentityResponse {
    return StreemApiGetCurrentAuthIdentityResponseFromJSONTyped(json, false);
}

export function StreemApiGetCurrentAuthIdentityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetCurrentAuthIdentityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authIdentity': !exists(json, 'auth_identity') ? undefined : StreemApiAuthIdentityFromJSON(json['auth_identity']),
    };
}

export function StreemApiGetCurrentAuthIdentityResponseToJSON(value?: StreemApiGetCurrentAuthIdentityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth_identity': StreemApiAuthIdentityToJSON(value.authIdentity),
    };
}


