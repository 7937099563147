import { FC } from 'react';
import 'focus-visible';
import Theme from '@streem/ui-react/lib/styles/theme';
import { SafariLandscapeFullHeightHack, Global, css } from '@streem/ui-react';

const styles = css`
    body {
        overflow: hidden;
    }
    html,
    body,
    #root {
        background: ${Theme.colors.black};
        color: ${Theme.colors.grey90};
    }
    /* these two settings (removing position absolute and setting display: flex) are required for fixing the safari landscape bug where there's a gap for the bottom half-inch of the screen only on physical iPhones. */
    html .ReactModalPortal {
        position: unset;
        display: flex;
    }
    .ReactModal__Overlay {
        ${SafariLandscapeFullHeightHack}
    }
`;

const CV2GlobalStyles: FC = () => <Global styles={styles} />;

export default CV2GlobalStyles;
