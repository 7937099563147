/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventScreenViewed
 */
export interface StreemApiAnalyticEventScreenViewed {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventScreenViewed
     */
    screenId?: string;
}

export function StreemApiAnalyticEventScreenViewedFromJSON(json: any): StreemApiAnalyticEventScreenViewed {
    return StreemApiAnalyticEventScreenViewedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventScreenViewedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventScreenViewed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'screenId': !exists(json, 'screen_id') ? undefined : json['screen_id'],
    };
}

export function StreemApiAnalyticEventScreenViewedToJSON(value?: StreemApiAnalyticEventScreenViewed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'screen_id': value.screenId,
    };
}


