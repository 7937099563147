/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiArtifactTexturedMesh
 */
export interface StreemApiArtifactTexturedMesh {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    meshSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    glbStorageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    usdzStorageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    status?: StreemApiArtifactTexturedMeshStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTexturedMesh
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTexturedMesh
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactTexturedMesh
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    glbUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    usdzUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    videoRecordingTrackSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactTexturedMesh
     */
    videoRecordingSid?: string;
}

export function StreemApiArtifactTexturedMeshFromJSON(json: any): StreemApiArtifactTexturedMesh {
    return StreemApiArtifactTexturedMeshFromJSONTyped(json, false);
}

export function StreemApiArtifactTexturedMeshFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactTexturedMesh {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meshSid': !exists(json, 'mesh_sid') ? undefined : json['mesh_sid'],
        'glbStorageUrl': !exists(json, 'glb_storage_url') ? undefined : json['glb_storage_url'],
        'usdzStorageUrl': !exists(json, 'usdz_storage_url') ? undefined : json['usdz_storage_url'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'glbUrl': !exists(json, 'glb_url') ? undefined : json['glb_url'],
        'usdzUrl': !exists(json, 'usdz_url') ? undefined : json['usdz_url'],
        'videoRecordingTrackSid': !exists(json, 'video_recording_track_sid') ? undefined : json['video_recording_track_sid'],
        'videoRecordingSid': !exists(json, 'video_recording_sid') ? undefined : json['video_recording_sid'],
    };
}

export function StreemApiArtifactTexturedMeshToJSON(value?: StreemApiArtifactTexturedMesh | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mesh_sid': value.meshSid,
        'glb_storage_url': value.glbStorageUrl,
        'usdz_storage_url': value.usdzStorageUrl,
        'status': value.status,
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'glb_url': value.glbUrl,
        'usdz_url': value.usdzUrl,
        'video_recording_track_sid': value.videoRecordingTrackSid,
        'video_recording_sid': value.videoRecordingSid,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactTexturedMeshStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}


