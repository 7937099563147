/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WallItemMeasureTool
 */
export interface WallItemMeasureTool {
    /**
     * 
     * @type {boolean}
     * @memberof WallItemMeasureTool
     */
    active?: boolean;
}

export function WallItemMeasureToolFromJSON(json: any): WallItemMeasureTool {
    return WallItemMeasureToolFromJSONTyped(json, false);
}

export function WallItemMeasureToolFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemMeasureTool {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function WallItemMeasureToolToJSON(value?: WallItemMeasureTool | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
    };
}


