/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiExternalUser,
    StreemApiExternalUserFromJSON,
    StreemApiExternalUserFromJSONTyped,
    StreemApiExternalUserToJSON,
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetUserByExternalUserIdResponse
 */
export interface StreemApiGetUserByExternalUserIdResponse {
    /**
     * 
     * @type {StreemApiExternalUser}
     * @memberof StreemApiGetUserByExternalUserIdResponse
     */
    externalUser?: StreemApiExternalUser;
    /**
     * 
     * @type {StreemApiUser}
     * @memberof StreemApiGetUserByExternalUserIdResponse
     */
    user?: StreemApiUser;
}

export function StreemApiGetUserByExternalUserIdResponseFromJSON(json: any): StreemApiGetUserByExternalUserIdResponse {
    return StreemApiGetUserByExternalUserIdResponseFromJSONTyped(json, false);
}

export function StreemApiGetUserByExternalUserIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetUserByExternalUserIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalUser': !exists(json, 'external_user') ? undefined : StreemApiExternalUserFromJSON(json['external_user']),
        'user': !exists(json, 'user') ? undefined : StreemApiUserFromJSON(json['user']),
    };
}

export function StreemApiGetUserByExternalUserIdResponseToJSON(value?: StreemApiGetUserByExternalUserIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user': StreemApiExternalUserToJSON(value.externalUser),
        'user': StreemApiUserToJSON(value.user),
    };
}


