/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateUserAssetResponse
 */
export interface StreemApiCreateUserAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateUserAssetResponse
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateUserAssetResponse
     */
    downloadUrl?: string;
}

export function StreemApiCreateUserAssetResponseFromJSON(json: any): StreemApiCreateUserAssetResponse {
    return StreemApiCreateUserAssetResponseFromJSONTyped(json, false);
}

export function StreemApiCreateUserAssetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateUserAssetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'downloadUrl': !exists(json, 'download_url') ? undefined : json['download_url'],
    };
}

export function StreemApiCreateUserAssetResponseToJSON(value?: StreemApiCreateUserAssetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storage_url': value.storageUrl,
        'download_url': value.downloadUrl,
    };
}


