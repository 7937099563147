/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateRoomInvitationRequest
 */
export interface StreemApiCreateRoomInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateRoomInvitationRequest
     */
    fromUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateRoomInvitationRequest
     */
    fromDeviceSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateRoomInvitationRequest
     */
    toUserSid?: string;
}

export function StreemApiCreateRoomInvitationRequestFromJSON(json: any): StreemApiCreateRoomInvitationRequest {
    return StreemApiCreateRoomInvitationRequestFromJSONTyped(json, false);
}

export function StreemApiCreateRoomInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateRoomInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromUserSid': !exists(json, 'from_user_sid') ? undefined : json['from_user_sid'],
        'fromDeviceSid': !exists(json, 'from_device_sid') ? undefined : json['from_device_sid'],
        'toUserSid': !exists(json, 'to_user_sid') ? undefined : json['to_user_sid'],
    };
}

export function StreemApiCreateRoomInvitationRequestToJSON(value?: StreemApiCreateRoomInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user_sid': value.fromUserSid,
        'from_device_sid': value.fromDeviceSid,
        'to_user_sid': value.toUserSid,
    };
}


