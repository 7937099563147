/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemRoomArTrackableDefinitionPlane
 */
export interface StreemRoomArTrackableDefinitionPlane {
    /**
     * 
     * @type {boolean}
     * @memberof StreemRoomArTrackableDefinitionPlane
     */
    horizontal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemRoomArTrackableDefinitionPlane
     */
    vertical?: boolean;
}

export function StreemRoomArTrackableDefinitionPlaneFromJSON(json: any): StreemRoomArTrackableDefinitionPlane {
    return StreemRoomArTrackableDefinitionPlaneFromJSONTyped(json, false);
}

export function StreemRoomArTrackableDefinitionPlaneFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemRoomArTrackableDefinitionPlane {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'horizontal': !exists(json, 'horizontal') ? undefined : json['horizontal'],
        'vertical': !exists(json, 'vertical') ? undefined : json['vertical'],
    };
}

export function StreemRoomArTrackableDefinitionPlaneToJSON(value?: StreemRoomArTrackableDefinitionPlane | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'horizontal': value.horizontal,
        'vertical': value.vertical,
    };
}


