/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiWebhookHeader
 */
export interface StreemApiWebhookHeader {
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookHeader
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookHeader
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiWebhookHeader
     */
    includeInRequestSignature?: boolean;
}

export function StreemApiWebhookHeaderFromJSON(json: any): StreemApiWebhookHeader {
    return StreemApiWebhookHeaderFromJSONTyped(json, false);
}

export function StreemApiWebhookHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWebhookHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'includeInRequestSignature': !exists(json, 'include_in_request_signature') ? undefined : json['include_in_request_signature'],
    };
}

export function StreemApiWebhookHeaderToJSON(value?: StreemApiWebhookHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'include_in_request_signature': value.includeInRequestSignature,
    };
}


