declare global {
    interface Window {
        env: any;
    }
}

interface CVConfig {
    reactAppEnv: string;
    globalDomain: string;
    appId: string;
    googleMapsKey: string;
    isDevelopment: boolean;
    isTest: boolean;
    isProduction: boolean;
    urls: {
        globalApi: string;
        scribe: string;
    };
    datadogClientToken: string;
    datadogApplicationId: string;
    datadogRumEnabled: boolean;
    buildTag: string;
    shouldShipLogs: boolean;
}

const retrieve = (envKey: string) =>
    window.__ENV && window.__ENV[envKey] ? window.__ENV[envKey] : process.env[envKey];

const nodeEnv = retrieve('NODE_ENV');
const globalDomain = retrieve('REACT_APP_DOMAIN'); // e.g. 'demo.streem.cloud'
const appId = retrieve('REACT_APP_CV_APP_ID');
const reactAppEnv = retrieve('REACT_APP_ENV'); // e.g. 'dev'

const googleMapsKey = retrieve('REACT_APP_GOOGLE_MAPS_API_KEY');
const datadogRumEnabled = retrieve('REACT_APP_DATADOG_RUM_ENABLED') === 'true';
const datadogClientToken = retrieve('REACT_APP_CV_DATADOG_CLIENT_TOKEN');
const datadogApplicationId = retrieve('REACT_APP_CV_DATADOG_APPLICATION_ID');

const isDevelopment = nodeEnv === 'development';
const isTest = nodeEnv === 'test';

// local development config items that will never be in __ENV file
const shipLocalLogs = process.env.DEV_SHIP_LOGS == 'true';

// STREEM-13347 for now always use `us` API env for scribe logs (unless it's a local env)
function getScribeUrl(env: string | undefined) {
    const apiEnv = env?.includes('local') ? env : `${env}-us`;
    return `https://scribe.${apiEnv}.streem.cloud`;
}

export const config: CVConfig = {
    reactAppEnv,
    isDevelopment,
    isTest,
    isProduction: nodeEnv === 'production',
    urls: {
        globalApi: `https://global-api.${globalDomain}`,
        scribe: getScribeUrl(reactAppEnv),
    },
    globalDomain,
    appId,
    googleMapsKey: googleMapsKey,
    datadogClientToken,
    datadogApplicationId,
    datadogRumEnabled,
    buildTag: process.env.BUILD_TAG || window.env?.BUILD_TAG,
    shouldShipLogs: isDevelopment ? shipLocalLogs : !isTest,
} as const;
