/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiWebhookSigningKey
 */
export interface StreemApiWebhookSigningKey {
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookSigningKey
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookSigningKey
     */
    webhookSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookSigningKey
     */
    sharedSecret?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiWebhookSigningKey
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiWebhookSigningKey
     */
    label?: string;
}

export function StreemApiWebhookSigningKeyFromJSON(json: any): StreemApiWebhookSigningKey {
    return StreemApiWebhookSigningKeyFromJSONTyped(json, false);
}

export function StreemApiWebhookSigningKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWebhookSigningKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'webhookSid': !exists(json, 'webhook_sid') ? undefined : json['webhook_sid'],
        'sharedSecret': !exists(json, 'shared_secret') ? undefined : json['shared_secret'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function StreemApiWebhookSigningKeyToJSON(value?: StreemApiWebhookSigningKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'webhook_sid': value.webhookSid,
        'shared_secret': value.sharedSecret,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'label': value.label,
    };
}


