/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CameraIntrinsics,
    CameraIntrinsicsFromJSON,
    CameraIntrinsicsFromJSONTyped,
    CameraIntrinsicsToJSON,
    StreemApiPoint,
    StreemApiPointFromJSON,
    StreemApiPointFromJSONTyped,
    StreemApiPointToJSON,
    StreemApiPose,
    StreemApiPoseFromJSON,
    StreemApiPoseFromJSONTyped,
    StreemApiPoseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiFulfillStreemshotRequest
 */
export interface StreemApiFulfillStreemshotRequest {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    fulfilledByUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    fulfilledByDeviceSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    storageUrl?: string;
    /**
     * 
     * @type {StreemApiPose}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    pose?: StreemApiPose;
    /**
     * 
     * @type {Array<StreemApiPoint>}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    featurePoints?: Array<StreemApiPoint>;
    /**
     * 
     * @type {CameraIntrinsics}
     * @memberof StreemApiFulfillStreemshotRequest
     */
    cameraIntrinsics?: CameraIntrinsics;
}

export function StreemApiFulfillStreemshotRequestFromJSON(json: any): StreemApiFulfillStreemshotRequest {
    return StreemApiFulfillStreemshotRequestFromJSONTyped(json, false);
}

export function StreemApiFulfillStreemshotRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiFulfillStreemshotRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'fulfilledByUserSid': !exists(json, 'fulfilled_by_user_sid') ? undefined : json['fulfilled_by_user_sid'],
        'fulfilledByDeviceSid': !exists(json, 'fulfilled_by_device_sid') ? undefined : json['fulfilled_by_device_sid'],
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'pose': !exists(json, 'pose') ? undefined : StreemApiPoseFromJSON(json['pose']),
        'featurePoints': !exists(json, 'feature_points') ? undefined : ((json['feature_points'] as Array<any>).map(StreemApiPointFromJSON)),
        'cameraIntrinsics': !exists(json, 'camera_intrinsics') ? undefined : CameraIntrinsicsFromJSON(json['camera_intrinsics']),
    };
}

export function StreemApiFulfillStreemshotRequestToJSON(value?: StreemApiFulfillStreemshotRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'fulfilled_by_user_sid': value.fulfilledByUserSid,
        'fulfilled_by_device_sid': value.fulfilledByDeviceSid,
        'storage_url': value.storageUrl,
        'pose': StreemApiPoseToJSON(value.pose),
        'feature_points': value.featurePoints === undefined ? undefined : ((value.featurePoints as Array<any>).map(StreemApiPointToJSON)),
        'camera_intrinsics': CameraIntrinsicsToJSON(value.cameraIntrinsics),
    };
}


