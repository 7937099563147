/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateCompanyUserRequest
 */
export interface StreemApiCreateCompanyUserRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyUserRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyUserRequest
     */
    userType?: StreemApiCreateCompanyUserRequestUserType;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiCreateCompanyUserRequest
     */
    groups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyUserRequest
     */
    bio?: string;
}

export function StreemApiCreateCompanyUserRequestFromJSON(json: any): StreemApiCreateCompanyUserRequest {
    return StreemApiCreateCompanyUserRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'userType': !exists(json, 'user_type') ? undefined : json['user_type'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'bio': !exists(json, 'bio') ? undefined : json['bio'],
    };
}

export function StreemApiCreateCompanyUserRequestToJSON(value?: StreemApiCreateCompanyUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'user_type': value.userType,
        'groups': value.groups,
        'bio': value.bio,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiCreateCompanyUserRequestUserType {
    UNSPECIFIED = 'USER_TYPE_UNSPECIFIED',
    LOGIN = 'USER_TYPE_LOGIN',
    SDK = 'USER_TYPE_SDK',
    INVITED = 'USER_TYPE_INVITED'
}


