/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroup,
    StreemApiGroupFromJSON,
    StreemApiGroupFromJSONTyped,
    StreemApiGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListUserGroupsResponse
 */
export interface StreemApiListUserGroupsResponse {
    /**
     * 
     * @type {Array<StreemApiGroup>}
     * @memberof StreemApiListUserGroupsResponse
     */
    groups?: Array<StreemApiGroup>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListUserGroupsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListUserGroupsResponse
     */
    totalSize?: number;
}

export function StreemApiListUserGroupsResponseFromJSON(json: any): StreemApiListUserGroupsResponse {
    return StreemApiListUserGroupsResponseFromJSONTyped(json, false);
}

export function StreemApiListUserGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListUserGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(StreemApiGroupFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListUserGroupsResponseToJSON(value?: StreemApiListUserGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(StreemApiGroupToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


