import { useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function InvalidLinkPage() {
    useEffect(() => {
        appLogger.info('User arrived at Invalid Link Page');
    }, []);

    return (
        <ErrorBox
            testid="invalid-link"
            headerText="This link is not valid"
            messageText="Please double check the URL or speak with your representative and try again."
        />
    );
}
