/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCreateSignedDownloadUrlRequest
 */
export interface StreemApiCreateSignedDownloadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedDownloadUrlRequest
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedDownloadUrlRequest
     */
    scope?: StreemApiCreateSignedDownloadUrlRequestScope;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedDownloadUrlRequest
     */
    scopeSid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCreateSignedDownloadUrlRequest
     */
    attachment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateSignedDownloadUrlRequest
     */
    filename?: string;
}

export function StreemApiCreateSignedDownloadUrlRequestFromJSON(json: any): StreemApiCreateSignedDownloadUrlRequest {
    return StreemApiCreateSignedDownloadUrlRequestFromJSONTyped(json, false);
}

export function StreemApiCreateSignedDownloadUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateSignedDownloadUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'scopeSid': !exists(json, 'scope_sid') ? undefined : json['scope_sid'],
        'attachment': !exists(json, 'attachment') ? undefined : json['attachment'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
    };
}

export function StreemApiCreateSignedDownloadUrlRequestToJSON(value?: StreemApiCreateSignedDownloadUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storage_url': value.storageUrl,
        'scope': value.scope,
        'scope_sid': value.scopeSid,
        'attachment': value.attachment,
        'filename': value.filename,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiCreateSignedDownloadUrlRequestScope {
    ROOM = 'SCOPE_ROOM',
    COMPANY = 'SCOPE_COMPANY',
    INVALID = 'SCOPE_INVALID'
}


