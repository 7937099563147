/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallSource,
    StreemApiCallSourceFromJSON,
    StreemApiCallSourceFromJSONTyped,
    StreemApiCallSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetCompanyCallSourceResponse
 */
export interface StreemApiGetCompanyCallSourceResponse {
    /**
     * 
     * @type {StreemApiCallSource}
     * @memberof StreemApiGetCompanyCallSourceResponse
     */
    callSource?: StreemApiCallSource;
}

export function StreemApiGetCompanyCallSourceResponseFromJSON(json: any): StreemApiGetCompanyCallSourceResponse {
    return StreemApiGetCompanyCallSourceResponseFromJSONTyped(json, false);
}

export function StreemApiGetCompanyCallSourceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetCompanyCallSourceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callSource': !exists(json, 'call_source') ? undefined : StreemApiCallSourceFromJSON(json['call_source']),
    };
}

export function StreemApiGetCompanyCallSourceResponseToJSON(value?: StreemApiGetCompanyCallSourceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_source': StreemApiCallSourceToJSON(value.callSource),
    };
}


