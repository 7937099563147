/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DestinationExit,
    DestinationExitFromJSON,
    DestinationExitFromJSONTyped,
    DestinationExitToJSON,
    DestinationStep,
    DestinationStepFromJSON,
    DestinationStepFromJSONTyped,
    DestinationStepToJSON,
    DestinationTutorial,
    DestinationTutorialFromJSON,
    DestinationTutorialFromJSONTyped,
    DestinationTutorialToJSON,
} from './';

/**
 * 
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * 
     * @type {DestinationStep}
     * @memberof Destination
     */
    step?: DestinationStep;
    /**
     * 
     * @type {DestinationTutorial}
     * @memberof Destination
     */
    tutorial?: DestinationTutorial;
    /**
     * 
     * @type {DestinationExit}
     * @memberof Destination
     */
    exit?: DestinationExit;
}

export function DestinationFromJSON(json: any): Destination {
    return DestinationFromJSONTyped(json, false);
}

export function DestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Destination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': !exists(json, 'step') ? undefined : DestinationStepFromJSON(json['step']),
        'tutorial': !exists(json, 'tutorial') ? undefined : DestinationTutorialFromJSON(json['tutorial']),
        'exit': !exists(json, 'exit') ? undefined : DestinationExitFromJSON(json['exit']),
    };
}

export function DestinationToJSON(value?: Destination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': DestinationStepToJSON(value.step),
        'tutorial': DestinationTutorialToJSON(value.tutorial),
        'exit': DestinationExitToJSON(value.exit),
    };
}


