/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArtifactStreemshotDepthMap,
    StreemApiArtifactStreemshotDepthMapFromJSON,
    StreemApiArtifactStreemshotDepthMapFromJSONTyped,
    StreemApiArtifactStreemshotDepthMapToJSON,
    StreemApiArtifactStreemshotRevision,
    StreemApiArtifactStreemshotRevisionFromJSON,
    StreemApiArtifactStreemshotRevisionFromJSONTyped,
    StreemApiArtifactStreemshotRevisionToJSON,
    StreemApiArtifactStreemshotSource,
    StreemApiArtifactStreemshotSourceFromJSON,
    StreemApiArtifactStreemshotSourceFromJSONTyped,
    StreemApiArtifactStreemshotSourceToJSON,
    StreemApiDetection,
    StreemApiDetectionFromJSON,
    StreemApiDetectionFromJSONTyped,
    StreemApiDetectionToJSON,
    StreemApiNote,
    StreemApiNoteFromJSON,
    StreemApiNoteFromJSONTyped,
    StreemApiNoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactStreemshot
 */
export interface StreemApiArtifactStreemshot {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshot
     */
    status?: StreemApiArtifactStreemshotStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactStreemshot
     */
    requestedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshot
     */
    requestedByUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshot
     */
    requestedByDeviceSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactStreemshot
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshot
     */
    fulfilledByUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactStreemshot
     */
    fulfilledByDeviceSid?: string;
    /**
     * 
     * @type {StreemApiArtifactStreemshotSource}
     * @memberof StreemApiArtifactStreemshot
     */
    source?: StreemApiArtifactStreemshotSource;
    /**
     * 
     * @type {Array<StreemApiDetection>}
     * @memberof StreemApiArtifactStreemshot
     */
    detections?: Array<StreemApiDetection>;
    /**
     * 
     * @type {StreemApiArtifactStreemshotRevision}
     * @memberof StreemApiArtifactStreemshot
     */
    currentRevision?: StreemApiArtifactStreemshotRevision;
    /**
     * 
     * @type {number}
     * @memberof StreemApiArtifactStreemshot
     */
    revisionNumber?: number;
    /**
     * 
     * @type {StreemApiNote}
     * @memberof StreemApiArtifactStreemshot
     */
    note?: StreemApiNote;
    /**
     * 
     * @type {StreemApiArtifactStreemshotDepthMap}
     * @memberof StreemApiArtifactStreemshot
     */
    depthMap?: StreemApiArtifactStreemshotDepthMap;
}

export function StreemApiArtifactStreemshotFromJSON(json: any): StreemApiArtifactStreemshot {
    return StreemApiArtifactStreemshotFromJSONTyped(json, false);
}

export function StreemApiArtifactStreemshotFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactStreemshot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'requestedByUserSid': !exists(json, 'requested_by_user_sid') ? undefined : json['requested_by_user_sid'],
        'requestedByDeviceSid': !exists(json, 'requested_by_device_sid') ? undefined : json['requested_by_device_sid'],
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'fulfilledByUserSid': !exists(json, 'fulfilled_by_user_sid') ? undefined : json['fulfilled_by_user_sid'],
        'fulfilledByDeviceSid': !exists(json, 'fulfilled_by_device_sid') ? undefined : json['fulfilled_by_device_sid'],
        'source': !exists(json, 'source') ? undefined : StreemApiArtifactStreemshotSourceFromJSON(json['source']),
        'detections': !exists(json, 'detections') ? undefined : ((json['detections'] as Array<any>).map(StreemApiDetectionFromJSON)),
        'currentRevision': !exists(json, 'current_revision') ? undefined : StreemApiArtifactStreemshotRevisionFromJSON(json['current_revision']),
        'revisionNumber': !exists(json, 'revision_number') ? undefined : json['revision_number'],
        'note': !exists(json, 'note') ? undefined : StreemApiNoteFromJSON(json['note']),
        'depthMap': !exists(json, 'depth_map') ? undefined : StreemApiArtifactStreemshotDepthMapFromJSON(json['depth_map']),
    };
}

export function StreemApiArtifactStreemshotToJSON(value?: StreemApiArtifactStreemshot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'requested_by_user_sid': value.requestedByUserSid,
        'requested_by_device_sid': value.requestedByDeviceSid,
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'fulfilled_by_user_sid': value.fulfilledByUserSid,
        'fulfilled_by_device_sid': value.fulfilledByDeviceSid,
        'source': StreemApiArtifactStreemshotSourceToJSON(value.source),
        'detections': value.detections === undefined ? undefined : ((value.detections as Array<any>).map(StreemApiDetectionToJSON)),
        'current_revision': StreemApiArtifactStreemshotRevisionToJSON(value.currentRevision),
        'revision_number': value.revisionNumber,
        'note': StreemApiNoteToJSON(value.note),
        'depth_map': StreemApiArtifactStreemshotDepthMapToJSON(value.depthMap),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactStreemshotStatus {
    REQUESTED = 'STATUS_REQUESTED',
    FULFILLED = 'STATUS_FULFILLED',
    INVALID = 'STATUS_INVALID'
}


