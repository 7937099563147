/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiDetectionBoundingBox,
    StreemApiDetectionBoundingBoxFromJSON,
    StreemApiDetectionBoundingBoxFromJSONTyped,
    StreemApiDetectionBoundingBoxToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiDetection
 */
export interface StreemApiDetection {
    /**
     * 
     * @type {string}
     * @memberof StreemApiDetection
     */
    detectionSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDetection
     */
    type?: StreemApiDetectionType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDetection
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiDetection
     */
    confidence?: number;
    /**
     * 
     * @type {StreemApiDetectionBoundingBox}
     * @memberof StreemApiDetection
     */
    boundingBox?: StreemApiDetectionBoundingBox;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDetection
     */
    source?: StreemApiDetectionSource;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDetection
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiDetection
     */
    extractor?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiDetection
     */
    lastUpdatedAt?: Date;
}

export function StreemApiDetectionFromJSON(json: any): StreemApiDetection {
    return StreemApiDetectionFromJSONTyped(json, false);
}

export function StreemApiDetectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiDetection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detectionSid': !exists(json, 'detection_sid') ? undefined : json['detection_sid'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
        'boundingBox': !exists(json, 'bounding_box') ? undefined : StreemApiDetectionBoundingBoxFromJSON(json['bounding_box']),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'extractor': !exists(json, 'extractor') ? undefined : json['extractor'],
        'lastUpdatedAt': !exists(json, 'last_updated_at') ? undefined : (new Date(json['last_updated_at'])),
    };
}

export function StreemApiDetectionToJSON(value?: StreemApiDetection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detection_sid': value.detectionSid,
        'type': value.type,
        'description': value.description,
        'confidence': value.confidence,
        'bounding_box': StreemApiDetectionBoundingBoxToJSON(value.boundingBox),
        'source': value.source,
        'hidden': value.hidden,
        'extractor': value.extractor,
        'last_updated_at': value.lastUpdatedAt === undefined ? undefined : (value.lastUpdatedAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiDetectionType {
    LOGO = 'DETECTION_TYPE_LOGO',
    LABEL = 'DETECTION_TYPE_LABEL',
    TEXT = 'DETECTION_TYPE_TEXT',
    FULLTEXT = 'DETECTION_TYPE_FULL_TEXT',
    SAFESEARCH = 'DETECTION_TYPE_SAFE_SEARCH',
    SERIAL = 'DETECTION_TYPE_SERIAL',
    MODEL = 'DETECTION_TYPE_MODEL',
    OBJECT = 'DETECTION_TYPE_OBJECT',
    INVALID = 'DETECTION_TYPE_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiDetectionSource {
    OCR = 'DETECTION_SOURCE_OCR',
    USER = 'DETECTION_SOURCE_USER',
    AUTO = 'DETECTION_SOURCE_AUTO',
    INVALID = 'DETECTION_SOURCE_INVALID'
}


