/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiPreflight
 */
export interface StreemApiPreflight {
    /**
     * 
     * @type {string}
     * @memberof StreemApiPreflight
     */
    testRoomId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiPreflight
     */
    publisherToken?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiPreflight
     */
    subscriberToken?: string;
}

export function StreemApiPreflightFromJSON(json: any): StreemApiPreflight {
    return StreemApiPreflightFromJSONTyped(json, false);
}

export function StreemApiPreflightFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiPreflight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testRoomId': !exists(json, 'test_room_id') ? undefined : json['test_room_id'],
        'publisherToken': !exists(json, 'publisher_token') ? undefined : json['publisher_token'],
        'subscriberToken': !exists(json, 'subscriber_token') ? undefined : json['subscriber_token'],
    };
}

export function StreemApiPreflightToJSON(value?: StreemApiPreflight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'test_room_id': value.testRoomId,
        'publisher_token': value.publisherToken,
        'subscriber_token': value.subscriberToken,
    };
}


