/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSaveTermsUpdatedResponse
 */
export interface StreemApiSaveTermsUpdatedResponse {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiSaveTermsUpdatedResponse
     */
    updatedAt?: Date;
}

export function StreemApiSaveTermsUpdatedResponseFromJSON(json: any): StreemApiSaveTermsUpdatedResponse {
    return StreemApiSaveTermsUpdatedResponseFromJSONTyped(json, false);
}

export function StreemApiSaveTermsUpdatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSaveTermsUpdatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function StreemApiSaveTermsUpdatedResponseToJSON(value?: StreemApiSaveTermsUpdatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}


