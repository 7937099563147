/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiModelNo
 */
export interface StreemApiModelNo {
    /**
     * 
     * @type {string}
     * @memberof StreemApiModelNo
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiModelNo
     */
    confidence?: number;
}

export function StreemApiModelNoFromJSON(json: any): StreemApiModelNo {
    return StreemApiModelNoFromJSONTyped(json, false);
}

export function StreemApiModelNoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiModelNo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
    };
}

export function StreemApiModelNoToJSON(value?: StreemApiModelNo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'confidence': value.confidence,
    };
}


