/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiRoomParticipant
 */
export interface StreemApiRoomParticipant {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomParticipant
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomParticipant
     */
    userSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomParticipant
     */
    status?: StreemApiRoomParticipantStatus;
}

export function StreemApiRoomParticipantFromJSON(json: any): StreemApiRoomParticipant {
    return StreemApiRoomParticipantFromJSONTyped(json, false);
}

export function StreemApiRoomParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRoomParticipant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'userSid': !exists(json, 'user_sid') ? undefined : json['user_sid'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function StreemApiRoomParticipantToJSON(value?: StreemApiRoomParticipant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_sid': value.roomSid,
        'user_sid': value.userSid,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiRoomParticipantStatus {
    PENDING = 'STATUS_PENDING',
    ACTIVE = 'STATUS_ACTIVE',
    LEFT = 'STATUS_LEFT',
    NEVERARRIVED = 'STATUS_NEVER_ARRIVED',
    RECONNECTING = 'STATUS_RECONNECTING',
    CONNECTED = 'STATUS_CONNECTED',
    INVALID = 'STATUS_INVALID'
}


