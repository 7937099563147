/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TutorialRequest
 */
export interface TutorialRequest {
    /**
     * 
     * @type {string}
     * @memberof TutorialRequest
     */
    tutorialId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TutorialRequest
     */
    tutorialFiles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TutorialRequest
     */
    requestedForUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof TutorialRequest
     */
    requestedForDeviceId?: string;
}

export function TutorialRequestFromJSON(json: any): TutorialRequest {
    return TutorialRequestFromJSONTyped(json, false);
}

export function TutorialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tutorialId': !exists(json, 'tutorial_id') ? undefined : json['tutorial_id'],
        'tutorialFiles': !exists(json, 'tutorial_files') ? undefined : json['tutorial_files'],
        'requestedForUserId': !exists(json, 'requested_for_user_id') ? undefined : json['requested_for_user_id'],
        'requestedForDeviceId': !exists(json, 'requested_for_device_id') ? undefined : json['requested_for_device_id'],
    };
}

export function TutorialRequestToJSON(value?: TutorialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tutorial_id': value.tutorialId,
        'tutorial_files': value.tutorialFiles,
        'requested_for_user_id': value.requestedForUserId,
        'requested_for_device_id': value.requestedForDeviceId,
    };
}


