/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCallDetailItem
 */
export interface StreemApiCallDetailItem {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    modelNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiCallDetailItem
     */
    symptoms?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallDetailItem
     */
    tradeDescription?: string;
}

export function StreemApiCallDetailItemFromJSON(json: any): StreemApiCallDetailItem {
    return StreemApiCallDetailItemFromJSONTyped(json, false);
}

export function StreemApiCallDetailItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallDetailItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'modelNumber': !exists(json, 'model_number') ? undefined : json['model_number'],
        'symptoms': !exists(json, 'symptoms') ? undefined : json['symptoms'],
        'tradeDescription': !exists(json, 'trade_description') ? undefined : json['trade_description'],
    };
}

export function StreemApiCallDetailItemToJSON(value?: StreemApiCallDetailItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'description': value.description,
        'manufacturer': value.manufacturer,
        'brand': value.brand,
        'model_number': value.modelNumber,
        'symptoms': value.symptoms,
        'trade_description': value.tradeDescription,
    };
}


