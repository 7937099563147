/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WallItemUserDetectionFeedbackCreateDetection,
    WallItemUserDetectionFeedbackCreateDetectionFromJSON,
    WallItemUserDetectionFeedbackCreateDetectionFromJSONTyped,
    WallItemUserDetectionFeedbackCreateDetectionToJSON,
    WallItemUserDetectionFeedbackHideDetection,
    WallItemUserDetectionFeedbackHideDetectionFromJSON,
    WallItemUserDetectionFeedbackHideDetectionFromJSONTyped,
    WallItemUserDetectionFeedbackHideDetectionToJSON,
    WallItemUserDetectionFeedbackUpdateDetection,
    WallItemUserDetectionFeedbackUpdateDetectionFromJSON,
    WallItemUserDetectionFeedbackUpdateDetectionFromJSONTyped,
    WallItemUserDetectionFeedbackUpdateDetectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemUserDetectionFeedback
 */
export interface WallItemUserDetectionFeedback {
    /**
     * 
     * @type {string}
     * @memberof WallItemUserDetectionFeedback
     */
    streemshotId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemUserDetectionFeedback
     */
    submittedByUserId?: string;
    /**
     * 
     * @type {WallItemUserDetectionFeedbackCreateDetection}
     * @memberof WallItemUserDetectionFeedback
     */
    createDetection?: WallItemUserDetectionFeedbackCreateDetection;
    /**
     * 
     * @type {WallItemUserDetectionFeedbackUpdateDetection}
     * @memberof WallItemUserDetectionFeedback
     */
    updateDetection?: WallItemUserDetectionFeedbackUpdateDetection;
    /**
     * 
     * @type {WallItemUserDetectionFeedbackHideDetection}
     * @memberof WallItemUserDetectionFeedback
     */
    hideDetection?: WallItemUserDetectionFeedbackHideDetection;
}

export function WallItemUserDetectionFeedbackFromJSON(json: any): WallItemUserDetectionFeedback {
    return WallItemUserDetectionFeedbackFromJSONTyped(json, false);
}

export function WallItemUserDetectionFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemUserDetectionFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streemshotId': !exists(json, 'streemshot_id') ? undefined : json['streemshot_id'],
        'submittedByUserId': !exists(json, 'submitted_by_user_id') ? undefined : json['submitted_by_user_id'],
        'createDetection': !exists(json, 'create_detection') ? undefined : WallItemUserDetectionFeedbackCreateDetectionFromJSON(json['create_detection']),
        'updateDetection': !exists(json, 'update_detection') ? undefined : WallItemUserDetectionFeedbackUpdateDetectionFromJSON(json['update_detection']),
        'hideDetection': !exists(json, 'hide_detection') ? undefined : WallItemUserDetectionFeedbackHideDetectionFromJSON(json['hide_detection']),
    };
}

export function WallItemUserDetectionFeedbackToJSON(value?: WallItemUserDetectionFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streemshot_id': value.streemshotId,
        'submitted_by_user_id': value.submittedByUserId,
        'create_detection': WallItemUserDetectionFeedbackCreateDetectionToJSON(value.createDetection),
        'update_detection': WallItemUserDetectionFeedbackUpdateDetectionToJSON(value.updateDetection),
        'hide_detection': WallItemUserDetectionFeedbackHideDetectionToJSON(value.hideDetection),
    };
}


