import { useEffect, useState } from 'react';
import { useGlobalStore } from '../hooks/use_global_context';
import { useObserver } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { getCompanyCodeFromUrl, getHashParamValue } from '@streem/toolbox';
import { customerRedirectUrlHandler, CustomerRedirectUrlStatuses } from '@streem/sdk-react';
import { LoadingPage } from '../pages/loading_page';
import appLogger from '../util/app_logger';

const log = appLogger.extend('TokenHandler');
const STREEM_ACCESS_TOKEN = 'STREEM_ACCESS_TOKEN';

interface TokenHandlerProps {
    children?: React.ReactNode;
    configuredPackages: boolean;
    isGetCompanyRegistrationError: boolean;
}
export const TokenHandler = (props: TokenHandlerProps): JSX.Element => {
    const { appStore, configStore, sdkStore } = useGlobalStore();
    const [sdkToken, setSdkToken] = useState<string>(null);
    const [isUsingAccessToken, setIsUsingAccessToken] = useState<boolean>(false);
    const [companyCode, setCompanyCode] = useState<string>(null);
    const [isImplementationError, setIsImplementationError] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        const companyCode = getCompanyCodeFromUrl();
        const sdkToken = getHashParamValue('token');
        const accessToken = sessionStorage.getItem(STREEM_ACCESS_TOKEN);

        if (sdkToken && companyCode) {
            setSdkToken(sdkToken);
            setCompanyCode(companyCode);
            /* Removes a cached access token if it exists. If a new sdkToken is provided, we should not use
             * the cached access token because it is likely expired or uses a group reservation id that is
             * no longer valid (from a previous session).
             */
            sessionStorage.removeItem(STREEM_ACCESS_TOKEN);
            // Removes the hash from the url and updates the browser history without reloading the page
            history.replace({ hash: '' });
        } else if (accessToken && companyCode && !sdkToken) {
            setIsUsingAccessToken(true);
            setCompanyCode(companyCode);
        } else {
            log.error('Implementation Error: missing sdk token or company code');
            setIsImplementationError(true);
        }
    }, [history]);

    useEffect(() => {
        if (props.configuredPackages && companyCode) {
            if (isUsingAccessToken) {
                log.debug('Logging in with access token');
                appStore
                    .loginWithAccessToken(companyCode)
                    .catch(e => log.error('Error logging in with access token', e));
            } else if (sdkToken) {
                log.debug('Logging in with sdk token');
                appStore
                    .loginWithSdkToken(sdkToken, companyCode)
                    .catch(e => log.error('Error logging in with sdk token', e));
            }
        }
    }, [isUsingAccessToken, sdkToken, companyCode, appStore, props.configuredPackages]);

    return useObserver(() => {
        if (!configStore.initialized) {
            return <LoadingPage message="Initializing..." />;
        }

        if (appStore.error || props.isGetCompanyRegistrationError) {
            const redirectUrl = configStore.publicSettings?.endRedirectUrl;
            log.error('Error: auth or fetching company registration', {
                error: appStore.error.toString(),
                redirectUrl,
            });
            customerRedirectUrlHandler(
                redirectUrl,
                appStore.roomId,
                appStore.groupReservationSid,
                CustomerRedirectUrlStatuses.LOGIN_ERROR,
            );
        }

        if (isImplementationError) {
            log.error('Error: implementation error');
            customerRedirectUrlHandler(
                appStore.companySettings?.endRedirectUrl,
                appStore.roomId,
                appStore.groupReservationSid,
                CustomerRedirectUrlStatuses.BAD_REQUEST,
            );
        }

        if (
            !appStore.isUserSignedIn ||
            !sdkStore.userSession?.current ||
            !props.configuredPackages
        ) {
            return <LoadingPage message="Validating reservation..." />;
        }

        return <>{props.children}</>;
    });
};
