/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUser
 */
export interface StreemApiUser {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    externalUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiUser
     */
    isExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiUser
     */
    active?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiUser
     */
    roles?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiUser
     */
    activeStatusModifiedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiUser
     */
    userTypes?: Array<StreemApiUserUserTypes>;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiUser
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiUser
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUser
     */
    availabilityStatus?: StreemApiUserAvailabilityStatus;
}

export function StreemApiUserFromJSON(json: any): StreemApiUser {
    return StreemApiUserFromJSONTyped(json, false);
}

export function StreemApiUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'externalUserId': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'isExpert': !exists(json, 'is_expert') ? undefined : json['is_expert'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'activeStatusModifiedAt': !exists(json, 'active_status_modified_at') ? undefined : (new Date(json['active_status_modified_at'])),
        'userTypes': !exists(json, 'user_types') ? undefined : json['user_types'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
        'bio': !exists(json, 'bio') ? undefined : json['bio'],
        'availabilityStatus': !exists(json, 'availability_status') ? undefined : json['availability_status'],
    };
}

export function StreemApiUserToJSON(value?: StreemApiUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'company_sid': value.companySid,
        'external_user_id': value.externalUserId,
        'is_expert': value.isExpert,
        'email': value.email,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'phone': value.phone,
        'active': value.active,
        'roles': value.roles,
        'active_status_modified_at': value.activeStatusModifiedAt === undefined ? undefined : (value.activeStatusModifiedAt.toISOString()),
        'user_types': value.userTypes,
        'deleted': value.deleted,
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'bio': value.bio,
        'availability_status': value.availabilityStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiUserUserTypes {
    UNSPECIFIED = 'USER_TYPE_UNSPECIFIED',
    LOGIN = 'USER_TYPE_LOGIN',
    SDK = 'USER_TYPE_SDK',
    INVITED = 'USER_TYPE_INVITED'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiUserAvailabilityStatus {
    AVAILABLE = 'AVAILABILITY_STATUS_AVAILABLE',
    NOTAVAILABLE = 'AVAILABILITY_STATUS_NOT_AVAILABLE',
    RESERVED = 'AVAILABILITY_STATUS_RESERVED',
    INCALL = 'AVAILABILITY_STATUS_IN_CALL',
    CONFIRMING = 'AVAILABILITY_STATUS_CONFIRMING',
    OFFLINE = 'AVAILABILITY_STATUS_OFFLINE',
    INVALID = 'AVAILABILITY_STATUS_INVALID'
}


