/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiArElement,
    StreemApiArElementFromJSON,
    StreemApiArElementFromJSONTyped,
    StreemApiArElementToJSON,
    StreemApiPose,
    StreemApiPoseFromJSON,
    StreemApiPoseFromJSONTyped,
    StreemApiPoseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiArtifactSceneshot
 */
export interface StreemApiArtifactSceneshot {
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactSceneshot
     */
    meshSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactSceneshot
     */
    previewStorageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactSceneshot
     */
    name?: string;
    /**
     * 
     * @type {StreemApiPose}
     * @memberof StreemApiArtifactSceneshot
     */
    pose?: StreemApiPose;
    /**
     * 
     * @type {Array<StreemApiArElement>}
     * @memberof StreemApiArtifactSceneshot
     */
    arElements?: Array<StreemApiArElement>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiArtifactSceneshot
     */
    status?: StreemApiArtifactSceneshotStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactSceneshot
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactSceneshot
     */
    erroredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactSceneshot
     */
    fulfilledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiArtifactSceneshot
     */
    capturedAt?: Date;
}

export function StreemApiArtifactSceneshotFromJSON(json: any): StreemApiArtifactSceneshot {
    return StreemApiArtifactSceneshotFromJSONTyped(json, false);
}

export function StreemApiArtifactSceneshotFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiArtifactSceneshot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meshSid': !exists(json, 'mesh_sid') ? undefined : json['mesh_sid'],
        'previewStorageUrl': !exists(json, 'preview_storage_url') ? undefined : json['preview_storage_url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pose': !exists(json, 'pose') ? undefined : StreemApiPoseFromJSON(json['pose']),
        'arElements': !exists(json, 'ar_elements') ? undefined : ((json['ar_elements'] as Array<any>).map(StreemApiArElementFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'erroredAt': !exists(json, 'errored_at') ? undefined : (new Date(json['errored_at'])),
        'fulfilledAt': !exists(json, 'fulfilled_at') ? undefined : (new Date(json['fulfilled_at'])),
        'capturedAt': !exists(json, 'captured_at') ? undefined : (new Date(json['captured_at'])),
    };
}

export function StreemApiArtifactSceneshotToJSON(value?: StreemApiArtifactSceneshot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mesh_sid': value.meshSid,
        'preview_storage_url': value.previewStorageUrl,
        'name': value.name,
        'pose': StreemApiPoseToJSON(value.pose),
        'ar_elements': value.arElements === undefined ? undefined : ((value.arElements as Array<any>).map(StreemApiArElementToJSON)),
        'status': value.status,
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'errored_at': value.erroredAt === undefined ? undefined : (value.erroredAt.toISOString()),
        'fulfilled_at': value.fulfilledAt === undefined ? undefined : (value.fulfilledAt.toISOString()),
        'captured_at': value.capturedAt === undefined ? undefined : (value.capturedAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiArtifactSceneshotStatus {
    UNKNOWN = 'STATUS_UNKNOWN',
    REJECTED = 'STATUS_REJECTED',
    ERROR = 'STATUS_ERROR',
    STARTED = 'STATUS_STARTED',
    CAPTURED = 'STATUS_CAPTURED',
    FULFILLED = 'STATUS_FULFILLED'
}


