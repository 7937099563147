/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiRoomShareToken
 */
export interface StreemApiRoomShareToken {
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomShareToken
     */
    sid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiRoomShareToken
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomShareToken
     */
    roomSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomShareToken
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiRoomShareToken
     */
    usageCount?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiRoomShareToken
     */
    shareUrl?: string;
}

export function StreemApiRoomShareTokenFromJSON(json: any): StreemApiRoomShareToken {
    return StreemApiRoomShareTokenFromJSONTyped(json, false);
}

export function StreemApiRoomShareTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRoomShareToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'usageCount': !exists(json, 'usage_count') ? undefined : json['usage_count'],
        'shareUrl': !exists(json, 'share_url') ? undefined : json['share_url'],
    };
}

export function StreemApiRoomShareTokenToJSON(value?: StreemApiRoomShareToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'room_sid': value.roomSid,
        'label': value.label,
        'usage_count': value.usageCount,
        'share_url': value.shareUrl,
    };
}


