/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiSendInvitationRequest
 */
export interface StreemApiSendInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    fromUserSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    toUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    companyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    deliveryType?: StreemApiSendInvitationRequestDeliveryType;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSendInvitationRequest
     */
    integrationId?: string;
}

export function StreemApiSendInvitationRequestFromJSON(json: any): StreemApiSendInvitationRequest {
    return StreemApiSendInvitationRequestFromJSONTyped(json, false);
}

export function StreemApiSendInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSendInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromUserSid': !exists(json, 'from_user_sid') ? undefined : json['from_user_sid'],
        'toUserName': !exists(json, 'to_user_name') ? undefined : json['to_user_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'companyCode': !exists(json, 'company_code') ? undefined : json['company_code'],
        'deliveryType': !exists(json, 'delivery_type') ? undefined : json['delivery_type'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
    };
}

export function StreemApiSendInvitationRequestToJSON(value?: StreemApiSendInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user_sid': value.fromUserSid,
        'to_user_name': value.toUserName,
        'phone': value.phone,
        'reference_id': value.referenceId,
        'company_code': value.companyCode,
        'delivery_type': value.deliveryType,
        'integration_id': value.integrationId,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiSendInvitationRequestDeliveryType {
    SMS = 'DELIVERY_TYPE_SMS',
    EMAIL = 'DELIVERY_TYPE_EMAIL',
    LINK = 'DELIVERY_TYPE_LINK',
    INVALID = 'DELIVERY_TYPE_INVALID'
}


