/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSettingDefinition,
    StreemApiSettingDefinitionFromJSON,
    StreemApiSettingDefinitionFromJSONTyped,
    StreemApiSettingDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGetSettingDefinitionResponse
 */
export interface StreemApiGetSettingDefinitionResponse {
    /**
     * 
     * @type {StreemApiSettingDefinition}
     * @memberof StreemApiGetSettingDefinitionResponse
     */
    settingDefinition?: StreemApiSettingDefinition;
}

export function StreemApiGetSettingDefinitionResponseFromJSON(json: any): StreemApiGetSettingDefinitionResponse {
    return StreemApiGetSettingDefinitionResponseFromJSONTyped(json, false);
}

export function StreemApiGetSettingDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetSettingDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settingDefinition': !exists(json, 'setting_definition') ? undefined : StreemApiSettingDefinitionFromJSON(json['setting_definition']),
    };
}

export function StreemApiGetSettingDefinitionResponseToJSON(value?: StreemApiGetSettingDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setting_definition': StreemApiSettingDefinitionToJSON(value.settingDefinition),
    };
}


