/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRoomParticipant,
    StreemApiRoomParticipantFromJSON,
    StreemApiRoomParticipantFromJSONTyped,
    StreemApiRoomParticipantToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiListRoomParticipantsResponse
 */
export interface StreemApiListRoomParticipantsResponse {
    /**
     * 
     * @type {Array<StreemApiRoomParticipant>}
     * @memberof StreemApiListRoomParticipantsResponse
     */
    roomParticipants?: Array<StreemApiRoomParticipant>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiListRoomParticipantsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiListRoomParticipantsResponse
     */
    totalSize?: number;
}

export function StreemApiListRoomParticipantsResponseFromJSON(json: any): StreemApiListRoomParticipantsResponse {
    return StreemApiListRoomParticipantsResponseFromJSONTyped(json, false);
}

export function StreemApiListRoomParticipantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiListRoomParticipantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomParticipants': !exists(json, 'room_participants') ? undefined : ((json['room_participants'] as Array<any>).map(StreemApiRoomParticipantFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'totalSize': !exists(json, 'total_size') ? undefined : json['total_size'],
    };
}

export function StreemApiListRoomParticipantsResponseToJSON(value?: StreemApiListRoomParticipantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_participants': value.roomParticipants === undefined ? undefined : ((value.roomParticipants as Array<any>).map(StreemApiRoomParticipantToJSON)),
        'next_page_token': value.nextPageToken,
        'total_size': value.totalSize,
    };
}


