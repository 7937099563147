/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiSsoProviderOidcDiscoverEndpoints,
    StreemApiSsoProviderOidcDiscoverEndpointsFromJSON,
    StreemApiSsoProviderOidcDiscoverEndpointsFromJSONTyped,
    StreemApiSsoProviderOidcDiscoverEndpointsToJSON,
    StreemApiSsoProviderOidcExplicitEndpoints,
    StreemApiSsoProviderOidcExplicitEndpointsFromJSON,
    StreemApiSsoProviderOidcExplicitEndpointsFromJSONTyped,
    StreemApiSsoProviderOidcExplicitEndpointsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiSsoProviderOidc
 */
export interface StreemApiSsoProviderOidc {
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    clientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    emailClaim?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiSsoProviderOidc
     */
    managedDomains?: Array<string>;
    /**
     * 
     * @type {StreemApiSsoProviderOidcDiscoverEndpoints}
     * @memberof StreemApiSsoProviderOidc
     */
    discoverEndpoints?: StreemApiSsoProviderOidcDiscoverEndpoints;
    /**
     * 
     * @type {StreemApiSsoProviderOidcExplicitEndpoints}
     * @memberof StreemApiSsoProviderOidc
     */
    explicitEndpoints?: StreemApiSsoProviderOidcExplicitEndpoints;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    fusionAuthLambdaId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiSsoProviderOidc
     */
    usernameClaim?: string;
}

export function StreemApiSsoProviderOidcFromJSON(json: any): StreemApiSsoProviderOidc {
    return StreemApiSsoProviderOidcFromJSONTyped(json, false);
}

export function StreemApiSsoProviderOidcFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiSsoProviderOidc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'emailClaim': !exists(json, 'email_claim') ? undefined : json['email_claim'],
        'managedDomains': !exists(json, 'managed_domains') ? undefined : json['managed_domains'],
        'discoverEndpoints': !exists(json, 'discover_endpoints') ? undefined : StreemApiSsoProviderOidcDiscoverEndpointsFromJSON(json['discover_endpoints']),
        'explicitEndpoints': !exists(json, 'explicit_endpoints') ? undefined : StreemApiSsoProviderOidcExplicitEndpointsFromJSON(json['explicit_endpoints']),
        'fusionAuthLambdaId': !exists(json, 'fusion_auth_lambda_id') ? undefined : json['fusion_auth_lambda_id'],
        'usernameClaim': !exists(json, 'username_claim') ? undefined : json['username_claim'],
    };
}

export function StreemApiSsoProviderOidcToJSON(value?: StreemApiSsoProviderOidc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
        'scope': value.scope,
        'email_claim': value.emailClaim,
        'managed_domains': value.managedDomains,
        'discover_endpoints': StreemApiSsoProviderOidcDiscoverEndpointsToJSON(value.discoverEndpoints),
        'explicit_endpoints': StreemApiSsoProviderOidcExplicitEndpointsToJSON(value.explicitEndpoints),
        'fusion_auth_lambda_id': value.fusionAuthLambdaId,
        'username_claim': value.usernameClaim,
    };
}


