/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiPoint
 */
export interface StreemApiPoint {
    /**
     * 
     * @type {number}
     * @memberof StreemApiPoint
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiPoint
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiPoint
     */
    z?: number;
}

export function StreemApiPointFromJSON(json: any): StreemApiPoint {
    return StreemApiPointFromJSONTyped(json, false);
}

export function StreemApiPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'z': !exists(json, 'z') ? undefined : json['z'],
    };
}

export function StreemApiPointToJSON(value?: StreemApiPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
        'z': value.z,
    };
}


