/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiUpdateUserAvailabilityRequest
 */
export interface StreemApiUpdateUserAvailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiUpdateUserAvailabilityRequest
     */
    availabilityStatus?: StreemApiUpdateUserAvailabilityRequestAvailabilityStatus;
}

export function StreemApiUpdateUserAvailabilityRequestFromJSON(json: any): StreemApiUpdateUserAvailabilityRequest {
    return StreemApiUpdateUserAvailabilityRequestFromJSONTyped(json, false);
}

export function StreemApiUpdateUserAvailabilityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUpdateUserAvailabilityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availabilityStatus': !exists(json, 'availability_status') ? undefined : json['availability_status'],
    };
}

export function StreemApiUpdateUserAvailabilityRequestToJSON(value?: StreemApiUpdateUserAvailabilityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availability_status': value.availabilityStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiUpdateUserAvailabilityRequestAvailabilityStatus {
    AVAILABLE = 'AVAILABILITY_STATUS_AVAILABLE',
    NOTAVAILABLE = 'AVAILABILITY_STATUS_NOT_AVAILABLE',
    RESERVED = 'AVAILABILITY_STATUS_RESERVED',
    INCALL = 'AVAILABILITY_STATUS_IN_CALL',
    CONFIRMING = 'AVAILABILITY_STATUS_CONFIRMING',
    OFFLINE = 'AVAILABILITY_STATUS_OFFLINE',
    INVALID = 'AVAILABILITY_STATUS_INVALID'
}


