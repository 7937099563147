/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateCountryRequest,
    StreemApiCreateCountryRequestFromJSON,
    StreemApiCreateCountryRequestToJSON,
    StreemApiGetCountriesResponse,
    StreemApiGetCountriesResponseFromJSON,
    StreemApiGetCountriesResponseToJSON,
} from '../models';

export interface CreateCountryRequest {
    isoCode: string;
    streemApiCreateCountryRequest?: StreemApiCreateCountryRequest;
}

/**
 * 
 */
export class CountriesApi extends runtime.BaseAPI {

    /**
     * Creates a new country.
     * Create country
     */
    async createCountryRaw(requestParameters: CreateCountryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.isoCode === null || requestParameters.isoCode === undefined) {
            throw new runtime.RequiredError('isoCode','Required parameter requestParameters.isoCode was null or undefined when calling createCountry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/countries/{iso_code}`.replace(`{${"iso_code"}}`, encodeURIComponent(String(requestParameters.isoCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateCountryRequestToJSON(requestParameters.streemApiCreateCountryRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a new country.
     * Create country
     */
    async createCountry(isoCode: string, streemApiCreateCountryRequest?: StreemApiCreateCountryRequest): Promise<void> {
        await this.createCountryRaw({ isoCode: isoCode, streemApiCreateCountryRequest: streemApiCreateCountryRequest });
    }

    /**
     * Get list of countries.
     * Get Countries
     */
    async getCountriesRaw(): Promise<runtime.ApiResponse<StreemApiGetCountriesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetCountriesResponseFromJSON(jsonValue));
    }

    /**
     * Get list of countries.
     * Get Countries
     */
    async getCountries(): Promise<StreemApiGetCountriesResponse> {
        const response = await this.getCountriesRaw();
        return await response.value();
    }

}
