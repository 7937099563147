/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiAnalyticEventPermissionRequested
 */
export interface StreemApiAnalyticEventPermissionRequested {
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    permission?: StreemApiAnalyticEventPermissionRequestedPermission;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    granted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    inviteSid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    promptShown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    reason?: StreemApiAnalyticEventPermissionRequestedReason;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEventPermissionRequested
     */
    groupReservationSid?: string;
}

export function StreemApiAnalyticEventPermissionRequestedFromJSON(json: any): StreemApiAnalyticEventPermissionRequested {
    return StreemApiAnalyticEventPermissionRequestedFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventPermissionRequestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEventPermissionRequested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
        'granted': !exists(json, 'granted') ? undefined : json['granted'],
        'inviteSid': !exists(json, 'invite_sid') ? undefined : json['invite_sid'],
        'promptShown': !exists(json, 'prompt_shown') ? undefined : json['prompt_shown'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'groupReservationSid': !exists(json, 'group_reservation_sid') ? undefined : json['group_reservation_sid'],
    };
}

export function StreemApiAnalyticEventPermissionRequestedToJSON(value?: StreemApiAnalyticEventPermissionRequested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permission': value.permission,
        'granted': value.granted,
        'invite_sid': value.inviteSid,
        'prompt_shown': value.promptShown,
        'reason': value.reason,
        'group_reservation_sid': value.groupReservationSid,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventPermissionRequestedPermission {
    CAMERA = 'PERMISSION_CAMERA',
    MICROPHONE = 'PERMISSION_MICROPHONE',
    LOCATION = 'PERMISSION_LOCATION',
    INVALID = 'PERMISSION_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventPermissionRequestedReason {
    CALL = 'REASON_CALL',
    GUIDEDSCAN = 'REASON_GUIDED_SCAN',
    INVALID = 'REASON_INVALID'
}


