/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleApiHttpBody,
    GoogleApiHttpBodyFromJSON,
    GoogleApiHttpBodyFromJSONTyped,
    GoogleApiHttpBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiUploadCompanyCallSourceLogoRequest
 */
export interface StreemApiUploadCompanyCallSourceLogoRequest {
    /**
     * 
     * @type {GoogleApiHttpBody}
     * @memberof StreemApiUploadCompanyCallSourceLogoRequest
     */
    httpBody?: GoogleApiHttpBody;
    /**
     * 
     * @type {string}
     * @memberof StreemApiUploadCompanyCallSourceLogoRequest
     */
    fileName?: string;
}

export function StreemApiUploadCompanyCallSourceLogoRequestFromJSON(json: any): StreemApiUploadCompanyCallSourceLogoRequest {
    return StreemApiUploadCompanyCallSourceLogoRequestFromJSONTyped(json, false);
}

export function StreemApiUploadCompanyCallSourceLogoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiUploadCompanyCallSourceLogoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'httpBody': !exists(json, 'http_body') ? undefined : GoogleApiHttpBodyFromJSON(json['http_body']),
        'fileName': !exists(json, 'file_name') ? undefined : json['file_name'],
    };
}

export function StreemApiUploadCompanyCallSourceLogoRequestToJSON(value?: StreemApiUploadCompanyCallSourceLogoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'http_body': GoogleApiHttpBodyToJSON(value.httpBody),
        'file_name': value.fileName,
    };
}


