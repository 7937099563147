import { Component } from 'react';
import appLogger from '../util/app_logger';

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        const errorMessage = typeof error.toString === 'function' ? error.toString() : undefined;
        appLogger.error('ErrorBoundary triggered: ', { error, errorInfo, errorMessage });
    }

    render() {
        if (this.state.error) {
            // we can display anything we like
            // as long as it is not from our component library
            return <div></div>;
        } else {
            return this.props.children;
        }
    }
}
