/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiRecordEventsResponseFailure,
    StreemApiRecordEventsResponseFailureFromJSON,
    StreemApiRecordEventsResponseFailureFromJSONTyped,
    StreemApiRecordEventsResponseFailureToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiRecordEventsResponse
 */
export interface StreemApiRecordEventsResponse {
    /**
     * 
     * @type {Array<StreemApiRecordEventsResponseFailure>}
     * @memberof StreemApiRecordEventsResponse
     */
    failures?: Array<StreemApiRecordEventsResponseFailure>;
}

export function StreemApiRecordEventsResponseFromJSON(json: any): StreemApiRecordEventsResponse {
    return StreemApiRecordEventsResponseFromJSONTyped(json, false);
}

export function StreemApiRecordEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiRecordEventsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failures': !exists(json, 'failures') ? undefined : ((json['failures'] as Array<any>).map(StreemApiRecordEventsResponseFailureFromJSON)),
    };
}

export function StreemApiRecordEventsResponseToJSON(value?: StreemApiRecordEventsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failures': value.failures === undefined ? undefined : ((value.failures as Array<any>).map(StreemApiRecordEventsResponseFailureToJSON)),
    };
}


