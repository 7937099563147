/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufValue,
    GoogleProtobufValueFromJSON,
    GoogleProtobufValueFromJSONTyped,
    GoogleProtobufValueToJSON,
    StreemApiArtifactRoomOutcomeReportEntry,
    StreemApiArtifactRoomOutcomeReportEntryFromJSON,
    StreemApiArtifactRoomOutcomeReportEntryFromJSONTyped,
    StreemApiArtifactRoomOutcomeReportEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateRoomOutcomeReportRequest
 */
export interface StreemApiCreateRoomOutcomeReportRequest {
    /**
     * 
     * @type {Array<StreemApiArtifactRoomOutcomeReportEntry>}
     * @memberof StreemApiCreateRoomOutcomeReportRequest
     */
    diagnoses?: Array<StreemApiArtifactRoomOutcomeReportEntry>;
    /**
     * 
     * @type {StreemApiArtifactRoomOutcomeReportEntry}
     * @memberof StreemApiCreateRoomOutcomeReportRequest
     */
    disposition?: StreemApiArtifactRoomOutcomeReportEntry;
    /**
     * 
     * @type {{ [key: string]: GoogleProtobufValue; }}
     * @memberof StreemApiCreateRoomOutcomeReportRequest
     */
    customFields?: { [key: string]: GoogleProtobufValue; };
}

export function StreemApiCreateRoomOutcomeReportRequestFromJSON(json: any): StreemApiCreateRoomOutcomeReportRequest {
    return StreemApiCreateRoomOutcomeReportRequestFromJSONTyped(json, false);
}

export function StreemApiCreateRoomOutcomeReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateRoomOutcomeReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diagnoses': !exists(json, 'diagnoses') ? undefined : ((json['diagnoses'] as Array<any>).map(StreemApiArtifactRoomOutcomeReportEntryFromJSON)),
        'disposition': !exists(json, 'disposition') ? undefined : StreemApiArtifactRoomOutcomeReportEntryFromJSON(json['disposition']),
        'customFields': !exists(json, 'custom_fields') ? undefined : (mapValues(json['custom_fields'], GoogleProtobufValueFromJSON)),
    };
}

export function StreemApiCreateRoomOutcomeReportRequestToJSON(value?: StreemApiCreateRoomOutcomeReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diagnoses': value.diagnoses === undefined ? undefined : ((value.diagnoses as Array<any>).map(StreemApiArtifactRoomOutcomeReportEntryToJSON)),
        'disposition': StreemApiArtifactRoomOutcomeReportEntryToJSON(value.disposition),
        'custom_fields': value.customFields === undefined ? undefined : (mapValues(value.customFields, GoogleProtobufValueToJSON)),
    };
}


