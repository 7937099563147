/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiTransform,
    StreemApiTransformFromJSON,
    StreemApiTransformFromJSONTyped,
    StreemApiTransformToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemARElementText
 */
export interface WallItemARElementText {
    /**
     * 
     * @type {StreemApiTransform}
     * @memberof WallItemARElementText
     */
    transform?: StreemApiTransform;
    /**
     * 
     * @type {number}
     * @memberof WallItemARElementText
     */
    rotation?: number;
    /**
     * 
     * @type {string}
     * @memberof WallItemARElementText
     */
    text?: string;
}

export function WallItemARElementTextFromJSON(json: any): WallItemARElementText {
    return WallItemARElementTextFromJSONTyped(json, false);
}

export function WallItemARElementTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemARElementText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transform': !exists(json, 'transform') ? undefined : StreemApiTransformFromJSON(json['transform']),
        'rotation': !exists(json, 'rotation') ? undefined : json['rotation'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function WallItemARElementTextToJSON(value?: WallItemARElementText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transform': StreemApiTransformToJSON(value.transform),
        'rotation': value.rotation,
        'text': value.text,
    };
}


