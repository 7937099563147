/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiGetGroupReservationWaitTimeResponse
 */
export interface StreemApiGetGroupReservationWaitTimeResponse {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGetGroupReservationWaitTimeResponse
     */
    estimatedWaitTime?: Date;
}

export function StreemApiGetGroupReservationWaitTimeResponseFromJSON(json: any): StreemApiGetGroupReservationWaitTimeResponse {
    return StreemApiGetGroupReservationWaitTimeResponseFromJSONTyped(json, false);
}

export function StreemApiGetGroupReservationWaitTimeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGetGroupReservationWaitTimeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimatedWaitTime': !exists(json, 'estimated_wait_time') ? undefined : (new Date(json['estimated_wait_time'])),
    };
}

export function StreemApiGetGroupReservationWaitTimeResponseToJSON(value?: StreemApiGetGroupReservationWaitTimeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimated_wait_time': value.estimatedWaitTime === undefined ? undefined : (value.estimatedWaitTime.toISOString()),
    };
}


