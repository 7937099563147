import { invariant } from './';

interface IOSVersion {
    major: number;
    minor: number;
    patch: number;
}

export function getIosVersionFromUserAgent(userAgent: string): IOSVersion {
    // Parses the major and minor version off the user agent data and converts it into a number
    const platformVersionMatchArray = userAgent.match(/OS (\w+)/) || [];
    invariant(
        platformVersionMatchArray.length === 2,
        'iOS version not found in provided user agent.',
    );

    const platformVersion = platformVersionMatchArray[1];
    const platformVersionAsArray = platformVersion.split('_');
    const platformMajorVersion = parseInt(platformVersionAsArray[0]);
    const platformMinorVersion = parseInt(platformVersionAsArray[1]);
    const platformPatchVersion = parseInt(platformVersionAsArray[2]) || 0;
    return {
        major: platformMajorVersion,
        minor: platformMinorVersion,
        patch: platformPatchVersion,
    };
}
