/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WallItemARElement,
    WallItemARElementFromJSON,
    WallItemARElementFromJSONTyped,
    WallItemARElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemStreemshotRevision
 */
export interface WallItemStreemshotRevision {
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRevision
     */
    downloadUrl?: string;
    /**
     * 
     * @type {Array<WallItemARElement>}
     * @memberof WallItemStreemshotRevision
     */
    arElements?: Array<WallItemARElement>;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRevision
     */
    storageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotRevision
     */
    streemshotId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WallItemStreemshotRevision
     */
    edited?: boolean;
}

export function WallItemStreemshotRevisionFromJSON(json: any): WallItemStreemshotRevision {
    return WallItemStreemshotRevisionFromJSONTyped(json, false);
}

export function WallItemStreemshotRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemStreemshotRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'downloadUrl': !exists(json, 'download_url') ? undefined : json['download_url'],
        'arElements': !exists(json, 'ar_elements') ? undefined : ((json['ar_elements'] as Array<any>).map(WallItemARElementFromJSON)),
        'storageUrl': !exists(json, 'storage_url') ? undefined : json['storage_url'],
        'streemshotId': !exists(json, 'streemshot_id') ? undefined : json['streemshot_id'],
        'edited': !exists(json, 'edited') ? undefined : json['edited'],
    };
}

export function WallItemStreemshotRevisionToJSON(value?: WallItemStreemshotRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'download_url': value.downloadUrl,
        'ar_elements': value.arElements === undefined ? undefined : ((value.arElements as Array<any>).map(WallItemARElementToJSON)),
        'storage_url': value.storageUrl,
        'streemshot_id': value.streemshotId,
        'edited': value.edited,
    };
}


