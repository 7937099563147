/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCreateGroupReservationRequestCallDetailCustomer,
    StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSON,
    StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSONTyped,
    StreemApiCreateGroupReservationRequestCallDetailCustomerToJSON,
    StreemApiCreateGroupReservationRequestCallDetailItem,
    StreemApiCreateGroupReservationRequestCallDetailItemFromJSON,
    StreemApiCreateGroupReservationRequestCallDetailItemFromJSONTyped,
    StreemApiCreateGroupReservationRequestCallDetailItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateGroupReservationRequestCallDetail
 */
export interface StreemApiCreateGroupReservationRequestCallDetail {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequestCallDetail
     */
    callSourceCode?: string;
    /**
     * 
     * @type {Array<StreemApiCreateGroupReservationRequestCallDetailItem>}
     * @memberof StreemApiCreateGroupReservationRequestCallDetail
     */
    items?: Array<StreemApiCreateGroupReservationRequestCallDetailItem>;
    /**
     * 
     * @type {StreemApiCreateGroupReservationRequestCallDetailCustomer}
     * @memberof StreemApiCreateGroupReservationRequestCallDetail
     */
    customer?: StreemApiCreateGroupReservationRequestCallDetailCustomer;
}

export function StreemApiCreateGroupReservationRequestCallDetailFromJSON(json: any): StreemApiCreateGroupReservationRequestCallDetail {
    return StreemApiCreateGroupReservationRequestCallDetailFromJSONTyped(json, false);
}

export function StreemApiCreateGroupReservationRequestCallDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateGroupReservationRequestCallDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callSourceCode': !exists(json, 'call_source_code') ? undefined : json['call_source_code'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(StreemApiCreateGroupReservationRequestCallDetailItemFromJSON)),
        'customer': !exists(json, 'customer') ? undefined : StreemApiCreateGroupReservationRequestCallDetailCustomerFromJSON(json['customer']),
    };
}

export function StreemApiCreateGroupReservationRequestCallDetailToJSON(value?: StreemApiCreateGroupReservationRequestCallDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'call_source_code': value.callSourceCode,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(StreemApiCreateGroupReservationRequestCallDetailItemToJSON)),
        'customer': StreemApiCreateGroupReservationRequestCallDetailCustomerToJSON(value.customer),
    };
}


