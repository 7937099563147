/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiDeleteCompanyAndAssetsRequest
 */
export interface StreemApiDeleteCompanyAndAssetsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    deactivateCompany?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    rooms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    roomNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    roomRecordings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    meshes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    streemshots?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    streemshotNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    gpsPositions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    deactivateUsers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiDeleteCompanyAndAssetsRequest
     */
    sceneshots?: boolean;
}

export function StreemApiDeleteCompanyAndAssetsRequestFromJSON(json: any): StreemApiDeleteCompanyAndAssetsRequest {
    return StreemApiDeleteCompanyAndAssetsRequestFromJSONTyped(json, false);
}

export function StreemApiDeleteCompanyAndAssetsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiDeleteCompanyAndAssetsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deactivateCompany': !exists(json, 'deactivate_company') ? undefined : json['deactivate_company'],
        'rooms': !exists(json, 'rooms') ? undefined : json['rooms'],
        'roomNotes': !exists(json, 'room_notes') ? undefined : json['room_notes'],
        'roomRecordings': !exists(json, 'room_recordings') ? undefined : json['room_recordings'],
        'meshes': !exists(json, 'meshes') ? undefined : json['meshes'],
        'streemshots': !exists(json, 'streemshots') ? undefined : json['streemshots'],
        'streemshotNotes': !exists(json, 'streemshot_notes') ? undefined : json['streemshot_notes'],
        'gpsPositions': !exists(json, 'gps_positions') ? undefined : json['gps_positions'],
        'deactivateUsers': !exists(json, 'deactivate_users') ? undefined : json['deactivate_users'],
        'sceneshots': !exists(json, 'sceneshots') ? undefined : json['sceneshots'],
    };
}

export function StreemApiDeleteCompanyAndAssetsRequestToJSON(value?: StreemApiDeleteCompanyAndAssetsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deactivate_company': value.deactivateCompany,
        'rooms': value.rooms,
        'room_notes': value.roomNotes,
        'room_recordings': value.roomRecordings,
        'meshes': value.meshes,
        'streemshots': value.streemshots,
        'streemshot_notes': value.streemshotNotes,
        'gps_positions': value.gpsPositions,
        'deactivate_users': value.deactivateUsers,
        'sceneshots': value.sceneshots,
    };
}


