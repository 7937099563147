/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiDetectionBoundingBox,
    StreemApiDetectionBoundingBoxFromJSON,
    StreemApiDetectionBoundingBoxFromJSONTyped,
    StreemApiDetectionBoundingBoxToJSON,
} from './';

/**
 * 
 * @export
 * @interface WallItemStreemshotDetectionsDetection
 */
export interface WallItemStreemshotDetectionsDetection {
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    detectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    type?: WallItemStreemshotDetectionsDetectionType;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    confidence?: number;
    /**
     * 
     * @type {StreemApiDetectionBoundingBox}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    boundingBox?: StreemApiDetectionBoundingBox;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    source?: WallItemStreemshotDetectionsDetectionSource;
    /**
     * 
     * @type {string}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    extractor?: string;
    /**
     * 
     * @type {Date}
     * @memberof WallItemStreemshotDetectionsDetection
     */
    lastUpdatedAt?: Date;
}

export function WallItemStreemshotDetectionsDetectionFromJSON(json: any): WallItemStreemshotDetectionsDetection {
    return WallItemStreemshotDetectionsDetectionFromJSONTyped(json, false);
}

export function WallItemStreemshotDetectionsDetectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallItemStreemshotDetectionsDetection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detectionId': !exists(json, 'detection_id') ? undefined : json['detection_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
        'boundingBox': !exists(json, 'bounding_box') ? undefined : StreemApiDetectionBoundingBoxFromJSON(json['bounding_box']),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'extractor': !exists(json, 'extractor') ? undefined : json['extractor'],
        'lastUpdatedAt': !exists(json, 'last_updated_at') ? undefined : (new Date(json['last_updated_at'])),
    };
}

export function WallItemStreemshotDetectionsDetectionToJSON(value?: WallItemStreemshotDetectionsDetection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detection_id': value.detectionId,
        'type': value.type,
        'description': value.description,
        'confidence': value.confidence,
        'bounding_box': StreemApiDetectionBoundingBoxToJSON(value.boundingBox),
        'source': value.source,
        'extractor': value.extractor,
        'last_updated_at': value.lastUpdatedAt === undefined ? undefined : (value.lastUpdatedAt.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum WallItemStreemshotDetectionsDetectionType {
    LOGO = 'DETECTION_TYPE_LOGO',
    LABEL = 'DETECTION_TYPE_LABEL',
    TEXT = 'DETECTION_TYPE_TEXT',
    FULLTEXT = 'DETECTION_TYPE_FULL_TEXT',
    SAFESEARCH = 'DETECTION_TYPE_SAFE_SEARCH',
    SERIAL = 'DETECTION_TYPE_SERIAL',
    MODEL = 'DETECTION_TYPE_MODEL',
    OBJECT = 'DETECTION_TYPE_OBJECT',
    INVALID = 'DETECTION_TYPE_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum WallItemStreemshotDetectionsDetectionSource {
    OCR = 'DETECTION_SOURCE_OCR',
    USER = 'DETECTION_SOURCE_USER',
    AUTO = 'DETECTION_SOURCE_AUTO',
    INVALID = 'DETECTION_SOURCE_INVALID'
}


