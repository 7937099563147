import { Box, PoweredByStreem, styled } from '@streem/ui-react';

export const PoweredByStreemFooter = () => {
    return (
        <PoweredByStreemBox>
            <PoweredByStreem />
        </PoweredByStreemBox>
    );
};

const PoweredByStreemBox = styled(Box)`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
`;
