/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCustomTheme,
    StreemApiCustomThemeFromJSON,
    StreemApiCustomThemeFromJSONTyped,
    StreemApiCustomThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateCompanyCallSourceRequest
 */
export interface StreemApiCreateCompanyCallSourceRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyCallSourceRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateCompanyCallSourceRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCreateCompanyCallSourceRequest
     */
    maxDiagnoses?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCreateCompanyCallSourceRequest
     */
    isDefault?: boolean;
    /**
     * 
     * @type {StreemApiCustomTheme}
     * @memberof StreemApiCreateCompanyCallSourceRequest
     */
    customTheme?: StreemApiCustomTheme;
}

export function StreemApiCreateCompanyCallSourceRequestFromJSON(json: any): StreemApiCreateCompanyCallSourceRequest {
    return StreemApiCreateCompanyCallSourceRequestFromJSONTyped(json, false);
}

export function StreemApiCreateCompanyCallSourceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateCompanyCallSourceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'maxDiagnoses': !exists(json, 'max_diagnoses') ? undefined : json['max_diagnoses'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'customTheme': !exists(json, 'custom_theme') ? undefined : StreemApiCustomThemeFromJSON(json['custom_theme']),
    };
}

export function StreemApiCreateCompanyCallSourceRequestToJSON(value?: StreemApiCreateCompanyCallSourceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'max_diagnoses': value.maxDiagnoses,
        'is_default': value.isDefault,
        'custom_theme': StreemApiCustomThemeToJSON(value.customTheme),
    };
}


