/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiCallLogParticipant,
    StreemApiCallLogParticipantFromJSON,
    StreemApiCallLogParticipantFromJSONTyped,
    StreemApiCallLogParticipantToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCallLogRoomInvitation
 */
export interface StreemApiCallLogRoomInvitation {
    /**
     * 
     * @type {StreemApiCallLogParticipant}
     * @memberof StreemApiCallLogRoomInvitation
     */
    fromUser?: StreemApiCallLogParticipant;
    /**
     * 
     * @type {StreemApiCallLogParticipant}
     * @memberof StreemApiCallLogRoomInvitation
     */
    toUser?: StreemApiCallLogParticipant;
}

export function StreemApiCallLogRoomInvitationFromJSON(json: any): StreemApiCallLogRoomInvitation {
    return StreemApiCallLogRoomInvitationFromJSONTyped(json, false);
}

export function StreemApiCallLogRoomInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallLogRoomInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromUser': !exists(json, 'from_user') ? undefined : StreemApiCallLogParticipantFromJSON(json['from_user']),
        'toUser': !exists(json, 'to_user') ? undefined : StreemApiCallLogParticipantFromJSON(json['to_user']),
    };
}

export function StreemApiCallLogRoomInvitationToJSON(value?: StreemApiCallLogRoomInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_user': StreemApiCallLogParticipantToJSON(value.fromUser),
        'to_user': StreemApiCallLogParticipantToJSON(value.toUser),
    };
}


