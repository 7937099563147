/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiListCompaniesForLoginIdentityResponse,
    StreemApiListCompaniesForLoginIdentityResponseFromJSON,
    StreemApiListCompaniesForLoginIdentityResponseToJSON,
} from '../models';

export interface ListCompaniesForLoginIdentityRequest {
    loginIdentitySid: string;
    switchWorkspacesEnabled?: boolean;
}

/**
 * 
 */
export class LoginIdentitiesApi extends runtime.BaseAPI {

    /**
     */
    async listCompaniesForLoginIdentityRaw(requestParameters: ListCompaniesForLoginIdentityRequest): Promise<runtime.ApiResponse<StreemApiListCompaniesForLoginIdentityResponse>> {
        if (requestParameters.loginIdentitySid === null || requestParameters.loginIdentitySid === undefined) {
            throw new runtime.RequiredError('loginIdentitySid','Required parameter requestParameters.loginIdentitySid was null or undefined when calling listCompaniesForLoginIdentity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.switchWorkspacesEnabled !== undefined) {
            queryParameters['switch_workspaces_enabled'] = requestParameters.switchWorkspacesEnabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/login-identities/{login_identity_sid}/companies`.replace(`{${"login_identity_sid"}}`, encodeURIComponent(String(requestParameters.loginIdentitySid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListCompaniesForLoginIdentityResponseFromJSON(jsonValue));
    }

    /**
     */
    async listCompaniesForLoginIdentity(loginIdentitySid: string, switchWorkspacesEnabled?: boolean): Promise<StreemApiListCompaniesForLoginIdentityResponse> {
        const response = await this.listCompaniesForLoginIdentityRaw({ loginIdentitySid: loginIdentitySid, switchWorkspacesEnabled: switchWorkspacesEnabled });
        return await response.value();
    }

}
