/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PickListOption,
    PickListOptionFromJSON,
    PickListOptionFromJSONTyped,
    PickListOptionToJSON,
    PickListTheme,
    PickListThemeFromJSON,
    PickListThemeFromJSONTyped,
    PickListThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PickListPresentation
 */
export interface PickListPresentation {
    /**
     * 
     * @type {Array<PickListOption>}
     * @memberof PickListPresentation
     */
    options?: Array<PickListOption>;
    /**
     * 
     * @type {PickListTheme}
     * @memberof PickListPresentation
     */
    themeOverride?: PickListTheme;
}

export function PickListPresentationFromJSON(json: any): PickListPresentation {
    return PickListPresentationFromJSONTyped(json, false);
}

export function PickListPresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickListPresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(PickListOptionFromJSON)),
        'themeOverride': !exists(json, 'theme_override') ? undefined : PickListThemeFromJSON(json['theme_override']),
    };
}

export function PickListPresentationToJSON(value?: PickListPresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(PickListOptionToJSON)),
        'theme_override': PickListThemeToJSON(value.themeOverride),
    };
}


